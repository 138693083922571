const request = require('./requestWrapper');

const syncGradesHost = '';

module.exports.getUploadParameters = function (school, file, callback) {
    const params = {
        fileType: file.syncFile.internalName,
        extension: file.syncFile.fileExtension,
        fileName: file.fileName.replace('.xlsx', ''),
        interactionDate: file.syncFile.interactionDate,
    };
    request
        .post(`${syncGradesHost}/api/schools/${school.schoolId}/terms/${school.schoolTermId}/upload/form`)
        .send(params)
        .end((err, res) => {
            callback(err, res.body);
        });
};

module.exports.import = function (school, file, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${school.schoolId}/terms/${school.schoolTermId}/interactions/${file.syncFile.interactionDate}/import`)
        .send({
            bucketFolder: file.bucketPath,
            localFileName: file.fileName.replace('.xlsx', ''),
        })
        .end((err, res) => {
            callback(err, res.body);
        });
};

module.exports.export = function (user, interactionDate, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/interactions/${interactionDate}/export`)
        .send()
        .end((err, res) => {
            callback(err, res.body);
        });
};

module.exports.list = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/interactions`)
        .end((err, res) => {
            callback(err, res.body);
        });
};
