/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listForSchool from './listForSchool';
import createTerm from './createTerm';

export {
    listForSchool,
    createTerm,
 };
