import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadCourseSections(commit, state, callback) {
    const {
        schoolId, schoolTermId,
    } = state.user.school;

    network.courseSections.listCourseSections({ url: { schoolId, schoolTermId } }, (err, res) => {
        if (err) return callback(err);
        const { courseSections } = res;
        commit(Types.mutations.SET_DB_COURSE_SECTIONS, courseSections);
        callback();
    });
}
