function Student(props) {
    this.studentId = props.studentId;
    this.studentEnrollmentId = props.studentEnrollmentId;
    this.schoolTermId = props.schoolTermId;
    this.pictureGuid = props.pictureGuid;
    this.extStudentId = props.extStudentId;
    this.schoolEmail = props.schoolEmail;
    this.googleEmail = props.googleEmail;
    this.lastName = props.lastName;
    this.firstName = props.firstName;
    this.cohortYear = props.cohortYear;
    this.nickname = props.nickname;
    this.gender = props.gender;
    this.dob = props.dob;
    this.gradeLevel = props.gradeLevel;
    this.homeRoom = props.homeRoom;
    this.primaryGuardian = props.primaryGuardian;
    this.street = props.street;
    this.city = props.city;
    this.state = props.state;
    this.zip = props.zip;
    this.phoneNumber = props.phoneNumber;
    this.emergencyPhoneNumber = props.emergencyPhoneNumber;
    this.specialEd = props.specialEd;
    this.physicalDisability504 = props.physicalDisability504;
    this.homeLanguage = props.homeLanguage;
    this.englishLearner = props.englishLearner;
    this.englishLearnerDescription = props.englishLearnerDescription;
    this.deleted = props.deleted;
    this.maskedStudentName = props.maskedStudentName;
    this.unlinkedGoogleEmail = props.unlinkedGoogleEmail;

    this.googleStudentId = props.googleStudentId || null;
    this.googleUserId = props.googleUserId || null;
    this.googlePhotoUrl = props.googlePhotoUrl || props.photoUrl || null;
    this.googleEmailAddress = props.googleEmailAddress || props.emailAddress || null;

    this.isEncrypted = props.isEncrypted;
    this.encryptedData = props.encryptedData;
    this.encryptedIdentifiers = props.encryptedIdentifiers;

    this.studentAvatar = {
        circleColor: props.circleColor || null,
        topType: props.topType || null,
        accessoriesType: props.accessoriesType || null,
        facialHairType: props.facialHairType || null,
        clotheType: props.clotheType || null,
        eyeType: props.eyeType || null,
        eyebrowType: props.eyebrowType || null,
        mouthType: props.mouthType || null,
        skinColor: props.skinColor || null,
        graphicType: props.graphicType || null,
        hairColor: props.hairColor || null,
        facialHairColor: props.facialHairColor || null,
        topColor: props.topColor || null,
        clotheColor: props.clotheColor || null,
    };

}

module.exports = Student;
