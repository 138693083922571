export default {
    userId: 0,
    userName: '',
    mappedUserName: '',
    mappedUserId: '',
    firstName: '',
    lastName: '',
    isDemoUser: false,
    hasLocalPassword: false,
    isSuperAdmin: false,
    unlinkedGoogleEmail: '',
    googleUserId: 0,
    googleAccountId: 0,
    googleAccountUserId: 0,
    googleEmail: '',
    localStorageKey: null,
    version: "0.0.0",
    awsImageUrl: null,
    school: {
        serverSideEncryptionEnabled: false,
        clientSideEncryptionEnabled: false,
        externalSchoolId: '',
        schoolStaffId: 0,
        externalDistrictId: '',
        districtId: 0,
        schoolName: '',
        schoolId: 0,
        schoolTermId: 0,
        schoolYear: 0,
        schoolTerm: '',
        name: '',
        termStart: '',
        termEnd: '',
        role: '',
        hasCourses: false,
        finalized: false,
        current: false,
        requireTwoFactorAuth: false,
        encryptionHash: null,
        showSchoolPasswordModal: false,
        googleSetting: null,
        googleAccountId: null,
    },
    subscription: {
        adminLocked: false,
        anecdotalsEnabled: false,
        gradingEnabled: false,
        encryptionEnabled: false,
        reportCardsEnabled: false,
        communicationEnabled: false,
        communicationPlan: '',
        emailQuota: 0,
        smsQuota: 0,
    },
    userPermissions: {
        canManageCourses: false,
        editSchoolAnecdotalEvents: false,
        accessAllAnecdotalEvents: false,
        accessAllTeacherPortfolios: false,
        accessAllStudentPortfolios: false,
        canChooseGradeTemplate: false,
        canManagePurchaseOrders: false,
        canSubmitBugReports: false,
        canMessageSchoolUsers: false,
        canMessageHomeUsers: false,
    },
    twoFactorAuth: {
        cipherEpochExpiry: null,
        cipherSessionId: null,
        oneTimePassword: null,
        twoFactorAuthEnabled: false,
        twoFactorAuthMethods: [],
        userTwoFactorAuthMethodId: null,
        verificationKey: null,
    },
};
