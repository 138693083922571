const userMixins = {
    computed: {
        $_userMixins_user() {
            return getSessionUser(this.$store.state);
        },
        $_userMixins_userName() {
            const { user } = this.$store.state;
            return user.mappedUserName || user.userName;
        },
        $_userMixins_schoolEmail() {
            const { user } = this.$store.state;
            return user.mappedUserName || user.userName;
        },
        $_userMixins_googleEmail() {
            const { user } = this.$store.state;
            return user.googleEmail;
        },
        $_userMixins_unlinkedGoogleEmail() {
            const { user } = this.$store.state;
            return user.unlinkedGoogleEmail;
        },
        $_userMixins_isDemoSchool() {
            return this.$store.state.user.school.externalSchoolId == '00X000';
        },
        $_userMixins_school() {
            return this.$store.state.user.school;
        },
        $_userMixins_userRole() {
            return this.$store.state.user.school.role;
        },
        $_userMixins_isGoogleDisabled() {
            return ['District Managed', 'Disabled'].includes(this.$store.state.user.school.googleSetting);
        },
        $_userMixins_isGoogleConfigured() {
            const { role } = this.$store.state.user.school;
            const { googleEmail, unlinkedGoogleEmail } = this.$store.state.user;
            if (!unlinkedGoogleEmail) return true; // google is configured because no unlinked email is assigned
            return Boolean(unlinkedGoogleEmail && googleEmail && ['School Admin', 'Staff', 'Teacher'].includes(role));
        },
        $_userMixins_googleDomain() {
            const { role } = this.$store.state.user.school;
            const { googleEmail } = this.$store.state.user;
            if (googleEmail && ['School Admin', 'Staff', 'Teacher'].includes(role)) {
                return `@${googleEmail.split('@')[1]}`;
            }
            return null;
        },
        $_userMixins_isTeacher() {
            const { user } = this.$store.state;
            if (!isSchoolUser(user)) return false;
            const { schoolStaffId } = this.$store.state.user.school;
            const { courseSectionTeachers } = this.$store.state.database;
            return Boolean(courseSectionTeachers.find((row) => row.schoolStaffId == schoolStaffId));
        },
        $_userMixins_isHomeUser() {
            const { user } = this.$store.state;
            return isHomeUser(user);
        },
        $_userMixins_isGuardian() {
            const { user } = this.$store.state;
            const { role } = user.school;
            return ['Guardian'].includes(role);
        },
        $_userMixins_isSchoolUser() {
            const { user } = this.$store.state;
            return isSchoolUser(user);
        },
        $_userMixins_isSchoolAdmin() {
            const { role } = this.$store.state.user.school;
            return role == 'School Admin';
        },
        $_userMixins_isSuperAdmin() {
            return this.$store.state.user.isSuperAdmin;
        },
        $_userMixins_isDelinquent() {
            return this.$store.state.user.access.delinquent;
        },
        $_userMixins_isDisabled() {
            return this.$store.state.user.access.disabled;
        },
    },
};

function getSessionUser(state) {
    return { ...state.user };
}

function isSchoolUser(user) {
    const { role } = user.school;
    return ['School Admin', 'Staff', 'Teacher'].includes(role);
}

function isHomeUser(user) {
    const { role } = user.school;
    return ['Guardian', 'Student'].includes(role);
}

export {
    userMixins as default,
    getSessionUser,
    isSchoolUser,
    isHomeUser,
};
