import moment from 'moment';
import Types from '../Types';
import navigation from './navigation';
import gradeTemplatesMutations from './gradeTemplates';
import reportCards from './reportCards';
import queueRequests from './queueRequests';
import user from './user';
import locale from './locale';

import search from './search';
import login from './login';

import SchoolAttendance from '../../../../server/models/SchoolAttendance';
import AnecdotalEvent from '../../../../server/models/AnecdotalEvent';
import AnecdotalEventCategory from '../../../../server/models/AnecdotalEventCategory';
import Badge from '../../../../server/models/Badge';
import Cache from '../../../../server/models/Cache';
import CommunicationGroup from '../../../../server/models/CommunicationGroup';
import CourseSection from '../../../../server/models/CourseSection';
import CourseSectionAttendance from '../../../../server/models/CourseSectionAttendance';
import CourseSectionGradeTemplate from '../../../../server/models/CourseSectionGradeTemplate';
import CourseSectionStudent from '../../../../server/models/CourseSectionStudent';
import CourseSectionTeacher from '../../../../server/models/CourseSectionTeacher';
import CourseSectionTeacherMetadata from '../../../../server/models/CourseSectionTeacherMetadata';
import CourseWork from '../../../../server/models/CourseWork';
import CourseWorkGrade from '../../../../server/models/CourseWorkGrade';
import DataSystem from '../../../../server/models/DataSystem';
import GoogleCourse from '../../../../server/models/GoogleCourse';
import GoogleCourseCourseSection from '../../../../server/models/GoogleCourseCourseSection';
import GoogleCourseGradeCategory from '../../../../server/models/GoogleCourseGradeCategory';
import GoogleCourseStudent from '../../../../server/models/GoogleCourseStudent';
import GoogleCourseTeacher from '../../../../server/models/GoogleCourseTeacher';
import GoogleCourseTopic from '../../../../server/models/GoogleCourseTopic';
import GoogleCourseWork from '../../../../server/models/GoogleCourseWork';
import GoogleStudent from '../../../../server/models/GoogleStudent';
import GoogleStudentSubmission from '../../../../server/models/GoogleStudentSubmission';
import GoogleTeacher from '../../../../server/models/GoogleTeacher';
import GradeTemplate from '../../../../server/models/GradeTemplate';
import MarkingPeriod from '../../../../server/models/MarkingPeriod';
import MarkingPeriodStudentAverage from '../../../../server/models/MarkingPeriodStudentAverage';
import Message from '../../../../server/models/Message';
import School from '../../../../server/models/School';
import SchoolStaff from '../../../../server/models/SchoolStaff';
import SchoolTerm from '../../../../server/models/SchoolTerm';
import StaffUser from '../../../../server/models/StaffUser';
import Student from '../../../../server/models/Student';
import StudentAggregate from '../../../../server/models/StudentAggregate';
import StudentBadge from '../../../../server/models/StudentBadge';
import StudentGuardian from '../../../../server/models/StudentGuardian';
import StudentMarkingPeriodGrade from '../../../../server/models/StudentMarkingPeriodGrade';
import StudentGroup from '../../../../server/models/StudentGroup';
import StudentPdfReport from '../../../../server/models/StudentPdfReport';
import Teacher from '../../../../server/models/Teacher';
import UserRole from '../../../../server/models/UserRole';

const legacyMutations = {
    ...navigation,
    ...gradeTemplatesMutations,
    // ...reportCards,
    ...queueRequests,
    ...locale,
    ...search,
    ...login,
};

const mutations = {
    ...user,
    ...reportCards,
    [Types.mutations.HANDLE_ERROR](s, error) {
        const state = s;
        state.error = error;
    },
    [Types.mutations.EDIT_GOOGLE_COURSE_COURSE_SECTION](s, { courseSectionId, googleCourseId, googleAccountId }) {
        const state = s;

        const googleCourseCourseSections = state.database.googleCourseCourseSections
            .filter((c) => c.courseSectionId !== courseSectionId); // delete by courseSectionId
        googleCourseCourseSections.push({ courseSectionId, googleCourseId, googleAccountId }); // add by googleCourseId

        const courseSections = state.database.courseSections.map((c) => {
            const course = c;
            if (c.courseSectionId == courseSectionId) {
                course.googleCourseId = googleCourseId;
            }
            return course;
        });

        state.database.googleCourseCourseSections = googleCourseCourseSections.slice();
        state.database.courseSections = courseSections.slice();
    },
    [Types.mutations.EDIT_STUDENT_EMAIL](s, { studentEnrollmentId, emailAddress }) {
        const state = s;
        const students = state.database.students.slice();
        state.database.students = students.map((t) => {
            const student = t;
            if (t.studentEnrollmentId == studentEnrollmentId) {
                student.googleEmail = emailAddress;
            }
            return student;
        });
    },
    [Types.mutations.UPSERT_COURSE_SECTION_ATTENDANCE](s, payload) {
        const state = s;
        if (!payload.length) return window.console.error('No attendance records passed to payload');
        const rows = state.database.courseSectionAttendance;
        payload.forEach((p) => {
            if (!p.attendanceDate) return window.console.error('No attendance date passed to payload');
            if (`${p.attendanceDate}`.length != 10) return window.console.error('Invalid attendance date, expects YYYY-MM-DD');

            const idx = rows.findIndex((r) => r.courseSectionStudentId == p.courseSectionStudentId && r.attendanceDate == p.attendanceDate && r.courseSectionId == p.courseSectionId);
            if (idx > -1) rows[idx] = new CourseSectionAttendance({ ...p });
            else rows.push(new CourseSectionAttendance({ ...p }));
        });

        state.database.courseSectionAttendance = rows.slice();
    },
    [Types.mutations.UPSERT_GOOGLE_COURSE_TEACHER](s, record) {
        const state = s;
        let add = true;
        const { googleCourseId, googleTeacherId, deleted } = record;

        const rows = state.database.googleCourseTeachers.map((t) => {
            if (t.googleCourseId == googleCourseId && t.googleTeacherId == googleTeacherId) {
                add = false;
                return new GoogleCourseTeacher({ googleCourseId, googleTeacherId, deleted }); // replace item with new item
            }
            return t;
        }).filter((t) => !t.deleted);

        if (add) {
            rows.push(new GoogleCourseTeacher({ googleCourseId, googleTeacherId, deleted }));
        }

        state.database.googleCourseTeachers = rows;
    },
    [Types.mutations.REMOVE_GOOGLE_COURSE_TEACHER](s, { googleCourseId, emailAddress }) {
        const state = s;
        const googleTeacher = state.database.googleTeachers.find((t) => t.emailAddress == emailAddress);
        if (!googleTeacher) return window.console.error('Could not find teacher to remove');
        const { googleTeacherId } = googleTeacher;
        const googleCourseTeachers = state.database.googleCourseTeachers
            .filter((t) => {
                if (t.deleted) return false;
                const toRemove = t.googleCourseId == googleCourseId && t.googleTeacherId == googleTeacherId;
                return !toRemove;
            });
        state.database.googleCourseTeachers = googleCourseTeachers;
    },
    [Types.mutations.REMOVE_COURSE_SECTION_TEACHER](s, record) {
        const state = s;
        const courseSectionTeachers = state.database.courseSectionTeachers
            .filter((t) => {
                const toRemove = t.schoolStaffId == record.schoolStaffId
                    && t.courseSectionId == record.courseSectionId;
                return !toRemove;
            });

        state.database.courseSectionTeachers = courseSectionTeachers.slice();
    },
    [Types.mutations.EDIT_ANECDOTAL_EVENT](s, anecdotalEvent) {
        const state = s;
        const anecdotalEvents = state.database.anecdotalEvents.map((e) => {
            if (e.anecdotalEventId == anecdotalEvent.anecdotalEventId) {
                return anecdotalEvent;
            }
            return e;
        });
        state.database.anecdotalEvents = anecdotalEvents.slice();
    },
    [Types.mutations.SET_STUDENT_AVATAR](s, { studentId, studentAvatar }) {
        const state = s;
        const students = state.database.students.map((s) => {
            if (students.studentId == studentId) {
                const student = { ...s };
                student.studentAvatar = studentAvatar;
                return student;
            }
            return s;
        });
        state.database.students = students.slice();
    },
    [Types.mutations.EDIT_BADGE](s, { badge }) {
        const state = s;
        const badges = state.database.badges.map((e) => {
            if (e.badgeId == badge.badgeId) {
                return { ...badge };
            }
            return e;
        });
        state.database.badges = badges.slice();
    },
    [Types.mutations.ADD_BADGE](s, { badge }) {
        const state = s;
        state.database.badges.unshift(badge);
    },
    [Types.mutations.REMOVE_BILTERACY_DATA_POINT](s, dataPoint) {
        const state = s;
        state.biliteracySeal.dataPoints = state.biliteracySeal.dataPoints.filter((dp) => dp.id !== dataPoint.id);
    },
    [Types.mutations.ADD_BILTERACY_DATA_POINT](s, dataPoint) {
        const state = s;
        state.biliteracySeal.dataPoints.push(dataPoint);
    },
    [Types.mutations.SET_BILTERACY_SEAL_DEMO_DATA](s, schoolYear) {
        const state = s;
        state.biliteracySeal.schoolYears = [{
            value: 2021,
            text: '2021 - 2022',
        }, {
            value: 2020,
            text: '2020 - 2021',
        }, {
            value: 2019,
            text: '2019 - 2020',
        }, {
            value: 2018,
            text: '2018 - 2019',
        }, {
            value: 2017,
            text: '2017 - 2018',
        }, {
            value: 2016,
            text: '2016 - 2017',
        }, {
            value: 2015,
            text: '2015 - 2016',
        }];
        state.biliteracySeal.big5 = [
            'New York City',
            'Yonkers',
            'Buffalo',
            'Rochester',
            'Syracuse',
        ];
        state.biliteracySeal.regions = [
            'Mid-State',
            'Long Island',
            'Capital-East',
            'New York City',
            'West',
            'Hudson Valley',
            'Mid-West',
        ];
        state.biliteracySeal.districts = [
            'Adirondack CSD',
            'Afton CSD',
            'Albany CSD',
            'Alden CSD',
            'All Hallows HS',
            'Amherst CSD',
            'Amityville UFSD',
            'Amsterdam CSD',
            'Ardsley UFSD',
            'Arkport CSD',
            'Auburn Enlarged CSD',
            'Averill Park CSD',
            'Babylon UFSD',
            'Baldwin UFSD',
            'Baldwinsville CSD',
            'Ballston Spa CSD',
            'Barker CSD',
            'Batavia CSD',
            'Bay Shore UFSD',
            'Bayport-Blue Point UFSD',
            'Beacon CSD',
            'Bedford CSD',
            'Beekmantown CSD',
            'Belleville Henderson CSD',
            'Bellmore-Merrick CHSD',
            'Bemus Point CSD',
            'Bethlehem CSD',
            'Brentwood UFSD',
            'Brewster CSD',
            'Brighton CSD',
            'Brockport CSD',
        ];

        state.biliteracySeal.schools = [
            'A. Philip Randolph Campus HS',
            'Academy of American Studies',
            'Adirondack HS',
            'Afton CS',
            'Albany HS',
            'Alden SHS',
            'Alexander Hamilton HS',
            'Amherst HS',
            'Amityville Memorial HS',
            'Amsterdam HS',
            'Arcadia HS',
            'Ardsley HS',
            'Arkport CS',
            'Astor Collegiate',
            'Athena HS',
            'Auburn HS',
            'Averill Park HS',
            'Babylon HS',
            'Baldwin HS',
            'Ballston Spa HS',
            'Barack Obama School for Social Justice',
            'Barker JSHS',
            'Baruch College Campus HS',
            'Batavia HS',
        ];
    },
    [Types.mutations.SET_BILITERACY_SEAL_SCHOOL_YEAR](s, schoolYear) {
        const state = s;
        state.biliteracySeal.schoolYear = schoolYear;
    },
    [Types.mutations.RESET_GOOGLE_COURSE_REFRESH_STATUS](s) {
        const state = s;
        state.googleCourseRefresh.running = false;
        state.googleCourseRefresh.index = 0;
        state.googleCourseRefresh.total = 0;
        state.googleCourseRefresh.title = null;
        state.googleCourseRefresh.subTitle = null;
    },
    [Types.mutations.SET_GOOGLE_SETTING](s, googleSetting) {
        const state = s;
        state.user.school.googleSetting = googleSetting;
    },
    [Types.mutations.SET_HIDE_WIZARD](s, val) {
        const state = s;
        state.wizard.hide = val;
        localStorage.setItem('hideWizard', val);
    },
    [Types.mutations.SKIP_SETUP_FOR_ROUTE](s, {routeName, value}) {
        const state = s;
        const route = state.wizard.steps.find(function(w) {
            return w.routeName == routeName;
        });
        if (!route) debugger;
        const { localStorageName } = route;
        state.wizard[localStorageName] = value;
        if (!value) {
            localStorage.removeItem(localStorageName);
            return;
        }
        localStorage.setItem(localStorageName, value);
    },
    [Types.mutations.SET_GOOGLE_COURSE_REFRESH_STATUS](s, status) {
        const state = s;
        state.googleCourseRefresh.running = status.running;
        state.googleCourseRefresh.index = status.index;
        state.googleCourseRefresh.total = status.total;
        state.googleCourseRefresh.title = status.title;
        state.googleCourseRefresh.subTitle = status.subTitle;
    },
    [Types.mutations.SET_STUDENT_PORTFOLIO_ATTENDANCE_COURSE](s, courseSectionId) {
        const state = s;
        state.database.studentPortfolio.attendance.courseSectionId = courseSectionId;
    },
    [Types.mutations.SET_STUDENT_PORTFOLIO_ATTENDANCE_DATE](s, date) {
        const state = s;
        state.database.studentPortfolio.attendance.date = date;
    },
    [Types.mutations.SET_STUDENT_PORTFOLIO_ATTENDANCE_MONTH](s, month) {
        const state = s;
        state.database.studentPortfolio.attendance.month = month;
    },
    [Types.mutations.CLEAR_UPLOADED_FILES_BY_NAME](s, internalName) {
        const state = s;
        state.database.fileUploads = state.database.fileUploads.filter((f) => f.internalName !== internalName);
    },
    [Types.mutations.CLEAR_UPLOADED_FILES_FOR_DATA_SYSTEM_ID](s, dataSystemId) {
        const state = s;
        state.database.fileUploads = state.database.fileUploads.filter((f) => f.dataSystemId !== dataSystemId);
    },
    [Types.mutations.SET_UPLOADED_FILES](s, files) {
        const state = s;
        state.database.fileUploads = files.map((f) => ({
            internalName: f.syncFile.internalName,
            syncFileId: f.syncFile.syncFileId,
            dataSystemId: f.syncFile.dataSystemId,
        }));
    },
    [Types.mutations.ABORT_REQUESTS](s) {
        const state = s;
        if (state.abortController) {
            state.abortController.abort();
        }
        state.database.cache = [];
    },
    [Types.mutations.SET_ABORT_CONTROLLER](s, controller) {
        const state = s;
        state.abortController = controller;
    },
    [Types.mutations.SET_GRADEBOOK_ACTIVE_CELL](s, payload) {
        const { linkingGuid, courseWorkDomain, studentEnrollmentId } = payload;
        const state = s;
        state.database.gradebook.activeCell.studentEnrollmentId = studentEnrollmentId;
        state.database.gradebook.activeCell.linkingGuid = linkingGuid;
        state.database.gradebook.activeCell.courseWorkDomain = courseWorkDomain;
    },
    [Types.mutations.SET_IGNORE_WARNINGS](s, value) {
        const state = s;
        state.ignoreWarnings = value;
    },
    [Types.mutations.SHOW_GLOBAL_MODAL](s, value) {
        const state = s;
        state.globalModal.show = value;
    },
    [Types.mutations.HIDE_GLOBAL_MODAL](s) {
        const state = s;
        state.globalModal.show = null;
    },
    [Types.mutations.CACHE_GOOGLE_COURSE_LOADING](s, { googleCourseId, googleCourseName }) {
        const state = s;
        const { cache } = state.database;
        const keyPair = {
            key: `googleCourseDataRefresh_${googleCourseId}`,
            cacheTitle: `${googleCourseName || 'Unknown Course'}`,
            cacheType: 'googleCourseTables',
            status: 'loading',
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        // remove old value
        const output = cache.filter((i) => i.key !== keyPair.key);
        output.push(new Cache({ ...keyPair }));
        state.database.cache = output;
    },
    [Types.mutations.BUMP_RELOAD_KEY](s) {
        const state = s;
        state.database.reloadKey += 1;
    },
    [Types.mutations.CLEAR_PORTFOLIO_CACHES](s) {
        const state = s;
        state.database.cache = state.database.cache.filter((i) => {
            const isPortCache = i.cacheType.indexOf('portfolio') == 1;
            return isPortCache;
        });
    },
    [Types.mutations.SET_RESTORED_TABLES_FROM_CACHE](s, restoredFromCache) {
        const state = s;
        state.database.restoredFromCache = restoredFromCache;
    },
    [Types.mutations.SET_LOCAL_CACHE_STATE_SAVE](s) {
        // this is a meaningless mutation
        // its used to trigger store/index.js saveState()
        const state = s;
        state.database.cache = state.database.cache.map((c) => ({ ...c }));
    },
    [Types.mutations.REMOVE_ITEM_FROM_CACHE](s, keyPair) {
        const state = s;
        state.database.cache = state.database.cache.filter((i) => i.key !== keyPair.key);
    },
    [Types.mutations.UPSERT_ITEM_TO_CACHE](s, keyPair) {
        const state = s;
        const { cache } = state.database;
        // remove old value
        const output = cache.filter((i) => i.key !== keyPair.key);
        output.push(new Cache({ ...keyPair }));
        state.database.cache = output;
    },
    [Types.mutations.SET_FORCE_STUDENT_VIEW](s, enabled) {
        const state = s;
        state.forceStudentView = enabled;
    },
    [Types.mutations.REMOVE_BILITERACY_PROFILE_HISTORY](s, profile) {
        const state = s;
        const profileHistory = [...state.biliteracySeal.profileHistory]
            .filter((p) => p.name !== profile.name);
        state.biliteracySeal.profileHistory = profileHistory;
    },
    [Types.mutations.ADD_BILITERACY_PROFILE_HISTORY](s, profile) {
        /*
            const item = {
                name: 'Some Name',
                type: 'region'
            };
        */
        const state = s;
        const { profileHistory } = s.biliteracySeal;
        const exists = profileHistory.find((p) => p.name == profile.name && p.type == profile.type);
        if (exists) return;

        if (profileHistory.length == 5) {
            const [first, second, third, fourth] = profileHistory;
            state.biliteracySeal.profileHistory = [ // put to top
                profile,
                first,
                second,
                third,
                fourth,
            ];
        } else {
            state.biliteracySeal.profileHistory.unshift(profile);
        }
    },
    [Types.mutations.REMOVE_PORTFOLIO_HISTORY](s, item) {
        const state = s;
        const { portfolioHistory } = s;
        if (item.type == 'Teacher') {
            const teacherExists = portfolioHistory.find((p) => p.schoolEmail == item.schoolEmail && p.type == 'Teacher');
            if (!teacherExists) return;

            state.portfolioHistory = portfolioHistory.filter((p) => p.schoolEmail != item.schoolEmail || p.type != 'Teacher');
        }
        if (item.type == 'Student') {
            const studentExists = portfolioHistory.find((p) => p.studentEnrollmentId == item.studentEnrollmentId && p.type == 'Student');
            if (!studentExists) return;

            state.portfolioHistory = portfolioHistory.filter((p) => p.studentEnrollmentId != item.studentEnrollmentId || p.type != 'Student');
        }
    },
    [Types.mutations.ADD_PORTFOLIO_HISTORY](s, item) {
        /*
            const item = {
                type: 'Teacher',
                schoolEmail,
            };
            const item = {
                type: 'Student',
                studentEnrollmentId,
            };
        */
        const state = s;
        const { portfolioHistory } = s;

        if (item.type == 'Teacher') {
            const teacherExists = s.portfolioHistory.find((p) => p.schoolEmail == item.schoolEmail && p.type == 'Teacher');
            if (teacherExists) return;

            // dont save yourself to history
            if (item.schoolEmail == (s.user.mappedUserName || s.user.userName)) return;
        }
        if (item.type == 'Student') {
            const studentExists = s.portfolioHistory.find((p) => p.studentEnrollmentId == item.studentEnrollmentId && p.type == 'Student');
            if (studentExists) return;
        }

        if (portfolioHistory.length == 3) {
            const [first, second] = portfolioHistory;
            state.portfolioHistory = [ // put to top
                item,
                first,
                second,
            ];
        } else {
            state.portfolioHistory.unshift(item);
        }
    },
    [Types.mutations.SET_IS_SCREEN_RECORDING](s, val) {
        const state = s;
        state.screenRecord.isRecording = val;
    },
    [Types.mutations.ADD_SCREEN_RECORD_HISTORY](s, item) {
        const state = s;
        if (state.screenRecord.isRecording) {
            state.screenRecord.history.push(item);
        }
    },
    [Types.mutations.ADD_ANECDOTAL_EVENT](s, anecdotalEvent) {
        const state = s;
        const anecdotalEvents = state.database.anecdotalEvents.slice();
        const event = anecdotalEvent;
        if (!event.author) {
            event.author = `${state.user.lastName}, ${state.user.firstName}`;
        }
        anecdotalEvents.unshift(event);
        state.database.anecdotalEvents = anecdotalEvents.slice();
    },
    [Types.mutations.ADD_COURSE_SECTION_TEACHER](s, record) {
        const state = s;
        const courseSectionTeachers = state.database.courseSectionTeachers.slice();
        courseSectionTeachers.push(record);
        state.database.courseSectionTeachers = courseSectionTeachers.slice();
    },
    [Types.mutations.EDIT_COURSE_SECTION_GRADE_TEMPLATE](s, { gradeTemplateId, courseSectionId }) {
        const state = s;
        const courseSectionGradeTemplates = state.database.courseSectionGradeTemplates.slice();
        state.database.courseSectionGradeTemplates = courseSectionGradeTemplates.map((t) => {
            const record = t;
            if (t.courseSectionId == courseSectionId) {
                record.gradeTemplateId = gradeTemplateId;
            }
            return record;
        });
    },
    [Types.mutations.EDIT_GOOGLE_COURSE_OWNER](s, { googleCourseId, extOwnerId }) {
        const state = s;
        const googleCourses = state.database.googleCourses
            .slice()
            .map((c) => {
                const course = c;
                if (course.googleCourseId == googleCourseId) {
                    course.extOwnerId = extOwnerId;
                }
                return course;
            });

        state.database.googleCourses = googleCourses;
    },
    [Types.mutations.DELETE_COURSE_WORK](s, courseWork) {
        const state = s;
        const courseWorks = state.database.courseWork.filter((c) => c.courseWorkId !== courseWork.courseWorkId);
        state.database.courseWork = courseWorks;
    },
    [Types.mutations.DELETE_ANECDOTAL_EVENT_CATEGORY](s, { anecdotalEventCategoryId }) {
        const state = s;
        const anecdotalEventCategories = [...state.database.anecdotalEventCategories]
            .slice()
            .filter((c) => c.anecdotalEventCategoryId !== anecdotalEventCategoryId);

        state.database.anecdotalEventCategories = [...anecdotalEventCategories];
    },
    [Types.mutations.EDIT_ANECDOTAL_EVENT_CATEGORY](s, { anecdotalEventCategory }) {
        const state = s;
        const anecdotalEventCategories = state.database.anecdotalEventCategories
            .slice()
            .map((c) => {
                const category = c;
                if (c.anecdotalEventCategoryId == anecdotalEventCategory.anecdotalEventCategoryId) {
                    category.anecdotalEventCategoryTitle = anecdotalEventCategory.anecdotalEventCategoryTitle;
                    category.anecdotalEventCategoryId = anecdotalEventCategory.anecdotalEventCategoryId;
                    category.categoryColor = anecdotalEventCategory.categoryColor;
                    category.categoryIcon = anecdotalEventCategory.categoryIcon;
                    category.categoryShortIcon = anecdotalEventCategory.categoryShortIcon;
                    category.pointValue = anecdotalEventCategory.pointValue;
                    category.defaultGuardianShare = anecdotalEventCategory.defaultGuardianShare;
                    category.defaultStudentShare = anecdotalEventCategory.defaultStudentShare;
                    category.defaultDescription = anecdotalEventCategory.defaultDescription;
                    category.subscribers = anecdotalEventCategory.subscribers;
                }
                return category;
            });

        state.database.anecdotalEventCategories = anecdotalEventCategories;
    },
    [Types.mutations.ADD_ANECDOTAL_EVENT_CATEGORY](s, { anecdotalEventCategory }) {
        const state = s;
        const anecdotalEventCategories = state.database.anecdotalEventCategories.slice();
        anecdotalEventCategories.push(anecdotalEventCategory);
        state.database.anecdotalEventCategories = anecdotalEventCategories;
    },
    [Types.mutations.DELETE_COURSE_SECTION_LINK](s, { courseSectionId }) {
        const state = s;
        const googleCourseCourseSections = state.database.googleCourseCourseSections
            .slice()
            .filter((c) => c.courseSectionId == courseSectionId);

        state.database.googleCourseCourseSections = googleCourseCourseSections;
    },
    [Types.mutations.ENABLE_STARS_GRADING_FOR_MP](s, markingPeriod) {
        const state = s;
        const markingPeriods = state.database.markingPeriods.map((mp) => {
            if (markingPeriod.markingPeriod == markingPeriod) {
                const x = { ...mp };
                x.starsGradingEnabled = true;
                return x;
            }
            return mp;
        });
        state.database.markingPeriods = markingPeriods;
    },
    [Types.mutations.EDIT_COURSE_SECTION_STATE](s, { courseSectionId, courseSectionState }) {
        const state = s;
        const courseSections = state.database.courseSections
            .slice()
            .map((c) => {
                const course = c;

                if (course.courseSectionId == courseSectionId) {
                    course.syncDisabled = courseSectionState.disabled;
                }
                return course;
            });

        state.database.courseSections = courseSections;
    },
    [Types.mutations.EDIT_GOOGLE_COURSE_STATE](s, { googleCourseId, googleCourseState }) {
        const state = s;
        const googleCourses = state.database.googleCourses
            .slice()
            .map((c) => {
                const course = c;
                if (course.googleCourseId == googleCourseId) {
                    course.googleCourseState = googleCourseState;
                }
                return course;
            });
        state.database.googleCourses = googleCourses;
    },
    [Types.mutations.EDIT_GOOGLE_COURSE_OWNER](s, { googleCourseId, extOwnerId }) {
        const state = s;
        const googleCourses = state.database.googleCourses
            .map((c) => {
                if (c.googleCourseId == googleCourseId) {
                    const course = { ...c };
                    course.extOwnerId = extOwnerId;
                    return course;
                }
                return c;
            });
        state.database.googleCourses = googleCourses;
    },
    [Types.mutations.DELETE_GOOGLE_COURSE_COURSE_SECTIONS](s, courseSectionId) {
        const state = s;
        const googleCourseCourseSections = state.database.googleCourseCourseSections.slice();
        state.database.googleCourseCourseSections = googleCourseCourseSections.filter((row) => row.courseSectionId !== courseSectionId);
    },
    [Types.mutations.EDIT_GOOGLE_COURSE_COURSE_SECTIONS](s, { googleCourseId, googleAccountId }) {
        const state = s;
        const googleCourseCourseSections = state.database.googleCourseCourseSections
            .map((c) => {
                const course = { ...c };
                if (course.googleCourseId == googleCourseId) {
                    course.googleAccountId = googleAccountId;
                }
                return course;
            });
        state.database.googleCourseCourseSections = googleCourseCourseSections;
    },
    [Types.mutations.UPSERT_USER_ROLE](s, { userRole }) {
        const state = s;
        let addUserRole = true;
        const userRoles = state.database.userRoles.map((ur) => {
            if (userRole.userId == ur.userId && userRole.schoolTermId == ur.schoolTermId) {
                addUserRole = false;
                return userRole; // replace item with new item
            }
            return ur;
        });
        if (addUserRole) { userRoles.push(userRole); }
        state.database.userRoles = userRoles;
    },
    [Types.mutations.UPSERT_DB_GOOGLE_COURSE_WORK](s, { googleCourseId, googleCourseWork }) {
        const state = s;
        let hasAdditions = true;
        const items = [...state.database.googleCourseWork].map((cw) => {
            const courseWork = cw;
            if (googleCourseId == courseWork.googleCourseId) {
                hasAdditions = false;
                return new GoogleCourseWork(googleCourseWork);
            }
            return courseWork;
        });
        if (hasAdditions) { items.push(new GoogleCourseWork(googleCourseWork)); }

        state.database.googleCourseWork = items;
    },
    [Types.mutations.UPSERT_USER](s, { u }) {
        const state = s;
        const newUser = u;
        let addUser = true;

        const users = [...state.database.users].map((us) => {
            const thisUser = us;
            if (newUser.userId == thisUser.userId) {
                addUser = false;
                return new StaffUser(newUser);
            }
            return thisUser;
        });

        if (addUser) { users.push(new StaffUser(newUser)); }

        state.database.users = users;
    },
    [Types.mutations.EDIT_TEACHER_EMAIL](s, { schoolStaffId, emailAddress }) {
        const state = s;
        const teachers = state.database.teachers.slice();
        state.database.teachers = teachers.map((t) => {
            const teacher = t;
            if (teacher.schoolStaffId == schoolStaffId) {
                teacher.googleEmail = emailAddress;
            }
            return teacher;
        });
    },
    [Types.mutations.SET_PROFILE_HAS_COURSES](s) {
        // sets hasCourses based off what is in VUEX db
        const state = s;
        const { database } = state;
        const { school } = state.user;
        const { schoolStaffId } = school;
        const courseSectionIds = database.courseSectionTeachers
            .filter((row) => row.schoolStaffId == schoolStaffId)
            .map((row) => row.courseSectionId);

        const courseSections = database.courseSections
            .filter((row) => courseSectionIds.includes(row.courseSectionId));

        const linked = courseSections.find((cs) => cs.googleCourseId > 0);

        state.database.myClassroom.googleCourseId = linked?.googleCourseId || 0;

        state.user.school.hasCourses = Boolean(courseSections.length > 0);
    },
    [Types.mutations.SET_RESPONSIVE_DEVICE_TYPE](s, deviceType) {
        const state = s;
        state.deviceType = deviceType;
    },
    [Types.mutations.SET_IS_LOADING](s, loading) {
        const state = s;
        state.navigation.loading = loading;
    },
    [Types.mutations.SET_SCHOOL_TERM](s, schoolTermId) {

        const keys = Object.keys(s.wizard);
        keys.forEach(function(key) {
            if (key.startsWith('bypassWizard')) {
                localStorage.removeItem(key);
            }
        });

        window.sessionStorage.setItem(Types.mutations.SET_SCHOOL_TERM, schoolTermId);
        setTimeout(() => {
            window.location.reload();
        }, 200);
    },
    [Types.mutations.SET_DB_GOOGLE_COURSE_TOPICS](s, toUpsert) {
        const state = s;
        const rows = state.database.googleCourseTopics.slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.googleCourseTopicId}_${row.googleCourseId}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.googleCourseTopicId}_${row.googleCourseId}`;
            unique.set(key, row);
        });
        // save to state
        state.database.googleCourseTopics = [...unique.values()].map((i) => new GoogleCourseTopic(i));
    },
    [Types.mutations.SET_DB_GOOGLE_COURSE_GRADE_CATEGORIES](s, toUpsert) {
        const state = s;
        const rows = state.database.googleCourseGradeCategories.slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.extGradeCategoryId}_${row.googleCourseId}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.extGradeCategoryId}_${row.googleCourseId}`;
            unique.set(key, row);
        });
        // save to state
        state.database.googleCourseGradeCategories = [...unique.values()].map((i) => new GoogleCourseGradeCategory(i));
    },
    [Types.mutations.SET_DB_SCHOOLS](s, schools) {
        const state = s;
        state.database.schools = (schools || []).map((i) => new School(i));
    },
    [Types.mutations.SET_DB_HELP_REDIRECTS](s, helpRedirects) {
        const state = s;
        state.database.helpRedirects = helpRedirects || {};
    },
    [Types.mutations.SET_DB_TRANSACTIONS](s, transactions) {
        const state = s;
        state.database.transactions = (transactions || []).slice();
    },
    [Types.mutations.SET_DB_SCHOOL_TERMS](s, schoolTerms) {
        const state = s;
        state.database.schoolTerms = (schoolTerms || []).slice();
    },
    [Types.mutations.SET_DB_SCHOOL_ATTENDANCE](s, toUpsert) {
        const state = s;
        const rows = state.database.schoolAttendance.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.studentEnrollmentId}_${row.attendanceDate}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.studentEnrollmentId}_${row.attendanceDate}`;
            unique.set(key, row);
        });

        // sort by key (date), then replace with new sorted rows
        const arr = Array.from(unique).sort(([aKey], [bKey]) => aKey.localeCompare(bKey));
        state.database.schoolAttendance = arr.map(([, row]) => new SchoolAttendance(row));
    },
    [Types.mutations.SET_DB_COURSE_SECTION_ATTENDANCE](s, toUpsert) {
        const state = s;
        const rows = state.database.courseSectionAttendance.slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.courseSectionStudentId}_${row.courseSectionId}_${row.attendanceDate}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.courseSectionStudentId}_${row.courseSectionId}_${row.attendanceDate}`;
            unique.set(key, row);
        });

        // sort by key (date), then replace with new sorted rows
        const arr = Array.from(unique).sort(([aKey], [bKey]) => aKey.localeCompare(bKey));
        state.database.courseSectionAttendance = arr.map(([, row]) => new CourseSectionAttendance(row));
    },
    [Types.mutations.SET_DB_GOOGLE_STUDENT_SUBMISSIONS](s, toUpsert) {
        const state = s;
        const rows = state.database.googleStudentSubmissions.slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.googleStudentSubmissionId}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.googleStudentSubmissionId}`;
            unique.set(key, row);
        });
        // save to state
        state.database.googleStudentSubmissions = [...unique.values()].map((i) => new GoogleStudentSubmission(i));
    },
    [Types.mutations.SET_DB_COURSE_WORK_GRADES](s, toUpsert) {
        const state = s;
        const rows = state.database.courseWorkGrades.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.courseSectionId}_${row.courseWorkId}_${row.studentEnrollmentId}`;
            unique.set(key, row);
        });
        toUpsert.map((i) => new CourseWorkGrade(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.courseSectionId}_${row.courseWorkId}_${row.studentEnrollmentId}`;
            unique.set(key, row);
        });
        // save to state
        const out = [...unique.values()];
        state.database.courseWorkGrades = out.slice();
    },
    [Types.mutations.SET_WINDOW_SIZE](s, size) {
        const state = s;
        state.window.width = size.windowWidth;
        state.window.height = size.windowHeight;
    },
    [Types.mutations.SET_ASSIGNMENT_IS_DIRTY](s, dirty) {
        const state = s;
        state.database.assignmentIsDirty = dirty;
    },
    [Types.mutations.SHOW_SCHOOL_PASSWORD_MODAL](s, show) {
        const state = s;
        state.user.school.showSchoolPasswordModal = show;
    },
    [Types.mutations.SET_WIZARD_STEPS](s, steps) {
        const state = s;
        state.wizard.steps = [...steps];
    },
    [Types.mutations.SET_SCHOOL_TERM_ENCRYPTION_KEY](s, schoolTermEncryptionKey) {
        const state = s;
        const { schoolTermId, encryptionHash } = state.user.school;
        state.database.schoolTermEncryptionKey = schoolTermEncryptionKey;
        if (!schoolTermEncryptionKey || !encryptionHash) {
            state.user.school.clientSideEncryptionEnabled = true;
            window.sessionStorage.removeItem(`stek_${schoolTermId}`);
            return;
        }
        state.user.school.clientSideEncryptionEnabled = false;
    },
    [Types.mutations.SET_SERVER_SIDE_ENCRYPTION_ENABLED](s, enabled) {
        const state = s;
        state.user.school.serverSideEncryptionEnabled = enabled;
        if (!enabled) {
            state.user.school.clientSideEncryptionEnabled = enabled;
            state.database.schoolTermEncryptionKey = null;
            window.sessionStorage.removeItem(`stek_${state.user.school.schoolTermId}`);
        }
    },
    [Types.mutations.SET_ENCRYPTION_HASH](s, encryptionHash) {
        const state = s;
        state.user.school.encryptionHash = encryptionHash;
    },
    [Types.mutations.SET_ASSIGNMENT_COURSE_SECTIONS](s, { assignmentCourseSections }) {
        const state = s;
        state.database.assignmentCourseSections = assignmentCourseSections;
    },
    [Types.mutations.SET_MUTABLE_BADGE](s, badge) {
        const state = s;
        if (badge.hasOwnProperty('badgeTitle')) {
            state.database.mutableBadge.badgeTitle = badge.badgeTitle;
        }
        if (badge.hasOwnProperty('badgeDescription')) {
            state.database.mutableBadge.badgeDescription = badge.badgeDescription;
        }
        if (badge.hasOwnProperty('badgeRank')) {
            state.database.mutableBadge.badgeRank = badge.badgeRank;
        }
        if (badge.hasOwnProperty('badgeType')) {
            state.database.mutableBadge.badgeType = badge.badgeType;
        }
        if (badge.hasOwnProperty('hasRequirements')) {
            state.database.mutableBadge.hasRequirements = badge.hasRequirements;
        }
        if (badge.hasOwnProperty('badgeLimiterType')) {
            state.database.mutableBadge.badgeLimiterType = badge.badgeLimiterType;
        }
        if (badge.hasOwnProperty('badgeColor')) {
            state.database.mutableBadge.badgeColor = badge.badgeColor;
        }
        if (badge.hasOwnProperty('badgeIcon')) {
            state.database.mutableBadge.badgeIcon = badge.badgeIcon;
        }
        if (badge.hasOwnProperty('badgeIconShortName')) {
            state.database.mutableBadge.badgeIconShortName = badge.badgeIconShortName;
        }
        if (badge.hasOwnProperty('guardianShare')) {
            state.database.mutableBadge.guardianShare = badge.guardianShare;
        }
        if (badge.hasOwnProperty('studentShare')) {
            state.database.mutableBadge.studentShare = badge.studentShare;
        }
        if (badge.hasOwnProperty('deleted')) {
            state.database.mutableBadge.deleted = badge.deleted;
        }
        if (badge.hasOwnProperty('unlocksAvatars')) {
            state.database.mutableBadge.unlocksAvatars = badge.unlocksAvatars;
        }
        if (badge.hasOwnProperty('badgeRequirements')) {
            state.database.mutableBadge.badgeRequirements = badge.badgeRequirements;
        }
        if (badge.hasOwnProperty('badgeManagers')) {
            state.database.mutableBadge.badgeManagers = [...badge.badgeManagers];
        }
    },
    [Types.mutations.SET_COURSE_WORK](s, courseWork) {
        const state = s;
        state.database.assignmentIsDirty = true;
        if (courseWork.hasOwnProperty('courseWorkTitle')) {
            state.database.assignment.courseWorkTitle = courseWork.courseWorkTitle;
        }
        if (courseWork.hasOwnProperty('allowExtraCredit')) {
            state.database.assignment.allowExtraCredit = courseWork.allowExtraCredit;
        }
        if (courseWork.hasOwnProperty('teacherExtCourseWorkId')) {
            state.database.assignment.teacherExtCourseWorkId = courseWork.teacherExtCourseWorkId;
        }
        if (courseWork.hasOwnProperty('courseWorkDescription')) {
            state.database.assignment.courseWorkDescription = courseWork.courseWorkDescription;
        }
        if (courseWork.hasOwnProperty('assigneeMode')) {
            state.database.assignment.assigneeMode = courseWork.assigneeMode;
        }
        if (courseWork.hasOwnProperty('courseWorkWeight')) {
            state.database.assignment.courseWorkWeight = courseWork.courseWorkWeight;
        }
        if (courseWork.hasOwnProperty('gradeTemplateCategoryId')) {
            state.database.assignment.gradeTemplateCategoryId = courseWork.gradeTemplateCategoryId;
        }
        if (courseWork.hasOwnProperty('schoolTermMarkingPeriodId')) {
            state.database.assignment.schoolTermMarkingPeriodId = courseWork.schoolTermMarkingPeriodId;
        }
        if (courseWork.hasOwnProperty('maxPoints')) {
            state.database.assignment.maxPoints = courseWork.maxPoints;
        }
        if (courseWork.hasOwnProperty('dueDate')) {
            state.database.assignment.dueDate = courseWork.dueDate;
        }
        if (courseWork.hasOwnProperty('dueTime')) {
            state.database.assignment.dueTime = courseWork.dueTime;
        }
        if (courseWork.hasOwnProperty('publishDate')) {
            state.database.assignment.publishDate = courseWork.publishDate;
        }
        if (courseWork.hasOwnProperty('publishTime')) {
            state.database.assignment.publishTime = courseWork.publishTime;
        }
        if (courseWork.hasOwnProperty('courseSectionId')) {
            state.database.assignment.courseSectionId = courseWork.courseSectionId;
        }
        if (courseWork.hasOwnProperty('excluded')) {
            state.database.assignment.excluded = courseWork.excluded;
        }
        if (courseWork.hasOwnProperty('hideMarks')) {
            state.database.assignment.hideMarks = courseWork.hideMarks;
        }
        if (courseWork.hasOwnProperty('isExternal')) {
            state.database.assignment.isExternal = courseWork.isExternal;
        }
        if (courseWork.hasOwnProperty('deleted')) {
            state.database.assignment.deleted = courseWork.deleted;
        }

        if (courseWork.hasOwnProperty('gradeTemplateId')) {
            state.database.assignment.gradeTemplateId = courseWork.gradeTemplateId;
        }
        if (courseWork.hasOwnProperty('gradeCategoryId')) {
            state.database.assignment.gradeCategoryId = courseWork.gradeCategoryId;
        }
        if (courseWork.hasOwnProperty('categoryName')) {
            state.database.assignment.categoryName = courseWork.categoryName;
        }
        if (courseWork.hasOwnProperty('color')) {
            state.database.assignment.color = courseWork.color;
        }
    },
    [Types.mutations.SET_SOCKET_ESTABLISHED](s, val) {
        const state = s;
        state.socketEstablished = val;
    },
    [Types.mutations.CLEAR_GRADEBOOK_AVERAGES](s) {
        const state = s;
        state.database.gradebook.updatedAverages = {};
    },
    [Types.mutations.SET_GRADEBOOK_UPDATED_AVERAGE](s, key) {
        const state = s;
        const { updatedAverages } = state.database.gradebook;
        const counter = updatedAverages[key] || 0;
        updatedAverages[key] = counter + 1;
        state.database.gradebook.updatedAverages = { ...updatedAverages };
    },
    [Types.mutations.SET_DB_MARKING_PERIOD_STUDENT_AVERAGES](s, toUpsert) {
        const state = s;
        const rows = state.database.markingPeriodStudentAverages;
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.courseSectionStudentId}_${row.schoolTermMarkingPeriodId}`;
            unique.set(key, { ...row });
        });
        toUpsert.map((i) => new MarkingPeriodStudentAverage(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.courseSectionStudentId}_${row.schoolTermMarkingPeriodId}`;
            unique.set(key, { ...row });
        });
        // save to state
        const out = [...unique.values()];
        state.database.markingPeriodStudentAverages = [...out];
    },
    [Types.mutations.SET_DB_MESSAGES](s, toUpsert) {
        const state = s;
        const rows = state.database.messages.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.messageId}`;
            unique.set(key, row);
        });
        toUpsert.map((i) => new Message(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.messageId}`;
            unique.set(key, row);
        });
        // save to state
        const out = [...unique.values()];
        state.database.messages = out.slice();
    },
    [Types.mutations.SET_DB_STUDENT_MESSAGES](s, toUpsert) {
        const state = s;
        const rows = state.database.studentMessages.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.messageId}_${row.studentEnrollmentId}`;
            unique.set(key, row);
        });
        toUpsert.map((i) => new Message(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.messageId}_${row.studentEnrollmentId}`;
            unique.set(key, row);
        });
        // save to state
        const out = [...unique.values()];
        state.database.studentMessages = out.slice();
    },
    [Types.mutations.SET_DB_COMMUNICATION_GROUPS](s, toUpsert) {
        const state = s;
        const rows = state.database.communicationGroups.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.communicationGroupId}`;
            unique.set(key, row);
        });
        toUpsert.map((i) => new CommunicationGroup(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.communicationGroupId}`;
            unique.set(key, row);
        });
        // save to state
        const out = [...unique.values()];
        state.database.communicationGroups = out.slice();
    },
    [Types.mutations.SET_DB_STUDENT_GROUPS](s, toUpsert) {
        const state = s;
        const rows = state.database.studentGroups.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.studentGroupId}`;
            unique.set(key, row);
        });
        toUpsert.map((i) => new StudentGroup(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.studentGroupId}`;
            unique.set(key, row);
        });
        // save to state
        const out = [...unique.values()];
        state.database.studentGroups = out.slice();
    },
    [Types.mutations.SET_DB_COURSE_WORK](s, toUpsert) {
        const state = s;
        const rows = state.database.courseWork.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.courseWorkId}_${row.courseSectionId}`;
            unique.set(key, row);
        });
        toUpsert.map((i) => new CourseWork(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.courseWorkId}_${row.courseSectionId}`;
            unique.set(key, row);
        });
        // save to state
        const out = [...unique.values()];
        state.database.courseWork = out.slice();
    },
    [Types.mutations.SET_DB_GOOGLE_COURSE_WORK](s, toUpsert) {
        const state = s;
        const rows = state.database.googleCourseWork.slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.googleCourseWorkId}_${row.googleCourseId}`;
            unique.set(key, row);
        });
        toUpsert.map((i) => new GoogleCourseWork(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.googleCourseWorkId}_${row.googleCourseId}`;
            unique.set(key, row);
        });
        // save to state
        const out = [...unique.values()];
        state.database.googleCourseWork = out.slice();
    },
    [Types.mutations.SET_DB_MARKING_PERIODS](s, markingPeriods) {
        const state = s;
        const rows = (markingPeriods || [])
            .map((i) => new MarkingPeriod(i))
            .sort((a, b) => ((a.markingPeriod > b.markingPeriod) ? 1 : ((b.markingPeriod > a.markingPeriod) ? -1 : 0)));
        state.database.markingPeriods = rows;

        // set default marking period
        const [firstMarkingPeriod] = rows;
        if (!firstMarkingPeriod) return window.syncGrades.log('No marking periods found', 'error');
        if (state.settings.schoolTermMarkingPeriodId == null) state.settings.schoolTermMarkingPeriodId = firstMarkingPeriod.schoolTermMarkingPeriodId;
        const exists = rows.find((r) => r.schoolTermMarkingPeriodId == state.settings.schoolTermMarkingPeriodId);
        if (!exists) state.settings.schoolTermMarkingPeriodId = firstMarkingPeriod.schoolTermMarkingPeriodId;
    },
    [Types.mutations.SET_DB_USERS](s, users) {
        const state = s;
        state.database.users = (users || []).map((i) => new StaffUser(i));
    },
    [Types.mutations.SET_DB_USAGE_TOTALS](s, totals) {
        const state = s;
        state.database.usageTotals = (totals || []);
    },
    [Types.mutations.SET_DB_USER_ROLES](s, userRoles) {
        const state = s;
        state.database.userRoles = (userRoles || []).map((i) => new UserRole(i));
    },
    [Types.mutations.SET_DB_GRADE_SCALES](s, gradeScales) {
        const state = s;
        state.database.gradeScales = (gradeScales || []).slice();
    },
    [Types.mutations.SET_DB_GRADE_TEMPLATES](s, gradeTemplates) {
        const state = s;
        state.database.gradeTemplates = (gradeTemplates || []).map((i) => new GradeTemplate(i));
    },
    [Types.mutations.SET_DB_ANECDOTAL_EVENT_CATEGORIES](s, anecdotalEventCategories) {
        const state = s;
        state.database.anecdotalEventCategories = (anecdotalEventCategories || []).map((i) => new AnecdotalEventCategory(i));
    },
    [Types.mutations.ADD_DB_ANECDOTAL_EVENTS](s, anecdotalEvents) {
        const state = s;
        const newEvents = [
            ...state.database.anecdotalEvents,
            ...anecdotalEvents,
        ].map((i) => new AnecdotalEvent(i));

        state.database.anecdotalEvents = newEvents.slice();
    },
    [Types.mutations.SET_DB_STUDENT_GUARDIANS](s, studentGuardians) {
        const state = s;
        const rows = state.database.studentGuardians.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            unique.set(`${row.studentGuardianId}`, new StudentGuardian(row));
        });
        studentGuardians.forEach((row) => { // overwrite with new rows
            unique.set(`${row.studentGuardianId}`, new StudentGuardian(row));
        });
        // save to state
        const out = [...unique.values()];
        state.database.studentGuardians = out.slice();
    },
    [Types.mutations.SET_DB_STUDENT_BIOGRAPHICAL](s, studentBiographical) {
        const state = s;
        const rows = state.database.studentBiographical.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.studentBiographicalId}`;
            unique.set(key, row);
        });
        studentBiographical.forEach((row) => { // overwrite with new rows
            const key = `${row.studentBiographicalId}`;
            unique.set(key, row);
        });
        // save to state
        const out = [...unique.values()];
        state.database.studentBiographical = out.slice();
    },
    [Types.mutations.SET_DB_ANECDOTAL_EVENTS](s, anecdotalEvents) {
        const state = s;
        state.database.anecdotalEvents = (anecdotalEvents || []).map((i) => new AnecdotalEvent(i));
    },
    [Types.mutations.SET_DB_COURSE_SECTION_GRADE_TEMPLATES](s, courseSectionGradeTemplates) {
        const state = s;
        state.database.courseSectionGradeTemplates = (courseSectionGradeTemplates || []).map((i) => new CourseSectionGradeTemplate(i));
    },
    [Types.mutations.SET_DB_GOOGLE_STUDENTS](s, googleStudents) {
        const state = s;
        const table = (googleStudents || []).map((i) => new GoogleStudent(i));
        state.database.googleStudents = table.slice();
    },
    [Types.mutations.SET_DB_GOOGLE_TEACHERS](s, googleTeachers) {
        const state = s;
        const table = (googleTeachers || []).map((i) => new GoogleTeacher(i));
        state.database.googleTeachers = table.slice();
    },
    [Types.mutations.SET_DB_COURSE_SECTION_STUDENTS](s, courseSectionStudents) {
        const state = s;
        const rows = state.database.courseSectionStudents.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.courseSectionStudentId}`;
            unique.set(key, { ...row });
        });
        courseSectionStudents.map((i) => new CourseSectionStudent(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.courseSectionStudentId}`;
            unique.set(key, { ...row });
        });
        // save to state
        const out = [...unique.values()];
        state.database.courseSectionStudents = [...out].slice();
    },
    [Types.mutations.REMOVE_COURSE_SECTION_STUDENT](s, courseSectionStudent) {
        const state = s;
        const courseSectionStudents = state.database.courseSectionStudents.slice();
        const { courseSectionStudentId } = courseSectionStudent;
        state.database.courseSectionStudents = courseSectionStudents.filter((row) => row.courseSectionStudentId !== courseSectionStudentId);
    },
    [Types.mutations.SET_DB_COURSE_SECTIONS](s, courseSections) {
        const state = s;
        state.database.courseSections = (courseSections || []).map((i) => new CourseSection(i));
    },
    [Types.mutations.SET_DB_COURSE_SECTION_TEACHER_METADATA](s, courseSectionTeacherMetadata) {
        const state = s;
        state.database.courseSectionTeacherMetadata = (courseSectionTeacherMetadata || []).map((i) => new CourseSectionTeacherMetadata(i));
    },
    [Types.mutations.SET_DB_GOOGLE_COURSES](s, googleCourses) {
        const state = s;
        const table = (googleCourses || []).map((i) => new GoogleCourse(i));
        state.database.googleCourses = table.slice();
    },
    [Types.mutations.SET_DB_GOOGLE_COURSE_STUDENTS](s, googleCourseStudents) {
        const state = s;
        const rows = state.database.googleCourseStudents.slice();
        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.googleStudentId}_${row.googleCourseId}`;
            unique.set(key, { ...row });
        });
        googleCourseStudents.map((i) => new GoogleCourseStudent(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.googleStudentId}_${row.googleCourseId}`;
            unique.set(key, { ...row });
        });
        // save to state
        const out = [...unique.values()];
        state.database.googleCourseStudents = [...out].slice();
    },
    [Types.mutations.SET_DB_SCHOOL_STAFF](s, schoolStaff) {
        const state = s;
        state.database.schoolStaff = (schoolStaff || []).map((i) => new SchoolStaff(i));
    },
    [Types.mutations.SET_DB_GOOGLE_COURSE_COURSE_SECTIONS](s, googleCourseCourseSections) {
        const state = s;
        const table = (googleCourseCourseSections || []).map((i) => new GoogleCourseCourseSection(i));
        state.database.googleCourseCourseSections = table.slice();
    },
    [Types.mutations.SET_DB_COURSE_SECTION_TEACHERS](s, courseSectionTeachers) {
        const state = s;
        const rows = state.database.courseSectionTeachers.slice();
        const unique = new Map();

        rows.forEach((row) => { // existing rows
            const key = `${row.courseSectionId}_${row.schoolStaffId}`;
            unique.set(key, { ...row });
        });
        (courseSectionTeachers || []).map((i) => new CourseSectionTeacher(i)).forEach((row) => { // overwrite with new rows
            const key = `${row.courseSectionId}_${row.schoolStaffId}`;
            unique.set(key, { ...row });
        });

        // save to state
        const out = [...unique.values()];
        state.database.courseSectionTeachers = [...out].slice();
    },
    [Types.mutations.SET_DB_GOOGLE_COURSE_TEACHERS](s, googleCourseTeachers) {
        const state = s;
        const table = (googleCourseTeachers || []).map((i) => new GoogleCourseTeacher(i));
        state.database.googleCourseTeachers = table.slice();
    },
    [Types.mutations.SET_DB_LOAD_STATE](s, loadState) {
        const state = s;
        state.database.loadState = loadState;
    },
    [Types.mutations.SET_DB_CACHE](s, cache) {
        const state = s;
        state.database.cache = (cache || []).map((i) => new Cache(i));
    },
    [Types.mutations.SET_DB_BADGES](s, badges) {
        const state = s;
        const newBadges = (badges || [])
            .map((i) => new Badge(i))
            .sort(function(a, b) {
                return b.badgeId - a.badgeId;
            });
        state.database.badges = newBadges;
    },
    [Types.mutations.SET_DB_STUDENT_AGGREGATES](s, studentAggregates) {
        const state = s;
        // state.database.studentAggregates = (studentAggregates || []).map((i) => new StudentAggregate(i));
        // use key value pairs for faster lookups
        const obj = {};
        studentAggregates.forEach((i) => {
            obj[i.studentId] = new StudentAggregate(i);
        });
        state.database.studentAggregates = obj;
    },
    [Types.mutations.SET_DB_STUDENT_BADGES](s, studentBadges) {
        const state = s;
        state.database.studentBadges = (studentBadges || [])
            .map(function(i) {
                const badge = new StudentBadge(i);
                return badge;
            });
    },
    [Types.mutations.UPSERT_STUDENT_BADGES](s, toUpsert) {
        const state = s;
        const rows = state.database.studentBadges.slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.badgeId}_${row.schoolStaffId}_${row.studentId}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.badgeId}_${row.schoolStaffId}_${row.studentId}`;
            unique.set(key, row);
        });
        // save to state
        state.database.studentBadges = [...unique.values()].map((i) => new StudentBadge(i));
    },
    [Types.mutations.SET_DB_STUDENTS](s, students) {
        const state = s;
        state.database.students = students;
    },
    [Types.mutations.SET_DB_SCHOOL_TERMS](s, schoolTerms) {
        const state = s;
        state.database.schoolTerms = (schoolTerms || []).map((i) => new SchoolTerm(i));
    },
    [Types.mutations.SET_DB_TEACHERS](s, teachers) {
        const state = s;
        state.database.teachers = (teachers || []).map((i) => new Teacher(i));
    },
    [Types.mutations.SET_ERROR](s, err) {
        const state = s;

        window.console.error(err);
        state.error = err;
        setTimeout(() => {
            state.error = null;
        }, 3000);
    },
    [Types.mutations.NAVIGATE_BACK_OR_HOME]() {
        if (document.referrer) {
            window.history.back();
        }
        const lastRoute = document.referrer;
        if (lastRoute) {
            const modalRoutePaths = ['/portfolio', '/google', '/school'];
            const inModalView = Boolean(modalRoutePaths.some((p) => lastRoute.indexOf(p) > -1));
            if (inModalView) {
                window.location.href = '/home';
                return;
            }
            window.history.back();
        } else {
            window.location.href = '/home';
        }
    },
    [Types.mutations.SET_DB_STUDENT_MARKING_PERIOD_GRADES](s, toUpsert) {
        const state = s;
        const rows = state.database.studentMarkingPeriodGrades.slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.studentEnrollmentId}_${row.schoolTermMarkingPeriodId}_${row.courseSectionId}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.studentEnrollmentId}_${row.schoolTermMarkingPeriodId}_${row.courseSectionId}`;
            unique.set(key, row);
        });
        // save to state
        state.database.studentMarkingPeriodGrades = [...unique.values()].map((i) => new StudentMarkingPeriodGrade(i));
    },
    [Types.mutations.SET_DB_STUDENT_PDF_REPORTS](s, toUpsert) {
        const state = s;
        const rows = state.database.studentPdfReports.slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.studentOfficialPdfId}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.studentOfficialPdfId}`;
            unique.set(key, row);
        });
        // save to state
        state.database.studentPdfReports = [...unique.values()].map((i) => new StudentPdfReport(i));
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_DOWNLOAD_EXCEL](s, enabled) {
        const state = s;
        state.database.anecdotalEventSearch.downloadExcel = enabled;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT](s, studentId) {
        const state = s;
        state.database.anecdotalEventSearch.studentId = studentId;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE](s, pointValue) {
        const state = s;
        state.database.anecdotalEventSearch.anecdotalPointValue = pointValue;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STAFF](s, schoolStaffId) {
        const state = s;
        state.database.anecdotalEventSearch.schoolStaffId = schoolStaffId;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY](s, anecdotalEventCategoryId) {
        const state = s;
        state.database.anecdotalEventSearch.anecdotalEventCategoryId = anecdotalEventCategoryId;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_TERM](s, schoolTermId) {
        const state = s;
        state.database.anecdotalEventSearch.schoolTermId = schoolTermId;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DATE](s, date) {
        const state = s;
        state.database.anecdotalEventSearch.anecdotalEventDate = date;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_PAGE_NUMBER](s, pageNumber) {
        const state = s;
        state.database.anecdotalEventSearch.pageNumber = pageNumber;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_LOADING](s, loading) {
        const state = s;
        state.database.anecdotalEventSearch.loading = loading;
    },
    [Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DESCRIPTION](s, anecdotalEventDescription) {
        const state = s;
        state.database.anecdotalEventSearch.anecdotalEventDescription = anecdotalEventDescription || null;
    },
    [Types.mutations.SET_ANECDOTAL_CREATE_OPEN](s, payload) {
        const state = s;
        state.createAnecdotalPanel.open = true;
        state.createAnecdotalPanel.students = [];
        if (payload) {
            state.createAnecdotalPanel.students = payload.students;
        }
    },
    [Types.mutations.SET_ANECDOTAL_CREATE_CLOSE](s) {
        const state = s;
        state.createAnecdotalPanel.open = false;
    },
    [Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN](s, payload) {
        const state = s;
        state.addStudentBadgePanel.open = true;
        state.addStudentBadgePanel.students = [];
        if (payload) {
            state.addStudentBadgePanel.students = payload.students;
            state.addStudentBadgePanel.badgeType = payload.badgeType || null;
        }
    },
    [Types.mutations.SET_STUDENT_BADGE_PANEL_CLOSE](s) {
        const state = s;
        state.addStudentBadgePanel.badgeType = null;
        state.addStudentBadgePanel.open = false;
    },
    [Types.mutations.APPLY_USER_SETTINGS](s, settings) {
        const state = s;
        state.settings = {
            ...state.settings,
            ...settings,
        };
        state.settings.dirty = false;
    },
    [Types.mutations.SAVE_SETTING_GRADE_BY](s, gradeBy) {
        const state = s;
        state.settings.gradebook.gradeBy = gradeBy;
        state.settings.dirty = true; // trigger save
    },
    [Types.mutations.SAVE_SETTING_GRADEBOOK_SORTINGS](s, toUpsert) {
        const state = s;
        const rows = (state.settings.gradebook.sortings || []).slice();

        const unique = new Map();
        rows.forEach((row) => { // existing rows
            const key = `${row.courseSectionId}`;
            unique.set(key, row);
        });
        toUpsert.forEach((row) => { // overwrite with new rows
            const key = `${row.courseSectionId}`;
            unique.set(key, row);
        });

        // save to state
        state.settings.gradebook.sortings = [...unique.values()];
        state.settings.dirty = true; // trigger save
    },
    [Types.mutations.SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD](s, schoolTermMarkingPeriodId) {
        const state = s;
        state.settings.schoolTermMarkingPeriodId = schoolTermMarkingPeriodId;
        state.settings.dirty = true; // trigger save
    },
    [Types.mutations.SAVE_SETTING_MESSAGE_NOTIFICATIONS_ENABLED](s, messageNotificationsEnabled) {
        const state = s;
        state.settings.communication.messageNotificationsEnabled = messageNotificationsEnabled;
        state.settings.dirty = true; // trigger save
    },
    [Types.mutations.SET_STUDENT_DATA_VIEW_ROWS](s, rows) {
        const state = s;
        state.database.studentDataView.rows = [...rows];
    },
    [Types.mutations.SET_STUDENT_DATA_VIEW_CURRENT_PAGE](s, currentPage) {
        const state = s;
        state.database.studentDataView.currentPage = currentPage;
    },
    [Types.mutations.SET_STUDENT_DATA_VIEW_PER_PAGE](s, perPage) {
        const state = s;
        state.database.studentDataView.perPage = perPage;
    },
    [Types.mutations.SET_STUDENT_DATA_VIEW_TOTAL_ROWS](s, totalRows) {
        const state = s;
        state.database.studentDataView.totalRows = totalRows;
    },
    [Types.mutations.SET_STUDENT_DATA_VIEW_COLUMNS](s, columns) {
        const state = s;
        state.database.studentDataView.columns = [...columns];
    },
    [Types.mutations.SET_STUDENT_DATA_VIEW_FILTER](s, filter) {
        const state = s;
        state.database.studentDataView.filter = filter;
    },
    [Types.mutations.SET_STUDENT_DATA_VIEW_SORT_INDEX](s, sortIndex) {
        const state = s;
        state.database.studentDataView.sortIndex = sortIndex;
    },
    [Types.mutations.TOGGLE_STUDENT_DATA_VIEW_SORT_ORDER](s) {
        const state = s;
        const {sortOrder} = state.database.studentDataView;
        if (sortOrder === 'asc') {
            state.database.studentDataView.sortOrder = 'desc';
        } else {
            state.database.studentDataView.sortOrder = 'asc';
        }
    },
    [Types.mutations.SET_ATTENDANCE_FOR_STUDENT](s, formattedAttendance) {
        const state = s;
        state.database.attendance.formattedAttendance = { ...formattedAttendance };
    },
    [Types.mutations.SET_ATTENDANCE_START_DATE](s, startDate) {
        const state = s;
        state.database.attendance.startDate = startDate;
    },
    [Types.mutations.SET_ATTENDANCE_END_DATE](s, endDate) {
        const state = s;
        state.database.attendance.endDate = endDate;
    },
    [Types.mutations.SET_ATTENDANCE_LABEL](s, label) {
        const state = s;
        state.database.attendance.label = label;
    },
    [Types.mutations.SET_ATTENDANCE_ONLY_COURSE_MEETINGS](s, onlyCourseMeetings) {
        const state = s;
        state.database.attendance.onlyCourseMeetings = onlyCourseMeetings;
    },
    [Types.mutations.SET_CREATE_MESSAGE_STAFF](s, staff) {
        const state = s;
        state.communication.createMessageStaff = staff;
    },
    [Types.mutations.SET_CREATE_MESSAGE_STUDENT](s, student) {
        const state = s;
        state.communication.createMessageStudent = student;
    },
    [Types.mutations.SET_CREATE_MESSAGE_GUARDIAN](s, guardian) {
        const state = s;
        state.communication.createMessageGuardian = guardian;
    },
    [Types.mutations.TOGGLE_CREATE_MESSAGE](s) {
        const state = s;
        const { createMessage } = state.communication;
        state.communication.createMessage = !createMessage;
    },
    [Types.mutations.TOGGLE_CREATE_ANNOUNCEMENT](s) {
        const state = s;
        const { createAnnouncement } = state.communication;
        state.communication.createAnnouncement = !createAnnouncement;
    },
    [Types.mutations.TOGGLE_CREATE_SCHOOL_ANNOUNCEMENT](s) {
        const state = s;
        const { createSchoolAnnouncement } = state.communication;
        state.communication.createSchoolAnnouncement = !createSchoolAnnouncement;
    },
    [Types.mutations.TOGGLE_CREATE_COMMUNICATION_GROUP](s) {
        const state = s;
        const { createCommunicationGroup } = state.communication;
        state.communication.createCommunicationGroup = !createCommunicationGroup;
    },
    [Types.mutations.TOGGLE_COMMUNICATION_POP_UP_CHAT](s) {
        const state = s;
        const { popupChat } = state.communication;
        state.communication.popupChat = !popupChat;
    },
    [Types.mutations.TOGGLE_COMMUNICATION_MESSAGE_NOTIFICATION](s) {
        const state = s;
        const { messageNotification } = state.communication;
        state.communication.messageNotification = !messageNotification;
    },
    [Types.mutations.SET_SELECTED_COMMUNICATION_GROUP](s, communicationGroup) {
        const state = s;
        state.communication.communicationGroup = communicationGroup;
    },
    [Types.mutations.SET_USER_TWO_FACTOR_AUTH_METHOD_ID](s, userTwoFactorAuthMethodId) {
        const state = s;
        state.user.twoFactorAuth.userTwoFactorAuthMethodId = userTwoFactorAuthMethodId;
    },
    [Types.mutations.SET_VERIFICATION_KEY](s, verificationKey) {
        const state = s;
        state.user.twoFactorAuth.verificationKey = verificationKey;
    },
    [Types.mutations.SET_VERIFICATION_OTP](s, oneTimePassword) {
        const state = s;
        state.user.twoFactorAuth.oneTimePassword = oneTimePassword;
    },
    [Types.mutations.SET_CIPHER_SESSION_ID](s, cipherSessionId) {
        const state = s;
        state.user.twoFactorAuth.cipherSessionId = cipherSessionId;
    },
    [Types.mutations.SET_CIPHER_EPOCH_EXPIRY](s, cipherEpochExpiry) {
        const state = s;
        state.user.twoFactorAuth.cipherEpochExpiry = cipherEpochExpiry;
    },
    [Types.mutations.SET_CIPHER_CHALLENGE_METHOD](s, challengeMethod) {
        const state = s;
        state.user.twoFactorAuth.challengeMethod = challengeMethod;
    },
    [Types.mutations.SET_CIPHER_SESSION_LOCKED](s) {
        const state = s;
        state.user.twoFactorAuth.cipherSessionId = null;
        state.user.twoFactorAuth.cipherEpochExpiry = null;
    },
    [Types.mutations.CYCLE_APP_KEY](s) {
        const state = s;
        state.appKey += 1;
    },
    [Types.mutations.REMOVE_CURRENT_FROM_QUEUE](state) {
        state.queue.pending.shift();
    },
    [Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION](s, payload) {
        const state = s;
        state.configuration.templateAssignments.bulkSelection = payload.values;
    },
    [Types.mutations.SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SAVING](s, payload) {
        const state = s;
        state.configuration.templateAssignments.savingBulkEdit = payload;
    },
    [Types.mutations.RESET_SCREEN_RECORD](s) {
        const state = s;
        const { screenRecord } = state;
        screenRecord.isRecording = false;
        screenRecord.recordingFile = null;
    },
    [Types.mutations.SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SELECTION](s, payload) {
        const state = s;
        state.portfolio.teacher.bulkSelection = payload.values;
    },
    [Types.mutations.SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SAVING](s, payload) {
        const state = s;
        state.portfolio.teacher.savingBulkEdit = payload;
    },
    [Types.mutations.SET_DB_DATA_SYSTEMS](s, dataSystems) {
        const state = s;
        state.database.dataSystems = dataSystems.map((i) => new DataSystem(i));
    },
    [Types.mutations.EDIT_DATA_SYSTEM_SYNC_FILE](s, { filePresentation, localFile, syncFile }) {
        const state = s;
        const dataSystems = state.database.dataSystems.map((ds) => {
            const dataSystem = { ...ds };
            dataSystem.files = ds.files.map((dataSystemFile) => {
                const f = dataSystemFile;
                if (f.syncFile.internalName == syncFile.internalName) {
                    f.localFile = localFile;
                    f.localFile.filePresentation = filePresentation;
                }
                return dataSystemFile;
            });
            return dataSystem;
        });

        state.database.dataSystems = dataSystems.slice();
    },
};

const output = {
    ...mutations,
    ...legacyMutations,
};

export default output;
