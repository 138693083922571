function CourseWork(props, gradeTemplate) {
    this.courseWorkId = props.courseWorkId; // sort by created
    this.courseWorkDomain = 'Local';

    this.courseSectionId = props.courseSectionId;
    this.schoolTermId = props.schoolTermId;
    this.schoolTermMarkingPeriodId = props.schoolTermMarkingPeriodId;

    this.teacherExtCourseWorkId = props.teacherExtCourseWorkId;
    this.courseWorkTitle = props.courseWorkTitle;
    this.courseWorkDescription = props.courseWorkDescription;

    this.assigneeMode = props.assigneeMode;
    this.maxPoints = props.maxPoints;
    this.allowExtraCredit = props.allowExtraCredit;
    this.courseWorkWeight = props.courseWorkWeight;

    this.hideMarks = props.hideMarks;
    this.excluded = props.excluded;

    this.publishDate = null;
    if (props.publishDate) {
        this.publishDate = (props.publishDate || '').substring(0, 10); // YYYY-MM-DD
    }
    this.publishTime = props.publishTime;

    this.dueDate = null;
    if (props.dueDate) {
        this.dueDate = (props.dueDate || '').substring(0, 10); // YYYY-MM-DD
    }
    this.dueTime = props.dueTime;

    this.gradeTemplateCategoryId = props.gradeTemplateCategoryId;

    // remove these and instead pass it to the model
    this.gradeCategoryId = props.gradeCategoryId;
    this.categoryName = props.categoryName;
    this.color = props.color;
    this.gradeTemplateId = props.gradeTemplateId;

    if (gradeTemplate) {
        const { categories } = gradeTemplate;
        const category = categories.find((c) => props.gradeTemplateCategoryId == c.gradeTemplateCategoryId) || null;
        if (category) {
            this.gradeCategoryId = category.gradeCategoryId;
            this.categoryName = category.categoryName;
            this.color = category.color;
            this.gradeTemplateId = category.gradeTemplateId;
        }
    }
    this.gradeCategoryId = props.gradeCategoryId; // added via inner join
    this.categoryName = props.categoryName; // added via inner join
    this.color = props.color; // added via inner join
    this.gradeTemplateId = props.gradeTemplateId; // added via inner join

    // this.sortDate = props.sortDate;
    // this.numericSortDate = props.numericSortDate;

    this.creatorUserId = props.creatorUserId;
    this.created = props.created;
    this.modified = props.modified;
    this.deleted = props.deleted;
    this.author = props.author;
    this.linkingGuid = props.linkingGuid;
    this.isExternal = props.isExternal;
}

module.exports = CourseWork;
