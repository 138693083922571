function StudentMarkingPeriodGrade(props) {
    this.schoolTermMarkingPeriodId = props.schoolTermMarkingPeriodId;
    this.studentEnrollmentId = props.studentEnrollmentId;
    this.courseSectionId = props.courseSectionId;
    this.courseSectionStudentId = props.courseSectionStudentId;
    this.markingPeriodValidMarkId = props.markingPeriodValidMarkId;
    this.markingPeriodValidExamMarkId = props.markingPeriodValidExamMarkId;
    this.narrativeComment = props.narrativeComment;
    this.level = props.level;
    this.isFinal = props.isFinal;
    this.comments = props.comments;
    this.deleted = props.deleted;
    this.examMark = props.examMark || null;
    this.mark = props.mark || null;
    this.markDescription = props.markDescription;
    this.numericEquivalent = props.numericEquivalent;
    this.passing = props.passing;
    this.conduct = props.conduct;
}

module.exports = StudentMarkingPeriodGrade;
