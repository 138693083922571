/* *
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
* */

const wrapper = require('../wrapper');

/**
 * @param {Object} params - http request parameters
 * @param {Object} params.body
 * @param {String} params.body.credential
 * @param {Function} callback
 */
export default function (params, callback) {
    wrapper.default('googleOneTapValidation', 'POST', '/api/auth/google/signin', params, callback);
}
