const HomePage = () => import( '../../rootComponents/home/HomePage.vue');
const SettingsMenu = () => import( '../../rootComponents/SettingsMenu.vue');
const CreateBugReport = () => import( '../../rootComponents/CreateBugReport.vue');
const SubmitBugReport = () => import( '../../rootComponents/SubmitBugReport.vue');
const ListBugReports = () => import( '../../rootComponents/ListBugReports.vue');

const baseRoutes = [
    {
        name: 'Home',
        path: '/home',
        component: HomePage,
        meta: {
            roles: ['All'],
        },
    },
    {
        name: 'SettingsMenu',
        path: '/configuration',
        component: SettingsMenu,
        meta: {
            roles: ['School Admin', 'Teacher', 'Staff'],
        },
    },
    {
        name: 'ListBugReports',
        path: '/bug-reports',
        component: ListBugReports,
        meta: {
            roles: ['School Admin', 'Teacher', 'Staff'],
        },
    },
    {
        name: 'CreateBugReport',
        path: '/bug-report/create',
        component: CreateBugReport,
        meta: {
            roles: ['School Admin', 'Teacher', 'Staff'],
        },
    },
    {
        name: 'SubmitBugReport',
        path: '/bug-report/submit',
        component: SubmitBugReport,
        meta: {
            roles: ['School Admin', 'Teacher', 'Staff'],
        },
    },
];

export default baseRoutes;
