/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import submitContactForm from './submitContactForm';
import submitSupportTicket from './submitSupportTicket';

export {
    submitContactForm,
    submitSupportTicket,
 };
