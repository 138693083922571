/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getAveragesForStaff from './getAveragesForStaff';
import getAveragesForStudent from './getAveragesForStudent';
import saveByStaff from './saveByStaff';

export {
    getAveragesForStaff,
    getAveragesForStudent,
    saveByStaff,
 };
