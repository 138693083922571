import async from 'async';
import moment from 'moment';
import network from '../../lib/network';
import Types from '../../store/Types';

export default {
    getActivities({ commit, rootState }, courseSectionIds) {
        commit('setIsFetchingActivities', true);
        async.auto({
            activities(next) {
                network.getActivityForStaffId(rootState.user, rootState.user.school.schoolStaffId, courseSectionIds, (err, res) => {
                    if (err) return next(err);
                    const activities = res.history.map((a) => {
                        const activity = a;
                        activity.date = moment(activity.submissionDate).format('YYYY-MM-DD');
                        activity.time = moment(activity.submissionDate).format('hA');
                        activity.fullDate = moment(activity.submissionDate).format('ddd MMM Do YYYY h:mm A');
                        return activity;
                    });
                    return next(err, activities);
                });
            },
        }, (err, results) => {
            commit('setIsFetchingActivities', false);
            if (err) return commit(Types.mutations.HANDLE_ERROR, err);
            commit('setActivities', results.activities);
        });
    },
};
