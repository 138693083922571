function SchoolStaff(props) {
    this.schoolId = props.schoolId;
    this.schoolTermId = props.schoolTermId;
    this.schoolStaffId = props.schoolStaffId;
    this.lastName = props.lastName;
    this.firstName = props.firstName;
    this.jobTitle = props.jobTitle;
    this.email = props.email;
    this.deleted = props.deleted;
    this.etlSourced = props.etlSourced;
}

module.exports = SchoolStaff;
