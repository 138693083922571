const request = require('./requestWrapper');

const syncGradesHost = '';

module.exports.google = require('./google');
module.exports.guardians = require('./guardians');
module.exports.students = require('./students');
module.exports.gradeTemplates = require('./gradeTemplates');
module.exports.grades = require('./grades');
module.exports.upload = require('./upload');
module.exports.interactions = require('./interactions');
module.exports.download = require('./download');
module.exports.reportCards = require('./reportCards');
module.exports.statistics = require('./statistics');
module.exports.studentGrades = require('./studentGrades');
module.exports.validMarks = require('./validMarks');
module.exports.auth = require('./auth');
module.exports.schools = require('./schools');
module.exports.schoolStaff = require('./schoolStaff');
module.exports.schoolTerms = require('./schoolTerms');
module.exports.markingPeriods = require('./markingPeriods');
module.exports.userRoles = require('./userRoles');
module.exports.roles = require('./roles');
module.exports.users = require('./users');
module.exports.reports = require('./reports');

module.exports.getReleaseNotes = function (callback) {
    request.get(`${syncGradesHost}/release_notes.html`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.text);
    });
};

module.exports.getMissingGrades = function (schoolId, schoolTermId, callback) {
    request.get(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/gradeTotals`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.getAttendanceForDates = function (schoolId, schoolTermId, courseSectionId, start, end, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/sections/${courseSectionId}/attendance?start=${start}&end=${end}`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getAttendance = function (schoolId, schoolTermId, courseSectionId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/sections/${courseSectionId}/attendance`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.saveAttendance = function (params, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${params.schoolId}/terms/${params.schoolTermId}/sections/${params.courseSectionId}/students/${params.courseSectionStudentId}/attendance`)
        .send({
            attendanceDate: params.attendanceDate, attendanceState: params.attendanceState, comment: params.comment, remoteAttendance: params.remoteAttendance,
        })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.saveSchoolAttendance = function (params, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${params.schoolId}/terms/${params.schoolTermId}/students/${params.studentId}/attendance`)
        .send({ attendanceDate: params.attendanceDate, attendanceState: params.attendanceState, comment: params.comment })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getSchoolAttendance = function (schoolId, schoolTermId, date, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/attendance/${date}`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getSchoolAttendanceTotalsByStaffId = function (user, startDate, endDate, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/attendance/totals/`)
        .query({ startDate, endDate })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getJoinedAttendanceTotalsByStaffId = function (user, staffId, courseSectionIds, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/attendance/totals`)
        .set('accept', 'json')
        .query({ 'courseSectionIds[]': courseSectionIds })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getCourseAttendanceTotals = function (user, date, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/course/attendance`)
        .query({ date })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getCourseAttendanceTotalsByStaffId = function (user, startDate, endDate, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/course/attendance/totals/`)
        .query({ startDate, endDate })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getParticipationTotalsByStaffId = function (user, startDate, endDate, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/submission/totals/`)
        .query({ startDate, endDate })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getGradeTotalsByStaffId = function (user, startDate, endDate, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/grade/totals/`)
        .query({ startDate, endDate })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getLinkedTotalsByStaffId = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/linked/totals/`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getStudentsWithGoogleAccount = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/students`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getStudentsForTerm = function (schoolId, schoolTermId, callback) {
    genericTermGet(schoolId, schoolTermId, 'students', callback);
};

module.exports.getStudentSchedulesForTerm = function (schoolId, schoolTermId, callback) {
    genericTermGet(schoolId, schoolTermId, 'studentsWithSchedules', callback);
};

module.exports.getSchedulesForTerm = function (schoolId, schoolTermId, callback) {
    genericTermGet(schoolId, schoolTermId, 'schedules', callback);
};

module.exports.getSectionsForTerm = function (schoolId, schoolTermId, callback) {
    genericTermGet(schoolId, schoolTermId, 'sections', callback);
};

module.exports.teachers = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/teachers`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.teachersWithStatistics = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/teachersStatistics`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.teacherByEmail = function (user, email, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/teachers/${email}/`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.courses = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.course = function (user, googleCourseId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

// module.exports.logOut = function (callback) {
//     request
//         .get(`${syncGradesHost}/api/logout`)
//         .set('accept', 'json')
//         .end((err, res) => {
//             if (err) return callback(err);
//             callback(err, res.body);
//         });
// };

function genericTermGet(schoolId, schoolTermId, type, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/${type}`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
}

module.exports.getGoogleCoursesForStaffId = function (user, staffId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/googleCourses`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getCoursesForStaffId = function (user, staffId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/courses`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getStudentsForStaffId = function (user, staffId, courseSectionIds, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/students`)
        .set('accept', 'json')
        .query({ 'courseSectionIds[]': courseSectionIds })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getSubmissionDataForStaffId = function (user, staffId, courseSectionIds, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/submission/data`)
        .set('accept', 'json')
        .query({ 'courseSectionIds[]': courseSectionIds })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getSubmissionLookupsForStaffId = function (user, staffId, courseSectionIds, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/submission/lookups`)
        .set('accept', 'json')
        .query({ 'courseSectionIds[]': courseSectionIds })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getCourseWorkForStaffId = function (user, staffId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/courseWork`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getCourseWorkForGoogleCourse = function (user, googleCourseId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/courseWork`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.saveCourseWorkProperties = function (user, markingPeriods, courseWork, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courseWorkProperties`)
        .set('accept', 'json')
        .send({ gradeTemplateUserMarkingPeriods: markingPeriods, courseWork })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getGradeTemplatesForStaff = function (user, staffId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/gradeTemplates`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getCourseWorkGradeTotalsForStaffId = function (user, staffId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/courseWork/totals`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getActivityForStaffId = function (user, staffId, courseSectionIds, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}/submissionHistory`)
        .set('accept', 'json')
        .query({ 'courseSectionIds[]': courseSectionIds })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.createDefaultTemplates = function (schoolId, schoolTermId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/grade/template/defaults`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getCourseInformation = function (user, courseSectionId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getGradesForAssignment = function (user, courseSectionId, googleCourseWorkId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}/courseWork/${googleCourseWorkId}/grades`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.setGoogleEmailForStudent = function (user, studentId, googleEmail, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/student/${studentId}/googleEmail/${googleEmail}`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.setGoogleEmailForTeacher = function (user, schoolStaffId, googleEmail, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${schoolStaffId}/googleEmail/${googleEmail}`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.setGoogleEmailForStudentBulk = function (user, students, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/students/googleEmail`)
        .set('accept', 'json')
        .send({ matches: students })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getAveragesForCourse = function (user, courseSectionId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}/averages`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getAverageImpactForStudent = function (user, googleCourseId, googleCourseWorkId, studentEnrollmentId, callback) {
    const { schoolId } = user.school;
    const { schoolTermId } = user.school;
    request
        .get(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/google/courses/${googleCourseId}/students/${studentEnrollmentId}/averages?googleCourseWorkId=${googleCourseWorkId}`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getCommentsForCourse = function (user, courseSectionId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}/comments`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.logError = function (clientError, callback) {
    request
        .post(`${syncGradesHost}/api/client/error`)
        .send({ clientError })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};
