import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadGradeTemplates(commit, state) {
    const {
        schoolId, schoolTermId,
    } = state.user.school;

    network.gradeTemplates.listGradeTemplatesForSchool({ url: { schoolId, schoolTermId } }, (err, res) => {
        if (err) return commit(Types.mutations.SET_ERROR, err);
        const { gradeTemplates } = res;
        commit(Types.mutations.SET_DB_GRADE_TEMPLATES, gradeTemplates);
    });
}
