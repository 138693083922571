/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import redirectToFeedback from './redirectToFeedback';
import upsertFeedbackAccount from './upsertFeedbackAccount';

export {
    redirectToFeedback,
    upsertFeedbackAccount,
 };
