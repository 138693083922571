import async from 'async';
import Types from '../Types';
import * as network from '../../network';

export default function bulkEditCourseWorkState(payload, commit, state) {
    const { assignments, callback } = payload;

    const { schoolId, schoolTermId } = state.user.school;
    const googleCourseWork = state.database.googleCourseWork.slice();

    async.eachOfLimit(assignments, 3, (a, idx, nextAssignment) => {
        const params = {
            url: {
                schoolId,
                schoolTermId,
                googleCourseId: a.googleCourseId,
                googleCourseWorkId: a.googleCourseWorkId,
                googleCourseWorkState: a.googleCourseWorkState,
            },
        };
        network.googleCourseWork.editCourseWorkState(params, (err, res) => {
            if (err) return nextAssignment(err);
            nextAssignment(err, res);
        });
    }, (err) => {
        if (err) return callback(err);

        commit(Types.mutations.SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SAVING, false);
        commit(Types.mutations.SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SELECTION, { values: [] });
        const output = [];

        assignments.forEach((a) => {
            const item = googleCourseWork.find((g) => g.googleCourseWorkId == a.googleCourseWorkId);
            if (!item) return;

            item.googleCourseWorkState = a.googleCourseWorkState;
            item.deleted = a.googleCourseWorkState == 'DELETED';
            output.push(item);
        });
        commit(Types.mutations.SET_DB_GOOGLE_COURSE_WORK, output);
    });
}
