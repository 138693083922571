const tableEncryptionSettings = [
    // {
    //     tableName: 'students',
    //     encryptedColumns: [],
    //     hashedColumns: [{
    //         name: 'extStudentId',
    //     }],
    // },
    {
        tableName: 'studentEnrollments',
        encryptedColumns: [
            {
                name: 'firstName',
                maskedValue: 'REDACTED',
            },
            {
                name: 'lastName',
                maskedValue: 'REDACTED',
            },
            {
                name: 'street',
                maskedValue: null,
            },
            {
                name: 'city',
                maskedValue: null,
            },
            {
                name: 'state',
                maskedValue: null,
            },
            {
                name: 'zip',
                maskedValue: null,
            },
            {
                name: 'primaryGuardian',
                maskedValue: 'REDACTED',
            },
            {
                name: 'phoneNumber',
                maskedValue: null,
            },
            {
                name: 'ethnicity',
                maskedValue: null,
            },
            {
                name: 'emergencyPhoneNumber',
                maskedValue: null,
            },
            {
                name: 'dob',
                maskedValue: null,
            },
            {
                name: 'gender',
                maskedValue: null,
            },
            {
                name: 'pictureGuid',
                maskedValue: null,
            },
            {
                name: 'specialEd',
                maskedValue: null,
            },
            {
                name: 'physicalDisability504',
                maskedValue: null,
            },
            {
                name: 'homeLanguage',
                maskedValue: null,
            },
            {
                name: 'englishLearner',
                maskedValue: null,
            },
            {
                name: 'englishLearnerDescription',
                maskedValue: null,
            },
            {
                name: 'admitDate',
                maskedValue: null,
            },
        ],
        hashedColumns: [],
    },
    {
        tableName: 'studentBiographical',
        encryptedColumns: [
            {
                name: 'bioValue',
                maskedValue: 'REDACTED',
            },
        ],
        hashedColumns: [],
        shouldEncryptRow(row) {
            const { bioCategory } = row;
            return ['Biographical', 'Special Education Program', 'English Language Learners'].includes(bioCategory);
        },
    },
    {
        tableName: 'studentGuardianBio',
        encryptedColumns: [
            {
                name: 'guardianTitle',
                maskedValue: null,
            },
            {
                name: 'guardianFirstName',
                maskedValue: 'REDACTED',
            },
            {
                name: 'guardianLastName',
                maskedValue: 'REDACTED',
            },
            {
                name: 'residesWithStudent',
                maskedValue: null,
            },
            {
                name: 'relationToStudent',
                maskedValue: null,
            },
            {
                name: 'guardianAuthorization',
                maskedValue: 'REDACTED',
            },
            {
                name: 'oralLanguage',
                maskedValue: null,
            },
            {
                name: 'writtenLanguage',
                maskedValue: null,
            },
        ],
        hashedColumns: [{
            name: 'oralLanguage',
        }, {
            name: 'writtenLanguage',
        }],
        /* {
            name: 'guardianNameConstraint',
        }, */
    },
    {
        tableName: 'studentGuardianPhones',
        encryptedColumns: [{
            name: 'phoneNumber',
        }],
        hashedColumns: [{
            name: 'phoneNumber',
        }],
    },
    {
        tableName: 'studentGuardianChangeLogs',
        encryptedColumns: [{
            name: 'previousStudentGuardian',
            maskedValue: null,
        }, {
            name: 'studentGuardian',
            maskedValue: null,
        }],
        hashedColumns: [],
    },
];

export { tableEncryptionSettings };
