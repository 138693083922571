function StaffUser(props) {
    this.roleId = props.roleId;
    this.roleName = props.roleName;

    this.userId = props.userId;
    this.userRoleId = props.userRoleId;
    this.userName = props.userName;
    this.schoolEmail = props.schoolEmail;
    this.unlinkedGoogleEmail = props.unlinkedGoogleEmail;

    this.googleEmail = props.googleEmail;
    this.firstName = props.firstName;
    this.lastName = props.lastName;
    this.jobTitle = props.jobTitle;
    this.userDeleted = props.userDeleted;
    this.roleDeleted = props.roleDeleted;
    this.etlSourced = props.etlSourced;

    this.userPermissions = props.userPermissions || null;
}

module.exports = StaffUser;
