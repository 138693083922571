<template v-if="isRouterReady">
<div>
    <TheLandingLayout v-if="isLandingPage" />
    <template v-else-if="!isLandingServer">
        <TheBlankLayout v-if="isPublicRoute" />
        <TheDistrictDashboardLayout v-else-if="isStateDashboard" />
        <b-overlay
            v-else
            opacity="1"
            variant="transparent"
            blur="0.5em"
            rounded="sm"
            :show="isSoftLocked"
            :z-index="1000"
            :style="[isSoftLocked ? { height: '100vh', width: '100vw' } : {}]"
        >
            <template #overlay>
                <SoftLocked v-if="isSoftLocked" />
            </template>
            <TheRouterLayout />
        </b-overlay>
    </template>
</div>
</template>

<script>
import Vue from 'vue';

const TheLandingLayout = () => import('./layouts/TheLandingLayout/TheLandingLayout.vue');
const TheBlankLayout = () => import('./layouts/BlankLayout/TheBlankLayout.vue');
const TheRouterLayout = () => import('./layouts/TheRouterLayout/TheRouterLayout.vue');
const TheDistrictDashboardLayout = () => import('./layouts/DistrictDashboard/TheDistrictDashboardLayout.vue');
const SoftLocked = () => import('./components/SoftLocked.vue');

export default {
    name: 'SyncGrades',
    components: {
        TheLandingLayout,
        TheBlankLayout,
        TheRouterLayout,
        TheDistrictDashboardLayout,
        SoftLocked,
    },
    data() {
        return {
            handle: null,
        };
    },
    computed: {
        isLandingServer() {
            return window.location.href.indexOf('https://www.syncgrades.com') > -1 || window.location.href.indexOf('https://syncgrades.com') > -1;
        },
        isRouterReady() {
            return this.$route.name && this.$route.meta;
        },
        isPublicRoute() {
            const { isRouterReady } = this;
            if (!isRouterReady) return false;
            const isPublicRoute = this.$route.meta.public || false;
            return isPublicRoute;
        },
        isLandingPage() {
            const { isRouterReady } = this;
            if (!isRouterReady) return false;
            const isLandingPage = this.$route.meta.landing || false;
            return isLandingPage;
        },
        isStateDashboard() {
            const { isRouterReady } = this;
            if (!isRouterReady) return false;
            const isStateDashboard = this.$route.meta.nysed || false;
            return isStateDashboard;
        },
        user() {
            return this.$store.state.user;
        },
        isSoftLocked() {
            const { user } = this;
            if (!user) return false;
            const { hasLocalPassword, twoFactorAuth } = user;
            if (!hasLocalPassword || !twoFactorAuth) return false;
            return twoFactorAuth.softLocked;
        },
    },
    watch: {
        user() {
            this.shortCircuit();
        },
        isSoftLocked(val) {
            if (val) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
        },
    },
    mounted() {
        this.shortCircuit();
    },
    methods: {
        shortCircuit() {
            // this is logic to kill vue dev tools in chrome when production

            const { config } = Vue;
            const { user } = this;
            let { handle } = this;

            const isLocal = (window.location.href.indexOf('http://localhost:8080') == 0);
            const isDev = (window.location.href.indexOf('https://app-dev.syncgrades.com') == 0);
            const isDemoSchool = user.school.extSchoolId == '00X000';

            if (isLocal) {
                config.devtools = true;
                if (handle) {
                    clearInterval(handle);
                    handle = null;
                }
                return;
            }
            if (isDev && isDemoSchool) {
                config.devtools = true;
                if (handle) {
                    clearInterval(handle);
                    handle = null;
                }
                return;
            }
            if (user.isSuperAdmin) {
                config.devtools = true;
                if (handle) {
                    clearInterval(handle);
                    handle = null;
                }
                return;
            }

            handle = setInterval(() => {
                config.devtools = false;
            }, 200);
        },
    },
};
</script>
