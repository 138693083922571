const assignment = {
    assigneeMode: 'ALL_STUDENTS',
    courseWorkTitle: null,
    courseWorkDescription: '',
    courseWorkWeight: 1,
    gradeTemplateCategoryId: 0,
    schoolTermMarkingPeriodId: null,
    maxPoints: 100,
    allowExtraCredit: false,
    dueDate: null,
    dueTime: null,
    publishDate: null,
    publishTime: null,
    teacherExtCourseWorkId: null,
    courseSectionId: null,
    excluded: false,
    hideMarks: false,
    isExternal: false,
    deleted: false,

    gradeTemplateId: null,
    gradeCategoryId: null,
    categoryName: null,
    color: null,
};

export default assignment;
