module.exports = {
    template: 'Template',
    scale: 'Scale',
    averageMethod: 'Average Method',
    gradeScales: 'Grade Scales',
    type: 'Type',
    locked: 'Locked',
    excusedGrade: 'Excused Grade',
    gradingCategories: 'Grading Categories',
    dropLowest: 'Drop Lowest',
};
