import async from 'async';
import moment from 'moment';
import Types from '../Types';
import * as network from '../../network';
import updateLocalGoogleTables from './etl/googleTables';

export function updateTeachersForCourse(/* googleCourseId, commit, state, dispatch */) {
    // can i deprecate this?
    /*
    net.google.refreshTeachersForCourse(state.user, googleCourseId, (err) => {
        if (err) {
            window.console.error(err);
            commit(Types.mutations.SET_ERROR, `
                Google returned an error when we asked for a teacher list. If this error continues, re-connect your google account and accept all permissions.
            `);
            return;
        }
        dispatch(Types.actions.REFRESH_GOOGLE_COURSE_TEACHERS);
    });
     */
}

export function createCourse(payload, commit, state) {
    const { course, callback } = payload;
    const { schoolId, schoolTermId } = state.user.school;

    const { name, primarySection, owner } = course;
    const { teachers, linkedSections } = course;
    const { courseSectionId } = primarySection;

    const url = { schoolId, schoolTermId, courseSectionId };
    const body = {
        name: name || null,
        owner: owner.googleEmail,
        teachers: teachers
            .filter((t) => t.googleEmail)
            .map((t) => ({ emailAddress: t.googleEmail })),
        courseSectionIds: linkedSections
            .map((c) => c.courseSectionId),
    };

    network.google.createGoogleForCourseSection({ url, body }, (err, resp) => {
        if (err) return callback(err);
        callback(err);

        const { googleCourseId } = resp.googleCourse;
        syncCourses([googleCourseId], commit, state, (syncErr) => {
            if (syncErr) {
                commit(Types.mutations.SET_ERROR, syncErr);
            }
        });
    });
}

export function linkCourse(params, commit, state) {
    const { googleCourseId, courseSectionId, callback } = params;
    const { schoolId, schoolTermId } = state.user.school;
    const { user } = state;
    const { googleAccountId } = user;
    const deleteParams = {
        url: { schoolId, schoolTermId, courseSectionId },
    };
    network.google.deleteLinkedCourse(deleteParams, (err) => {
        if (err) return callback(err);
        if (!googleCourseId) return callback(); // nothing to link
        const linkParams = {
            url: { schoolId, schoolTermId, googleCourseId },
            body: { courseSectionIds: [courseSectionId] },
        };
        network.google.linkCourse(linkParams, (err2) => {
            if (err2) return callback(err2);
            commit(Types.mutations.EDIT_GOOGLE_COURSE_COURSE_SECTION, {
                courseSectionId,
                googleCourseId,
                googleAccountId,
            });
            return callback();
        });
    });
}

export function syncCourses(googleCourseIds, commit, state, callback) {
    const { schoolId, schoolTermId } = state.user.school;
    const controller = new AbortController();

    commit(Types.mutations.SET_ABORT_CONTROLLER, controller);

    const threadCount = 2; // how many courses at once
    async.eachOfLimit(googleCourseIds, threadCount, (googleCourseId, idx, nextCourse) => {
        // Start Google Loader add to cache table
        const course = state.database.googleCourses.find((c) => c.googleCourseId == googleCourseId);
        const key = `googleCourseDataRefresh_${googleCourseId}`;
        commit(Types.mutations.CACHE_GOOGLE_COURSE_LOADING, course);

        const params = {
            url: { schoolId, schoolTermId, googleCourseId },
            abortController: controller,
        };

        async.auto({
            googleCourse(next) {
                network.google.refreshCourseById(params, (err, res) => {
                    if (err) return next(err);
                    const { googleCourse } = res;
                    if (googleCourse.deleted) {
                        return next(new Error('Google Course is deleted and cannot be synced'));
                    }
                    if (googleCourse.courseState !== 'ACTIVE') {
                        return next(new Error(`The google course state is ${googleCourse.courseState}, but must be ACTIVE in order to sync`));
                    }
                    next(err, googleCourse);
                });
            },
            teachers: ['googleCourse', function (results, next) {
                network.google.refreshTeachersForCourse(params, next);
            }],
            students: ['googleCourse', function (results, next) {
                network.google.refreshStudentsForCourse(params, next);
            }],
            topics: ['googleCourse', function (results, next) {
                network.google.refreshTopicsForCourse(params, next);
            }],
            assignments: ['topics', function (results, next) {
                network.google.refreshCourseWorkForCourse(params, next);
            }],
            grades: ['teachers', 'students', 'assignments', function (results, next) {
                network.google.refreshSubmissionsForCourse(params, next);
            }],
        }, 5, (err) => {
            if (err) {
                commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key }); // kill cached item
                return nextCourse(err);
            }

            // download was successful so, save timestamp to cache table
            const cache = {
                key,
                cacheTitle: `${course ? course.googleCourseName : 'Unknown Course'}`,
                cacheType: 'googleCourseTables',
                status: 'cached',
                timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
            };

            commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache); // mark as completed
            nextCourse(err);
        });
    }, (err) => {
        if (err) {
            commit(Types.mutations.SET_ERROR, err);
        }
        updateGoogleTables(commit, state, callback);
    });
}

export function updateGoogleTables(commit, state, callback) {
    // download the refreshed data from server
    updateLocalGoogleTables(commit, state, (err, res) => {
        if (err) {
            commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key: 'googleBaseTables' }); // kill loader
            return callback(err);
        }

        const cache = {
            key: 'googleBaseTables',
            cacheTitle: 'Google base Linking tables',
            cacheType: 'googleBaseTables',
            status: 'cached',
            timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
        };
        // all was successful so, save timestamp to cache table
        commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache); // mark as completed
        callback(err, res);
    });
}

export function updateGoogleGradeCategories({ googleCourseId, categories, callback }, commit, state) {
    const { schoolId, schoolTermId } = state.user.school;
    const params = {
        url: { schoolId, schoolTermId, googleCourseId },
        body: { gradeTemplateCategoryGoogleCategory: categories },
    };

    network.google.saveCategoryMappings(params, (err) => {
        if (err) return callback(err);
        callback(err);
    });
}
