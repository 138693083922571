import humps from 'humps';
import { tableDefinitions } from '../../database';

export default function (commit, state, tableName, callback) {
    const {
        schoolId, schoolTermId,
        role, schoolStaffId, studentEnrollmentId
    } = state.user.school;

    const params = { url: { schoolId, schoolTermId } };
    if (role === 'Student' || role === 'Guardian') {
        params.url.studentEnrollmentId = studentEnrollmentId;
    } else {
        params.url.schoolStaffId = schoolStaffId
    }
    const defs = tableDefinitions
        .filter((t) => {
            if (!t.saturation) return false;
            if (t.roles && !t.roles.includes(role)) return false;
            return true;
        });

    const table = defs.find((t) => t.tableName == tableName) || null;
    if (!table) return callback(new Error(`table ${tableName} not found`));

    table.saturation.saturate(params, (err, res) => {
        if (err) return callback(err);
        window.performance.mark(`startDataLoading_${tableName}`);
        commit(`SET_DB_${humps.decamelize(tableName).toUpperCase()}`, res[tableName]);
        window.performance.mark(`endDataLoading_${tableName}`);

        window.performance.measure(`${tableName}_dataLoad`, `startDataLoading_${tableName}`, `endDataLoading_${tableName}`);
        const [item] = performance.getEntriesByName(`${tableName}_dataLoad`);
        const message = `${tableName}_saturate: ${item.duration.toFixed(2)}ms, ${res[tableName].length} items`;
        window.syncGrades.log(message, 'info', 'saturate', item.duration.toFixed(2));
        callback();
    });
}
