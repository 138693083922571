import * as network from '../../network';
import Types from '../Types';

export function saveCourseWorkGrades(commit, state, courseWorkGrades, callback) {
    const { schoolId, schoolTermId, schoolStaffId } = state.user.school;
    const q = {
        url: { schoolId, schoolStaffId, schoolTermId },
        body: { courseWorkGrades },
    };
    network.courseWorkGrades.saveCourseWorkGrades(q, (err, results) => {
        if (err) return callback(err);
        const grades = courseWorkGrades.map((g) => {
            const grade = { ...g };

            const savedGrade = results.courseWorkGrades.find((r) => r.courseSectionId == grade.courseSectionId
                    && r.courseWorkId == grade.courseWorkId
                    && r.studentEnrollmentId == grade.studentEnrollmentId) || null;

            const invalidGrade = results.invalidGrades.find((r) => r.courseSectionId == grade.courseSectionId
                    && r.courseWorkId == grade.courseWorkId
                    && r.studentEnrollmentId == grade.studentEnrollmentId) || null;

            if (savedGrade && !invalidGrade) {
                grade.change = { history: savedGrade.change.history.slice() };
                return grade;
            }
                return null;
        }).filter((g) => g != null);

        commit(Types.mutations.SET_DB_COURSE_WORK_GRADES, grades);
        callback(err, { courseWorkGrades: grades, invalidGrades: results.invalidGrades });
    });
}
