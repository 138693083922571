import courseSubRoutes from './courseSubRoutes';

const TeacherList = () => import( '../../rootComponents/TeacherList.vue');
const TeacherInfo = () => import( '../../rootComponents/TeacherInfo.vue');

const teacherRoutes = [
    { // redirect
        path: '/teachers/:schoolEmail',
        redirect: '/teachers/:schoolEmail/info',
    },
    { // redirect
        path: '/teachers/:schoolEmail/classes/:extCourseSectionId',
        redirect: '/teachers/:schoolEmail/classes/:extCourseSectionId/info',
    },
    {
        name: 'Teachers',
        path: '/teachers',
        component: TeacherList,
        meta: {
            subHeader: true,
            title: 'Teacher List',
            icon: 'teachers',
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    }, {
        name: 'TeacherInfo',
        path: '/teachers/:schoolEmail/info',
        component: TeacherInfo,
        meta: {
            subHeader: true,
            title: 'Teacher Profile',
            icon: 'info',
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    },
];

// add alias so /teachers/:schoolEmail/classes/:extCourseSectionId/*
// uses same component as /classes/:extCourseSectionId/*

courseSubRoutes.forEach((r) => {
    if (r.name && r.meta?.teacherRouteName) {
        const route = {
            path: `/teachers/:schoolEmail/classes/:extCourseSectionId/${r.path}`,
            name: r.meta?.teacherRouteName,
            alias: `/classes/:extCourseSectionId/${r.path}`,
            meta: {
                ...r.meta,
                roles: ['School Admin', 'Staff', 'Teacher'],
            },
            component: r.component,
        };

        // @ts-ignore
        teacherRoutes.push(route);
    }
});

export default teacherRoutes;
