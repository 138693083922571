function Teacher(props) {
    this.lastName = props.lastName;
    this.firstName = props.firstName;
    this.schoolEmail = props.schoolEmail;
    this.googleEmail = props.googleEmail || null;
    this.unlinkedGoogleEmail = props.unlinkedGoogleEmail || null;

    this.userId = props.userId;
    this.schoolStaffId = props.schoolStaffId;
    this.jobTitle = props.jobTitle;

    this.etlSourced = props.etlSourced;

    this.googleAccountId = props.googleAccountId || null;
    this.hasGoogleAccount = Boolean(this.googleAccountId);

    this.googleTeacherId = props.googleTeacherId || null;
    this.googleUserId = props.googleUserId || null;
    this.awsImageUrl = props.awsImageUrl || null;

    this.courseSections = props.courseSections || [];
    this.linkedCourseSections = this.courseSections.filter((cs) => cs.googleCourseId);
    this.totals = props.totals || null;
}

module.exports = Teacher;
