/* eslint-disable max-len */
module.exports = {
    autoLink: 'Enlace automático',
    autoLinkDesc: 'Enlace todos los cursos',
    cancelLinking: 'Cancelar enlace',
    explanations: {
        enqueue: 'Se está procesando su solicitud para vincular automáticamente sus cursos. Esto puede tomar algún tiempo, pero verifique periódicamente el progreso. Puede ver el progreso haciendo clic en el botón verde de arriba.',
        processing: 'Se está procesando su solicitud para vincular automáticamente sus cursos. Esto puede tomar algún tiempo, pero verifique periódicamente el progreso. Puede ver el progreso haciendo clic en el botón verde de arriba.',
        completed: 'Su solicitud para vincular automáticamente sus cursos se ha completado.',
        completedWithErrors: 'Su solicitud para vincular automáticamente sus cursos se completó; sin embargo, hubo algunos errores. Puede ver el progreso haciendo clic en el botón amarillo de arriba.',
        skipped: 'Su solicitud para vincular automáticamente sus cursos se ha omitido porque no es necesario. Si tiene algún problema o pregunta sobre la vinculación automática, comuníquese con el soporte para obtener ayuda.',
        postponed: 'Su solicitud para vincular automáticamente sus cursos ha sido pospuesta. Esto se debe a que Google impone límites sobre la cantidad de invitaciones que un solo usuario puede enviar en un período de 24 horas. Volveremos a enviar su solicitud cada 24 horas hasta que se hayan enviado todas las invitaciones para sus cursos de Google. Esto generalmente solo afecta a los administradores; En la mayoría de los casos, los maestros deberían poder enviar una solicitud y completarla en el primer intento.',
        canceled: 'Su solicitud para vincular automáticamente sus cursos ha sido cancelada. Haga clic en el botón "Enlace automático" a continuación para enviar una solicitud para vincular automáticamente sus cursos y enviar invitaciones a los estudiantes nuevamente.',
        error: 'Se produjo un error al procesar su solicitud para vincular automáticamente sus cursos. Póngase en contacto con el soporte para obtener ayuda.',
        default: 'No ha solicitado vincular automáticamente sus cursos. Haga clic en el botón "Enlace automático" a continuación para enviar una solicitud para vincular automáticamente sus cursos y enviar invitaciones a los estudiantes. Puede verificar si necesita enviar una solicitud mirando en la pantalla de inicio.',
    },
    linkingStatus: 'Estado de vinculación',
    tasksPending: 'tareas pendientes',
    viewLinkingProgress: 'Ver el progreso de la vinculación',
};
