/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getCourseWorkForTeacher from './getCourseWorkForTeacher';
import getCourseWorkForStudent from './getCourseWorkForStudent';
import createCourseWork from './createCourseWork';
import deleteCourseWork from './deleteCourseWork';
import editCourseWorkState from './editCourseWorkState';
import editCourseWork from './editCourseWork';
import editCourseWorkProperties from './editCourseWorkProperties';
import gradeTemplateCategoryGoogleCategory from './gradeTemplateCategoryGoogleCategory';

export {
    getCourseWorkForTeacher,
    getCourseWorkForStudent,
    createCourseWork,
    deleteCourseWork,
    editCourseWorkState,
    editCourseWork,
    editCourseWorkProperties,
    gradeTemplateCategoryGoogleCategory,
 };
