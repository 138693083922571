module.exports = {
    teachers: 'Teachers',
    notLinked: 'not linked',
    through: 'through',
    sortBy: {
        sortBy: 'Sort By',
        defaultGoogleAlpha: 'Default - Google Alpha',
        participationRate: 'Participation Rate',
        grades: 'Grades',
        schoolAttendance: 'School Attendance',
        courseAttendance: 'Course Attendance',
    },
    pinToTop: 'Pin to top',
    unpin: 'Unpin',
    numberOfClasses: '{count} classes',
    numberOfPreps: '{count} preps',
    numberOfStudents: '{count} students',
    numberLinked: '{count} linked to Google courses',
    errorPopulatingTeachers: 'Error populating teachers',
};

module.exports.progressWidget = {
    participationRate: {
        title: '@:pages.teachers.sortBy.participationRate',
        subTitle: 'Google assignments',
        hover: 'Assignment student submission percentage (includes non linked courses)',
        progress: {
            tooltip0: '{count} students who submitted',
        },
        legend: {
            left: '{submissionCount} of {studentCount} participated',
            right: '',
        },
    },
    schoolAttendance: {
        title: '@:pages.teachers.sortBy.schoolAttendance',
        subTitle: 'Percent Present',
        hover: 'Daily attendance percent Present (late + present)',
        progress: {
            tooltip0: '{count} ({percent}%) Present',
            tooltip1: '{count} ({percent}%) Late',
            tooltip2: '{count} ({percent}%) Absent',
            tooltip3: '{count} ({percent}%) Excused',
        },
        legend: {
            left: '{percent}% - Absent',
            right: '',
        },
    },
    courseAttendance: {
        title: '@:pages.teachers.sortBy.courseAttendance',
        subTitle: 'Percent Present',
        hover: 'Course attendance percent Present (late + present)',
        progress: {
            tooltip0: '{count} ({percent}%) Present',
            tooltip1: '{count} ({percent}%) Late',
            tooltip2: '{count} ({percent}%) Absent',
            tooltip3: '{count} ({percent}%) Excused',
        },
        legend: {
            left: '{percent}% - Absent',
            right: '',
        },
    },
    grades: {
        title: '@:pages.teachers.sortBy.grades',
        subTitle: 'Student Assignment Pass Rate',
        hover: 'Assignment grade distributions (includes non linked courses)',
        progress: {
            tooltip0: '{count} ({percent}%) passing grades',
            tooltip1: '{count} ({percent}%) honors grades',
            tooltip2: '{count} ({percent}%) failing grades',
        },
        legend: {
            left: '{total} passing of {allAssignments} grades',
            right: '',
        },
    },
};
