const request = require('./requestWrapper');

const syncGradesHost = '';

module.exports.getUploadParameters = function (school, fileDetails, callback) {
    const params = { fileType: fileDetails.internalName, extension: fileDetails.fileExtension };
    request
        .post(`${syncGradesHost}/api/schools/${school.schoolId}/terms/${school.schoolTermId}/upload/form`)
        .send(params)
        .end((err, res) => {
            callback(err, res.body);
        });
};

module.exports.saveUpload = function (school, file, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${school.schoolId}/terms/${school.schoolTermId}/syncFiles`)
        .send({
            internalName: file.syncFile.internalName,
            bucketPath: file.bucketPath,
            fileSize: file.fileSize,
            fileLastModified: file.dateModified,
        })
        .end((err, res) => {
            callback(err, res.body);
        });
};

module.exports.processUpload = function (school, syncFileUploadId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${school.schoolId}/terms/${school.schoolTermId}/syncFiles/${syncFileUploadId}/process`)
        .end((err, res) => {
            callback(err, res.body);
        });
};

module.exports.loadSyncFiles = function (school, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${school.schoolId}/terms/${school.schoolTermId}/syncFiles`)
        .end((err, res) => {
            callback(err, res.body);
        });
};

module.exports.assignStudentImage = (school, file, callback) => {
    const extStudentId = file.fileName.split('.').shift();
    const imageGuid = file.bucketPath.split('/').pop().split('.').shift();
    request
        .post(`${syncGradesHost}/api/schools/${school.schoolId}/terms/${school.schoolTermId}/student/${extStudentId}/studentImage`)
        .send({ imageGuid })
        .end((err, res) => {
            callback(err, res.body);
        });
};
