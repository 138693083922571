const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.attendanceReversals = function (user, attendanceDate, submissionStart, submissionEnd, includeCreated, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/downloads/attendanceReversals`)
        .responseType('blob')
        .send({
            attendanceDate, submissionStart, submissionEnd, includeCreated,
        })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.eggFileForMarkingPeriod = function (user, schoolTermMarkingPeriodId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/reportCard/markingPeriod/${schoolTermMarkingPeriodId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(res.body.message || err);
            callback(err, res.body);
        });
};

module.exports.eggCSVForMarkingPeriod = function (user, schoolTermMarkingPeriodId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/reportCard/markingPeriod/${schoolTermMarkingPeriodId}/csv`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(res.body.message || err);
            callback(err, res.body);
        });
};

module.exports.courseAttendanceForDate = function (user, startDate, endDate, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/downloads/courseAttendanceForDate`)
        .responseType('blob')
        .send({
            startDate,
            endDate,
        })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.attendancePercentages = function (user, startDate, endDate, cutoff, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/downloads/attendancePercentages`)
        .responseType('blob')
        .send({ startDate, endDate, cutoff })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.studentLinks = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/downloads/studentLinks`)
        .responseType('blob')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.teacherLinks = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/downloads/teacherLinks`)
        .responseType('blob')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.studentProgressSummary = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/downloads/progressSummary`)
        .responseType('blob')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.assignmentsProgressForTeachers = function (user, googleCourseId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/googleCourse/${googleCourseId}/downloads/assignmentsProgress`)
        .responseType('blob')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.assignmentsProgressForSchoolAdmins = function (user, startDate, endDate, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/downloads/assignmentsProgress`)
        .responseType('blob')
        .send({
            startDate,
            endDate,
        })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.reportCardsCSVForTeachers = function (user, schoolTermMarkingPeriodId, courseSectionIds, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/markingPeriods/${schoolTermMarkingPeriodId}/downloads/reportCards`)
        .responseType('blob')
        .send({ courseSectionIds })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
