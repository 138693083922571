/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listStudents from './listStudents';
import studentImage from './studentImage';
import saveStudentAvatar from './saveStudentAvatar';
import ellStudents from './ellStudents';
import getBiographicsForStudent from './getBiographicsForStudent';

export {
    listStudents,
    studentImage,
    saveStudentAvatar,
    ellStudents,
    getBiographicsForStudent,
 };
