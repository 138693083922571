import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadAdminSchools(commit, state) {
    if (!state.user.isSuperAdmin) return;
    network.schools.listSchools({}, (err, res) => {
        if (err) return commit(Types.mutations.SET_ERROR, err);
        const { schools } = res;
        commit(Types.mutations.SET_DB_SCHOOLS, schools);
    });
}
