const SchoolAnecdotalLogs = () => import( '../../rootComponents/SchoolAnecdotalLogs.vue');
const EditAnecdotal = () => import( '../../rootComponents/anecdotals/EditAnecdotal.vue');
const ViewAnecdotal = () => import( '../../rootComponents/anecdotals/ViewAnecdotal.vue');

const anecdotalRoutes = [
    {
        name: 'AnecdotalLogs',
        path: '/anecdotal-logs',
        component: SchoolAnecdotalLogs,
        meta: {
            subHeader: true,
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    },
    {
        name: 'ViewAnecdotal',
        path: '/anecdotal-logs/:anecdotalEventId',
        component: ViewAnecdotal,
        meta: {
            subHeader: true,
            roles: ['School Admin', 'Staff', 'Teacher', 'Guardian', 'Student'],
        },
    },
    {
        name: 'EditAnecdotal',
        path: '/anecdotal-logs/:anecdotalEventId/edit',
        component: EditAnecdotal,
        meta: {
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    },
];

export default anecdotalRoutes;
