import Types from '../../../Types';
import * as network from '../../../../network';

export default function (commit, state, googleCourseId) {
    const params = {
        url: {
            schoolId: state.user.school.schoolId,
            schoolTermId: state.user.school.schoolTermId,
            googleCourseId,
        },
    };

    network.google.getGoogleCourseTopics(params, (err, res) => {
        if (err) return commit(Types.mutations.SET_ERROR, err);
        const { googleCourseTopics } = res;
        commit(Types.mutations.SET_DB_GOOGLE_COURSE_TOPICS, googleCourseTopics);
    });
}
