const AnecdotalSetup = () => import( '../../rootComponents/anecdotals/AnecdotalSetup.vue');
const BadgeSetup = () => import( '../../rootComponents/BadgeSetup.vue');
const EditBadge = () => import( '../../rootComponents/EditBadge.vue');
const EditAnecdotalCategory = () => import( '../../rootComponents/anecdotals/EditAnecdotalCategory.vue');
const GradeTemplateAssign = () => import( '../../rootComponents/GradeTemplates/GradeTemplateAssign.vue');
const GradeTemplateEditCreate = () => import( '../../rootComponents/GradeTemplateEditCreate.vue');
const GradeTemplateList = () => import( '../../rootComponents/GradeTemplateList.vue');
const MarkingPeriods = () => import( '../../rootComponents/MarkingPeriods.vue');
const SchoolAdmin = () => import( '../../rootComponents/schoolAdmin/SchoolAdmin.vue');
const ManageUsers = () => import( '../../rootComponents/users/ManageUsers.vue');
const SchoolTerms = () => import( '../../rootComponents/SchoolTerms.vue');
const SchoolTermSettings = () => import( '../../rootComponents/SchoolTermSettings.vue');
const UserEditOrCreate = () => import( '../../rootComponents/users/UserEditOrCreate.vue');

const schoolSettingsRoutes = [
    {
        name: 'SchoolAdmin',
        path: '/configuration/school',
        component: SchoolAdmin,
        children: [
            {
                name: 'ManageUsers',
                title: 'Manage Users',
                icon: 'fa fa-users',
                path: '/configuration/school/users',
                component: ManageUsers,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
            {
                name: 'UserEdit',
                icon: 'fa fa-users',
                path: '/configuration/school/users/:schoolEmail',
                component: UserEditOrCreate,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'SchoolTerms',
                title: 'School Terms',
                icon: 'flaticon2-calendar-1',
                path: '/configuration/school/terms',
                component: SchoolTerms,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'ConfigureTerm',
                title: 'Configure Term',
                icon: 'flaticon2-calendar-1',
                path: '/configuration/school/configure-term',
                component: SchoolTermSettings,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'BadgeSetup',
                title: 'Badge Setup',
                icon: 'flaticon2-tag',
                path: '/configuration/school/badges',
                component: BadgeSetup,
                meta: {
                    subHeader: true,
                    roles: ['School Admin', 'Teacher', 'Staff'],
                },
            },
            {
                name: 'EditBadge',
                icon: 'flaticon2-tag',
                path: '/configuration/school/badges/:badgeId',
                component: EditBadge,
                meta: {
                    roles: ['School Admin', 'Teacher', 'Staff'],
                },
            },
            {
                name: 'AnecdotalSetup',
                title: 'Anecdotal Setup',
                icon: 'flaticon2-clipboard',
                path: '/configuration/school/anecdotals',
                component: AnecdotalSetup,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'EditAnecdotalCategory',
                icon: 'flaticon2-clipboard',
                path: '/configuration/school/anecdotal/category/:anecdotalEventCategoryId',
                component: EditAnecdotalCategory,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'MarkingPeriods',
                title: 'Marking Periods',
                icon: 'flaticon2-calendar-2',
                path: '/configuration/school/marking-periods',
                component: MarkingPeriods,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
            {
                name: 'GradeTemplateList',
                path: '/configuration/school/grade-templates',
                title: 'Grade Templates',
                component: GradeTemplateList,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
            {
                name: 'GradeTemplateEditCreate',
                path: '/configuration/school/grade-templates/:gradeTemplateId',
                component: GradeTemplateEditCreate,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
            {
                name: 'GradeTemplateAssign',
                path: '/configuration/school/grade-template/assign',
                title: 'Template Assignments',
                component: GradeTemplateAssign,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
        ],
    },
];

export default schoolSettingsRoutes;
