const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.getAtsLookups = function getAtsLookups(user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/ats/lookups`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.changeLogs = function changeLogs(user, studentEnrollmentId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/student/${studentEnrollmentId}/guardianChangeLogs`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.guardiansByEnrollmentId = function guardiansByEnrollmentId(user, studentEnrollmentId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/student/${studentEnrollmentId}/guardians`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.saveGuardian = function saveGuardian(user, studentEnrollmentId, guardian, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/student/${studentEnrollmentId}/guardians`)
        .set('accept', 'json')
        .send(guardian)
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.setGuardianDeleted = function saveGuardian(user, studentEnrollmentId, studentGuardianId, callback) {
    request
        .delete(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/student/${studentEnrollmentId}/guardians/${studentGuardianId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
