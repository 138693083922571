/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import googleOneTapValidation from './googleOneTapValidation';

export {
    googleOneTapValidation,
 };
