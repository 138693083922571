/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getStudentMarkingPeriodGradesForTeacher from './getStudentMarkingPeriodGradesForTeacher';
import getStudentMarkingPeriodGradesForStudent from './getStudentMarkingPeriodGradesForStudent';
import downloadExcelFile from './downloadExcelFile';
import downloadGradeData from './downloadGradeData';
import uploadDataSource from './uploadDataSource';

export {
    getStudentMarkingPeriodGradesForTeacher,
    getStudentMarkingPeriodGradesForStudent,
    downloadExcelFile,
    downloadGradeData,
    uploadDataSource,
 };
