/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listStudentGuardians from './listStudentGuardians';
import getStudentGuardians from './getStudentGuardians';
import getStudentGuardianChangeLogs from './getStudentGuardianChangeLogs';
import saveStudentGuardians from './saveStudentGuardians';
import deleteStudentGuardians from './deleteStudentGuardians';
import getLogInStatusForTerm from './getLogInStatusForTerm';
import sendRegistrationEmail from './sendRegistrationEmail';

export {
    listStudentGuardians,
    getStudentGuardians,
    getStudentGuardianChangeLogs,
    saveStudentGuardians,
    deleteStudentGuardians,
    getLogInStatusForTerm,
    sendRegistrationEmail,
 };
