/* *
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
* */

const wrapper = require('../wrapper');

/**
 * @param {Object} params - http request parameters
 * @param {Object} params.body
 * @param {function Object() { [native code] }} params.body.transaction
 * @param {Function} callback
 */
export default function (params, callback) {
    wrapper.default('upsertTransaction', 'POST', '/api/sg-admin/purchasing/transactions', params, callback);
}
