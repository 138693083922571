function CourseWorkGrade(props) {
    this.schoolTermId = props.schoolTermId;
    this.courseSectionId = props.courseSectionId;
    this.courseWorkId = props.courseWorkId;
    this.studentEnrollmentId = props.studentEnrollmentId;
    this.mark = props.mark;
    this.deleted = props.deleted;
    this.comment = props.comment;
    this.change = props.change;
}

module.exports = CourseWorkGrade;
