import { decimalToPercent, round } from '../mixins/utilMixins';
export { formatCell as default, formatCell, createDataSet };

function formatCell(column, object) {
    const { source, name } = column;
    if (source == 'Biographical') {
        switch (name) {
            case 'student':
                return object;
            case 'extStudentId':
                const { extStudentId } = object;
                return `${extStudentId.substring(0, 3)}-${extStudentId.substring(3, 6)}-${extStudentId.substring(6, 9)}`;
            case 'address':
                if (!object.street) return '';
                return `${object.street}, ${object.city} ${object.state} ${object.zip}`;
            case 'dob':
                return (object.dob || '').substring(0, 10);
            default:
                return object[name];
        }
    }
    if (source == 'Anecdotals') {
        if (!object || !object.anecdotals) return "-";
        const { anecdotals } = object;
        const schoolTermIds = column.params.schoolTermIds || [];
        const anecdotalEventCategoryId = column.params.anecdotalEventCategoryId || null;

        switch (name) {
            case 'totalAnecdotalEvents':
                return anecdotals.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (anecdotalEventCategoryId && item.anecdotalEventCategoryId !== anecdotalEventCategoryId) return n;
                    return n + item.totalEvents;
                }, 0);
            case 'totalAnecdotalPositivePoints':
                return anecdotals.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (anecdotalEventCategoryId && item.anecdotalEventCategoryId !== anecdotalEventCategoryId) return n;
                    return n + item.totalPositivePoints;
                }, 0);
            case 'totalAnecdotalNegativePoints':
                return anecdotals.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (anecdotalEventCategoryId && item.anecdotalEventCategoryId !== anecdotalEventCategoryId) return n;
                    return n + item.totalNegativePoints;
                }, 0);
            case 'totalAnecdotalTotalResolved':
                return anecdotals.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (anecdotalEventCategoryId && item.anecdotalEventCategoryId !== anecdotalEventCategoryId) return n;
                    return n + item.totalResolved;
                }, 0);
            case 'totalAnecdotalResolvedPoints':
                return anecdotals.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (anecdotalEventCategoryId && item.anecdotalEventCategoryId !== anecdotalEventCategoryId) return n;
                    return n + item.totalResolvedPoints;
                }, 0);
            default:
                return null;
        }
    }

    if (source == 'Report Cards') {
        if (!object || !object.reportCardAverages) return "-";
        const { reportCardAverages } = object;
        const schoolTermMarkingPeriodId = column.params.schoolTermMarkingPeriodId || null;
        const reportCardAverage = reportCardAverages.find((row) => row.schoolTermMarkingPeriodId === schoolTermMarkingPeriodId);
        if (!reportCardAverage) return '';

        switch (name) {
            case 'reportCardAverage':
                return `${round(parseFloat(reportCardAverage.reportCardAverage), 2)}%`;
            case 'reportCardTotalCourses':
                return `${parseFloat(reportCardAverage.totalReportCardCourses)}`;
            case 'reportCardPassing':
                return `${parseFloat(reportCardAverage.totalReportCardPassing)}`;
            case 'reportCardFailing':
                return `${parseFloat(reportCardAverage.totalReportCardFailing)}`;
            default:
                return null;
        }
    }

    if (source == 'Progress Averages') {
        if (!object || !object.progressAverages) return "-";
        const { progressAverages } = object;
        const schoolTermMarkingPeriodId = column.params.schoolTermMarkingPeriodId || null;
        const progressAverage = progressAverages.find((row) => row.schoolTermMarkingPeriodId === schoolTermMarkingPeriodId);
        if (!progressAverage) return '';

        switch (name) {
            case 'progressAverage':
                return `${decimalToPercent(progressAverage.progressAverage)}`;
            case 'progressTotalCourses':
                return `${parseFloat(progressAverage.totalProgressCourses)}`;
            default:
                return null;
        }
    }

    if (source == 'School Attendance') {
        if (!object || !object.studentAttendance) return "-";
        const { studentAttendance } = object;

        const schoolTermIds = column.params.schoolTermIds || [];
        const attendanceMonth = column.params.attendanceMonth || null;

        switch (name) {
            case 'totalAbsence':
                return studentAttendance.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (attendanceMonth && item.attendanceMonth !== attendanceMonth) return n;
                    return n + item.totalAbsence;
                }, 0);
            case 'totalPresent':
                return studentAttendance.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (attendanceMonth && item.attendanceMonth !== attendanceMonth) return n;
                    return n + item.totalPresent;
                }, 0);
            case 'totalExcused':
                return studentAttendance.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (attendanceMonth && item.attendanceMonth !== attendanceMonth) return n;
                    return n + item.totalExcused;
                }, 0);
            case 'totalLate':
                return studentAttendance.reduce(function (n, item) {
                    if (schoolTermIds.length > 0 && !schoolTermIds.includes(item.schoolTermId)) return n;
                    if (attendanceMonth && item.attendanceMonth !== attendanceMonth) return n;
                    return n + item.totalLate;
                }, 0);
        }
    }

    return null;
}

function createDataSet(state, students, columns, columnSortIndex) {
    const filter = (state.database.studentDataView.filter || '').trim().toLowerCase();
    const { sortOrder } = state.database.studentDataView;
    const encryptionEnabled = state.user.school.clientSideEncryptionEnabled
            && state.user.school.serverSideEncryptionEnabled;

    const { studentAggregates } = state.database;
    const rows = students
        .map((s, idx) => {
            const record = columns.map((c) => {
                const column = { ...c };
                const { source } = column;
                const student = { ...s };
                const studentAggregate = studentAggregates[s.studentId] || null;

                const cell = {
                    column,
                    cellValue: '',
                    color: null,
                    rowPosition: idx + 1, //header row
                    rowHidden: false,
                };

                if (source == 'Biographical') {
                    cell.cellValue = formatCell(column, student);
                    if (filter) {
                        const searchString = `${ student.firstName } ${ student.lastName } ${student.maskedStudentName}`.toLowerCase();
                        cell.rowHidden = !searchString.includes(filter);
                    } else {
                        cell.rowHidden = false;
                    }
                } else {
                    if (!studentAggregate) {
                        cell.cellValue = '';
                    } else {
                        cell.cellValue = formatCell(column, studentAggregate);
                    }
                }

                return cell;
            });
            return record;
        })
        .filter(function(row) {
            if (!filter) return true;
            const [student] = row;
            return student.rowHidden === false;
        })
        .sort((a, b) => {
            let aVal = a[columnSortIndex].cellValue;
            let bVal = b[columnSortIndex].cellValue;
            if (columnSortIndex === 0) { //student cell
                if (encryptionEnabled) {
                    aVal = `${aVal.maskedStudentName}`;
                    bVal = `${bVal.maskedStudentName}`;
                } else {
                    aVal = `${aVal.lastName}, ${aVal.firstName}`;
                    bVal = `${bVal.lastName}, ${bVal.firstName}`;
                }
            }
            if (sortOrder == 'asc') {
                if (aVal < bVal) return -1;
                if (aVal > bVal) return 1;
            } else {
                if (aVal < bVal) return 1;
                if (aVal > bVal) return -1;
            }
            return 0;
        });
    return rows;
}