const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.getGradeTemplates = function (user, callback) {
    request
        .get(
            `${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/grade/templates`,
        )
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.editGradeTemplate = function (user, gradeTemplate, callback) {
    request
        .post(
            `${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/grade/templates`,
        )
        .send(gradeTemplate)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getGradeTemplateForCourse = function (user, courseSectionId, callback) {
    request
        .get(
            `${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/grade/templates/course/${courseSectionId}`,
        )
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getGradeTemplateForStaff = function (user, schoolStaffId, callback) {
    request
        .get(
            `${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/grade/templates/staff/${schoolStaffId}`,
        )
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.linkGradeTemplate = function (user, gradeTemplateId, courseSectionId, callback) {
    request
        .post(
            `${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/grade/templates/${gradeTemplateId}/course/${courseSectionId}`,
        )
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.setDefaultTemplate = function (user, gradeTemplateId, callback) {
    request
        .post(
            `${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/grade/templates/${gradeTemplateId}/default`,
        )
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getDefaultTemplate = function (user, callback) {
    request
        .get(
            `${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/grade/templates/default`,
        )
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
