const AccountSetup = () => import( '../../rootComponents/account/AccountSetup.vue');
const Billing = () => import( '../../rootComponents/account/Billing.vue');
const Password = () => import( '../../rootComponents/account/Password.vue');
const Configuration = () => import( '../../rootComponents/Configuration.vue');
const GoogleAccount = () => import( '../../rootComponents/GoogleAccount/GoogleAccount.vue');
const GoogleCourseManager = () => import( '../../rootComponents/GoogleCourseManager/GoogleCourseManager.vue');
const AdminGoogleAccount = () => import( '../../rootComponents/AdminGoogleAccount.vue');
const GoogleAccountCreator = () => import( '../../rootComponents/GoogleAccountCreator.vue');
const GoogleSetup = () => import( '../../rootComponents/google/GoogleSetup.vue');
const GoogleUserLink = () => import( '../../rootComponents/GoogleUserLink/GoogleUserLink.vue');
const AccountSecurity = () => import( '../../rootComponents/account/AccountSecurity.vue');
const AccountSessions = () => import( '../../rootComponents/account/AccountSessions.vue');
const MyProfile = () => import( '../../rootComponents/account/MyProfile.vue');
const CommunicationSettings = () => import( '../../rootComponents/account/CommunicationSettings.vue');

const configurationRoutes = [
    {
        name: 'Configuration',
        path: '/configuration',
        component: Configuration,
        children: [
            {
                name: 'AccountSetup',
                path: '/configuration/account',
                component: AccountSetup,
                children: [
                    {
                        name: 'MyProfile',
                        path: '/configuration/account/profile',
                        title: 'Account',
                        component: MyProfile,
                        meta: {
                            subHeader: false,
                            roles: ['All'],
                        },
                    },
                    {
                        name: 'AccountSecurity',
                        path: '/configuration/account/security',
                        title: 'Security',
                        component: AccountSecurity,
                        meta: {
                            subHeader: false,
                            roles: ['All'],
                        },
                    },
                    {
                        name: 'AccountSessions',
                        path: '/configuration/account/sessions',
                        title: 'Sessions',
                        component: AccountSessions,
                        meta: {
                            subHeader: false,
                            roles: ['All'],
                        },
                    },
                    {
                        name: 'Billing',
                        path: '/configuration/account/billing',
                        title: 'Billing',
                        component: Billing,
                        meta: {
                            subHeader: false,
                            roles: ['All'],
                        },
                    },
                    {
                        name: 'Password',
                        path: '/configuration/account/password',
                        title: 'Password',
                        component: Password,
                        meta: {
                            subHeader: false,
                            roles: ['All'],
                        },
                    },
                    {
                        name: 'CommunicationSettings',
                        path: '/configuration/account/communication',
                        title: 'Communication',
                        component: CommunicationSettings,
                        meta: {
                            subHeader: false,
                            roles: ['All'],
                        },
                    },
                ],
            },
            {
                name: 'Google',
                path: '/configuration/google',
                component: GoogleSetup,
                children: [
                    {
                        name: 'GoogleAccount',
                        path: '/configuration/google/account',
                        title: 'Classroom Account',
                        component: GoogleAccount,
                        meta: {
                            roles: ['All'],
                        },
                    },

                    {
                        name: 'AdminGoogleAccount',
                        path: '/configuration/google/admin',
                        title: 'Admin Google Account',
                        component: AdminGoogleAccount,
                        meta: {
                            subHeader: false,
                            roles: ['School Admin'],
                        },
                    },
                    {
                        name: 'GoogleAccountCreator',
                        path: '/configuration/google/account-creator',
                        title: 'Google Account Creator',
                        component: GoogleAccountCreator,
                        meta: {
                            roles: ['All'],
                            subHeader: true,
                        },
                    },
                    {
                        name: 'GoogleUserLink',
                        path: '/configuration/google/user/link',
                        title: 'User Linker',
                        component: GoogleUserLink,
                        meta: {
                            subHeader: true,
                            roles: ['All'],
                        },
                    },
                    {
                        name: 'GoogleCourseManager',
                        path: '/configuration/google/course/manager',
                        title: 'Course Manager',
                        component: GoogleCourseManager,
                        meta: {
                            subHeader: true,
                            roles: ['All'],
                        },
                    },

                ],
            },
        ],
    },
];

export default configurationRoutes;
