import { tableDefinitions } from '../database';
import blankAssignment from './assignment';
import blankBadge from './badge';

const baseState = {
    loadState: 'BOOTING',
    fileUploads: [],
    restoredFromCache: [],
    reloadKey: 0,
    schoolTermEncryptionKey: null,
    gradebook: {
        activeCell: {
            studentEnrollmentId: null,
            linkingGuid: null,
            courseWorkDomain: null,
        },
        updatedAverages: {
            // ${studentEnrollmentId}_${courseSectionId}_${schoolTermMarkingPeriodId}}: counter++
        },
    },
    attendance: {
        label: null,
        startDate: null,
        endDate: null,
        onlyCourseMeetings: true,
        formattedAttendance: {},
    },
    reportCards: {
        schoolStaffId: null,

        validMarks: [],
        courseValidMarks: [],
        examValidMarks: [],
        commentCodes: [],

        students: [],
        grades: [],
        courseStandards: [],
        averages: [],
        comments: [],
    },
    studentDataView: {
        columns: [],
        perPage: 25,
        currentPage: 1,
        rows: [],
        totalRows: 0,
        sortIndex: 0,
        sortOrder: 'asc',
        filter: null,
    },
    anecdotalEventSearch: {
        pageNumber: 1,
        pageSize: 100,
        anecdotalEventCategoryId: null,
        loading: false,
        downloadExcel: false,
        authorId: null,
        anecdotalEventDescription: null,
        anecdotalEventDate: null,
        anecdotalPointValue: null,
        studentId: null,
        schoolStaffId: null,
        schoolYear: null,
        schoolTermId: null,
        schoolTermMarkingPeriodId: null,
    },

    // assignment editing
    assignmentCourseSections: [],
    assignmentIsDirty: false,
    assignment: { ...blankAssignment },

    // badge editing
    mutableBadge: { ...blankBadge },
};

// add empty database tables to default state
tableDefinitions.forEach((d) => {
    if (!baseState[d.tableName]) {
        if (d.tableName == 'studentAggregates') {
            baseState[d.tableName] = {};
            return;
        }
        baseState[d.tableName] = [];
    }
});

export default baseState;
