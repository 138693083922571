function DataSystem(props) {
    this.dataSystemId = props.dataSystemId;
    this.dataSystem = props.dataSystem;
    this.dataSystemName = props?.dataSystemName || props.dataSystem;

    this.files = [...(props?.files || [])];

    // this.files = props.files.map(function(f) {

    // 	const file = {
    //         dataSystemId: f.dataSystemId,
    //         syncFileId: f.syncFileId,
    //         externalName: f.externalName,
    //         friendlyName: f.friendlyName,
    //         internalName: f.internalName,
    //         fileExtension: f.fileExtension,
    //         importRank: f.importRank,
    //         isRequired: f.isRequired,
    //         isDependent: f.isDependent,
    //         triggersCourseSync: f.triggersCourseSync,
    // 		upload: null,
    // 	};

    // 	if (f.upload) {
    // 		file.upload = {
    // 			syncFileId: f.upload.syncFileId,
    // 			isUploaded: f.upload.isUploaded,
    // 			isImported: f.upload.isImported,
    // 			importError: f.upload.importError,
    // 			fileLastModified: f.upload.fileLastModified,
    // 			bucketPath: f.upload.bucketPath,
    // 			fileSize: f.upload.fileSize,
    // 			author: f.upload.author,
    // 		};
    // 	}

    // 	return file;
    // });
}

module.exports = DataSystem;
