/* *
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
* */

const wrapper = require('../wrapper');

/**
 * @param {Object} params - http request parameters
 * @param {Object} params.url
 * @param {String} params.url.schoolId
 * @param {String} params.url.schoolTermId
 * @param {Object} params.query
 * @param {Number} params.query.pageNumber
 * @param {Number} params.query.pageSize
 * @param {Number} params.query.anecdotalEventCategoryId
 * @param {Number} params.query.authorId
 * @param {String} params.query.anecdotalEventDescription
 * @param {String} params.query.anecdotalEventDate
 * @param {String} params.query.schoolStaffId
 * @param {String} params.query.anecdotalPointValue
 * @param {String} params.query.studentEnrollmentId
 * @param {String} params.query.downloadExcel
 * @param {Number} params.query.schoolTermId
 * @param {Function} callback
 */
export default function (params, callback) {
    wrapper.default('listAnecdotals', 'GET', '/api/schools/:schoolId/terms/:schoolTermId/anecdotal-events', params, callback);
}
