import * as network from '../../network';

export function addGoogleCourseWork(params, commit, state) {
    const { googleCourseWork, googleCourseIds, callback } = params;
    const [googleCourseId] = googleCourseIds;
    googleCourseWork.googleCourseIds = googleCourseIds;
    googleCourseWork.googleCourseId = googleCourseId || null;

    const { schoolId, schoolTermId } = state.user.school;
    const requestParams = {
        url: { schoolId, schoolTermId, googleCourseId },
        body: { courseWork: googleCourseWork },
    };
    network.googleCourseWork.createCourseWork(requestParams, callback);
}

export function deleteGoogleCourseWork(params, commit, state) {
    const { googleCourseWorkId, googleCourseId, callback } = params;
    const { schoolId, schoolTermId } = state.user.school;
    const requestParams = {
        url: {
            schoolId,
            schoolTermId,
            googleCourseId,
            googleCourseWorkId,
        },
    };
    network.googleCourseWork.deleteCourseWork(requestParams, callback);
}

export function editGoogleCourseWork(params, commit, state) {
    const { googleCourseWorkProperties, googleCourseWork, callback } = params;
    const { googleCourseId, googleCourseWorkId } = params;
    const { schoolId, schoolTermId } = state.user.school;

    const requestParams = {
        url: {
            schoolId,
            schoolTermId,
            googleCourseId,
            googleCourseWorkId,
        },
        body: { googleCourseWork, googleCourseWorkProperties },
    };
    debugger;
    network.googleCourseWork.editCourseWork(requestParams, callback);
}

export function editGoogleCourseWorkProperties(params, commit, state) {
    const { courseWorks, callback } = params;
    const { schoolId, schoolTermId } = state.user.school;
    const requestParams = {
        url: {
            schoolId,
            schoolTermId,
        },
        body: { courseWorks },
    };
    network.googleCourseWork.editCourseWorkProperties(requestParams, callback);
}
