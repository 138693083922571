/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listGradeTemplatesForSchool from './listGradeTemplatesForSchool';
import listGradeScales from './listGradeScales';
import listCourseSectionGradeTemplates from './listCourseSectionGradeTemplates';
import createGradeTemplate from './createGradeTemplate';
import editGradeTemplate from './editGradeTemplate';
import deleteGradingTemplate from './deleteGradingTemplate';
import setDefaultTemplate from './setDefaultTemplate';
import duplicateTemplate from './duplicateTemplate';

export {
    listGradeTemplatesForSchool,
    listGradeScales,
    listCourseSectionGradeTemplates,
    createGradeTemplate,
    editGradeTemplate,
    deleteGradingTemplate,
    setDefaultTemplate,
    duplicateTemplate,
 };
