import async from 'async';
import humps from 'humps';
import moment from 'moment';
import Types from '../../Types';
import { tableDefinitions } from '../../database';

const slowTableIndicator = 10; // ms

/**
 * @description This routine downloads a fresh vuex database and applies it to the frontend
 * @param {Function} commit
 * @param {Object} state
 * @param {Function} callback
 */
export default function (commit, state, callback) {
    // commit(Types.mutations.SET_LOADING_BASE_TABLES, true);
    // triggers cache save to local storage, since it's a new DB
    const timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
    const key = 'baseTables';
    const c = {
        key,
        cacheType: 'baseTables',
        cacheTitle: 'School Rosters',
        status: 'loading',
        timestamp,
    };
    commit(Types.mutations.UPSERT_ITEM_TO_CACHE, c);

    const {
        schoolId, schoolTermId, role,
    } = state.user.school;
    const { restoredFromCache } = state.database;
    const params = { url: { schoolId, schoolTermId } };

    const items = tableDefinitions
        .filter((t) => {
            if (!t.saturation) return false;
            if (t.cacheType !== 'baseTables' && t.cacheType !== 'googleBaseTables') return false; // exclude portfolio tables
            if (t.roles && !t.roles.includes(role)) return false;
            return true;
        }).map((t) => {
            // remove pointer to original object def
            const table = { ...t };
            return table;
        });

    // download items which are not cached but needed for data load
    const neededItems = items.filter((t) => !restoredFromCache.includes(t.tableName));
    window.performance.mark('startTableSaturation');
    async.eachOfLimit(neededItems, 5, (table, idx, next) => {
        // see frontend/src/store/database.js for saturation routines for each table
        table.saturation.saturate(params, (err, res) => {
            if (err) return next(err);
            const { tableName } = table;
            const rows = res[tableName];
            // window.console.log(`SET_DB_${humps.decamelize(tableName).toUpperCase()} ${rows.length}`);
            commit(`SET_DB_${humps.decamelize(tableName).toUpperCase()}`, rows);
            next();
        });
    }, (err) => {
        window.performance.mark('endTableSaturation');
        if (err) return callback(err);
        commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key });
        window.performance.measure('tableSaturation', 'startTableSaturation', 'endTableSaturation');
        const [item] = performance.getEntriesByName('tableSaturation');
        const message = `baseTable saturation took ${item.duration.toFixed(2)}ms`;
        window.syncGrades.log(message, item.duration > slowTableIndicator ? 'warn' : 'info', 'baseTables', item.duration.toFixed(2));

        callback(); // this is called early intentially

        // // now download cached items again, so the next page load, the cache is updated
        // const cachedItems = items.filter((t) => restoredFromCache.includes(t.tableName));

        // if (cachedItems.length == 0) {
        //     commit(Types.mutations.SET_LOCAL_CACHE_STATE_SAVE);
        //     return;
        // }

        // window.performance.mark('startCacheTableSaturation');
        // async.eachOfLimit(cachedItems, 5, (table, idx, next) => {
        //     table.saturation.saturate(params, (err2, res) => {
        //         if (err2) return next(err2);
        //         const { tableName } = table;
        //         const rows = res[tableName];
        //         commit(`SET_DB_${humps.decamelize(table.tableName).toUpperCase()}`, rows);
        //         next();
        //     });
        // }, (err2) => {
        //     commit(Types.mutations.SET_LOCAL_CACHE_STATE_SAVE);
        //     if (err2) {
        //         window.syncGrades.log(err2, 'error', 'baseTables');
        //         return;
        //     }
        //     window.performance.mark('endCacheTableSaturation');
        //     window.performance.measure('tableCacheSaturation', 'startCacheTableSaturation', 'endCacheTableSaturation');
        //     [item] = performance.getEntriesByName('tableCacheSaturation');
        //     window.syncGrades.log(`cache saturation refresh took ${item.duration.toFixed(2)}ms`, item.duration > slowTableIndicator ? 'warn' : 'info', 'baseTables', item.duration.toFixed(2));
        // });
    });
}
