/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import changePassword from './changePassword';
import forgotPassword from './forgotPassword';
import processUserNamePassword from './processUserNamePassword';

export {
    changePassword,
    forgotPassword,
    processUserNamePassword,
 };
