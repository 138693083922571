/* *
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
* */

const wrapper = require('../wrapper');

/**
 * @param {Object} params - http request parameters
 * @param {Object} params.url
 * @param {String} params.url.schoolId
 * @param {String} params.url.schoolTermId
 * @param {String} params.url.syncFileErrorId
 * @param {Function} callback
 */
export default function (params, callback) {
    wrapper.default('deleteSyncFileError', 'DELETE', '/api/schools/:schoolId/terms/:schoolTermId/error-bin/:syncFileErrorId', params, callback);
}
