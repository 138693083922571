import Types from '../../../Types';
import * as network from '../../../../network';

export default function (commit, state, schoolStaffId) {
    const params = {
        url: {
            schoolId: state.user.school.schoolId,
            schoolTermId: state.user.school.schoolTermId,
            schoolStaffId,
        },
    };

    network.google.getGoogleCourseGradeCategoriesForTeacher(params, (err, res) => {
        if (err) return commit(Types.mutations.SET_ERROR, err);
        const { googleCourseGradeCategories } = res;
        commit(Types.mutations.SET_DB_GOOGLE_COURSE_GRADE_CATEGORIES, googleCourseGradeCategories);
    });
}
