const StudentDataView = () => import('../../rootComponents/StudentDataView.vue');
const StudentEvaluation = () => import('../../rootComponents/StudentEvaluation.vue');
const StudentGuardians = () => import('../../rootComponents/StudentGuardians.vue');
const StudentInfo = () => import('../../rootComponents/StudentInfo.vue');
const StudentAttendance = () => import( '../../rootComponents/StudentAttendance.vue');
const StudentGroupsList = () => import( '../../rootComponents/StudentGroupsList.vue');
const StudentGroup = () => import('../../rootComponents/StudentGroup.vue');

const StudentCourseOverview = () => import( '../../rootComponents/StudentCourseOverview.vue');
const StudentAnecdotals = () => import('../../rootComponents/StudentAnecdotals.vue');
const StudentBadges = () => import('../../rootComponents/StudentBadges.vue');
const StudentAvatarEditor = () => import('../../rootComponents/StudentAvatarEditor.vue');
const StudentReports = () => import('../../rootComponents/StudentReports.vue');
const StudentCommunication = () => import( '../../rootComponents/StudentCommunication.vue');

const studentRoutes = [
    { // redirect
        path: '/students/:studentEnrollmentId',
        redirect: '/students/:studentEnrollmentId/info',
    }, { // redirect
        path: '/students/:studentEnrollmentId/classes/:extCourseSectionId',
        redirect: '/students/:studentEnrollmentId/classes/:extCourseSectionId/info',
    }, {
        name: 'Students',
        path: '/students',
        component: StudentDataView,
        meta: {
            subHeader: true,
            title: 'Student Data Table',
            icon: 'students',
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    }, {
        name: 'StudentInfo',
        path: '/students/:studentEnrollmentId/info',
        component: StudentInfo,
        meta: {
            subHeader: true,
            title: 'Info',
            icon: 'info',
            roles: ['All'],
        },
    }, {
        name: 'StudentAttendance',
        path: '/students/:studentEnrollmentId/attendance',
        component: StudentAttendance,
        meta: {
            subHeader: true,
            title: 'Attendance',
            icon: 'attendance',
            roles: ['All'],
        },
    }, {
        name: 'StudentEvaluation',
        path: '/students/:studentEnrollmentId/evaluation',
        component: StudentEvaluation,
        meta: {
            subHeader: true,
            title: 'Evaluation',
            icon: 'evaluation',
            roles: ['All'],
        },
    }, {
        name: 'StudentGuardians',
        path: '/students/:studentEnrollmentId/guardians',
        component: StudentGuardians,
        meta: {
            subHeader: true,
            title: 'Guardians',
            icon: 'guardians',
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    }, {
        name: 'StudentCourseOverview',
        path: '/students/:studentEnrollmentId/classes/:extCourseSectionId/info',
        component: StudentCourseOverview,
        meta: {
            subHeader: true,
            title: 'Overview',
            icon: 'assignments',
            roles: ['All'],
        },
    }, {
        name: 'StudentBadges',
        path: '/students/:studentEnrollmentId/badges',
        component: StudentBadges,
        meta: {
            subHeader: true,
            title: 'Badges',
            icon: 'badges',
            roles: ['All'],
        },
    }, {
        name: 'StudentAvatarEditor',
        path: '/students/:studentEnrollmentId/avatar',
        component: StudentAvatarEditor,
        meta: {
            subHeader: true,
            title: 'Student Avatar',
            icon: 'student',
            roles: ['All'],
        },
    }, {
        name: 'StudentAnecdotals',
        path: '/students/:studentEnrollmentId/anecdotals',
        component: StudentAnecdotals,
        meta: {
            subHeader: true,
            title: 'Overview',
            icon: 'anecdotal',
            roles: ['All'],
        },
    }, {
        name: 'StudentReports',
        path: '/students/:studentEnrollmentId/reports',
        component: StudentReports,
        meta: {
            subHeader: true,
            title: 'Overview',
            icon: 'progressReports',
            roles: ['All'],
        },
    }, {
        name: 'StudentCommunication',
        path: '/students/:studentEnrollmentId/communication',
        component: StudentCommunication,
        meta: {
            subHeader: true,
            title: 'Overview',
            icon: 'communication',
            roles: ['All'],
            isBeta: true,
        },
    }, {
        name: 'StudentGroupsList',
        path: '/student/groups',
        component: StudentGroupsList,
        meta: {
            subHeader: true,
            title: 'Overview',
            icon: 'group',
            roles: ['School Admin', 'Staff', 'Teacher'],
            isLocal: true,
        },
    }, {
        name: 'StudentGroup',
        path: '/student/groups/:studentGroupId',
        component: StudentGroup,
        meta: {
            subHeader: true,
            title: 'Overview',
            icon: 'group',
            roles: ['School Admin', 'Staff', 'Teacher'],
            isLocal: true,
        },
    },
];

export default studentRoutes;

export function getStudentCourseMenuItems() {
    return studentRoutes
        .filter((r) => r.meta && r.meta.title && r.path.indexOf('/:studentEnrollmentId/') > 1)
        .map((r) => ({
            name: r.name,
            path: r.path,
            title: r.meta.title,
            icon: r.meta?.icon || null,
            active: false,
        }));
}
