/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import createProgressReport from './createProgressReport';
import getCommentsForStaff from './getCommentsForStaff';
import saveStudentComments from './saveStudentComments';

export {
    createProgressReport,
    getCommentsForStaff,
    saveStudentComments,
 };
