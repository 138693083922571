function StudentGuardian(props) {

	this.studentGuardianId = props.studentGuardianId;
	this.studentEnrollmentId = props.studentEnrollmentId;
	this.guardianTitle = props.guardianTitle;
	this.guardianFirstName = props.guardianFirstName;
	this.guardianLastName = props.guardianLastName;
	this.residesWithStudent = props.residesWithStudent;
	this.relationToStudent = props.relationToStudent;
	this.guardianAuthorization = props.guardianAuthorization;
	this.oralLanguage = props.oralLanguage;
	this.writtenLanguage = props.writtenLanguage;
	this.restricted = props.restricted;
	this.etlSourced = props.guardianEtlSourced;
	this.author = props.guardianAuthor;
	this.updated = props.guardianUpdated;
	this.deleted = props.guardianDeleted;
	this.isEncrypted = props.isEncrypted;
	this.encryptedData = props.encryptedData;
	this.registered = props.registered;
	this.phones = props.phones || [];
	this.emailAddresses = props.emailAddresses || [];
}

module.exports = StudentGuardian;