const Login = () => import( '../../rootComponents/login/Login.vue');
const Logout = () => import( '../../rootComponents/logout/Logout.vue');
const AccountActivation = () => import( '../../rootComponents/AccountActivation.vue');
const AccountLocked = () => import( '../../rootComponents/account/AccountLocked.vue');
const GuardianRegister = () => import( '../../rootComponents/GuardianRegister.vue');

const authRoutes = [
    {
        name: 'Login',
        path: '/login',
        layout: 'AuthLayout',
        component: Login,
        meta: {
            roles: ['All'],
            public: true,
        },
    },
    {
        name: 'Logout',
        path: '/logout',
        component: Logout,
        meta: {
            roles: ['All'],
            public: true,
        },
    },
    {
        name: 'AccountActivation',
        path: '/activate',
        component: AccountActivation,
        meta: {
            roles: ['All'],
            public: true,
        },
    },
    {
        name: 'AccountLocked',
        path: '/account/locked',
        component: AccountLocked,
        meta: {
            roles: ['All'],
        },
    },
    {
        name: 'GuardianRegister',
        path: '/guardians/register/:registrationGuid',
        component: GuardianRegister,
        meta: {
            public: true,
            roles: ['All'],
        },
    }
];

export default authRoutes;
