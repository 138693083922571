/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listSchools from './listSchools';
import getDataSystems from './getDataSystems';
import getSubscription from './getSubscription';
import getSubscriptionByYear from './getSubscriptionByYear';
import updatePurchaseOrder from './updatePurchaseOrder';
import createBugReport from './createBugReport';
import listBugReportsForSchool from './listBugReportsForSchool';
import saveSchoolLogo from './saveSchoolLogo';
import getSchoolLogoBase64 from './getSchoolLogoBase64';

export {
    listSchools,
    getDataSystems,
    getSubscription,
    getSubscriptionByYear,
    updatePurchaseOrder,
    createBugReport,
    listBugReportsForSchool,
    saveSchoolLogo,
    getSchoolLogoBase64,
 };
