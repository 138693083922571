import async from 'async';
import moment from 'moment';
import humps from 'humps';

import Types from '../../Types';
import * as network from '../../../network';
import { getGoogleCourses, GoogleCourseFilter } from '../../mixins/googleCourseMixins';
import { tableDefinitions } from '../../database';

// force googleRefresh if older than googleExpiryInHours
const googleExpiryInHours = 2;

// force re-download portfolio if older than portfolioExpiryInHours
const portfolioExpiryInHours = 2;

// logging, perf tools
const logCategory = 'portfolioTables';
const slowTableIndicator = 10; // ms

/**
 * @description Downloads and caches classroom data for teacher portfolio
 * @param {Function} commit
 * @param {Object} state
 * @param {Object} payload
 * @param {String} payload.schoolEmail
 * @param {String} payload.studentEnrollmentId
 * @param {Function} payload.callback
 * @param {Object} dispatch
 */
export function populatePortfolioData(commit, state, payload, dispatch) {
    const { user } = state;

    const schoolEmail = payload.schoolEmail || null;
    const studentEnrollmentId = payload.studentEnrollmentId || null;

    const { callback } = payload;
    const { cache } = state.database;

    if (!schoolEmail && !studentEnrollmentId) return callback(); // Nothing to pull

    if (schoolEmail && !['School Admin', 'Staff', 'Teacher'].includes(user.school.role)) return callback(new Error('Bad permission'));

    // this checkes if the last time i checked with the server
    // is older than portfolioExpiryInHours, if so invalidate and refresh
    const teacher = state.database.teachers.find((t) => t.schoolEmail == schoolEmail);
    if (!teacher && schoolEmail) return callback(new Error(`Teacher not found for schoolStaffId ${schoolEmail}`));

    const student = state.database.students.find((t) => t.studentEnrollmentId == studentEnrollmentId);
    if (!student && studentEnrollmentId) return callback(new Error(`Student not found for studentEnrollmentId ${studentEnrollmentId}`));

    const key = teacher ? `staffProfileData_${teacher.schoolStaffId}` : `studentProfileData_${student.studentEnrollmentId}`;
    const exists = cache.find((c) => c.key == key);

    let lastUpdateFromServer = null; // this is the last time i asked the server for an update
    let shouldUpdate = true;
    if (exists) {
        const { status, timestamp } = exists;
        if (status == 'loading') {
            window.syncGrades.log('There is another duplicate download already in progress', 'warn', logCategory);
            shouldUpdate = false;
        }
        if (status == 'cached' && timestamp) {
            lastUpdateFromServer = timestamp || null;
            window.syncGrades.log(`Portfolio cache exists as of ${lastUpdateFromServer}`, 'info', logCategory);
            const now = moment();
            // value can be loading or timestamp
            const expiry = moment(timestamp, 'YYYY-MM-DD HH:mm').add(portfolioExpiryInHours, 'hour');
            // dont re-query unless expired
            if (expiry.isAfter(now)) {
                window.syncGrades.log(`Expires in ${expiry.format('YYYY-MM-DD HH:mm')} ${lastUpdateFromServer}`, 'warn', logCategory);
                // cancel the server update, this is new
                shouldUpdate = false;
            }
        }
    } else {
        window.syncGrades.log('No cached portfolio payload to retrieve', 'info', logCategory);
    }

    if (shouldUpdate) {
        // start network requests for server update
        window.syncGrades.log('Downloading new portfolio data from server', 'info', logCategory);
        if (teacher) return downloadPortfolioData(user, teacher, commit, state, dispatch, callback);
        if (student) return downloadPortfolioData(user, student, commit, state, dispatch, callback);
        callback(new Error('bad params to downloadPortfolioData'));
    }
    callback();
}

function downloadPortfolioData(user, portfolioUser, commit, state, dispatch, callback) {
    const { schoolId, schoolTermId, googleSetting } = state.user.school;
    const { schoolStaffId, studentEnrollmentId } = portfolioUser;
    const isGoogleDisabled = ['District Managed', 'Disabled'].includes(googleSetting);

    let portfolioType = schoolStaffId ? 'teacher' : null;
    if (studentEnrollmentId) portfolioType = 'student';
    if (!portfolioType) return callback(new Error('No portfolio type found'));

    window.syncGrades.log(`Downloading portfolio data for ${portfolioType}`, 'info', logCategory);
    async.auto({
        updateLocalTables(next) {
            const controller = new AbortController();
            commit(Types.mutations.SET_ABORT_CONTROLLER, controller);
            const params = {
                url: { schoolId, schoolTermId },
                abortController: controller,
            };
            if (portfolioType == 'teacher') params.url.schoolStaffId = schoolStaffId;
            if (portfolioType == 'student') params.url.studentEnrollmentId = studentEnrollmentId;
            // upsertPortfolioTables(user, portfolioUser, params, commit, next);
            const store = { commit, state, dispatch };
            upsertPortfolioTables(user, portfolioUser, params, store, next);
        },
        googleRefreshDates(next) {
            if (portfolioType !== 'teacher' || isGoogleDisabled) return next();
            // TBD: move this into googleCourseModel, so i can display the date in the UI
            // this is a light network call to check the last google refresh date, in case another user refreshed before you
            network.google.refreshDatesForStaffId({ url: { schoolId, schoolTermId, schoolStaffId } }, next);
        },
        refreshGoogle: ['updateLocalTables', 'googleRefreshDates', function (results, next) {
            if (portfolioType !== 'teacher' || isGoogleDisabled) return next();
            const key = schoolStaffId ? `staffGoogleData_${schoolStaffId}` : `studentProfileData_${studentEnrollmentId}`;
            const cache = {
                key,
                cacheTitle: `${portfolioUser.lastName}, ${portfolioUser.firstName}`,
                cacheType: 'googleCourseTables',
                status: 'loading',
                timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
            };
            // determine google course id's for the school staff id
            const googleCourseIds = getGoogleCourses(
                state.database,
                new GoogleCourseFilter({ schoolStaffId }),
            ).map((c) => c.googleCourseId);

            // loop each googleCourseId and determine if the data is stale
            const { refreshDates } = results.googleRefreshDates;
            const staleGoogleCourseIds = googleCourseIds.filter((googleCourseId) => {
                const lastGoogleRefresh = refreshDates.find((d) => d.googleCourseId == googleCourseId);
                if (!lastGoogleRefresh) return true; // no last date, so it's stale
                const now = moment();
                const lastUpdated = moment(lastGoogleRefresh.lastUpdated, 'YYYY-MM-DD HH:mm:ss');

                // value can be loading or timestamp
                const expiry = moment(lastUpdated, 'YYYY-MM-DD HH:mm').add(googleExpiryInHours, 'hour');
                // dont re-query unless expired
                if (expiry.isAfter(now)) {
                    window.syncGrades.log(`Expires in ${expiry.format('YYYY-MM-DD HH:mm')} ${lastGoogleRefresh}`, 'info', logCategory);
                    return false; // it's not stale, this is new enough
                }
                return true; // it's stale
            });

            if (staleGoogleCourseIds.length == 0) {
                window.syncGrades.log('There are no linked google courses that require a google refresh', 'warn', logCategory);
                commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, cache); // kill loader
                return next(null, []);
            }
            window.syncGrades.log(`Syncing the following googleCourseIds ${staleGoogleCourseIds.join(', ')}`, 'info', logCategory);

            dispatch(Types.actions.REFRESH_GOOGLE_COURSES_BY_STAFF_ID, {
                schoolStaffId,
                callback(err) {
                    commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, cache); // kill loader
                    if (err) return next(err);
                    window.syncGrades.log('Background google syncing is completed', 'info', logCategory);
                    next(err, staleGoogleCourseIds);
                },
            });
        }],
    }, 5, callback);
}

function upsertPortfolioTables(user, portfolioUser, params, store, callback) {
    const { schoolStaffId, studentEnrollmentId } = params.url;
    const { commit } = store;
    const isGoogleDisabled = ['District Managed', 'Disabled'].includes(user.school.googleSetting);;

    const key = schoolStaffId ? `staffProfileData_${schoolStaffId}` : `studentProfileData_${studentEnrollmentId}`;
    const cache = {
        key,
        cacheTitle: `${portfolioUser.lastName}, ${portfolioUser.firstName}`,
        cacheType: 'portfolioCourseTables',
        status: 'loading',
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache);

    const items = tableDefinitions.filter((t) => {
        if (!t.saturation) return false;
        if (schoolStaffId) { // teacher population
            if (t.cacheType === 'googleCourseTables' && isGoogleDisabled) return false;
            if (t.cacheType !== 'portfolioCourseTables' && t.cacheType !== 'googleCourseTables' && t.cacheType !== 'portfolioCourseTeacherTables') return false;
        }
        if (studentEnrollmentId) { // student population
            if (t.cacheType === 'googleCourseTables' && isGoogleDisabled) return false;
            if (t.cacheType !== 'portfolioCourseTables' && t.cacheType !== 'googleCourseTables' && t.cacheType !== 'portfolioCourseStudentTables') return false;
        }
        if (t.roles && !t.roles.includes(user.school.role)) return false;
        return true;
    }).map((t) => {
        const table = { ...t }; // new pointer
        return table;
    });

    window.syncGrades.log(`Saturating ${cache.cacheType} portfolio ${cache.cacheTitle}`, 'info', logCategory);
    async.eachOfLimit(items, 5, (table, idx, next) => {
        // see frontend/src/store/database.js for saturation routines for each table
        table.saturation.saturate(params, (err, res) => {
            if (err) return next(err);
            // apply to state
            const { tableName } = table;
            const records = res[tableName];
            window.performance.mark(`startDataLoading_${tableName}`);
            commit(`SET_DB_${humps.decamelize(tableName).toUpperCase()}`, records);
            window.performance.mark(`endDataLoading_${tableName}`);
            window.performance.measure(`${tableName}_dataLoad`, `startDataLoading_${tableName}`, `endDataLoading_${tableName}`);
            const [item] = performance.getEntriesByName(`${tableName}_dataLoad`);
            const message = `${tableName}_dataLoad: ${item.duration.toFixed(2)}ms, ${records.length} items`;
            window.syncGrades.log(message, item.duration > slowTableIndicator ? 'warn' : 'info', logCategory, item.duration.toFixed(2));
            next();
        });
    }, (err) => {
        commit(Types.mutations.SET_ABORT_CONTROLLER, null);
        if (err) {
            commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, cache); // kill loader
            return callback(err);
        }
        // successful so save timestamp to cache table
        cache.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
        cache.status = 'cached';
        commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache); // mark as completed
        window.syncGrades.log(`Successfully saturated ${cache.cacheType} portfolio ${cache.cacheTitle}`, 'info', logCategory);
        callback(err);
    });
}
