import { getTeachersForCourseSectionId } from './courseSectionTeacherMixins';
import { CourseFilter, getCourses } from './courseMixins';

const googleCourseMixins = {
    methods: {
        $_googleCourseMixins_getGoogleCourseFromRoute() {
            const { extCourseSectionId } = this.$route.params;
            if (!extCourseSectionId) return null;
            const { database } = this.$store.state;
            const courseSections = getCourses(database, new CourseFilter({ extCourseSectionId }));
            const [course] = courseSections;
            if (!course) return null;
            const { googleCourseId } = course;
            if (!googleCourseId) return null;
            const googleCourses = getGoogleCourses(this.$store.state.database, new GoogleCourseFilter({ googleCourseId }));
            const [googleCourse] = googleCourses;
            return googleCourse || null;
        },
        $_googleCourseMixins_getGoogleCourseById(googleCourseId) {
            const googleCourses = getGoogleCourses(this.$store.state.database, new GoogleCourseFilter({ googleCourseId }));
            const [googleCourse] = googleCourses;
            return googleCourse || null;
        },
    },
};

function GoogleCourseFilter(props) {
  this.schoolStaffId = props.schoolStaffId || false;
  this.googleCourseId = props.googleCourseId || false;
  this.extCourseSectionId = props.extCourseSectionId || false;
  return this;
}

function getGoogleCourses(database, limitBy = {
    schoolStaffId: null,
    googleCourseId: null,
    extCourseSectionId: null,
}) {
    const {
        courseSections,
        googleCourseCourseSections,
        googleCourses,
        googleCourseTeachers,
        googleTeachers,
        teachers,
    } = database;

    const { schoolStaffId, extCourseSectionId } = limitBy;

    let googleCourseIds = [];
    if (schoolStaffId) {
        // limit by schoolStaffId
        googleCourseIds = getCourses(database, new CourseFilter({ schoolStaffId }))
            .map((c) => c.googleCourseId)
            .filter((c) => c);
    } else if (extCourseSectionId) {
        // limit by extCourseSectionId
        googleCourseIds = getCourses(database, new CourseFilter({ extCourseSectionId }))
            .map((c) => c.googleCourseId)
            .filter((c) => c);
    }

    return googleCourses.filter((row) => {
        if (limitBy.googleCourseId) {
            return row.googleCourseId == limitBy.googleCourseId;
        }
        return true;
    })
    .filter((row) => {
        if (schoolStaffId || extCourseSectionId) {
            return googleCourseIds.includes(row.googleCourseId);
        }
        return true;
    })
    .map((row) => { // add teachers & search & google
        const googleCourse = { ...row };
        const { googleCourseId } = googleCourse;

        const googleTeacherIds = googleCourseTeachers
            .filter((t) => t.googleCourseId == googleCourseId && !t.deleted)
            .map((c) => c.googleTeacherId);

        const schoolStaffIds = googleTeachers
            .filter((c) => googleTeacherIds.includes(c.googleTeacherId))
            .map((t) => t.schoolStaffId);

        googleCourse.googleTeachers = teachers.filter((t) => schoolStaffIds.includes(t.schoolStaffId));

        const courseSectionIds = googleCourseCourseSections
            .filter((c) => c.googleCourseId == googleCourseId)
            .map((c) => c.courseSectionId);

        googleCourse.courseSections = courseSections
            .filter((c) => courseSectionIds.includes(c.courseSectionId))
            .map((c) => {
                const section = c;
                const { courseSectionId } = c;
                section.courseSectionTeachers = getTeachersForCourseSectionId(database, courseSectionId);
                return section;
            });

        // adds some convienence properties
        googleCourse.sort = `${googleCourse.googleCourseName}_${googleCourse.googleCourseSection}`;
        googleCourse.search = `${googleCourse.googleCourseName} ${googleCourse.googleCourseSection} ${googleCourse.googleCourseDescription} ${googleCourse.extGoogleCourseId}`.toLowerCase();

        googleCourse.linked = Boolean(googleCourse.courseSections.length > 0);

        googleCourse.deleted = Boolean(googleCourse.googleCourseState == 'DELETED');
        return googleCourse;
    });
}

export { googleCourseMixins as default, getGoogleCourses, GoogleCourseFilter };
