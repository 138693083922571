import Types from '../Types';

export default {
    closeAllNavigationPopovers(s) {
        const state = s;
        closeAllNavigationPopovers(state);
    },
    toggleMenuItemByTitle(s, title) {
        const state = s;
        closeAllNavigationPopovers(state);
        const item = state.navigation.teacherTopMenu.find((i) => i.title === title);
        if (item) {
            item.show = !item.show;
        }
    },
    setStickyAsideMenu(s, enabled) {
        const state = s;
        if (enabled == 'toggle') {
            state.navigation.showAside = !state.navigation.showAside;
        } else {
            state.navigation.showAside = enabled;
        }
        state.navigation.showSearchBar = false;
        state.navigation.showLinkingMenu = false;
        state.navigation.showUserBar = false;
        // window.localStorage.showAside = state.navigation.showAside;
    },
    openCoursePicker(s) {
        const state = s;
        state.coursePicker.open = true;
    },
    closeCoursePicker(s) {
        const state = s;
        state.coursePicker.open = false;
    },
    [Types.mutations.OPEN_QUICK_PANEL](s) {
        const state = s;
        state.quickPanel = {
            open: true,
        };
    },
    [Types.mutations.CLEAR_AI_RESPONSE](s) {
        const state = s;
        state.aiResponse.chatbotResult = '';
    },
    [Types.mutations.SET_AI_LOADING](s, loading) {
        const state = s;
        state.aiResponse.loading = loading;
    },
    [Types.mutations.SET_AI_RESPONSE](s, payload) {
        const state = s;
        if (payload && payload.requestId) {
            state.aiResponse.requestId = payload.requestId;
        }
        if (payload && payload.hasOwnProperty('chatbotResult')) {
            state.aiResponse.chatbotResult += payload.chatbotResult;
            if (!payload.chatbotResult) {
                state.aiResponse.loading = false;
            }
        }
        if (payload && payload.attachment) {
            state.aiResponse.attachment = payload.attachment;
        }
    },
    [Types.mutations.CLOSE_QUICK_PANEL](s) {
        const state = s;
        state.quickPanel = {
            open: false,
        };
    },
    setSearchBar(s, enabled) {
        const state = s;
        if (enabled == 'toggle') {
            state.navigation.showSearchBar = !state.navigation.showSearchBar;
        } else {
            state.navigation.showSearchBar = enabled;
        }
        state.navigation.showLinkingMenu = false;
        state.navigation.showUserBar = false;
        state.navigation.showLocales = false;
        state.navigation.showSuperMenuButton = false;
    },
    setPageParameters(s, pageParameters) {
        const state = s;
        state.navigation.pageParameters = Object.assign(state.navigation.pageParameters, pageParameters);
    },
    setLocales(s, enabled) {
        const state = s;
        if (enabled == 'toggle') {
            state.navigation.showLocales = !state.navigation.showLocales;
        } else {
            state.navigation.showLocales = enabled;
        }
        state.navigation.showLinkingMenu = false;
        state.navigation.showUserBar = false;
        state.navigation.showSearchBar = false;
        state.navigation.showSuperMenuButton = false;
    },
    setLinkingMenu(s, enabled) {
        const state = s;
        if (enabled == 'toggle') {
            state.navigation.showLinkingMenu = !state.navigation.showLinkingMenu;
        } else {
            state.navigation.showLinkingMenu = enabled;
        }
        state.navigation.showSearchBar = false;
        state.navigation.showUserBar = false;
        state.navigation.showLocales = false;
        state.navigation.showSuperMenuButton = false;
    },
    setUserBar(s, enabled) {
        const state = s;
        if (enabled == 'toggle') {
            state.navigation.showUserBar = !state.navigation.showUserBar;
        } else {
            state.navigation.showUserBar = enabled;
        }
        state.navigation.showLinkingMenu = false;
        state.navigation.showSearchBar = false;
        state.navigation.showLocales = false;
        state.navigation.showSuperMenuButton = false;
    },
    setMobileMenu(s, enabled) {
        const state = s;
        if (enabled == 'toggle') {
            state.navigation.showMobileMenu = !state.navigation.showMobileMenu;
        } else {
            state.navigation.showMobileMenu = enabled;
        }
    },
    setMobileTopBar(s, enabled) {
        const state = s;
        if (enabled == 'toggle') {
            state.navigation.showMobileTopBar = !state.navigation.showMobileTopBar;
        } else {
            state.navigation.showMobileTopBar = enabled;
        }
    },
    setLoading(s, isLoading) {
        const state = s;
        state.navigation.loading = isLoading;
    },
    hideAllNavigationMenus(s) {
        const state = s;
        state.navigation.hideAllNavigation = true;
    },
    showAllNavigationMenus(s) {
        const state = s;
        state.navigation.hideAllNavigation = false;
    },
    setSuperMenuButton(s, enabled) {
        const state = s;
        if (enabled == 'toggle') {
            state.navigation.showSuperMenuButton = !state.navigation.showSuperMenuButton;
        } else {
            state.navigation.showSuperMenuButton = enabled;
        }
        state.navigation.showLinkingMenu = false;
        state.navigation.showUserBar = false;
        state.navigation.showLocales = false;
        state.navigation.showSearchBar = false;
    },
};

function closeAllNavigationPopovers(s) {
    const openMenu = hasOpenMenu(s);
    if (!openMenu) return;

    const state = s;

    state.navigation.showSearchBar = false;
    state.navigation.showLocales = false;
    state.navigation.showLinkingMenu = false;
    state.navigation.showUserBar = false;
    state.coursePicker.open = false;
    state.navigation.showSuperMenuButton = false;
}

function hasOpenMenu(state) {
    if (state.navigation.showSearchBar
        || state.navigation.showLocales
        || state.navigation.showLinkingMenu
        || state.navigation.showUserBar
        || state.navigation.showSuperMenuButton
        || state.coursePicker.open) {
        return true;
    }
    return false;
}
