import async from 'async';
import network from '../../lib/network';
import Types from '../../store/Types';

export default {
    getQueueRequests({ commit, rootState }) {
        commit('setIsFetchingQueueRequests', true);
        async.auto({
            queueRequests(next) {
                network.google.getQueueRequests(rootState.user, 'AutoLinkCourse', (err, result) => {
                    if (err) return next(err);
                    return next(err, result.requests);
                });
            },
            queueRequestItemCount: ['queueRequests', (results, next) => {
                if (results.queueRequests.length > 0) {
                    const queueRequest = results.queueRequests[results.queueRequests.length - 1];
                    network.google.getQueueRequestItemCount(rootState.user, queueRequest.googleQueueRequestId, (err, result) => {
                        if (err) return next(err);
                        return next(err, result);
                    });
                } else {
                    return next();
                }
            }],
            queueRequestItems: ['queueRequests', (results, next) => {
                if (results.queueRequests.length > 0) {
                    const queueRequest = results.queueRequests[results.queueRequests.length - 1];
                    network.google.getQueueRequestItems(rootState.user, queueRequest.googleQueueRequestId, (err, result) => {
                        if (err) return next(err);
                        return next(err, result);
                    });
                } else {
                    return next();
                }
            }],
        }, (err, results) => {
            commit('setIsFetchingQueueRequests', false);
            if (err) return commit(Types.mutations.HANDLE_ERROR, err);
            if (results.queueRequests.length > 0) {
                const queueRequest = results.queueRequests[results.queueRequests.length - 1];
                queueRequest.requestItems = results.queueRequestItems.requestItems;
                queueRequest.requestItemCount = results.queueRequestItemCount ? results.queueRequestItemCount.requestItems.itemCount.count : 0;
                commit('setQueueRequests', results.queueRequests);
            }
        });
    },
};
