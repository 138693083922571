import async from 'async';
import { decrypt, encrypt, hashString } from './aes.mjs';
import { tableEncryptionSettings } from './encryptionSettings.mjs';

/**
 * Encrypts the rows of a table using the specified encryption settings and keys.
 *
 * @param {string} tableName - The name of the table to encrypt.
 * @param {Array} rows - The rows to encrypt.
 * @param {Object} keys - The AES encryption keys.
 * @param {String} keys.schoolTermEncryptionKey - The AES encryption key set by the school
 * @param {String} keys.hashEncryptionKey - The AES encryption key set by cipher
 * @param {Function} callback - The callback function to invoke when encryption is complete or an error occurs.
 */
function encryptTable(tableName, rows, keys, callback) {
    const encryptionSettings = encryptionSettingsForTable(tableName);
    if (!encryptionSettings) return callback(new Error('Table not found in encryption settings.'));
    async.mapLimit(rows, 3, (row, nextRow) => {
        encryptRecord(encryptionSettings, keys, row, nextRow);
    }, (err, encryptedRows) => {
        if (err) return callback(err);
        callback(null, encryptedRows);
    });
}

/**
 * Encrypts the specified columns of a student record using AES encryption.
 *
 * @param {Object} encryptionSettings - The encryption settings for the table.
 * @param {Object} keys - The AES encryption keys.
 * @param {String} keys.schoolTermEncryptionKey - The AES encryption key set by the school
 * @param {String} keys.hashEncryptionKey - The AES encryption key set by cipher
 * @param {Object} record - The record to be encrypted.
 * @param {Function} callback - The callback function to be called after encryption is complete.
 */
function encryptRecord(encryptionSettings, keys, record, callback) {
    const { encryptedColumns, hashedColumns } = encryptionSettings;
    const { schoolTermEncryptionKey, hashEncryptionKey } = keys;

    if (encryptionSettings.hasOwnProperty('shouldEncryptRow')) {
        const { shouldEncryptRow } = encryptionSettings;
        if (!shouldEncryptRow(record)) {
            return callback(null, {
                ...record,
                isEncrypted: false,
                encryptedData: null,
            });
        }
    }
    async.waterfall([
        function addIdentifierEncryptions(next) {
            // save in an object so that can be decrypted by the hashEncryptionKey
            const encryptedRow = { ...record };
            if (hashedColumns.length === 0 || !encryptedRow.hasOwnProperty('encryptedIdentifiers')) return next(null, encryptedRow);
            const objectToEncrypt = {};
            hashedColumns.forEach((column) => {
                objectToEncrypt[column.name] = encryptedRow[column.name];
            });

            const encryptedIdentifiers = encryptObject(objectToEncrypt, hashEncryptionKey);
            encryptedRow.isEncrypted = true;
            encryptedRow.encryptedIdentifiers = encryptedIdentifiers;
            next(null, encryptedRow);
        },
        function addSchoolEncryption(row, next) {
            const encryptedRow = { ...row };
            // save and encrypt the encryptedColumns to an object using the schoolTermEncryptionKey
            if (encryptedColumns.length === 0 || !encryptedRow.hasOwnProperty('encryptedData')) return next(null, encryptedRow);

            const objectToEncrypt = {};
            encryptedColumns.forEach((column) => {
                objectToEncrypt[column.name] = encryptedRow[column.name];
            });

            const encryptedData = encryptObject(objectToEncrypt, schoolTermEncryptionKey);
            encryptedRow.isEncrypted = true;
            encryptedRow.encryptedData = encryptedData;
            next(null, encryptedRow);
        },
        function hashIdentifiers(row, next) {
            // create one-way hashes of the specified external foreign keys
            const encryptedRow = { ...row };
            if (hashedColumns.length === 0) return next(null, encryptedRow);
            hashedColumns.forEach((column) => {
                const toEncrypt = encryptedRow[column.name];
                encryptedRow[column.name] = hashString(toEncrypt, hashEncryptionKey);
            });
            next(null, encryptedRow);
        },
        function redact(row, next) {
            // remove the original values from the record
            const encryptedRow = { ...row };
            encryptedColumns
                .filter((c) => c.hasOwnProperty('maskedValue'))
                .forEach((column) => {
                    encryptedRow[column.name] = column.maskedValue;
                });
            next(null, encryptedRow);
        },
    ], (err, row) => {
        if (err) return callback(err);
        setTimeout(() => callback(null, row), 0);
    });
}

/**
 * Encrypts an object using the given AES key.
 *
 * @param {Object} object - The object to be encrypted.
 * @param {string} aesKey - The AES key used for encryption.
 * @returns {string} The encrypted object as a base64-encoded string.
 */
function encryptObject(object, aesKey) {
    const cipherObject = encrypt(aesKey, JSON.stringify(object));
    return Buffer.from(
        JSON.stringify(cipherObject),
    ).toString('base64');
}

/**
 * Decrypts the rows of a table using the specified encryption settings and keys.
 *
 * @param {string} tableName - The name of the table to decrypt.
 * @param {Array} rows - The rows to decrypt.
 * @param {Object} keys - The AES encryption keys.
 * @param {String|null} keys.schoolTermEncryptionKey - The AES encryption key set by the school
 * @param {String} keys.hashEncryptionKey - The AES encryption key set by cipher
 * @param {Function} callback - The callback function to invoke when decryption is complete.
 * @returns {void}
 */
function decryptTable(tableName, rows, keys, callback) {
    const encryptionSettings = encryptionSettingsForTable(tableName);
    if (!encryptionSettings) return callback(new Error('Table not found in encryption settings.'));
    async.mapLimit(rows, 3, (row, nextRow) => {
        decryptRecord(encryptionSettings, keys, row, nextRow);
    }, (err, encryptedRows) => {
        if (err) return callback(err);
        callback(null, encryptedRows);
    });
}
/**
 * Decrypts a record using the provided encryption settings and keys.
 * @param {object} encryptionSettings - The encryption settings.
 * @param {Object} keys - The AES encryption keys.
 * @param {String|null} keys.schoolTermEncryptionKey - The AES encryption key set by the school
 * @param {String} keys.hashEncryptionKey - The AES encryption key set by cipher
 * @param {object} record - The record to decrypt.
 * @param {function} callback - The callback function to invoke when decryption is complete.
 */
function decryptRecord(encryptionSettings, keys, record, callback) {
    const { encryptedColumns, hashedColumns } = encryptionSettings;
    const { schoolTermEncryptionKey, hashEncryptionKey } = keys;

    async.waterfall([
        function decryptIdentifiers(next) {
            const encryptedRow = { ...record };
            if (hashedColumns.length === 0) return next(null, encryptedRow);
            if (!encryptedRow.encryptedIdentifiers) return next(null, encryptedRow);
            const { encryptedIdentifiers } = encryptedRow;

            const jsonString = Buffer.from(encryptedIdentifiers, 'base64').toString('utf8');
            let json;
            try {
                json = JSON.parse(jsonString);
            } catch (e) {
                console.error(e);
            }
            if (!json) return next(new Error('row decryption failed'));
            const { cipherText, iv, tag } = json;
            const decryptedIdentifiers = decrypt(hashEncryptionKey, cipherText, iv, tag);
            if (!decryptedIdentifiers) return next(new Error('Decryption failed'));
            const originalObject = JSON.parse(decryptedIdentifiers);
            Object.assign(encryptedRow, originalObject);
            encryptedRow.encryptedIdentifiers = null;
            encryptedRow.decryptedIdentifiers = originalObject;
            next(null, encryptedRow);
        },
        function decryptSchoolEncryption(row, next) {
            const encryptedRow = { ...row };
            if (encryptedColumns.length === 0) return next(null, encryptedRow);
            if (!encryptedRow.encryptedData) return next(null, encryptedRow);
            if (!schoolTermEncryptionKey) return next(null, encryptedRow);
            const { encryptedData } = encryptedRow;

            const jsonString = Buffer.from(encryptedData, 'base64').toString('utf8');
            let json;
            try {
                json = JSON.parse(jsonString);
            } catch (e) {
                console.error(e);
            }
            if (!json) return next(new Error('row decryption failed'));
            const { cipherText, iv, tag } = json;
            const decryptedData = decrypt(schoolTermEncryptionKey, cipherText, iv, tag);
            if (!decryptedData) return next(new Error('Decryption failed'));
            const originalObject = JSON.parse(decryptedData);
            Object.assign(encryptedRow, originalObject);
            encryptedRow.encryptedData = null;
            encryptedRow.decryptedData = originalObject;
            next(null, encryptedRow);
        },
    ], (err, row) => {
        const output = { ...row };
        output.isEncrypted = false;
        if (err) return callback(err);
        setTimeout(() => callback(null, output), 0);
    });
}

/**
 * Decrypts an encrypted object and mutates it with unencrytped values
 * Used in VUEX actions / mixins
 *
 * @param {object} object - The original object to be merged with the decrypted data.
 * @param {string} encryptedData - The encrypted data to be decrypted.
 * @param {string} aesKey - The AES key used for decryption.
 * @returns {object} - The decrypted object merged with the original object.
 */
function decryptObject(object, encryptedData, aesKey) {
    let cipherObject;
    try {
        cipherObject = JSON.parse(Buffer.from(encryptedData, 'base64').toString('utf8'));
    } catch (e) {
        console.error(e);
    }
    if (!cipherObject) return null;
    const { cipherText, iv, tag } = cipherObject;
    const decryptedObject = decrypt(aesKey, cipherText, iv, tag);
    const decrypted = JSON.parse(decryptedObject);

    const result = { ...object };
    result.decryptedObject = decrypted;
    if (result.extStudentId) {
        decrypted.extStudentId = result.extStudentId;
    }
    Object.assign(result, decrypted);
    return result;
}

/**
 * Retrieves the encryption settings for a given table.
 *
 * @param {string} tableName - The name of the table.
 * @returns {object|null} - The encryption settings for the table, or null if not found.
 */
function encryptionSettingsForTable(tableName) {
    const tableSettings = tableEncryptionSettings.find((s) => s.tableName == tableName);
    return (tableSettings || null);
}

export {

    encryptionSettingsForTable,
    tableEncryptionSettings,

    encryptTable,
    encryptRecord,
    encryptObject,

    decryptTable,
    decryptRecord,
    decryptObject,

};
