const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.search = (user, searchTerm, callback) => {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/search`)
        .query({ term: searchTerm })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.superSearch = (searchTerm, callback) => {
    request
        .get(`${syncGradesHost}/api/search`)
        .query({ term: searchTerm })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getAllUsers = (callback) => {
    request
        .get(`${syncGradesHost}/api/users`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getSchoolStaff = (schoolId, schoolTermId, callback) => {
    request.get(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/staff`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.getSchoolAdmins = (schoolId, callback) => {
    request.get(`${syncGradesHost}/api/schools/${schoolId}/admins`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.getSchoolStaffByStaffId = (user, staffId, callback) => {
    request.get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${staffId}`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.createNewStaff = (schoolId, schoolTermId, newUser, callback) => {
    request
        .post(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/staff`)
        .set('accept', 'json')
        .send({
            email: newUser.email,
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            roleId: newUser.roleId,
        })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.deleteStaff = (schoolId, schoolTermId, userId, callback) => {
    request
        .put(`${syncGradesHost}/api/schools/${schoolId}/terms/${schoolTermId}/staff`)
        .set('accept', 'json')
        .send({ userId })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.impersonateUser = (user, callback) => {
    request
        .patch(`${syncGradesHost}/api/users`)
        .send({ userId: user.userId, schoolTermId: user.schoolTermId })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getSchoolStudents = (callback) => {
    request.get(`${syncGradesHost}/api/schoolStudents`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.createUsers = function createUsers(users, callback) {
    request
        .post(`${syncGradesHost}/api/users`)
        .set('accept', 'json')
        .send({ users })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
