function MarkingPeriodStudentAverage(props) {
    this.courseSectionId = props.courseSectionId;
    this.studentEnrollmentId = props.studentEnrollmentId;
    this.courseSectionStudentId = props.courseSectionStudentId;
    this.schoolTermMarkingPeriodId = props.schoolTermMarkingPeriodId;
    this.markingPeriodAverage = props.markingPeriodAverage ? parseFloat(props.markingPeriodAverage) : null;
    this.averageCalculation = { ...props.averageCalculation };
}

module.exports = MarkingPeriodStudentAverage;
