/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listStudentBadgesForUser from './listStudentBadgesForUser';
import listDefaultBadges from './listDefaultBadges';
import listBadgesForSchool from './listBadgesForSchool';
import setBadgeDefaults from './setBadgeDefaults';
import addBadgeForSchool from './addBadgeForSchool';
import computeBadgesForSchool from './computeBadgesForSchool';
import checkStudentBadgeRequirements from './checkStudentBadgeRequirements';
import deleteBadge from './deleteBadge';
import editRanks from './editRanks';
import editBadge from './editBadge';
import removeStudentBadge from './removeStudentBadge';
import addStudentBadge from './addStudentBadge';

export {
    listStudentBadgesForUser,
    listDefaultBadges,
    listBadgesForSchool,
    setBadgeDefaults,
    addBadgeForSchool,
    computeBadgesForSchool,
    checkStudentBadgeRequirements,
    deleteBadge,
    editRanks,
    editBadge,
    removeStudentBadge,
    addStudentBadge,
 };
