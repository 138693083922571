/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getCourseWorkByCourseSectionId from './getCourseWorkByCourseSectionId';
import getCourseWorkByStaffId from './getCourseWorkByStaffId';
import getCourseWorkByStudentEnrollmentId from './getCourseWorkByStudentEnrollmentId';
import createCourseWork from './createCourseWork';
import editCourseWorks from './editCourseWorks';
import bulkGradingCategoryChange from './bulkGradingCategoryChange';

export {
    getCourseWorkByCourseSectionId,
    getCourseWorkByStaffId,
    getCourseWorkByStudentEnrollmentId,
    createCourseWork,
    editCourseWorks,
    bulkGradingCategoryChange,
 };
