function StudentPdfReport(props) {
    this.studentEnrollmentId = props.studentEnrollmentId;
    this.studentOfficialPdfId = props.studentOfficialPdfId;
    this.officialPdfUploadId = props.officialPdfUploadId;
    this.pdfType = props.pdfType;
    this.studentId = props.studentId;
    this.pdfBucketPath = props.pdfBucketPath;
    this.jpgBucketPath = props.jpgBucketPath;
    this.pageNumber = props.pageNumber;
    this.extSchoolYear = props.extSchoolYear;
    this.extSchoolTerm = props.extSchoolTerm;
    this.extMarkingPeriod = props.extMarkingPeriod;
    this.showOnPortfolio = props.showOnPortfolio;
    this.created = props.created;
}

module.exports = StudentPdfReport;
