import Types from '../Types';

export default {

    // this script flows down sequentially
    // to build all step states from localStorage
    // on page load

    [Types.actions.CALCULATE_SETUP_STEPS]({ commit, state }) {
        const school = state.database.schools
            .find((s) => s.schoolId == state.user.school.schoolId);
        const { schoolYear } = state.user.school;
        const { subscriptions, externalSchoolId } = school;
        const { isSuperAdmin } = state.user;
        const isDemoSchool = externalSchoolId == '00X000';
        // translate steps to object for easy access
        const steps = {};
        state.wizard.steps.forEach((s) => {
            steps[s.routeName] = s;
        });

        const subscription = subscriptions.find((s) => s.schoolYear == schoolYear );
        let isAdminLocked = false;
        if (subscription) {
            isAdminLocked = subscription.adminLocked && !isSuperAdmin;
        }

        // check if SetupDataUploads is completed
        const { bypassWizardDataUploads } = state.wizard;
        const {
            students, teachers,
            courseSections,
        } = state.database;

        const studentCutOff = 20;
        const teacherCutOff = 5;
        const courseSectionCutOff = 10;
        const termName = `${state.user.school.schoolYear}-${state.user.school.schoolYear + 1}`;

        steps['SetupDataUploads'].message = null;
        if (bypassWizardDataUploads) {
            steps['SetupDataUploads'].status = 'success';
        } else {
            if (students.length < studentCutOff) {
                steps['SetupDataUploads'].status = 'warning';
                steps['SetupDataUploads'].message = `You have less than ${studentCutOff} students in the ${termName} school term`;
            } else if (teachers.length < teacherCutOff) {
                steps['SetupDataUploads'].status = 'warning';
                steps['SetupDataUploads'].message = `You have less than ${teacherCutOff} teachers in the ${termName} school term`;
            } else if (courseSections.length < courseSectionCutOff) {
                steps['SetupDataUploads'].status = 'warning';
                steps['SetupDataUploads'].message = `You have less than ${courseSectionCutOff} courses in the ${termName} school term`;
            } else {
                steps['SetupDataUploads'].status = 'success';
            }
        }
        // console.log('SetupDataUploads', steps['SetupDataUploads']);

        // check if setup-grading is completed
        const { bypassWizardGradingEnabled } = state.wizard;
        steps['setup-grading'].value = bypassWizardGradingEnabled;
        if (bypassWizardGradingEnabled == 'true') {
            steps['setup-grading'].status = 'success';
        } else {
            steps['setup-grading'].status = 'danger';
        }

        // console.log('setup-grading', steps['setup-grading']);



        // check if setup-google-classroom is completed
        const { bypassWizardGoogleDomain } = state.wizard;
        steps['setup-google-classroom'].visible = !isAdminLocked && bypassWizardGradingEnabled === 'true';
        steps['setup-google-classroom'].value = bypassWizardGoogleDomain;
        if ( bypassWizardGoogleDomain == '') {
            steps['setup-google-classroom'].status = 'danger';
        } else {
            if (bypassWizardGoogleDomain == 'Disabled') {
                steps['setup-google-classroom'].status = 'danger';
            }
            if (bypassWizardGoogleDomain == 'School Managed') {
                steps['setup-google-classroom'].status = 'success';
            }
            if (bypassWizardGoogleDomain == 'District Managed') {
                steps['setup-google-classroom'].status = 'success';
            }
        }

        // console.log('setup-google-classroom', steps['setup-google-classroom']);



        // check if setup-communication is completed
        const { bypassWizardCommunication } = state.wizard;
        steps['setup-communication'].value = bypassWizardCommunication;
        if ( bypassWizardCommunication == '') {
            steps['setup-communication'].status = 'danger';
        } else {
            if (bypassWizardCommunication == 'disabled' || bypassWizardCommunication == '') {
                steps['setup-communication'].status = 'danger';
            } else {
                steps['setup-communication'].status = 'success';
            }
        }
        // console.log('setup-communication', steps['setup-communication']);

        // check if setup-anecdotals is completed
        const { bypassWizardBadges } = state.wizard;
        steps['setup-anecdotals'].value = bypassWizardBadges;
        if ( bypassWizardBadges == '') {
            steps['setup-anecdotals'].status = 'danger';
        } else {
            if (bypassWizardBadges == 'disabled' || bypassWizardBadges == '') {
                steps['setup-anecdotals'].status = 'danger';
            } else {
                steps['setup-anecdotals'].status = 'success';
            }
        }
        // console.log('setup-anecdotals', steps['setup-anecdotals']);

        // check if setup-encryption is completed
        const { bypassWizardEncryption } = state.wizard;
        const { serverSideEncryptionEnabled } = state.user.school;
        steps['setup-encryption'].enabled = steps['SetupDataUploads'].status == 'success' && isDemoSchool;
        steps['setup-encryption'].visible = isDemoSchool;

        if ( bypassWizardEncryption == '') {
            steps['setup-encryption'].status = 'danger';
        } else {
            if (bypassWizardEncryption == 'disabled' || bypassWizardEncryption == '') {
                steps['setup-encryption'].status = 'danger';
            } else {
                if (serverSideEncryptionEnabled) {
                    steps['setup-encryption'].status = 'success';
                } else {
                    steps['setup-encryption'].status = 'warning';
                }
            }
        }

        // console.log('setup-encryption', steps['setup-encryption']);


        // check if GoogleAccount is completed
        // const { bypassWizardLinkGoogleAccount } = state.wizard;
        // steps['GoogleAccount'].value = googleSetting;
        // steps['GoogleAccount'].enabled = googleSetting == 'Disabled' ? false : true;
        // if (bypassWizardLinkGoogleAccount) {
        //     steps['GoogleAccount'].status = 'success';
        // } else if (googleSetting !== 'Disabled') {
        //     steps['GoogleAccount'].value = state.user.googleAccountId;
        //     steps['GoogleAccount'].status = state.user.googleAccountId ? 'success' : 'danger';
        // }
        // console.log('GoogleAccount', steps['GoogleAccount']);

        // check if SetupGoogleArchive is completed
        const googleSetting = bypassWizardGoogleDomain;
        const { bypassWizardGoogleArchive } = state.wizard;
        steps['SetupGoogleArchive'].enabled = googleSetting == 'School Managed';
        if (bypassWizardGoogleArchive) {
            steps['SetupGoogleArchive'].status = 'success';
        } else if (googleSetting == 'School Managed') {
            steps['SetupGoogleArchive'].message = null;
            const activeNotLinked = state.database.googleCourses.filter((c) => Boolean(c.googleCourseState == 'ACTIVE') && Boolean(!c.linked));
            const total = activeNotLinked.length;
            if (state.database.googleCourses.length == 0) {
                steps['SetupGoogleArchive'].status = 'danger';
                steps['SetupGoogleArchive'].message = 'No google courses found, click Refresh Courses below';
            } else if (total < 150) {
                steps['SetupGoogleArchive'].status = 'success';
            } else {
                steps['SetupGoogleArchive'].status = 'warning';
                steps['SetupGoogleArchive'].message =  `
                You have ${total} active google courses that are not linked in SyncGrades this semester and could be archived.
                It's a good idea to archive your older google classroom courses to keep things organized.
            `;
            }
        }
        // console.log('SetupGoogleArchive', steps['SetupGoogleArchive']);




        const users = [...students, ...teachers];
        const linkedUsers = users.filter((u) => Boolean(u.googleEmail));
        const percentage = Math.floor(users.length == 0 ? 0 : ((linkedUsers.length / users.length) * 100));
        steps['SetupUserLinking'].enabled = googleSetting == 'School Managed' && steps['SetupDataUploads'].status == 'success';

        if (users.length == 0) {
            steps['SetupUserLinking'].status = 'danger';
            steps['SetupUserLinking'].message = 'There are no users imported for this school term. Please upload data files.';
        }
        if (percentage == 0) {
            steps['SetupUserLinking'].status = 'danger';
            steps['SetupUserLinking'].message = `None of the users for the ${termName} school term have been assigned a google classroom email address`;
        }
        if (percentage < 100) {
            steps['SetupUserLinking'].status = 'warning';
            steps['SetupUserLinking'].message = `${percentage}% of the users in the ${termName} school term have been assigned a google classroom email address`;
        } else {
            steps['SetupUserLinking'].status = 'success';
        }

        // check if SetupSchoolTerm is completed
        // const { bypassWizardSchoolTerm } = state.wizard;
        if (school.finalized) {
            steps['SetupSchoolTerm'].status = 'success';
        } else {
            steps['SetupSchoolTerm'].status = 'warning';
            steps['SetupSchoolTerm'].message = 'Warning: You must finalize the term to open to all teachers';
        }

        const keys = Object.keys(steps);
        const output = [];

        keys.forEach((k) => {
            output.push(steps[k]);
        });
        // console.log('output', output);
        commit(Types.mutations.SET_WIZARD_STEPS, output);
    },

};
