function AnecdotalEvent(props) {
    this.schoolTermId = props.schoolTermId;
    this.anecdotalEventId = props.anecdotalEventId;
    this.anecdotalEventCategoryId = props.anecdotalEventCategoryId;
    this.anecdotalEventCategoryTitle = props.anecdotalEventCategoryTitle;
    this.anecdotalEventDate = props.anecdotalEventDate;
    this.anecdotalEventTime = props.anecdotalEventTime;
    this.isConfidential = props.isConfidential;
    this.visibleStudent = props.visibleStudent;
    this.visibleGuardian = props.visibleGuardian;
    this.categoryIcon = props.categoryIcon;
    this.categoryColor = props.categoryColor;
    this.anecdotalEventDescription = props.anecdotalEventDescription;
    this.authorId = props.authorId;
    this.author = props.author;
    this.lastUpdated = props.lastUpdated;
    this.pointValue = props.pointValue;
    this.resolved = props.resolved;
    this.deleted = props.deleted;

    this.students = [];
    if (props.students) {
        this.students = props.students.slice();
    }
    if (props.studentEnrollmentId && props.studentId) {
        this.students.push({ studentEnrollmentId: props.studentEnrollmentId, studentId: props.studentId });
    }
    this.teachers = [];
    if (props.teachers) {
        this.teachers = props.teachers.slice();
    }
    if (props.schoolStaffId) {
        this.teachers.push({ schoolStaffId: props.schoolStaffId });
    }
}

module.exports = AnecdotalEvent;
