import moment from 'moment';
import navigation from './navigation';
import Types from '../Types';


const legacyGetters = {
    ...navigation,
};

// new getters with [CONSTANTS]
const getters = {
    [Types.getters.IS_DB_LOADED](state) {
        return state.database.loadState === 'READY';
    },
    [Types.getters.IS_CIPHER_SESSION_LOCKED](state) {
        const { twoFactorAuth } = state.user;
        if (!twoFactorAuth || !twoFactorAuth.twoFactorAuthEnabled) return false;
        const { cipherEpochExpiry, cipherSessionId, verificationKey } = twoFactorAuth;
        if (!cipherEpochExpiry || !cipherSessionId || verificationKey) return true;
        const now = moment().valueOf();
        if (cipherEpochExpiry <= now) return true;
        return false;
    },
    [Types.getters.IS_TWO_FACTOR_AUTH_REQUIRED](state) {
        // if (state.user.isSuperAdmin) {
        //     const vueJsNodeEnv = process.env.NODE_ENV;
        //     const isProdBuild = vueJsNodeEnv == 'production';
        //     if (isProdBuild) return true;
        //     return false;
        // }
        return state.user.school.requireTwoFactorAuth;
    },
    [Types.getters.IS_TWO_FACTOR_AUTH_ENABLED](state) {
        return state.user.twoFactorAuth.twoFactorAuthEnabled;
    },
    [Types.getters.IS_NYC_GOOGLE_DOMAIN_USER](state) {
        const domain = state.user.googleEmail ? state.user.googleEmail.split('@')[1] : '';
        return domain == 'schools.nyc.gov';
    },
    [Types.getters.GET_MONTHS_IN_TERM](state) {
        const start = moment(state.user.school.termStart.substring(0, 10), 'YYYY-MM-DD');
        const end = moment(state.user.school.termEnd.substring(0, 10), 'YYYY-MM-DD');
        const months = [];
        const lookup = [];
        for (let m = start; m.isSameOrBefore(end); m.add(1, 'days')) {
            const day = m.format('YYYY-MM-DD');
            const month = m.format('MM');
            if (!lookup.includes(month)) {
                lookup.push(month);
                months.push(day);
            }
        }
        return months;
    },
    [Types.getters.GET_USER_SCHOOL_ROLE](state) {
        return state.user.school.role;
    },
};

const output = {
    ...getters,
    ...legacyGetters,
};

export default output;
