import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadDataSystems(commit, state, callback) {
    const {
        schoolId, schoolTermId,
    } = state.user.school;

    network.markingPeriods.getForSchoolTerm({ url: { schoolId, schoolTermId } }, (err, res) => {
        if (err) return callback(err);
        commit(Types.mutations.SET_DB_MARKING_PERIODS, res.markingPeriods);
        callback();
    });
}
