/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import saveCategoryMappings from './saveCategoryMappings';
import getQueueStatus from './getQueueStatus';
import refreshCoursesForTeacher from './refreshCoursesForTeacher';
import refreshGoogleCourseAccess from './refreshGoogleCourseAccess';
import modifyGoogleCourse from './modifyGoogleCourse';
import checkCourseExistence from './checkCourseExistence';
import refreshCourseById from './refreshCourseById';
import listGoogleCourses from './listGoogleCourses';
import listGoogleTeachers from './listGoogleTeachers';
import changeCourseOwner from './changeCourseOwner';
import listGoogleStudents from './listGoogleStudents';
import listGoogleCourseStudents from './listGoogleCourseStudents';
import getGoogleCourseStudentsForTeacher from './getGoogleCourseStudentsForTeacher';
import getGoogleCourseStudentsForStudent from './getGoogleCourseStudentsForStudent';
import listGoogleCourseTeachers from './listGoogleCourseTeachers';
import listGoogleCourseCourseSections from './listGoogleCourseCourseSections';
import createGoogleForCourseSection from './createGoogleForCourseSection';
import refreshDatesForStaffId from './refreshDatesForStaffId';
import setCredentialsforCourse from './setCredentialsforCourse';
import getGoogleCourseGradeCategoriesForTeacher from './getGoogleCourseGradeCategoriesForTeacher';
import deleteLinkedCourse from './deleteLinkedCourse';
import linkCourse from './linkCourse';
import refreshStudentsForCourse from './refreshStudentsForCourse';
import addStudentToCourse from './addStudentToCourse';
import refreshTeachersForCourse from './refreshTeachersForCourse';
import addTeacherToCourse from './addTeacherToCourse';
import removeTeacherFromCourse from './removeTeacherFromCourse';
import refreshTopicsForCourse from './refreshTopicsForCourse';
import getGoogleCourseTopics from './getGoogleCourseTopics';
import getTopicsForTeacher from './getTopicsForTeacher';
import refreshCourseWorkForCourse from './refreshCourseWorkForCourse';
import refreshSubmissionsForCourse from './refreshSubmissionsForCourse';
import getGoogleAccountCreatorSettings from './getGoogleAccountCreatorSettings';
import saveGoogleAccountCreatorSettings from './saveGoogleAccountCreatorSettings';
import setAdminCredentials from './setAdminCredentials';

export {
    saveCategoryMappings,
    getQueueStatus,
    refreshCoursesForTeacher,
    refreshGoogleCourseAccess,
    modifyGoogleCourse,
    checkCourseExistence,
    refreshCourseById,
    listGoogleCourses,
    listGoogleTeachers,
    changeCourseOwner,
    listGoogleStudents,
    listGoogleCourseStudents,
    getGoogleCourseStudentsForTeacher,
    getGoogleCourseStudentsForStudent,
    listGoogleCourseTeachers,
    listGoogleCourseCourseSections,
    createGoogleForCourseSection,
    refreshDatesForStaffId,
    setCredentialsforCourse,
    getGoogleCourseGradeCategoriesForTeacher,
    deleteLinkedCourse,
    linkCourse,
    refreshStudentsForCourse,
    addStudentToCourse,
    refreshTeachersForCourse,
    addTeacherToCourse,
    removeTeacherFromCourse,
    refreshTopicsForCourse,
    getGoogleCourseTopics,
    getTopicsForTeacher,
    refreshCourseWorkForCourse,
    refreshSubmissionsForCourse,
    getGoogleAccountCreatorSettings,
    saveGoogleAccountCreatorSettings,
    setAdminCredentials,
 };
