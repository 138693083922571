const teacherMixins = {
    methods: {
        $_teacherMixins_getTeacherFromRoute() {
            if (!this.$route) return null;
            return getTeacherFromRoute(this.$route, this.$store);
        },
        $_teacherMixins_getTeacherFromEmail(schoolEmail) {
            const { database } = this.$store.state;
            const teachers = getTeachers(database, { schoolEmail, courseSectionId: false });
            const [firstItem] = teachers;
            return firstItem || null;
        },
        $_teacherMixins_getTeachersForCourseSectionId(courseSectionId) {
            const { database } = this.$store.state;
            return getTeachers(database, { schoolEmail: false, courseSectionId });
        },
        $_teacherMixins_getTeachers(limitBy) {
            const { database } = this.$store.state;
            return getTeachers(database, limitBy);
        },
        $_teacherMixins_getReducedTeachers(limitBy) {
            const { database } = this.$store.state;
            return getReducedTeachers(database, limitBy);
        },
    },
};

function getTeachers(database, limitBy = {
    schoolEmail: false,
    courseSectionId: false,
}) {
    const { teachers, courseSectionTeachers, courseSections } = database;

    let schoolStaffIds = [];
    if (limitBy.courseSectionId) {
        schoolStaffIds = courseSectionTeachers
            .filter((cst) => cst.courseSectionId == limitBy.courseSectionId)
            .map((cst) => cst.schoolStaffId);
    }

    return teachers.map((t) => {
        const teacher = { ...t };
        const courseSectionIds = courseSectionTeachers
            .filter((cst) => cst.schoolStaffId == teacher.schoolStaffId)
            .map((cst) => cst.courseSectionId);
        teacher.courseSections = courseSections.filter((cs) => courseSectionIds.includes(cs.courseSectionId)) || [];
        teacher.sort = `${t.lastName}_${t.firstName}`;
        teacher.search = `${t.lastName}_${t.firstName}_${t.googleEmail}_${t.schoolEmail}_${t.jobTitle}`;
        return teacher;
    }).filter((t) => {
        if (limitBy.schoolEmail) {
            return t.schoolEmail == limitBy.schoolEmail;
        }
        return true;
    })
    .filter((t) => {
        if (limitBy.courseSectionId) {
            return schoolStaffIds.includes(t.schoolStaffId);
        }
        return true;
    });
}

// Function to get reduced course objects for performance in large lists
function getReducedTeachers(database, limitBy = {
    schoolEmail: false,
    courseSectionId: false,
}) {
    const { teachers, courseSectionTeachers } = database;

    let schoolStaffIds = [];
    if (limitBy.courseSectionId) {
        schoolStaffIds = courseSectionTeachers
            .filter((cst) => cst.courseSectionId == limitBy.courseSectionId)
            .map((cst) => cst.schoolStaffId);
    }

    return teachers.filter((t) => {
        if (limitBy.schoolEmail) {
            return t.schoolEmail == limitBy.schoolEmail;
        }
        return true;
    })
    .filter((t) => {
        if (limitBy.courseSectionId) {
            return schoolStaffIds.includes(t.schoolStaffId);
        }
        return true;
    });
}

export function getTeacherFromRoute($route, $store) {
    const { schoolEmail } = $route.params;
    if (!schoolEmail) return null;
    const { state } = $store;
    const { database } = state;
    const teachers = getReducedTeachers(database, { schoolEmail, courseSectionId: false });
    const [teacher] = teachers;
    return teacher || null;
}

export {
    teacherMixins as default,
    getTeachers,
};
