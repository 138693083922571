function Badge(props) {
	this.badgeId = props.badgeId;
	this.badgeType = props.badgeType;
	// this.schoolId = props.schoolId;
	this.badgeTitle = props.badgeTitle;
	this.badgeDescription = props.badgeDescription;
	this.badgeRank = props.badgeRank;
	this.hasRequirements = props.hasRequirements;
	this.badgeLimiterType = props.badgeLimiterType;
	this.badgeColor = props.badgeColor;
	this.badgeIcon = props.badgeIcon;
	this.badgeIconShortName = props.badgeIconShortName;
	this.guardianShare = props.guardianShare;
	this.studentShare = props.studentShare;
	this.badgeRequirements = props.badgeRequirements || [];
	this.badgeManagers = props.badgeManagers || [];
	this.unlocksAvatars = props.unlocksAvatars;
	this.author = props.author;
	this.deleted = props.deleted;

	this.achievementLevel = props.achievementLevel || null;
	this.totalAchievementLevels = props.totalAchievementLevels || null;
}

module.exports = Badge;