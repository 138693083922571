const courseSectionTeacherMixins = {
    methods: {
        $_courseSectionTeacherMixins_getTeachersForCourseSectionId(courseSectionId) {
            const { database } = this.$store.state;

            return getTeachersForCourseSectionId(database, courseSectionId);
        },
    },
};

function getTeachersForCourseSectionId(database, courseSectionId) {
    const schoolStaffIds = database.courseSectionTeachers
        .filter((g) => courseSectionId == g.courseSectionId)
        .map((g) => g.schoolStaffId);

    const teachers = database.teachers
        .filter((g) => schoolStaffIds.includes(g.schoolStaffId));
    return teachers || [];
}

export { courseSectionTeacherMixins as default, getTeachersForCourseSectionId };
