module.exports.greetings = {
    hello: 'Hello',
    hi: 'Hi',
    welcome: 'Welcome',
};

module.exports.labels = {
    account: 'Account',
    action: 'Action',
    actions: 'Actions',
    after: 'After',
    before: 'Before',
    cancel: 'Cancel',
    close: 'Close',
    closed: 'Closed',
    comment: 'Comment',
    configure: 'Switch',
    confirm: 'Confirm',
    continue: 'Continue',
    course: 'Course',
    created: 'Created',
    daily: 'Daily',
    date: 'Date',
    delete: 'Delete',
    download: 'Download',
    editLink: 'Edit Link',
    email: 'Email',
    google: 'Google',
    googleClassroom: 'Google Classroom',
    id: 'ID',
    link: 'Link',
    linkAccount: 'Link Account',
    markingPeriod: 'Marking Period',
    no: 'No',
    okay: 'Okay',
    open: 'Open',
    openInGoogle: 'Open In Google',
    password: 'Password',
    pdf: 'PDF',
    percentage: 'Percentage',
    save: 'Save',
    somethingWentWrong: 'Something went wrong!',
    topic: 'Google Topic',
    state: 'State',
    status: 'Status',
    teacherProfile: 'Teacher Profile',
    term: 'Term',
    time: 'Time',
    type: 'Type',
    user: 'User',
    yes: 'Yes',
};

module.exports.attendance = {
    present: 'Present',
    late: 'Late',
    absent: 'Absent',
    excused: 'Excused',
};

module.exports.missingInformation = {
    incorrectAverages: 'Incorrect averages? Click to recalculate averages.',
    recalculateAverages: 'Recalculate Averages',
    refresh: 'Refresh {type}',
    statement: 'Missing information? Click to import latest {type} from google.',
    types: {
        activity: 'Activity',
        assignments: 'Assignments',
        courses: 'Courses',
        courseList: 'Course List',
        grades: 'Grades',
        students: 'Students',
        teacherList: 'Teacher List',
        teachers: 'Teachers',
    },
};

module.exports.titles = {
    assistantPrincipal: 'Assistant Principal',
    principal: 'Principal',
    staff: 'Staff',
    teacher: 'Teacher',
    syncGrades: 'SyncGrades',
};

module.exports.units = {
    day: 'day | days',
};
