import crypto from 'crypto';

/**
 * Encrypts the given plaintext using AES-256-GCM encryption algorithm.
 * @param {string} encryptionKey - The encryption key used for encryption.
 * @param {string} plaintext - The plaintext to be encrypted.
 * @returns {Object} - An object containing the cipher text, initialization vector (iv), and authentication tag.
 */
function encrypt(encryptionKey, plaintext) {
    // create a random initialization vector
    const iv = crypto.randomBytes(32).toString('base64');

    const bitOutput = Buffer.from(encryptionKey, 'hex');
    const cipher = crypto.createCipheriv('aes-256-gcm', bitOutput, iv);

    // update the cipher object with the plaintext to encrypt
    let cipherText = cipher.update(plaintext, 'utf8', 'base64');

    // finalize the encryption process
    cipherText += cipher.final('base64');

    // retrieve the authentication tag for the encryption
    const tag = cipher.getAuthTag();
    return { cipherText, iv, tag };
}

/**
 * Decrypts the given cipher text using AES-256-GCM algorithm.
 *
 * @param {string} decryptionKey - The decryption key in hexadecimal format.
 * @param {string} cipherText - The base64-encoded cipher text to decrypt.
 * @param {Buffer} iv - The initialization vector used for encryption.
 * @param {string} tag - The base64-encoded authentication tag.
 * @returns {string|null} The decrypted plaintext, or null if decryption fails.
 */
function decrypt(decryptionKey, cipherText, iv, tag) {
    const bitOutput = Buffer.from(decryptionKey, 'hex');

    let plaintext = null;
    try {
        // create a decipher object
        const decipher = crypto.createDecipheriv('aes-256-gcm', bitOutput, iv);

        // set the authentication tag for the decipher object
        decipher.setAuthTag(Buffer.from(tag, 'base64'));

        // update the decipher object with the base64-encoded cipherText
        plaintext = decipher.update(cipherText, 'base64', 'utf8');

        // finalize the decryption process
        plaintext += decipher.final('utf8');
    } catch (error) {
        console.error('AES decryption error:', error);
        return null;
    }
    return plaintext;
}

/**
 * Derives a key from a passphrase using PBKDF2 algorithm.
 *
 * @param {string} passPhrase - The passphrase to derive the key from.
 * @param {Buffer} hashEncryptionKey - The encryption key used as a salt.
 * @param {function} callback - The callback function to be called with the derived key.
 */
function passPhraseToKey(passPhrase, hashEncryptionKey, callback) {
    const iterations = 100000;
    const salt = hashEncryptionKey.toString('hex');
    const keyLength = 32;
    const keyType = 'sha256';
    crypto.pbkdf2(passPhrase, salt, iterations, keyLength, keyType, (err, derivedKey) => {
        if (err) return callback(err);
        callback(err, derivedKey.toString('hex'));
    });
}

/**
 * Creates a synchronous one way hash using the specified input string and AES key.
 *
 * @param {string} inputString - The input string to be hashed.
 * @param {string} aesKey - The AES key used for hashing.
 * @returns {string} The synchronous hash value.
 */
function hashString(inputString, aesKey) {
    const toEncode = (inputString || '').toString();
    const hashed = crypto
        .createHmac('sha256', aesKey)
        .update(toEncode, 'binary')
        .digest('base64');
    return hashed;
}

export {
    encrypt,
    decrypt,
    hashString,
    passPhraseToKey,
};
