function UserRole(props) {

    this.userId = props.userId;
    this.schoolStaffId = props.schoolStaffId;
    this.userRoleId = props.userRoleId;
    this.roleId = props.roleId;
    this.roleName = props.roleName;
    this.schoolTermId = props.schoolTermId;
    this.roleEnabled = props.roleEnabled;

}

module.exports = UserRole;