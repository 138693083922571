module.exports = {
    studentPortfolio: 'Portafolio del Estudiante',
    studentDoesNotExist: 'El estudiante no existe, está en otro término escolar o no tiene acceso, por favor',
    searchAgain: 'busque de nuevo',
    discharged: 'Descargado',
    downloadPDF: 'Descargar PDF',
    studentStats: {
        studentId: 'ID del Estudiante',
        homeRoom: 'Inicio Habitación',
        gradeLevel: 'Nivel de Grado',
        googleEmail: 'Correo Electrónico de Google',
        guardian: 'Guardián',
        phoneNumber: 'Número de Teléfono',
        homeLanguage: 'Idioma del Hogar',
        enteredNinthGrade: 'Ingresó al noveno grado',
        gender: 'Género',
        admissionDate: 'Fecha de Admisión',
        ethnicity: 'Etnicidad',
        englishLanguageLearner: 'Aprendiz del Idioma Inglés',
        specialEducationProgram: 'Programa de Educación Especial',
    },
    fileNames: {
        'Student Report Cards': '@:pages.studentPortfolio.officialReportCards.studentReportCards',
        'Middle School Student Report Cards': 'Boletas de calificaciones de estudiantes de secundaria',
        'Student Transcripts': '@:pages.studentPortfolio.officialTranscripts.studentTranscripts',
        'Student Schedules': '@:pages.studentPortfolio.officialSchedules.studentSchedules',
        'Student Grid Schedules': 'Horarios de Cuadrícula de Estudiantes',
    },
    personal: {
        personalInformation: 'Informacion Personal',
        biographicalInformation: 'Información Biográfica',
        picture: 'Imagen',
        clickThePencil: 'Haz clic en el lápiz para subir un avatar.',
        firstName: 'Primer Nombre',
        lastName: 'Apellido',
        preferredName: 'Nombre Preferido',
        preferredPronoun: 'Pronombre Preferido',
        he: 'él',
        him: 'él',
        she: 'ella',
        her: 'ella',
        they: 'ellos',
        them: 'ellos',
        editGoogleEmail: 'Editar Correo Electrónico de Google',
    },
    evaluation: {
        remoteLearning: {
            remoteLearning: 'Aprendizaje Remoto',
            remoteLearningCohort: '@:pages.studentPortfolio.evaluation.remoteLearning.remoteLearning Grupo',
            remoteLearningPreference: '@:pages.studentPortfolio.evaluation.remoteLearning.remoteLearning Preferencia',
        },
    },
    pleaseUpload: 'Cargue un archivo pdf completo {title} de la escuela desde STARS a través de un administrador de archivos para completar esta sección.',
    officialReportCards: {
        title: 'Boletas de Calificaciones Oficiales',
        studentReportCards: 'Boletas de Calificaciones de los Estudiantes',
        'Student Report Cards': '@:pages.studentPortfolio.officialReportCards.studentReportCards',
        'Middle School Student Report Cards': 'Escuela Intermedia @:pages.studentPortfolio.officialReportCards.studentReportCards',
    },
    officialTranscripts: {
        title: 'Transcripciones Oficiales',
        studentTranscripts: 'Transcripciones de Estudiantes',
    },
    officialSchedules: {
        title: 'Horarios Oficiales',
        studentSchedules: 'Horarios de Estudiantes',
        studentGridSchedules: 'Horarios de Cuadrícula de Estudiantes',
    },
    progressReports: {
        title: 'Reporte de Progreso',
        downloadBulk: 'Descargar a Granel',
        createNew: 'Crear Nuevo',
    },
    attendance: {
        attendanceTotals: 'Totales de Asistencia',
        attendanceHeatmap: 'Mapa de Calor de Asistencia',
        showDailyAttendance: 'Mostrar Asistencia Diaria',
        attendanceDetails: 'Detalles de Asistencia',
        dailyAttendanceFor: 'Asistencia Diaria para {date}',
        courseAttendanceFor: 'Asistencia al Curso para {date}',
        present: 'Regalo',
        late: 'Tarde',
        absent: 'Ausente',
        excused: 'Excusado',
    },
    behaviors: {
        behaviorEvent: 'Evento de comportamiento | Eventos de comportamiento',
        createEvent: 'Crear Evento',
        thereAreNone: 'No hay eventos de comportamiento para {student} en este momento',
        eventsOnThisDay: 'Eventos en este día',
        involvingNStudents: 'Involucrar a {studentCount} Estudiante | Involucrar a {studentCount} estudiantes',
        eventDate: 'Fecha del Evento',
        eventTime: 'Hora del evento',
        behaviorDefinition: 'Definición de Comportamiento',
        location: 'Ubicación',
        studentSearch: 'Búsqueda de Estudiantes',
        studentInvolvement: 'Estudiantes Involucrados',
        noStudentsSelected: 'No hay Estudiantes Seleccionados',
        highest: 'Mas Alto',
        high: 'Alto',
        low: 'Bajo',
        lowest: 'Mas Bajo',
    },
    activity: {
        activityHeatmap: 'Mapa de Calor de la Actividad',
        activityTimeline: 'Cronología de la Actividad',
        activityOnThisDay: 'Actividad en este día',
        unlinkedCourse: 'Curso no Vinculado',
        DRAFT: 'SEQUÍA',
        PUBLISHED: 'PUBLICADO',
        CREATED: 'CREADO',
        NEW: 'NUEVO',
        RECLAIMED_BY_STUDENT: 'RECUPERADO POR EL ESTUDIANTE',
        RETURNED: 'DEVUELTO',
        TURNED_IN: 'CONVERTIDO EN',
        COMPLETE: 'COMPLETO',
        LATE: 'TARDE',
        ON_TIME: 'A TIEMPO',
        unknown: 'Desconocido',
        ungraded: 'Sin Calificar',
    },
    assignments: {
        assignment: 'Tarea | Tareas',
        // eslint-disable-next-line no-useless-escape
        category: 'Categoría',
        topic: 'Tema',
        fullTitle: 'Título Completo',
        noTitleProvided: 'No se proporciona título',
        description: 'Descripción',
        noDescriptionProvided: 'No se proporcionó descripción',
        assignmentDetails: 'Detalles de la Asignación',
        ASSIGNEE_MODE_UNSPECIFIED: 'Sin Especificar',
        ALL_STUDENTS: 'Todos los Estudiantes',
        INDIVIDUAL_STUDENTS: 'Individuos',
        id: 'ID',
        type: 'Escribe',
        assigned: 'Asignado',
        created: 'Creado',
        dueDate: 'Fecha de Vencimiento',
        state: 'Expresar',
        weight: 'Peso',
        lastActivity: 'Última Actividad',
        draftAssignedGrade: 'Borrador/Calificación Asignada',
        gradeType: 'Tipo de grado',
        lateStatus: 'Estado Tardío',
        maxPoints: 'Puntos Máximos',
        submissionState: 'Estado de Envío',
        averageImpact: 'Impacto Medio',
        googleActivity: 'Actividad de Google',
        thisIsTheActivityStream: 'Este es el flujo de actividad que muestra toda la actividad de Google de maestros y estudiantes para esta tarea.',
        effectWithCategory: 'Este es el efecto que está teniendo esta tarea en sus promedios generales y de categoría {categoryName}.',
        effectWithoutCategory: 'Este es el efecto que está teniendo esta tarea en los promedios generales de su categoría.',
        overall: 'General',
    },
    averages: {

    },
    englishLanguageLearner: {
        title: 'Aprendizaje del Idioma Inglés',
    },
};
