function GoogleCourseGradeCategory(props) {
    this.googleCourseGradeCategoryId = props.googleCourseGradeCategoryId;
    this.googleCourseId = props.googleCourseId;
    this.schoolTermId = props.schoolTermId;
    this.extGradeCategoryId = props.extGradeCategoryId;
    this.gradeCategoryName = props.gradeCategoryName;
    this.gradeCategoryWeight = props.gradeCategoryWeight;
    this.defaultGradeDenominator = props.defaultGradeDenominator;
    this.deleted = props.deleted;
    this.gradeTemplateId = props.gradeTemplateId;
    this.gradeTemplateName = props.gradeTemplateName;
    this.gradeTemplateCategoryId = props.gradeTemplateCategoryId;
    this.categoryName = props.categoryName;
    this.categoryPercentage = props.categoryPercentage;
}

module.exports = GoogleCourseGradeCategory;
