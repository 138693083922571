const Reports = () => import( '../../rootComponents/Reports.vue');

const reportRoutes = [
    {
        name: 'Reports',
        path: '/reports',
        component: Reports,
        meta: {
            subHeader: true,
            title: 'Reports',
            icon: 'progressReports',
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    },
];

export default reportRoutes;
