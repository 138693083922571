module.exports = {
    teachers: 'Profesores',
    notLinked: 'no enlazado',
    through: 'a',
    sortBy: {
        sortBy: 'Ordenar Por',
        defaultGoogleAlpha: 'Predeterminado: Google Alfa',
        participationRate: 'Tasa de Participación',
        grades: 'Los Grados',
        schoolAttendance: 'Asistencia a la escuela',
        courseAttendance: 'Asistencia al curso',
    },
    pinToTop: 'Pin en la parte superior',
    unpin: 'Desprender',
    numberOfClasses: '{count} clases',
    numberOfPreps: '{count} preparaciones',
    numberOfStudents: '{count} estudiantes',
    numberLinked: '{count} vinculados a estudiantes de Google',
    errorPopulatingTeachers: 'Error poblando maestros',
};

module.exports.progressWidget = {
    participationRate: {
        title: '@:pages.teachers.sortBy.participationRate',
        subTitle: 'Tareas de Google',
        hover: 'Porcentaje de envío de tareas por parte de los estudiantes (incluye cursos no vinculados)',
        progress: {
            tooltip0: '{count} estudiantes que enviaron',
        },
        legend: {
            left: '{submissionCount} de {studentCount} participó',
            right: '',
        },
    },
    schoolAttendance: {
        title: '@:pages.teachers.sortBy.schoolAttendance',
        subTitle: 'Porcentaje presente',
        hover: 'Porcentaje de asistencia diaria Presente (tarde + presente)',
        progress: {
            tooltip0: '{count} ({percent}%) Regalo',
            tooltip1: '{count} ({percent}%) Tarde',
            tooltip2: '{count} ({percent}%) Ausente',
            tooltip3: '{count} ({percent}%) Excusado',
        },
        legend: {
            left: '{percent}% - Ausente',
            right: '',
        },
    },
    courseAttendance: {
        title: '@:pages.teachers.sortBy.courseAttendance',
        subTitle: 'Porcentaje presente',
        hover: 'Porcentaje de asistencia al curso Presente (tarde + presente)',
        progress: {
            tooltip0: '{count} ({percent}%) Regalo',
            tooltip1: '{count} ({percent}%) Tarde',
            tooltip2: '{count} ({percent}%) Ausente',
            tooltip3: '{count} ({percent}%) Excusado',
        },
        legend: {
            left: '{percent}% - Ausente',
            right: '',
        },
    },
    grades: {
        title: '@:pages.teachers.sortBy.grades',
        subTitle: 'Calificaciones de tareas',
        hover: 'Distribuciones de calificaciones de tareas (incluye cursos no vinculados)',
        progress: {
            tooltip0: '{count} ({percent}%) calificaciones aprobatorias',
            tooltip1: '{count} ({percent}%) calificaciones de honores',
            tooltip2: '{count} ({percent}%) bajas calificaciones',
        },
        legend: {
            left: '{total} de {allAssignments} calificaciones aprobatorias',
            right: '',
        },
    },
};
