const request = require('./requestWrapper');

const syncGradesHost = '';

module.exports.getMarkingPeriods = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/markingPeriods`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getMarkingPeriodsForSchoolTerm = function (user, schoolTermId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/markingPeriods?schoolTermId=${schoolTermId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};
module.exports.createMarkingPeriods = function (user, markingPeriods, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/markingPeriods`)
        .set('accept', 'json')
        .send({ markingPeriods })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.setMarkingPeriodDates = function (user, markingPeriod, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/markingPeriods/${markingPeriod.schoolTermMarkingPeriodId}/dates`)
        .set('accept', 'json')
        .send({
            markingPeriodStart: markingPeriod.markingPeriodStart,
            markingPeriodEnd: markingPeriod.markingPeriodEnd,
        })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getMarkingPeriodDatesForCourse = function (user, courseSectionId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}/markingPeriods`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

// DEPRECATED - JCB
module.exports.setMarkingPeriodDatesForCourse = function (user, schoolTermMarkingPeriodId, courseSectionId, gradeTemplateId, startDate, endDate, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}/markingPeriods/${schoolTermMarkingPeriodId}/`)
        .set('accept', 'json')
        .send({
            markingPeriodStart: startDate,
            markingPeriodEnd: endDate,
            gradeTemplateId,
        })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};
