import moment from 'moment';
import async from 'async';
import * as localForage from 'localforage';
import numeral from 'numeral';
import confetti from 'canvas-confetti';
import presentation from '../../config';
import routes from '../../router/routes';

const cannonDefaults = {

    launchCannons: false,
    cannonColor: null,

    lanchEmojiBurst: false,
    emoji: null,
    emojiColor: null,
};

export default {
    computed: {
        isBetaUser() {
            const { user } = this.$store.state;
            const { school } = user;
            return school.isBetaSchool;
        },
        isLocalDev() {
            const isProduction = process.env.NODE_ENV == 'production';
            return !isProduction && window.location.hostname === 'localhost';
        },
    },
    methods: {
        // call celebrate() to run confetti alone
        isBetaRoute(routeName) {
            let route = null;
            routes.every((r) => {
                if (r.name == routeName) {
                    route = r;
                    return false;
                }
                if (r.children) {
                    route = iterateRouteChildren(r, routeName);
                    if (route) return false;
                }
                return true;
            });
            if (!route) return false;
            return route.meta.isBeta === true;
        },
        isLocalRoute(routeName) {
            let route = null;
            routes.every((r) => {
                if (r.name == routeName) {
                    route = r;
                    return false;
                }
                if (r.children) {
                    route = iterateRouteChildren(r, routeName);
                    if (route) return false;
                }
                return true;
            });
            if (!route) return false;
            return route.meta.isLocal === true;
        },
        celebrate(options = cannonDefaults) {
            const { launchCannons, cannonColor } = options;
            const { lanchEmojiBurst, emoji, emojiColor } = options;
            if (launchCannons) {
                const color = cannonColor || '#007bff';
                explodeSideCannons(color);
            }
            if (lanchEmojiBurst && emoji) {
                const color = emojiColor || '#007bff';
                explodeEmojis(emoji, color);
            }
            rainConfetti();
        },
        decimalToPercent(v) {
            let value = v;
            if (value === 0) return 0;
            if (!value) return '-';
            value *= 100;
            const decimals = 2;
            // @ts-ignore
            return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
        },
        percentify(numerator, denominator = 1) {
            if (!numerator) return 0.00;
            if (numerator == 'NaN') return 0.00;
            if (numerator == 0) return 0.00;
            return ((numerator / denominator) * 100).toFixed(2);
        },
        createPercentage(num, den) {
            if (num <= 0 || den <= 0) return 0;
            const percentage = ((num / den) * 100);
            const roundToDecimals = 1;
            // @ts-ignore
            return Number(`${Math.round(`${percentage}e${roundToDecimals}`)}e-${roundToDecimals}`);
        },
        formatCurrency(amount) {
            return numeral(amount).format('$ 0,0[.]00');
        },
        formatNumber(n) {
            return numeral(n).format('0,0');
        },
        abbrevNumber(n) {
            return numeral(n).format('0.0a');
        },
        isValidEmailAddress(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String((email || '')).toLowerCase());
        },
        storageClear(callback = null) {
            // remove expiry in localStorage
            const { localStorage } = window;
            Object.keys(localStorage)
                .filter((key) => key.endsWith('_expiry'))
                .forEach((key) => {
                    localStorage.removeItem(key);
                });

            // remove any all db related keys
            localForage.keys((err, keys) => {
                if (err) {
                    if (callback) return callback(err);
                    return console.error(err);
                }

                keys.forEach((key) => {
                    if (key.endsWith('_syncgrades')) {
                        localForage.removeItem(key);
                    }
                });

                localForage.dropInstance({
                    name: 'syncgrades',
                    storeName: 'sg_cache',
                }).then(() => {
                    window.syncGrades.log('Local forage indexed db cache was cleared', 'info', 'TheRouterLayout');
                    if (callback) callback();
                });
            });
        },
        copyTextToClipboard(text) {
            const v = this;
            v.$copyText(text).then(() => {
                v.$bvToast.toast(text, {
                    title: 'Copied to clipboard',
                    autoHideDelay: 250,
                    variant: 'primary',
                    solid: true,
                    appendToast: true,
                });
            }, (e) => {
            // alert('Can not copy')
                window.console.log('Copy error');
                window.console.log(e);
            });
        },
        storageClearExpired(callback = null) {
            // remove any items that have expired
            const { sessionStorage } = window;
            localForage.keys((err, keys) => {
                if (err) {
                    if (callback) return callback(err);
                    return console.error(err);
                }
                async.each(keys, (key, next) => {
                    if (key.endsWith('_syncgrades')) {
                        const prefix = key.split('_syncgrades')[0];
                        const expiryKey = `${prefix}_expiry`;

                        // wipe localForage db if expired
                        if (sessionStorage.getItem(expiryKey)) {
                            const expiry = new Date(sessionStorage.getItem(expiryKey));

                            if (expiry.getTime() < new Date().getTime()) {
                                localForage.removeItem(key, next);
                                sessionStorage.removeItem(expiryKey);
                                return;
                            }
                            return next();
                        }
                        return localForage.removeItem(key, next);
                    }
                    next();
                }, (err2) => {
                    if (err2) {
                        if (callback) return callback(err2);
                        return console.error(err2);
                    }
                    if (callback) callback();
                });
            });
        },
        prettyConfirm(bvModal, title, message, callback) {
            const options = {
                title,
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true,
                okTitle: 'Yes',
                cancelTitle: 'No',
            };
            bvModal.msgBoxConfirm(message, options).then((confirm) => {
                callback(null, confirm);
            }).catch((e) => {
                callback(e);
            });
        },
        showAlert(title, subTitle, className) {
            // 'Notification Sent', null, 'success'
            const options = {
                title: subTitle,
                size: 'md',
                buttonSize: 'sm',
                okVariant: className,
                toaster: 'b-toaster-bottom-right',
                autoHideDelay: 750,
                variant: className,
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true,
                okTitle: 'Okay',
                solid: true,
                appendToast: true,
            };
            this.$bvToast.toast(title, options);
        },
        prettyAlert(bvModal, title, message, callback) {
            const options = {
                title,
                size: 'md',
                buttonSize: 'md',
                okVariant: 'success',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true,
                okTitle: 'Okay',
            };
            bvModal.msgBoxOk(message, options).then(() => {
                if (callback) {
                    callback(null);
                }
            }).catch((e) => {
                if (callback) {
                    callback(e);
                }
            });
        },
        showSessionExpiredModal() {
            return this.$bvModal
                .msgBoxOk('Please log in again', {
                    title: 'Session expired',
                    okVariant: 'primary',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true,
                    noCloseOnEsc: true,
                    noCloseOnBackdrop: true,
                })
                .then(value => {
                    window.location.href = '/login?sessionExpired';
                })
                .catch(err => {
                    // An error occurred
                });
        },
        showError(err, autoHide = true) {
            const v = this;

            if (!err) return;
            let displayErr = 'Uh oh, something went wrong. Try again later, or contact techincal support for assistance.';
            try {
                if (typeof err === 'object') {
                    if (err.text || err.message) {
                        displayErr = v.translateError(err.text || err.message);
                    } else if (err.response && err.response.text) {
                        if (v.isJsonString(err.response.text)) {
                            const errObject = JSON.parse(err.response.text);
                            displayErr = v.translateError(errObject.error.message || errObject.error);
                        } else {
                            displayErr = v.translateError(err.response.text);
                        }
                    }
                } else {
                    if (`${err}`.startsWith('401: ')) {
                        return v.showSessionExpiredModal();
                    }
                    displayErr = v.translateError(`${err}`);
                }
            } catch (e) { }

            const errors = displayErr.split('\n')
                .filter((e, idx) => Boolean((e || '').trim()) && idx < 3)
                .map((e) => e.trim());

            errors.forEach((e) => {
                window.syncGrades.log(e, 'error', 'showError');
                v.$bvToast.toast(e, {
                    title: 'An Error Occured',
                    autoHideDelay: 10000,
                    noAutoHide: !autoHide,
                    variant: 'danger',
                    solid: true,
                    appendToast: true,
                });
            });
        },
        translateError(error) {
            switch (error.toLowerCase()) {
            case 'error refreshing':
            case 'invalid_grant':
                return `You don't have permission to perform this task or you don't have access to this course or its
                    assignments. Please try again later, or contact your school administrator or technical support.`;
            case 'the service is currently unavailable.':
                return `There was a problem refreshing your data on Google. Please try again later, or contact your
                    school administrator or technical support for help.`;
            case 'marking_period_out_of_range':
                return `One of the marking periods you are trying to save does not overlap with the existing
                    marking periods. Please check the marking period dates and re-submit.`;
            default:
                return error;
            }
        },
        showNotification(notification, autoHide = true) {
            const v = this;
            const displayNotification = notification;

            v.$bvToast.toast(displayNotification, {
                title: 'Success',
                toaster: 'b-toaster-top-center',
                autoHideDelay: 2500,
                noAutoHide: !autoHide,
                variant: 'success',
                solid: true,
                appendToast: false,
                class: 'mt-5 pt-5',
            });
        },
        // Marking Periods
        inRange(assignment, markingPeriod) {
            const sortDate = assignment.sortDate || (assignment.dueDate || assignment.creationTime);
            if (!sortDate || !markingPeriod) {
                return false;
            }
            const betweenDates = moment(assignment.sortDate).isBetween(moment(markingPeriod.markingPeriodStart, 'YYYY-MM-DD'), moment(markingPeriod.markingPeriodEnd, 'YYYY-MM-DD'), 'days', '[]');
            return (assignment.schoolTermMarkingPeriodId == markingPeriod.schoolTermMarkingPeriodId) || (betweenDates && !assignment.averageExclusiveForMp);
        },
        relativeDate(date) {
            const deltaInDays = moment(date).diff(moment(), 'days');
            if (deltaInDays < 0) {
                const absDeltaInDays = Math.abs(deltaInDays);
                return this.$t('pages.teacher.assignments.dueWhenPast', {
                    count: absDeltaInDays,
                    unit: this.$tc('generic.units.day', absDeltaInDays),
                });
            }
            if (deltaInDays > 0) {
                return this.$t('pages.teacher.assignments.dueWhenFuture', {
                    count: deltaInDays,
                    unit: this.$tc('generic.units.day', deltaInDays),
                });
            }
            return this.$t('pages.teacher.assignments.dueWhenNow');
        },
        monthFromString(monthString) {
            return new Date(Date.parse(`${monthString}1, 2012`)).getMonth();
        },
        getFormattedDate(dateString, format = 'dddd, MMMM Do YYYY') {
            return moment(dateString).format(format);
        },
        isBillingAdmin() {
            return ['paul@syncgrades.com', 'jed@syncgrades.com'].includes(this.$store.state.user.userName);
        },
        shouldAppear(name, type, externalDistrictId) {
            return presentation.shouldAppear(name, type, externalDistrictId);
        },
    },
};

function iterateRouteChildren(route, routeName) {
    if (route.name == routeName) {
        return route;
    }
    if (route.children) {
        for (let i = 0; i < route.children.length; i += 1) {
            const child = route.children[i];
            if (child.name == routeName) {
                return child;
            }
            if (child.children) {
                const result = iterateRouteChildren(child, routeName);
                if (result) {
                    return result;
                }
            }
        }
    }
    return null;
}

function explodeEmojis(text, hexColor) {
    const scalar = 2;
    const emoji = confetti.shapeFromText({ text, scalar });
    const colors = [hexColor, '#ffffff'];

    const defaults = {
        scalar: 2,
        spread: 180,
        particleCount: 30,
        origin: { y: -0.1 },
        startVelocity: -35,
    };

    confetti({
        ...defaults,
        shapes: [emoji],
    });
    confetti({
        ...defaults,
        particleCount: 100,
        colors,
    });
    confetti({
        ...defaults,
        shapes: [emoji],
    });
}

function explodeSideCannons(hexColor) {
    const runtime = 1.2;
    const end = Date.now() + (runtime * 1000);
    const colors = [hexColor, '#ffffff'];

    (function frame() {
        confetti({
            particleCount: 2,
            angle: 60,
            spread: 55,
            origin: { x: 0 },
            colors,
        });
        confetti({
            particleCount: 2,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors,
        });

        if (Date.now() < end) {
            requestAnimationFrame(frame);
        }
    }());
}

function rainConfetti() {
    confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
    });
}
