/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import * as admin from './admin';
import * as aggregates from './aggregates';
import * as anecdotals from './anecdotals';
import * as attendance from './attendance';
import * as auth from './auth';
import * as authGoogle from './authGoogle';
import * as authLocalPasswords from './authLocalPasswords';
import * as averages from './averages';
import * as awsAdmin from './awsAdmin';
import * as badges from './badges';
import * as cipher from './cipher';
import * as communication from './communication';
import * as courseSectionAttendance from './courseSectionAttendance';
import * as courseSectionStudents from './courseSectionStudents';
import * as courseSections from './courseSections';
import * as courseSectionsTeachers from './courseSectionsTeachers';
import * as courseWork from './courseWork';
import * as courseWorkGrades from './courseWorkGrades';
import * as google from './google';
import * as googleAuth from './googleAuth';
import * as googleCourseWork from './googleCourseWork';
import * as googleStudentSubmissions from './googleStudentSubmissions';
import * as gradeTemplates from './gradeTemplates';
import * as guardians from './guardians';
import * as help from './help';
import * as landing from './landing';
import * as markingPeriods from './markingPeriods';
import * as outreach from './outreach';
import * as pdf from './pdf';
import * as progressReports from './progressReports';
import * as reportCard from './reportCard';
import * as reports from './reports';
import * as schoolDayCalendar from './schoolDayCalendar';
import * as schoolTerms from './schoolTerms';
import * as schools from './schools';
import * as server from './server';
import * as session from './session';
import * as storage from './storage';
import * as student from './student';
import * as studentGroups from './studentGroups';
import * as studentPdfReports from './studentPdfReports';
import * as studentProfileDocument from './studentProfileDocument';
import * as students from './students';
import * as teachers from './teachers';
import * as users from './users';
import * as webSockets from './webSockets';

export {
    admin,
    aggregates,
    anecdotals,
    attendance,
    auth,
    authGoogle,
    authLocalPasswords,
    averages,
    awsAdmin,
    badges,
    cipher,
    communication,
    courseSectionAttendance,
    courseSectionStudents,
    courseSections,
    courseSectionsTeachers,
    courseWork,
    courseWorkGrades,
    google,
    googleAuth,
    googleCourseWork,
    googleStudentSubmissions,
    gradeTemplates,
    guardians,
    help,
    landing,
    markingPeriods,
    outreach,
    pdf,
    progressReports,
    reportCard,
    reports,
    schoolDayCalendar,
    schoolTerms,
    schools,
    server,
    session,
    storage,
    student,
    studentGroups,
    studentPdfReports,
    studentProfileDocument,
    students,
    teachers,
    users,
    webSockets,
};
