import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadTeachers(commit, state, getters, callback = null) {
    const {
        schoolId, schoolTermId,
    } = state.user.school;

    network.teachers.listTeachers({ url: { schoolId, schoolTermId } }, (err, res) => {
        if (err) return commit(Types.mutations.SET_ERROR, err);
        const { teachers } = res;
        commit(Types.mutations.SET_DB_TEACHERS, teachers);
        if (callback) return callback();
    });
}
