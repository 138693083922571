/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getForTeacher from './getForTeacher';
import getForStudent from './getForStudent';
import uploadAttendance from './uploadAttendance';
import uploadCourseAttendance from './uploadCourseAttendance';
import clearPeriodAttendance from './clearPeriodAttendance';

export {
    getForTeacher,
    getForStudent,
    uploadAttendance,
    uploadCourseAttendance,
    clearPeriodAttendance,
 };
