module.exports = {
    sections: {
        studentPortfolio: {
            title: 'Portafolio del estudiante',
            routes: {
                personal: {
                    title: 'Personal',
                    description: 'Información demográfica del estudiante seleccionado',
                },
                evaluation: {
                    title: 'Evaluación',
                    description: 'Información de evaluación para el estudiante seleccionado',
                },
                guardians: {
                    title: 'Guardianes',
                    description: 'Guardianes para el estudiante seleccionado',
                },
                englishLanguageLearner: {
                    title: 'Aprendizaje del idioma inglés',
                    description: 'Datos de aprendizaje del idioma inglés para el estudiante seleccionado',
                },
                officialReportCards: {
                    title: 'Boletas de calificaciones oficiales',
                    description: 'Boletas de calificaciones oficiales para el estudiante seleccionado',
                },
                officialTranscripts: {
                    title: 'Transcripciones Oficiales',
                    description: 'Transcripciones oficiales del estudiante seleccionado',
                },
                officialSchedules: {
                    title: 'Horarios Oficiales',
                    description: 'Horarios oficiales del alumno seleccionado',
                },
                progressReports: {
                    title: 'Reporte de progreso',
                    description: 'Informes de progreso para el estudiante seleccionado',
                },
                attendance: {
                    title: 'Asistencia',
                    description: 'Datos de asistencia del alumno seleccionado',
                },
                behaviors: {
                    title: 'Comportamientos',
                    description: 'Registros de comportamiento para el estudiante seleccionado',
                },
                activity: {
                    title: 'Actividad',
                    description: 'Datos de actividad del alumno seleccionado',
                },
                assignments: {
                    title: 'Tareas',
                    description: 'Datos de actividad del alumno seleccionado',
                },
                averages: {
                    title: 'Promedios',
                    description: 'Promedios para el estudiante seleccionado',
                },
            },
        },
        teacherPortfolio: {
            title: 'Portafolio del maestro',
            routes: {
                courses: {
                    title: 'Cursos',
                    description: 'Cursos para este profesor',
                },
                gradebook: {
                    title: 'GradeLibro de calificacionesbook',
                    description: 'Libro de calificaciones del curso seleccionado',
                },
                assignments: {
                    title: 'Tareas',
                    description: 'Todas las tareas del curso seleccionado',
                    detail: 'Detalles de la tarea seleccionada',
                },
                'grade-template': {
                    title: 'Plantilla del curso',
                    description: 'La plantilla del curso seleccionado',
                },
                attendance: {
                    title: 'Asistencia',
                    description: 'Ver e ingresar datos de asistencia',
                },
                activity: {
                    title: 'Actividad',
                    description: 'Actividad de los alumnos en el curso seleccionado',
                },
                students: {
                    title: 'Estudiantes',
                    description: 'Lista de alumnos del curso seleccionado',
                },
                averages: {
                    title: 'Promedios',
                    description: 'Promedios de los estudiantes para el curso seleccionado',
                },
                progressReports: {
                    title: 'Reporte de progreso',
                    description: 'Informes de progreso para los estudiantes en el curso seleccionado',
                },
                reportCards: {
                    title: 'Las boletas de calificaciones',
                    description: 'Boletas de calificaciones para los estudiantes en el curso seleccionado',
                },
            },
        },
        classroom: {
            title: 'Classroom',
            routes: {
                teacherProfile: {
                    title: 'Cursos',
                    description: 'Gestión de asignaciones, listas y calificaciones',
                },
                gradeBook: {
                    title: 'Libro de calificaciones Beta',
                    description: 'Editar calificaciones en Google',
                },
            },
        },
        configuration: {
            title: 'Configuración',
            routes: {
                googleCourseLink: {
                    title: 'Google',
                    description: 'Configure la conexión de Google, vincule cursos y profesores a Google Classroom',
                },
                adminGradeTemplates: {
                    title: 'Plantillas',
                    description: 'Modificar las plantillas de calificación utilizadas para promediar',
                },
                behaviorDefinitions: {
                    title: 'Comportamientos',
                    description: 'Modificar definiciones de comportamiento',
                },
            },
        },
        dashboard: {
            title: 'Tablero de mandos',
            routes: {
                ellPopulation: {
                    title: 'EII Población',
                    description: 'EII Población',
                },
                ellLevels: {
                    title: 'EII Rendimiento',
                    description: 'EII Niveles de rendimiento',
                },
                ellCompletionRate: {
                    title: 'EII Terminación',
                    description: 'EII Tasa de finalización',
                },
                ellAdvancementRate: {
                    title: 'EII Avance',
                    description: 'EII Tasa de Avance',
                },
            },
        },
        school: {
            title: 'Colegio',
            routes: {
                interactions: {
                    title: 'Interacciones de los estudiantes',
                    description: 'Calcule la asistencia a través de interacciones en el aula y métricas personalizadas',
                },
                teachers: {
                    title: 'Profesores',
                    description: 'Ver todos los profesores y estadísticas básicas',
                },
                adminUsers: {
                    title: 'Usuarios de la escuela',
                    description: 'Administrar administradores escolares',
                },
                progressReports: {
                    title: 'Reporte de progreso',
                    description: 'Calcule la asistencia a través de interacciones en el aula y métricas personalizadas',
                },
            },
        },
        fileManager: {
            title: 'Administrador de archivos',
            routes: {
                fileUploader: {
                    title: 'Subir archivos',
                    description: 'Cargue archivos SIS para completar SyncGrades',
                },
                fileDownloader: {
                    title: 'Descargar archivos',
                    description: 'Exportar datos capturados por SyncGrades',
                },
            },
        },
        superAdmin: {
            title: 'Superadministrador',
            routes: {
                superAdminConfig: {
                    title: 'Configuración',
                    description: 'Configuración de superadministrador',
                },
                schoolStats: {
                    title: 'Estadísticas',
                    description: 'Ver registros de uso por escuela',
                },
            },
        },
    },
};
