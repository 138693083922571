var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLandingPage
        ? _c("TheLandingLayout")
        : [
            _vm.isPublicRoute
              ? _c("TheBlankLayout")
              : _vm.isStateDashboard
              ? _c("TheDistrictDashboardLayout")
              : _c(
                  "b-overlay",
                  {
                    style: [
                      _vm.isSoftLocked
                        ? { height: "100vh", width: "100vw" }
                        : {},
                    ],
                    attrs: {
                      opacity: "1",
                      variant: "transparent",
                      blur: "0.5em",
                      rounded: "sm",
                      show: _vm.isSoftLocked,
                      "z-index": 1000,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "overlay",
                        fn: function () {
                          return [
                            _vm.isSoftLocked ? _c("SoftLocked") : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [_c("TheRouterLayout")],
                  1
                ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }