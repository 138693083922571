import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadGoogleCourseTeachers(commit, state, getters, callback = null) {
    const {
        schoolId, schoolTermId,
    } = state.user.school;

    network.google.listGoogleCourseTeachers({ url: { schoolId, schoolTermId } }, (err, res) => {
        if (err) return commit(Types.mutations.SET_ERROR, err);
        const { googleCourseTeachers } = res;
        commit(Types.mutations.SET_DB_GOOGLE_COURSE_TEACHERS, googleCourseTeachers);
        if (callback) return callback();
    });
}
