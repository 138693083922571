function Message(props) {
    this.messageId = props.messageId;
    this.schoolId = props.schoolId;
    this.schoolTermId = props.schoolTermId;
    this.userRoleId = props.userRoleId;
    this.messageSubject = props.messageSubject;
    this.messageBody = props.messageBody;
    this.isSchoolAnnouncement = props.isSchoolAnnouncement;
    this.allowReplies = props.allowReplies;
    this.notifyEmail = props.notifyEmail;
    this.smsEnabled = props.smsEnabled;
    this.isOwner = props.isOwner;
    this.messageRead = props.messageRead || false;
    this.studentEnrollmentId = props.studentEnrollmentId || null;
    this.public = props.public;
    this.author = props.author;
    this.created = props.created;
    this.modified = props.modified;
    this.recentlyActive = props.recentlyActive || null;
    this.deleted = props.deleted;
}

module.exports = Message;
