import * as network from '../../network';
import Types from '../Types';

export function addLocalCourseWork(params, commit, state) {
    const { courseWorks, gradeTemplate, callback } = params;
    const { schoolId, schoolTermId, schoolStaffId } = state.user.school;
    const requestParams = {
        url: { schoolId, schoolTermId, schoolStaffId },
        body: { courseWorks },
    };

    network.courseWork.createCourseWork(requestParams, (err, res) => {
        if (err) return callback(err);

        const { categories } = gradeTemplate;

        const singleAssignment = res.courseWork.every((cw) => cw.linkingGuid == res.courseWork[0].linkingGuid);
        const output = res.courseWork.map((cw) => {
            const courseWork = { ...cw };

            if (singleAssignment) {
                const createdAssignment = res.courseWork.find((c) => cw.courseSectionId == c.courseSectionId) || null;
                if (!createdAssignment) return null;
                courseWork.courseWorkId = createdAssignment.courseWorkId;
                courseWork.linkingGuid = createdAssignment.linkingGuid;
            }

            const category = categories.find((c) => cw.gradeTemplateCategoryId == c.gradeTemplateCategoryId) || null;
            if (category) {
                courseWork.gradeCategoryId = category.gradeCategoryId;
                courseWork.categoryName = category.categoryName;
                courseWork.color = category.categoryColor;
                courseWork.gradeTemplateId = category.gradeTemplateId;
            }
            return courseWork;
        }).filter((cw) => cw != null);

        if (output.length !== courseWorks.length) {
            return callback(new Error('Failed to create some of the assignments, reload the page and try again.'));
        }
        commit(Types.mutations.SET_DB_COURSE_WORK, output);
        commit(Types.mutations.SET_ASSIGNMENT_IS_DIRTY, false);
        callback(err, res);
    });
}

export function editLocalCourseWork(params, commit, state) {
    const { courseWorks, gradeTemplate, callback } = params;
    const { schoolId, schoolTermId, schoolStaffId } = state.user.school;
    const requestParams = {
        url: { schoolId, schoolTermId, schoolStaffId },
        body: { courseWorks },
    };
    network.courseWork.editCourseWorks(requestParams, (err, res) => {
        if (err) return callback(err);

        const { categories } = gradeTemplate;
        const output = res.courseWork.map((cw) => {
            const courseWork = { ...cw };
            const category = categories.find((c) => cw.gradeTemplateCategoryId == c.gradeTemplateCategoryId) || null;
            if (category) {
                courseWork.gradeCategoryId = category.gradeCategoryId;
                courseWork.categoryName = category.categoryName;
                courseWork.color = category.categoryColor;
                courseWork.gradeTemplateId = category.gradeTemplateId;
            }
            return courseWork;
        });

        commit(Types.mutations.SET_DB_COURSE_WORK, output);
        commit(Types.mutations.SET_ASSIGNMENT_IS_DIRTY, false);
        callback(err, res);
    });
}
