import baseTables from './baseTables';
import googleTables from './googleTables';
import saturate from './saturate';
import * as reload from './reload';
import * as portfolioTables from './portfolioTables';
import * as reportCards from './reportCards';

export function saturateTableByName(commit, state, tableName, callback) {
    saturate(commit, state, tableName, callback);
}
export function populateBaseTables(commit, state, callback) {
    baseTables(commit, state, callback);
}
export function populateGoogleTables(commit, state, callback) {
    googleTables(commit, state, callback);
}
export function populateReportCardData(commit, state, teacher, callback) {
    reload.markingPeriods.default(commit, state, (err) => {
        if (err) return callback(err);
        reportCards.populateReportCardData(commit, state, teacher, callback);
    });
}
export function populatePortfolioData(commit, state, getters, payload) {
    portfolioTables.populatePortfolioData(commit, state, getters, payload);
}
export function reloadGoogleCourseTeachers(commit, state, getters, callback = null) {
    reload.googleCourseTeachers.default(commit, state, getters, callback);
}
export function reloadGoogleCourseWork(schoolStaffId, commit, state, getters, callback = null) {
    reload.googleCourseWork.default(schoolStaffId, commit, state, getters, callback);
}
export function reloadGoogleCourseGradeCategories(commit, state, schoolStaffId) {
    reload.googleCourseGradeCategories.default(commit, state, schoolStaffId);
}
export function reloadGoogleCourseTopics(commit, state, googleCourseId) {
    reload.googleCourseTopics.default(commit, state, googleCourseId);
}
export function reloadGoogleCourseCourseSections(commit, state, getters, callback = null) {
    reload.googleCourseCourseSections.default(commit, state, getters, callback);
}
export function reloadCourseSections(commit, state, callback) {
    reload.courseSections.default(commit, state, callback);
}
export function reloadGoogleCourseStudents(commit, state) {
    reload.googleCourseStudents.default(commit, state);
}
export function reloadGradeTemplates(commit, state) {
    reload.gradeTemplates.default(commit, state);
}
export function reloadTeachers(commit, state, getters, callback = null) {
    reload.teachers.default(commit, state, getters, callback);
}
export function reloadStudents(commit, state) {
    reload.students.default(commit, state);
}
export function reloadGoogleCourses(commit, state, getters, callback = null) {
    reload.googleCourses.default(commit, state, getters, callback);
}
export function reloadschools(commit, state) {
    reload.schools.default(commit, state);
}
export function reloadtransactions(commit, state) {
    reload.transactions.default(commit, state);
}
export function reloadschoolTerms(commit, state) {
    reload.schoolTerms.default(commit, state);
}
export function reloadAnecdotalEvents(commit, state, rootState) {
    reload.anecdotalEvents.default(commit, state, rootState);
}
export function addAnecdotalEventById(anecdotalEventId, commit, state) {
    reload.anecdotalEvents.addAnecdotalEventById(anecdotalEventId, commit, state);
}
export function reloadAnecdotalEventCategories(commit, state) {
    reload.anecdotalEventCategories.default(commit, state);
}
export function reloadDataSystems(commit, state, callback) {
    reload.dataSystems.default(commit, state, callback);
}
export function reloadMarkingPeriods(commit, state, callback) {
    reload.markingPeriods.default(commit, state, callback);
}
