/* eslint-disable max-len */
module.exports = {
    autoLink: 'Auto Link',
    autoLinkDesc: 'Link all courses, etc!',
    cancelLinking: 'Cancel linking!',
    explanations: {
        enqueue: 'Your request to auto-link your courses is processing. This can take some time but check back periodically for progress. You can view progress by clicking the green button above.',
        processing: 'Your request to auto-link your courses is processing. This can take some time but check back periodically for progress. You can view progress by clicking the green button above.',
        completed: 'Your request to auto-link your courses has completed.',
        completedWithErrors: 'Your request to auto-link your courses has completed however there were some errors. You can view progress by clicking the yellow button above.',
        skipped: 'Your request to auto-link your courses has been skipped because it is not necessary. If you have any issues or questions about auto-linking, please reach out to support for assistance.',
        postponed: 'Your request to auto-link your courses has been postponed. This is because Google imposes limits on how many invitations a single user can send in a 24 hour period. We will re-submit your request every 24 hours until all invitations for your google courses have been sent. This usually only affects admins; teachers should be able to submit a request and have it complete on the first attempt in most cases.',
        canceled: 'Your request to auto-link your courses has been canceled. Click the button labeled "Auto Link" below to submit a request to automatically link your courses and send invitations to students again.',
        error: 'There was an error processing your request to auto-link your courses. Please contact support for assistance.',
        default: 'You have not requested to auto-link your courses. Click the button labeled "Auto Link" below to submit a request to automatically link your courses and send invitations to students. You can check to see if you need to send a request by looking on the home screen.',
    },
    linkingStatus: 'Linking status',
    tasksPending: 'tasks pending',
    viewLinkingProgress: 'View Linking Progress',
};
