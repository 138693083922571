const request = require('superagent-use')(require('superagent'));

request.use((req) => {
    const csrfToken = document.querySelector('meta[name=csrf-token]').content;
    if (csrfToken && csrfToken.length > 0) {
        req.header['CSRF-Token'] = csrfToken;
    }
    return req;
});

module.exports = request;
