/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listStudentGroups from './listStudentGroups';
import createGroup from './createGroup';
import editGroup from './editGroup';
import saveGroupStaff from './saveGroupStaff';
import saveGroupStudent from './saveGroupStudent';
import saveGroupRules from './saveGroupRules';
import refreshGroupStudents from './refreshGroupStudents';

export {
    listStudentGroups,
    createGroup,
    editGroup,
    saveGroupStaff,
    saveGroupStudent,
    saveGroupRules,
    refreshGroupStudents,
 };
