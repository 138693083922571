function GoogleStudentSubmission(props) {
    this.googleStudentSubmissionId = props.googleStudentSubmissionId;
    this.googleSubmissionId = props.googleSubmissionId;
    this.googleUserId = props.googleUserId;
    this.googleCourseId = props.googleCourseId;
    this.googleCourseWorkId = props.googleCourseWorkId;
    this.draftGrade = props.draftGrade;
    this.assignedGrade = props.assignedGrade;
    this.extCourseWorkId = props.extCourseWorkId;
    this.courseWorkTitle = props.courseWorkTitle;
    this.maxPoints = props.maxPoints;
    this.associatedWithDeveloper = props.associatedWithDeveloper;
    this.submissionState = props.submissionState;
    this.gradeTemplateMarkId = props.gradeTemplateMarkId;
}

module.exports = GoogleStudentSubmission;
