import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '../store';
import messages from '../lang';

Vue.use(VueI18n);

function getLocale() {
    const cleanLanguages = navigator.languages.map((language) => language.split('-')[0]);
    const preferredLanguage = cleanLanguages[0];
    const defaultLocale = store.getters.getLocale || preferredLanguage || 'en';

    return store.getters.getLocale || defaultLocale;
}

export default new VueI18n({
    silentTranslationWarn: true,
    locale: getLocale(),
    fallbackLocale: 'en',
    messages,
});
