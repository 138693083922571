module.exports = {
    studentPortfolio: 'Student Portfolio',
    studentDoesNotExist: 'The student does not exist, is in a different school term or you do not have access, please',
    searchAgain: 'seach again',
    discharged: 'Discharged',
    downloadPDF: 'Download PDF',
    tabNames: {
        personal: 'Personal',
        evaluation: 'Evaluation',
        guardians: 'Guardians',
        officialReportCards: 'Official Report Cards',
        officialTranscripts: 'Official Transcripts',
        officialSchedules: 'Official Schedules',
        progressReports: 'Progress Reports',
        attendance: 'Attendance',
        behaviors: 'Behaviors',
        remarks: 'Remarks',
        activity: 'Activity',
        assignments: 'Assignments',
        averages: 'Averages',
        englishLanguageLearning: 'English Language Learning',
    },
    studentStats: {
        studentId: 'Student Id',
        homeRoom: 'HomeRoom',
        gradeLevel: 'Grade Level',
        googleEmail: 'Google Email',
        guardian: 'Guardian',
        phoneNumber: 'Phone Number',
        homeLanguage: 'Home Language',
        enteredNinthGrade: 'Entered 9th Grade',
        gender: 'Gender',
        admissionDate: 'Admission Date',
        ethnicity: 'Ethnicity',
        englishLanguageLearner: 'English Language Learner',
        specialEducationProgram: 'Special Education Program',
    },
    fileNames: {
        'Student Report Cards': '@:pages.studentPortfolio.officialReportCards.studentReportCards',
        'Middle School Student Report Cards': 'Middle School Student Report Cards',
        'Student Transcripts': '@:pages.studentPortfolio.officialTranscripts.studentTranscripts',
        'Student Schedules': '@:pages.studentPortfolio.officialSchedules.studentSchedules',
        'Student Grid Schedules': 'Student Grid Schedules',
    },
    personal: {
        personalInformation: 'Personal Information',
        biographicalInformation: 'Biographical Information',
        picture: 'Picture',
        clickThePencil: 'Click the pencil to upload an avatar',
        firstName: 'First Name',
        lastName: 'Last Name',
        preferredName: 'Preferred Name',
        preferredPronoun: 'Preferred Pronoun',
        he: 'he',
        him: 'him',
        she: 'she',
        her: 'her',
        they: 'they',
        them: 'them',
        editGoogleEmail: 'Edit Google Email',
    },
    evaluation: {
        remoteLearning: {
            remoteLearning: 'Remote Learning',
            remoteLearningCohort: '@:pages.studentPortfolio.evaluation.remoteLearning.remoteLearning Cohort',
            remoteLearningPreference: '@:pages.studentPortfolio.evaluation.remoteLearning.remoteLearning Preference',
        },
    },
    pleaseUpload: 'Please upload an entire school {title} pdf file from STARS via a file manager to populate this section.',
    officialReportCards: {
        title: 'Official Report Cards',
        studentReportCards: 'Student Report Cards',
        'Student Report Cards': '@:pages.studentPortfolio.officialReportCards.studentReportCards',
        'Middle School Student Report Cards': 'Middle School @:pages.studentPortfolio.officialReportCards.studentReportCards',
    },
    officialTranscripts: {
        title: 'Official Transcripts',
        studentTranscripts: 'Student Transcripts',
    },
    officialSchedules: {
        title: 'Official Schedules',
        studentSchedules: 'Student Schedules',
        studentGridSchedules: 'Student Grid Schedules',
    },
    progressReports: {
        title: 'Progress Reports',
        downloadBulk: 'Download Bulk',
        createNew: 'Create New',
    },
    attendance: {
        attendanceHeader: 'Course Attendance',
        courseAttendanceFor: 'Course Attendance for {date}',
        present: 'Present',
        late: 'Late',
        absent: 'Absent',
        excused: 'Excused',

        attendanceHeatmap: 'Attendance Heatmap',
        showDailyAttendance: 'Show Daily Attendance',
        attendanceDetails: 'Attendance Details',
        dailyAttendanceFor: 'Daily Attendance for {date}',
    },
    behaviors: {
        behaviorEvent: 'Behavior Event | Behavior Events',
        createEvent: 'Create Event',
        thereAreNone: 'There are no behavior events for {student} at this time',
        eventsOnThisDay: 'Events on this day',
        involvingNStudents: 'Involving {studentCount} student | Involving {studentCount} students',
        eventDate: 'Event Date',
        eventTime: 'Event Time',
        behaviorDefinition: 'Behavior Definition',
        location: 'Location',
        studentSearch: 'Student Search',
        studentInvolvement: 'Students Involved',
        noStudentsSelected: 'No Students Selected',
        highest: 'Highest',
        high: 'High',
        low: 'Low',
        lowest: 'Lowest',
    },
    remarks: {
        remarkThread: 'Student Remark | Student Remarks',
        createRemarkThread: 'Create New Thread',
    },
    activity: {
        activityHeatmap: 'Activity Heatmap',
        activityTimeline: 'Activity Timeline',
        activityOnThisDay: 'Activity on this day',
        unlinkedCourse: 'Unlinked Course',
        DRAFT: 'DRAFT',
        PUBLISHED: 'PUBLISHED',
        CREATED: 'CREATED',
        NEW: 'NEW',
        RECLAIMED_BY_STUDENT: 'RECLAIMED BY STUDENT',
        RETURNED: 'RETURNED',
        TURNED_IN: 'TURNED IN',
        COMPLETE: 'COMPLETE',
        LATE: 'LATE',
        ON_TIME: 'ON TIME',
        unknown: 'Unknown',
        ungraded: 'Ungraded',
    },
    assignments: {
        assignment: 'Assignment | Assignments',
        // eslint-disable-next-line no-useless-escape
        category: 'Category',
        topic: 'Topic',
        fullTitle: 'Full Title',
        noTitleProvided: 'No title provided',
        description: 'Description',
        noDescriptionProvided: 'No description provided',
        assignmentDetails: 'Assignment Details',
        ASSIGNEE_MODE_UNSPECIFIED: 'Unspecified',
        ALL_STUDENTS: 'All Students',
        INDIVIDUAL_STUDENTS: 'Individuals',
        id: 'ID',
        type: 'Type',
        assigned: 'Assigned',
        created: 'Created',
        dueDate: 'Due Date',
        state: 'State',
        weight: 'Weight',
        lastActivity: 'Last Activity',
        draftAssignedGrade: 'Draft/Assigned Grade',
        gradeType: 'Grade Type',
        lateStatus: 'Late Status',
        maxPoints: 'Max Points',
        submissionState: 'Submission State',
        averageImpact: 'Average Impact',
        googleActivity: 'Google Activity',
        thisIsTheActivityStream: 'This is the activity stream showing all teacher and student google activity for this assignment.',
        effectWithCategory: 'This is the effect that this assignment is having on your overall and {categoryName} category averages.',
        effectWithoutCategory: 'This is the effect that this assignment is having on your overall category averages.',
        overall: 'Overall',
    },
    averages: {

    },
    englishLanguageLearner: {
        title: 'English Language Learning',
    },
};
