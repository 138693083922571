import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadGoogleCourseWork(schoolStaffId, commit, state, getters, callback = null) {
    const {
        schoolId, schoolTermId
    } = state.user.school;

    network.googleCourseWork.getCourseWorkForTeacher({ url: { schoolId, schoolTermId, schoolStaffId } },
    (err, res) => {
        if (err) return commit(Types.mutations.SET_ERROR, err);

        if (callback) callback();

        commit(Types.mutations.SET_DB_GOOGLE_COURSE_WORK, res.googleCourseWork);
    });
}