const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.getRoles = function getRoles(callback) {
    request
        .get(`${syncGradesHost}/api/roles`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) {
                return callback(prettyError(err, res));
            }
            callback(err, res.body);
        });
};
