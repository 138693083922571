/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getPdfTypes from './getPdfTypes';
import getPdfUploads from './getPdfUploads';
import savePdf from './savePdf';
import deletePdf from './deletePdf';
import updateShowPortfolio from './updateShowPortfolio';
import downloadPdf from './downloadPdf';

export {
    getPdfTypes,
    getPdfUploads,
    savePdf,
    deletePdf,
    updateShowPortfolio,
    downloadPdf,
 };
