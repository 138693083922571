/* *
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
* */

const wrapper = require('../wrapper');

/**
 * @param {Object} params - http request parameters
 * @param {Object} params.url
 * @param {String} params.url.schoolId
 * @param {String} params.url.schoolTermId
 * @param {String} params.url.schoolStaffId
 * @param {Object} params.body
 * @param {Array} params.body.comments
 * @param {Function} callback
 */
export default function (params, callback) {
    wrapper.default('saveStudentComments', 'POST', '/api/schools/:schoolId/terms/:schoolTermId/staff/:schoolStaffId/progress-reports/comments', params, callback);
}
