const SchoolAdmin = () => import( '../../rootComponents/schoolAdmin/SchoolAdmin.vue');
const UploadReportCards = () => import( '../../rootComponents/UploadReportCards.vue');
const DownloadReportCards = () => import( '../../rootComponents/DownloadReportCards.vue');
const MarkingPeriods = () => import( '../../rootComponents/MarkingPeriods.vue');
const ValidMarks = () => import( '../../rootComponents/ValidMarks.vue');

const routes = [
    {
        name: 'SchoolAdminRootReportCards',
        path: '/file/manager',
        component: SchoolAdmin,
        children: [
            {
                name: 'UploadReportCards',
                title: 'Report Cards',
                icon: 'fa fa-upload',
                path: '/configuration/report-cards/upload',
                component: UploadReportCards,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
            {
                name: 'DownloadReportCards',
                title: 'Report Cards',
                icon: 'fa fa-download',
                path: '/configuration/report-cards/download',
                component: DownloadReportCards,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
            {
                name: 'ReportCardMarkingPeriods',
                title: 'Marking Periods',
                icon: 'flaticon2-calendar-2',
                path: '/configuration/report-cards/marking-periods',
                component: MarkingPeriods,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
            {
                name: 'ValidMarks',
                title: 'Define Valid Marks',
                icon: 'flaticon2-checkmark',
                path: '/configuration/report-cards/valid-marks',
                component: ValidMarks,
                meta: {
                    subHeader: true,
                    roles: ['School Admin'],
                },
            },
        ],
    },
];

export default routes;
