/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listCourseSectionStudents from './listCourseSectionStudents';
import getForTeacher from './getForTeacher';
import getForStudent from './getForStudent';
import addStudent from './addStudent';
import deleteStudent from './deleteStudent';

export {
    listCourseSectionStudents,
    getForTeacher,
    getForStudent,
    addStudent,
    deleteStudent,
 };
