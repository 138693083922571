module.exports = {
    sections: {
        studentPortfolio: {
            title: 'Student Portfolio',
            routes: {
                anecdotals: {
                    title: 'Anecdotal Logs',
                    description: '',
                },
                personal: {
                    title: 'Personal',
                    description: 'Demographic information for the selected student',
                },
                evaluation: {
                    title: 'Evaluation',
                    description: 'Evaluation information for the selected student',
                },
                guardians: {
                    title: 'Guardians',
                    description: 'Guardians for the selected student',
                },
                englishLanguageLearning: {
                    title: 'English Language Learning',
                    description: 'ELL data for the selected student',
                },
                officialReportCards: {
                    title: 'Official Report Cards',
                    description: 'Official Report Cards for the selected student',
                },
                officialTranscripts: {
                    title: 'Official Transcripts',
                    description: 'Official Transcripts for the selected student',
                },
                officialSchedules: {
                    title: 'Official Schedules',
                    description: 'Official Schedules for the selected student',
                },
                progressReports: {
                    title: 'Progress Reports',
                    description: 'Progress Reports for the selected student',
                },
                attendance: {
                    title: 'Attendance',
                    description: 'Attendance data for the selected student',
                },
                behaviors: {
                    title: 'Behaviors',
                    description: 'Behavior logs for the selected student',
                },
                remarks: {
                    title: 'Remarks',
                    description: 'Remarks for the selected student',
                },
                activity: {
                    title: 'Activity',
                    description: 'Activity data for the selected student',
                },
                assignments: {
                    title: 'Assignments',
                    description: 'Assignments and grades for the selected student',
                },
                averages: {
                    title: 'Averages',
                    description: 'Averages for the selected student',
                },
            },
        },
        teacherPortfolio: {
            title: 'Teacher Portfolio',
            routes: {
                courses: {
                    title: 'Courses',
                    description: 'Courses for this teacher',
                },
                teachers: {
                    title: 'Teachers',
                    description: 'View teachers in a google course',
                },
                gradebook: {
                    title: 'Gradebook',
                    description: 'Gradebook for the selected course',
                },
                assignments: {
                    title: 'Assignments',
                    description: 'All assignments for the selected course',
                    detail: 'Details of the selected assignment',
                },
                'grade-template': {
                    title: 'Grade Template',
                    description: 'The template of the selected course',
                },
                attendance: {
                    title: 'Attendance',
                    description: 'View and enter attendance data',
                },
                activity: {
                    title: 'Activity',
                    description: 'Activity of students in the selected course',
                },
                students: {
                    title: 'Class Roster',
                    description: 'List of students in the selected course',
                },
                averages: {
                    title: 'Averages',
                    description: 'Student averages for the selected course',
                },
                progressReports: {
                    title: 'Progress Reports',
                    description: 'Progress Reports for students in the selected course',
                },
                reportCards: {
                    title: 'Report Cards',
                    description: 'Report Cards for students in the selected course',
                },
                info: {
                    title: 'Course Information',
                    description: 'Course description',
                },
            },
        },
        classroom: {
            title: 'Classroom',
            routes: {
                teacherProfile: {
                    title: 'Courses',
                    description: 'Assignment, Roster and grade management',
                },
                gradeBook: {
                    title: 'GradeBook Beta',
                    description: 'Edit grades on Google',
                },
            },
        },
        configuration: {
            title: 'Configuration',
            routes: {
                googleCourseLink: {
                    title: 'Google',
                    description: 'Configure google connection, link courses and teachers to google classroom',
                },
                adminGradeTemplates: {
                    title: 'Templates',
                    description: 'Modify grading templates used for averaging',
                },
                behaviorConfiguration: {
                    title: 'Behaviors',
                    description: 'Modify behavior configuration',
                },
                schoolTerms: {
                    title: 'School Terms',
                    description: 'Manage school terms',
                },
                markingPeriods: {
                    title: 'Marking Periods',
                    description: 'Manage marking periods',
                },
                validMarks: {
                    title: 'Valid Marks',
                    description: 'Manage valid marks',
                },
            },
        },
        dashboard: {
            title: 'Dashboard',
            routes: {
                ellPopulation: {
                    title: 'ELL Population',
                    description: 'ELL Population',
                },
                ellLevels: {
                    title: 'ELL Performance',
                    description: 'ELL Performance Levels',
                },
                ellCompletionRate: {
                    title: 'ELL Completion',
                    description: 'ELL Completion Rate',
                },
                ellAdvancementRate: {
                    title: 'ELL Advancement',
                    description: 'ELL Advancement Rate',
                },
            },
        },
        school: {
            title: 'School',
            routes: {
                interactions: {
                    title: 'Student Interactions',
                    description: 'Calculate attendance via classroom interactions and custom metrics',
                },
                teachers: {
                    title: 'Teachers',
                    description: 'View all teachers and basic statistics',
                },
                students: {
                    title: 'Students',
                    description: 'View all students and basic statistics',
                },
                adminUsers: {
                    title: 'School Users',
                    description: 'Manage school administrators',
                },
                progressReports: {
                    title: 'School Progress Reports',
                    description: 'Calculate attendance via classroom interactions and custom metrics',
                },
            },
        },
        googleSettings: {
            title: 'Google Settings',
            routes: {
                googleCourseLink: {
                    title: 'Google',
                    description: 'Configure google connection, link courses and teachers to google classroom',
                },
            },
        },
        adminSettings: {
            title: 'Admin Settings',
            routes: {
                adminGradeTemplates: {
                    title: 'Templates',
                    description: 'Modify grading templates used for averaging',
                },
                behaviorConfiguration: {
                    title: 'Behaviors',
                    description: 'Modify behavior definitions',
                },
                schoolTerms: {
                    title: 'School Terms',
                    description: 'Manage school terms',
                },
                markingPeriods: {
                    title: 'Marking Periods',
                    description: 'Manage marking periods',
                },
                validMarks: {
                    title: 'Valid Marks',
                    description: 'Manage valid marks',
                },
                fileUploader: {
                    title: 'Upload Files',
                    description: 'Upload SIS files to populate SyncGrades',
                },
                fileDownloader: {
                    title: 'Download Files',
                    description: 'Export data captured by SyncGrades',
                },
            },
        },
        fileManager: {
            title: 'File Manager',
            routes: {
            },
        },
        superAdmin: {
            title: 'Super Admin',
            routes: {
                superAdminConfig: {
                    title: 'Configuration',
                    description: 'Super Admin Configuration',
                },
                schoolStats: {
                    title: 'Statistics',
                    description: 'View usage logs by school',
                },
            },
        },
    },
};
