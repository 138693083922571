const Downloads = () => import( '../../rootComponents/file-downloads/FileDownloader.vue');
const SchoolAdmin = () => import( '../../rootComponents/schoolAdmin/SchoolAdmin.vue');
const StudentImageUploader = () => import( '../../rootComponents/StudentImageUploader.vue');
const StudentPDFUploader = () => import( '../../rootComponents/StudentPDFUploader.vue');
const UploadRosters = () => import( '../../rootComponents/UploadRosters.vue');
const UploadAttendance = () => import( '../../rootComponents/UploadAttendance.vue');
const DataEncryption = () => import( '../../rootComponents/DataEncryption.vue');

const schoolSettingsRoutes = [
    {
        name: 'SchoolAdminRoot',
        path: '/file/manager',
        component: SchoolAdmin,
        children: [
            {
                name: 'UploadRosters',
                title: 'Upload Rosters',
                icon: 'fa fa-upload',
                path: '/file/manager/rosters',
                component: UploadRosters,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'UploadStudentPdf',
                title: 'Official PDFs',
                icon: 'fa fa-upload',
                path: '/file/manager/pdf',
                component: StudentPDFUploader,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'ExportFiles',
                title: 'Data Exports',
                icon: 'fa fa-file-export',
                path: '/file/manager/exports',
                component: Downloads,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'UploadStudentImages',
                title: 'Update Images',
                icon: 'fa fa-picture',
                path: '/file/manager/student-images',
                component: StudentImageUploader,
                meta: {
                    roles: ['School Admin'],
                },
            },
            {
                name: 'UploadAttendance',
                path: '/file/manager/attendance',
                component: UploadAttendance,
                meta: {
                    subHeader: true,
                    title: 'Upload Attendance',
                    icon: 'calendar',
                    roles: ['School Admin'],
                },
            },
            {
                name: 'DataEncryption',
                path: '/file/manager/encryption',
                component: DataEncryption,
                meta: {
                    subHeader: true,
                    title: 'Data Encryption',
                    icon: 'lock',
                    roles: ['School Admin'],
                },
            },
        ],
    },
];

export default schoolSettingsRoutes;
