/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getSubmissionsForTeacher from './getSubmissionsForTeacher';
import getSubmissionsForStudent from './getSubmissionsForStudent';

export {
    getSubmissionsForTeacher,
    getSubmissionsForStudent,
 };
