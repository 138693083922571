export default {
    setGradeTemplates(s, gradeTemplates) {
        const state = s;
        state.gradeTemplates = gradeTemplates;
    },
    setGradeScales(s, gradeScales) {
        const state = s;
        const gradeScalesObject = gradeScales.reduce((scalesObject, scale) => {
            let scaleName = scalesObject[scale.scaleName];
            if (!scaleName) {
                scaleName = {
                    scaleDescription: scale.description,
                    scaleId: scale.scaleId,
                    scaleName: scale.scaleName,
                    grades: [],
                };
            }
            scaleName.grades.push(scale);
            scaleName.grades.sort((gradeA, gradeB) => gradeA.minGrade - gradeB.minGrade);
            return scalesObject;
        }, {});
        state.gradeScales = gradeScalesObject;
    },
    setGradeTemplatesSavingError(s, errorState) {
        const state = s;
        state.gradeTemplatesSavingError = errorState;
    },
    setGradeTemplatesSavingSuccess(s, successState) {
        const state = s;
        state.gradeTemplatesSavingSuccess = successState;
    },
    setIsFetchingTemplates(s, isFetching) {
        const state = s;
        state.isFetchingTemplates = isFetching;
    },
    setIsEditingTemplate(s, isEditing) {
        const state = s;
        state.isEditingTemplate = isEditing;
    },
};
