/* *
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
* */

const wrapper = require('../wrapper');

/**
 * @param {Object} params - no parameters needed, pass empty object {}
 * @param {Function} callback
 */
export default function (params, callback) {
    wrapper.default('listActiveSessions', 'GET', '/api/me/sessions', params, callback);
}
