import Types from '../Types';

import * as network from '../../network';

export default function bulkEditCourseWorkCategory(params, commit, state) {
    const { assignments, callback } = params;
    const { schoolId, schoolTermId } = state.user.school;
    const googleCourseWork = state.database.googleCourseWork.slice();

    const reqParams = {
        url: { schoolId, schoolTermId },
        body: { courseWork: assignments },
    };

    network.googleCourseWork.editCourseWorkProperties(reqParams, (err) => {
        if (err) return callback(err);
        commit(Types.mutations.SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SAVING, false);
        commit(Types.mutations.SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SELECTION, { values: [] });
        const output = [];
        assignments.forEach((a) => {
            const item = googleCourseWork.find((g) => g.googleCourseWorkId == a.googleCourseWorkId);
            if (!item) return;
            item.gradeTemplateCategoryId = a.gradeTemplateCategoryId;
            item.courseWorkWeight = a.courseWorkWeight;
            item.averageExclusiveForMp = a.averageExclusiveForMp;
            item.schoolTermMarkingPeriodId = a.schoolTermMarkingPeriodId;
            output.push(item);
        });
        commit(Types.mutations.SET_DB_GOOGLE_COURSE_WORK, output);

        callback();
        // etl.reloadGoogleCourseWork(schoolStaffId, commit, state, getters, callback);
    });
}
