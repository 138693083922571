function StudentAggregate(props) {
    this.studentId = props.studentId;

    this.anecdotals = (props.anecdotals || []).map((row) => ({
        schoolTermId: row.schoolTermId,
        studentId: row.studentId,
        studentEnrollmentId: row.studentEnrollmentId,
        anecdotalEventCategoryId: row.anecdotalEventCategoryId,
        totalPositivePoints: convertToNumber(row.totalPositivePoints),
        totalNegativePoints: convertToNumber(row.totalNegativePoints),
        totalResolvedPoints: convertToNumber(row.totalResolvedPoints),
        totalEvents: convertToNumber(row.totalEvents),
        totalResolved: convertToNumber(row.totalResolved),
    }));

    this.progressAverages = (props.progressAverages || []).map((row) => ({
        schoolTermId: row.schoolTermId,
        studentId: row.studentId,
        studentEnrollmentId: row.studentEnrollmentId,
        schoolTermMarkingPeriodId: row.schoolTermMarkingPeriodId,
        progressAverage: convertToNumber(row.progressAverage),
        totalProgressCourses: convertToNumber(row.totalProgressCourses),
    }));

    this.reportCardAverages = (props.reportCardAverages || []).map((row) => ({
        schoolTermId: row.schoolTermId,
        studentId: row.studentId,
        studentEnrollmentId: row.studentEnrollmentId,
        schoolTermMarkingPeriodId: row.schoolTermMarkingPeriodId,
        totalReportCardCourses: convertToNumber(row.totalReportCardCourses),
        totalReportCardPassing: convertToNumber(row.totalReportCardPassing),
        totalReportCardFailing: convertToNumber(row.totalReportCardFailing),
        reportCardAverage: convertToNumber(row.reportCardAverage),
    }));

    this.studentAttendance = (props.studentAttendance || []).map((row) => ({
        schoolTermId: row.schoolTermId,
        studentId: row.studentId,
        studentEnrollmentId: row.studentEnrollmentId,
        attendanceMonth: row.attendanceMonth,
        totalAbsence: convertToNumber(row.totalAbsence),
        totalPresent: convertToNumber(row.totalPresent),
        totalExcused: convertToNumber(row.totalExcused),
        totalLate: convertToNumber(row.totalLate),
    }));

    this.studentCourseAttendance = (props.studentCourseAttendance || []).map((row) => ({
        schoolTermId: row.schoolTermId,
        studentId: row.studentId,
        studentEnrollmentId: row.studentEnrollmentId,
        courseSectionId: row.courseSectionId,
        attendanceMonth: row.attendanceMonth,
        totalAbsence: convertToNumber(row.totalAbsence),
        totalPresent: convertToNumber(row.totalPresent),
        totalExcused: convertToNumber(row.totalExcused),
        totalLate: convertToNumber(row.totalLate),
    }));
}

function convertToNumber(val) {
    if (val == null) return null;
    const newVal = parseFloat(val);
    return newVal;
}

module.exports = StudentAggregate;
