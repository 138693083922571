import Types from '../Types';

export default {
    [Types.mutations.SET_RPCD_COMMENT_CODES](s, rows) {
        const state = s;
        state.database.reportCards.commentCodes = rows.slice();
    },
    [Types.mutations.SET_RPCD_VALID_MARKS](s, rows) {
        const state = s;
        state.database.reportCards.validMarks = rows.slice();
    },
    [Types.mutations.SET_RPCD_VALID_COURSE_MARKS](s, rows) {
        const state = s;
        state.database.reportCards.courseValidMarks = rows.slice();
    },
    [Types.mutations.SET_RPCD_VALID_EXAM_MARKS](s, rows) {
        const state = s;
        state.database.reportCards.examValidMarks = rows.slice();
    },
    [Types.mutations.SET_RPCD_DATA](s, data) {
        const state = s;
        // if (state.database.reportCards.schoolStaffId == data.schoolStaffId) {
        //     window.console.log('Already set report card data for this user');
        //     return;
        // }

        state.database.reportCards.students = data.students.slice();
        state.database.reportCards.grades = data.grades.slice();
        state.database.reportCards.courseStandards = data.courseStandards.slice();
        state.database.reportCards.averages = data.averages.slice();
        state.database.reportCards.comments = data.comments.slice();

        state.database.reportCards.schoolStaffId = data.schoolStaffId;
    },
    // setDataSystems(s, dataSystems) {
    //     const state = s;
    //     state.dataSystems = dataSystems;
    // },
    // setCommentCodes(s, commentCodes) {
    //     const state = s;
    //     state.reportCards.commentCodes = commentCodes.slice();
    // },
    // setValidMarks(s, validMarks) {
    //     const state = s;
    //     state.reportCards.validMarks = validMarks.slice();
    // },
    // setCourseValidMarks(s, validMarks) {
    //     const state = s;
    //     state.reportCards.courseValidMarks = validMarks.slice();
    // },
    // setExamValidMarks(s, validMarks) {
    //     const state = s;
    //     state.reportCards.examValidMarks = validMarks.slice();
    // },
    // setReportCardData(s, data) {
    //     const state = s;
    //     if (!state.reportCards.reportCardData.hasOwnProperty(data.sections)) {
    //         state.reportCards.reportCardData[data.sections] = [];
    //     }
    //     state.reportCards.reportCardData[data.sections] = data.students.slice();
    // },
};
