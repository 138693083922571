const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.uploadReportCardExcelFile = uploadReportCardExcelFile;
function uploadReportCardExcelFile(user, schoolTermMarkingPeriodId, bucketPath, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/reportCard/submissions`)
        .send({ schoolTermMarkingPeriodId, bucketPath })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
}

module.exports.download = function (user, classroomSubmissionId, schoolStaffId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/reportCard/submissions/${classroomSubmissionId}`)
        .send({ schoolStaffId })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(res.body.message || err);
            callback(err, res.body);
        });
};

module.exports.saveStudentComment = function (user, schoolTermMarkingPeriodId, records, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/markingperiods/${schoolTermMarkingPeriodId}/comments`)
        .send({ comments: records })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(res.body.message || err);
            callback(err, res.body);
        });
};
module.exports.saveStudentStandardGrade = function (user, schoolTermMarkingPeriodId, records, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/markingperiods/${schoolTermMarkingPeriodId}/standardGrades`)
        .send({ grades: records })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(res.body.message || err);
            callback(err, res.body);
        });
};

module.exports.saveStudentGrade = function (user, schoolTermMarkingPeriodId, records, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/markingperiods/${schoolTermMarkingPeriodId}/grades`)
        .send({ grades: records })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(res.body.message || err);
            callback(err, res.body);
        });
};

module.exports.getReportCardSubmissions = getReportCardSubmissions;
function getReportCardSubmissions(user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/reportCard/submissions`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
}

module.exports.importFromExcel = importFromExcel;
function importFromExcel(classroomSubmissionId, fileType, callback) {
    request
        .post(`${syncGradesHost}/api/submissions/${classroomSubmissionId}/etl/${fileType}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
}

module.exports.getRules = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/submissionReplacements`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(res.body.message || err);
            callback(err, res.body);
        });
};

module.exports.saveRules = function (user, replacements, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/submissionReplacements`)
        .send({ rules: replacements })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(res.body.message || err);
            callback(err, res.body);
        });
};
