module.exports = {
    changePassword: 'Cambia tu contraseña',
    oldPassword: 'Contraseña anterior',
    newPassword: 'Nueva contraseña',
    retypePassword: 'Vuelva a escribir la contraseña',
    passwordRequirements: {
        title: 'Estos son los requisitos de seguridad de la contraseña para el sitio de SyncGrades',
        length: 'La contraseña debe tener al menos 15 caracteres de longitud',
        uppercase: 'La contraseña debe contener al menos 3 letras mayúsculas',
        digits: 'La contraseña debe contener al menos 3 dígitos o números',
        special: 'La contraseña debe contener al menos 3 caracteres especiales',
        dictionary: 'La contraseña no puede contener palabras del diccionario',
    },
    impersonate: 'Personificar',
    switchToUser: 'Cambiar a otro usuario',
    myStudents: 'Mis estudiantes',
    selectStudent: 'Selecciona un estudiante diferente',
    help: 'Need help? Email Us¿Necesitas ayuda? Envíenos un correo electrónico',
    wiki: 'Wiki',
    userDocs: 'Documentación del usuario',
    feedback: 'Reacción',
    requestFeature: 'Solicitar características',
    selectUser: 'Selecciona un usuario',
    chooseUser: 'Elegir Usuario',
    chooseToImpersonate: 'Elige un usuario para hacerse pasar por',
    selectTerm: 'Seleccione un término escolar',
    current: 'Actual',
};
