function CourseSectionTeacher(props) {
    this.schoolStaffId = props.schoolStaffId;
    this.courseSectionId = props.courseSectionId;
    this.secondaryTeacher = props.secondaryTeacher;
    this.courseId = props.courseId;
    this.etlSourced = props.etlSourced;
    this.totals = props.totals || [];
}

module.exports = CourseSectionTeacher;
