function CourseSection(props) {
    this.courseId = props.courseId;
    this.courseSectionId = props.courseSectionId;
    this.schoolId = props.schoolId;
    this.schoolTermId = props.schoolTermId;
    this.extCourseSectionId = props.extCourseSectionId;
    this.name = props.name;
    this.deleted = props.deleted;
    this.googleCourseId = props.googleCourseId;

    // these are saved db lookups by course
    // with all schoolStaffId settings present
    this.courseMeetings = props.courseMeetings;
    this.courseSectionTeacherMetadata = props.courseSectionTeacherMetadata;
    this.courseSectionCombos = props.courseSectionCombos;

    // these are overwritten later by
    // passing schoolStaffId to courseMixins.formatCourseForPortfolioUser
    this.courseSectionSchoolStaffId = null;
    this.courseSectionIds = [];
    this.courseSectionTitle = props.name;
    this.courseSectionColor = props.courseSectionColor || 'Blue';
    this.courseSectionHexColor = props.courseSectionHexColor || null;
    this.courseSectionAbbreviation = props.courseSectionAbbreviation || null;
    this.courseSectionIcon = props.courseSectionIcon || null;
    this.hideFromNav = props.hideFromNav || false;

    this.comboCourses = [];

    const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
    let todaysMeeting = props.courseMeetings.find((m) => m.day == today);
    if (!todaysMeeting) [todaysMeeting] = props.courseMeetings;
    if (!props.courseSectionAbbreviation) {
        todaysMeeting
            ? `${todaysMeeting.period || ''}`.substring(0, 2)
            : props.extCourseSectionId.substring(0, 2)
    }

}

module.exports = CourseSection;
