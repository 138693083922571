/* *
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
* */

const wrapper = require('../wrapper');

/**
 * @param {Object} params - http request parameters
 * @param {Object} params.body
 * @param {String} params.body.cipherSessionId
 * @param {String} params.body.verificationKey
 * @param {String} params.body.otp
 * @param {String} params.body.sessionLength
 * @param {String} params.body.check
 * @param {Function} callback
 */
export default function (params, callback) {
    wrapper.default('validateOneTimePassword', 'PUT', '/api/cipher/handshake', params, callback);
}
