const request = require('./requestWrapper');

const syncGradesHost = '';

module.exports.getSchoolTerms = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/terms`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.getTermStatus = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/termstatus`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.createSchoolTerms = function (user, schoolTerms, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/terms`)
        .set('accept', 'json')
        .send({ schoolTerms })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.updateSchoolTerms = function (user, schoolTerms, callback) {
    request
        .put(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/terms`)
        .set('accept', 'json')
        .send({ schoolTerms })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.finalizeSchoolTerm = function (user, schoolTermId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/finalize`)
        .set('accept', 'json')
        .send({ schoolTermId })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.selectAutoLinking = function (user, autoLinkingType, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/autoLinking`)
        .set('accept', 'json')
        .send({ autoLinkingType })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};
