function GoogleStudent(props) {
	this.googleStudentId = props.googleStudentId;
	this.firstName = props.firstName;
	this.lastName = props.lastName;
	this.emailAddress = props.emailAddress;
	this.photoUrl = props.photoUrl;
	this.googleUserId = props.googleUserId;
	this.studentEnrollmentId = props.studentEnrollmentId;
}

module.exports = GoogleStudent;