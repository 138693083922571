import async from 'async';
import { decrypt } from '../../../../shared/aes.mjs';
import { passPhraseToKey } from '../../../../shared/aes.mjs';
import { decryptSchoolTermEncryptionKey, saveCipherCredentials } from '../../lib/cipher';
import { tableEncryptionSettings } from '../../../../shared/encryption.mjs';
import * as network from '../../network';
import Types from '../Types';


/**
 * Enables encryption for the database by converting data from unencrypted to encrypted in PostgreSQL.
 *
 * @param {Object} state - The current state of the application.
 * @param {Function} commit - A function to commit mutations to the Vuex store.
 * @param {string} schoolPassword - The password for the school.
 * @param {Function} callback - A callback function to be called when the encryption process is complete.
 */
function enableEncryption(state, commit, schoolPassword, callback) {

    const { encryptionHash } = state.user.school;
    if (!encryptionHash) return callback(new Error('Encryption not enabled'));
    const { schoolId, schoolTermId } = state.user.school;
    const params = { url: { schoolId, schoolTermId } };
    commit(Types.mutations.SET_DB_LOAD_STATE, 'DECRYPTING');

    async.auto({
        schoolTermEncryptionKeyCipherText(next) {
            network.cipher.getSchoolTermEncryptionKey(params, (err, schoolTermEncryptionKeyCipherText) => {
                if (err) return next(err);
                next(err, schoolTermEncryptionKeyCipherText);
            });
        },
        hashEncryptionKey(next) {
            network.cipher.getHashEncryptionKey(params, (err, res) => {
                if (err) return next(err);
                next(null, res.hashEncryptionKey);
            });
        },
        schoolPasswordKey: ['hashEncryptionKey', 'schoolTermEncryptionKeyCipherText', (results, next) => {
            const { hashEncryptionKey } = results;
            // cant use the school password directly as the key bc it is not the correct length or entrophy
            passPhraseToKey(schoolPassword, hashEncryptionKey, next);
        }],
        schoolTermEncryptionKey: ['schoolPasswordKey', (results, next) => {
            const {schoolTermEncryptionKeyCipherText, schoolPasswordKey} = results;
            const jsonString = Buffer
                .from(schoolTermEncryptionKeyCipherText, 'base64')
                .toString();

            let json;
            try {
                json = JSON.parse(jsonString);
            } catch (e) {
                console.error(e);
            }
            if (!json) return next(new Error('Invalid encryption hash'));
            const { cipherText, iv, tag } = json;
            const schoolTermEncryptionKey = decrypt(schoolPasswordKey, cipherText, iv, tag);
            if (!schoolTermEncryptionKey) return next(new Error('Decryption failed'));
            next(null, schoolTermEncryptionKey);
        }],
        encrypt: ['schoolTermEncryptionKey', (results, next) => {
            const { schoolTermEncryptionKey } = results;
            async.eachSeries(tableEncryptionSettings, (item, nextTable) => {
                const { tableName } = item;
                const params = {
                    url: { schoolId, schoolTermId, tableName },
                    body: {
                        schoolTermEncryptionKey,
                        hashEncryptionKey: results.hashEncryptionKey,
                    },
                };
                network.cipher.encryptTable(params, nextTable);
            }, next);
        }],
    }, 5, (err) => {
        commit(Types.mutations.SET_DB_LOAD_STATE, 'READY');
        commit(Types.mutations.CYCLE_APP_KEY);
        if (err) return callback(err);
        callback();
    });
}

/**
 * Disables encryption for the database by converting data from encrypted to unencrypted in PostgreSQL.
 *
 * @param {Object} state - The current state of the application.
 * @param {Function} commit - A function to commit mutations to the Vuex store.
 * @param {Function} callback - A callback function to be called when the encryption process is complete.
 */
function disableEncryption(state, commit, callback) {
    const { encryptionHash } = state.user.school;
    const { schoolId, schoolTermId } = state.user.school;
    const params = { url: { schoolId, schoolTermId } };
    if (!encryptionHash) return callback(new Error('Encryption not enabled'));
    commit(Types.mutations.SET_DB_LOAD_STATE, 'DECRYPTING');
    async.auto({
        hashEncryptionKey(next) {
            network.cipher.getHashEncryptionKey(params, (err, res) => {
                if (err) return next(err);
                next(null, res.hashEncryptionKey);
            });
        },
        schoolTermEncryptionKey(next) {
            decryptSchoolTermEncryptionKey(state.user.school, next);
        },
        decrypt: ['schoolTermEncryptionKey', 'hashEncryptionKey', (results, next) => {
            const { schoolTermEncryptionKey, hashEncryptionKey } = results;
            if (!schoolTermEncryptionKey) return next(new Error('Unlock decryption before disabling encryption'));
            debugger;
            async.eachSeries(tableEncryptionSettings, (item, nextTable) => {
                const { tableName } = item;
                const params = {
                    url: { schoolId, schoolTermId, tableName },
                    body: { schoolTermEncryptionKey, hashEncryptionKey },
                };
                network.cipher.decryptTable(params, nextTable);
            }, next);
        }],
        clearKeys: ['decrypt', (results, next) => {
            network.cipher.removeSchoolTermEncryptionPassword(params, next);
        }],
    }, 5, (err) => {
        commit(Types.mutations.SET_DB_LOAD_STATE, 'READY');
        commit(Types.mutations.CYCLE_APP_KEY);
        if (err) return callback(err);
        callback();
    });
}


/**
 * Saves the two-factor authentication settings for the user.
 *
 * @param {Object} state - The current state of the store.
 * @param {Function} commit - The commit function from Vuex.
 * @param {string} twoFactorAuthMethod - The two-factor authentication method to be saved.
 * @param {Function} callback - The callback function to be called after saving the settings.
 */
function saveTwoFactorSettings(state, commit, twoFactorAuthMethod, callback) {
    const params = {
        url: {
            schoolId: state.user.school.schoolId,
            schoolTermId: state.user.school.schoolTermId,
        },
        body: { twoFactorAuthMethod },
    };
    network.cipher.saveTwoFactorSettings(params, callback);
}

/**
 * Removes an authentication method for a user.
 *
 * @param {Object} state - The current state of the store.
 * @param {Function} commit - The commit function from Vuex store.
 * @param {string} userTwoFactorAuthMethodId - The ID of the user's authentication method to be removed.
 * @param {Function} callback - The callback function to be called after the authentication method is removed.
 */
function removeAuthMethod(state, commit, userTwoFactorAuthMethodId, callback) {
    const params = {
        url: {
            schoolId: state.user.school.schoolId,
            schoolTermId: state.user.school.schoolTermId,
            userTwoFactorAuthMethodId,
        },
    };
    network.cipher.removeAuthMethod(params, callback);
}


export {
    saveTwoFactorSettings,
    removeAuthMethod,
    enableEncryption,
    disableEncryption,
    saveCipherCredentials,
    decryptSchoolTermEncryptionKey,
}