function MarkingPeriod(props) {
    this.schoolTermMarkingPeriodId = props.schoolTermMarkingPeriodId;
    this.markingPeriodName = props.markingPeriodName;
    this.markingPeriod = props.markingPeriod;
    this.starsGradingEnabled = props.starsGradingEnabled;

    // enforce YYYY-MM-DD via substr(0, 10)
    this.markingPeriodStart = props.markingPeriodStart ? String(props.markingPeriodStart || '').substring(0, 10) : null;
    this.markingPeriodEnd = props.markingPeriodEnd ? String(props.markingPeriodEnd || '').substring(0, 10) : null;
    this.submissionStart = props.submissionStart ? String(props.submissionStart || '').substring(0, 10) : null;
    this.submissionEnd = props.submissionEnd ? String(props.submissionEnd || '').substring(0, 10) : null;

    this.submissionStartTime = props.submissionStartTime;
    this.submissionEndTime = props.submissionEndTime;

    this.exam = props.exam;
    this.conduct = props.conduct;
    this.level = props.level;
    this.final = props.final;
    this.narrative = props.narrative;
    this.commentOne = props.commentOne;
    this.commentTwo = props.commentTwo;
    this.commentThree = props.commentThree;
    this.cumulative = props.cumulative;

    this.gradesVisibleDate = props.gradesVisibleDate;

    this.deleted = props.deleted;
}

module.exports = MarkingPeriod;
