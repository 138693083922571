import network from '../../lib/network';
import Types from '../../store/Types';

export default {
    getGradeTemplates({ commit, rootState }) {
        commit('setIsFetchingTemplates', true);
        network.gradeTemplates.getGradeTemplates(rootState.user, (err, response) => {
            commit('setIsFetchingTemplates', false);
            if (err) return commit(Types.mutations.HANDLE_ERROR, err);
            commit('setGradeTemplates', response.gradeTemplates.templates);
            commit('setGradeScales', response.gradeTemplates.scales);
        });
    },
};
