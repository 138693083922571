import courseSubRoutes from './courseSubRoutes';

const CourseList = () => import( '../../rootComponents/CourseList.vue');
const CourseHome = () => import( '../../rootComponents/CourseHome.vue');

const classRoutes = [
    { // redirect
        path: '/classes/:extCourseSectionId',
        redirect: '/classes/:extCourseSectionId/info',
    },
    {
        name: 'CourseList',
        path: '/courses',
        component: CourseList,
        meta: {
            subHeader: true,
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    },
    {
        path: '/classes/:extCourseSectionId',
        component: CourseHome,
        meta: {
            root: true,
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
        children: courseSubRoutes,
    },
];

export default classRoutes;

export function getCourseMenuItems() {
    const root = classRoutes.find((r) => r.meta?.root);
    if (!root) return [];

    return root.children
        .filter((r) => r.meta.title)
        .map((r) => ({
            name: r.name,
            path: r.path,
            title: r.meta.title,
            icon: r.meta?.icon || null,
            teacherRouteName: r.meta?.teacherRouteName || null,
            active: false,
            roles: ['School Admin', 'Staff', 'Teacher'],
        }));
}
