export default {
    setLocale(s, locale) {
        const state = s;
        state.locale = locale;

        const cleanedLocales = navigator.languages.map((l) => l.split('-')[0]);
        const homeLanguage = cleanedLocales[0];
        if (homeLanguage && homeLanguage == locale) {
            localStorage.removeItem('locale');
        } else {
            localStorage.setItem('locale', locale);
        }
    },
};
