import * as network from '../../network';
import Types from '../Types';

export function create(anecdotalEvent, commit, state, dispatch, callback) {
    const { schoolId, schoolTermId } = state.user.school;
    const url = { schoolId, schoolTermId };
    const body = { anecdotalEvent };
    network.anecdotals.create({ url, body }, (err, res) => {
        if (err) return callback(err);
        const event = { ...anecdotalEvent };
        event.anecdotalEventId = res.anecdotalEventId;
        commit(Types.mutations.ADD_ANECDOTAL_EVENT, event);
        commit(Types.mutations.SET_ANECDOTAL_CREATE_CLOSE);
        if (callback) callback();
    });
}

export function edit(anecdotalEvent, commit, state, dispatch, callback) {
    const { schoolId, schoolTermId } = state.user.school;
    const url = { schoolId, schoolTermId, anecdotalEventId: anecdotalEvent.anecdotalEventId };
    const body = { anecdotalEvent };
    network.anecdotals.edit({ url, body }, (err) => {
        if (err) return callback(err);
        const event = { ...anecdotalEvent };
        commit(Types.mutations.EDIT_ANECDOTAL_EVENT, event);
        callback(err);
    });
}

export function resolve(anecdotalEvent, resolved, commit, state, callback) {
    const { schoolId, schoolTermId } = state.user.school;
    const url = { schoolId, schoolTermId, anecdotalEventId: anecdotalEvent.anecdotalEventId };
    const body = { anecdotalEvent: { resolved } };
    network.anecdotals.markAsResolved({ url, body }, (err) => {
        if (err) return callback(err);
        const event = { ...anecdotalEvent };
        event.resolved = resolved;
        commit(Types.mutations.EDIT_ANECDOTAL_EVENT, event);
        callback(err);
    });
}
