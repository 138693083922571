import authRoutes from './authRoutes';
import anecdotalRoutes from './anecdotalRoutes';
import baseRoutes from './baseRoutes';
import reportRoutes from './reportRoutes';
import schoolSettingsRoutes from './schoolSettingsRoutes';
import fileSettingsRoutes from './fileSettingsRoutes';
import reportCardRoutes from './reportCardRoutes';
import setupWizardRoutes from './setupWizardRoutes';
import superRoutes from './superRoutes';
import configurationRoutes from './configurationRoutes';
import classRoutes from './classRoutes';
import teacherRoutes from './teacherRoutes';
import studentRoutes from './studentRoutes';
import landingRoutes from './landingRoutes';
import communicationRoutes from './communicationRoutes';
import homeUserRoutes from './homeUserRoutes';
import dataAnalyticsRoutes from './dataAnalyticsRoutes';

const PageNotFound = () => import('../../rootComponents/PageNotFound.vue');

const routes = [
    ...baseRoutes,
    ...anecdotalRoutes,
    ...authRoutes,
    ...landingRoutes,
    ...reportRoutes,
    ...schoolSettingsRoutes,
    ...fileSettingsRoutes,
    ...reportCardRoutes,
    ...setupWizardRoutes,
    ...superRoutes,
    ...configurationRoutes,
    ...classRoutes,
    ...teacherRoutes,
    ...studentRoutes,
    ...communicationRoutes,
    ...homeUserRoutes,
    ...dataAnalyticsRoutes,
];

routes.push({
    name: 'PageNotFound',
    path: '/:catchAll(.*)',
    component: PageNotFound,
    meta: {
        subHeader: false,
        title: 'PageNotFound',
        icon: 'bug',
        roles: ['All'],
    },
});


export default routes;
