const types = {
    actions: {
        REFRESH_GOOGLE_BASE_TABLES: 'REFRESH_GOOGLE_BASE_TABLES',
        REFRESH_GOOGLE_COURSE: 'REFRESH_GOOGLE_COURSE',
        REFRESH_GOOGLE_COURSES_FOR_TEACHER: 'REFRESH_GOOGLE_COURSES_FOR_TEACHER',
        REFRESH_GOOGLE_COURSES_BY_STAFF_ID: 'REFRESH_GOOGLE_COURSES_BY_STAFF_ID',
        REFRESH_TABLE_BY_NAME: 'REFRESH_TABLE_BY_NAME',
        IMPERSONATE: 'IMPERSONATE',
        IMPERSONATE_PRIMARY: 'IMPERSONATE_PRIMARY',
        REFRESH_GOOGLE_TABLES: 'REFRESH_GOOGLE_TABLES',
        POPULATE_PORTFOLIO: 'POPULATE_PORTFOLIO',
        POPULATE_REPORT_CARDS: 'POPULATE_REPORT_CARDS',

        POPULATE_RECIPIENT_BY_COURSES: 'POPULATE_RECIPIENT_BY_COURSES',
        POPULATE_RECIPIENT_BY_ROLES: 'POPULATE_RECIPIENT_BY_ROLES',

        REFRESH_GOOGLE_COURSE_TEACHERS: 'REFRESH_GOOGLE_COURSE_TEACHERS',

        GOOGLE_REFRESH_TEACHERS_FOR_COURSE: 'GOOGLE_REFRESH_TEACHERS_FOR_COURSE',

        REFRESH_TEACHERS: 'REFRESH_TEACHERS',
        REFRESH_DATA_SYSTEMS: 'REFRESH_DATA_SYSTEMS',
        REFRESH_ADMIN_SCHOOLS: 'REFRESH_ADMIN_SCHOOLS',
        REFRESH_ADMIN_SCHOOL_TERMS: 'REFRESH_ADMIN_SCHOOL_TERMS',
        REFRESH_ADMIN_TRANSACTIONS: 'REFRESH_ADMIN_TRANSACTIONS',
        REFRESH_GOOGLE_COURSE_GRADE_CATEGORIES: 'REFRESH_GOOGLE_COURSE_GRADE_CATEGORIES',
        REFRESH_GOOGLE_COURSE_TOPICS: 'REFRESH_GOOGLE_COURSE_TOPICS',
        REFRESH_COURSE_SECTIONS: 'REFRESH_COURSE_SECTIONS',
        REFRESH_ANECDOTAL_EVENTS: 'REFRESH_ANECDOTAL_EVENTS',
        REFRESH_ANECDOTAL_EVENT_CATEGORIES: 'REFRESH_ANECDOTAL_EVENT_CATEGORIES',
        REFRESH_GOOGLE_COURSE_COURSE_SECTIONS: 'REFRESH_GOOGLE_COURSE_COURSE_SECTIONS',
        REFRESH_MARKING_PERIODS: 'REFRESH_MARKING_PERIODS',

        LINK_COURSE_SECTION_TO_GOOGLE: 'LINK_COURSE_SECTION_TO_GOOGLE',

        REFRESH_GOOGLE_COURSES: 'REFRESH_GOOGLE_COURSES',
        REFRESH_STUDENTS: 'REFRESH_STUDENTS',
        REFRESH_GOOGLE_COURSE_STUDENTS: 'REFRESH_GOOGLE_COURSE_STUDENTS',
        RELOAD_GRADE_TEMPLATES: 'RELOAD_GRADE_TEMPLATES',
        CREATE_AND_LINK_COURSE_SECTION_TO_GOOGLE: 'CREATE_AND_LINK_COURSE_SECTION_TO_GOOGLE',
        CREATE_ANECDOTAL: 'CREATE_ANECDOTAL',
        UPDATE_READ_STATE: 'UPDATE_READ_STATE',
        GET_ANECDOTAL_EVENT_BY_ID: 'GET_ANECDOTAL_EVENT_BY_ID',
        EDIT_ANECDOTAL: 'EDIT_ANECDOTAL',
        RESOLVE_ANECDOTAL_EVENT: 'RESOLVE_ANECDOTAL_EVENT',
        UNRESOLVE_ANECDOTAL_EVENT: 'UNRESOLVE_ANECDOTAL_EVENT',
        EDIT_COURSE_TEMPLATE_ASSIGNMENTS: 'EDIT_COURSE_TEMPLATE_ASSIGNMENTS',

        ENABLE_SERVER_SIDE_ENCRYPTION: 'ENABLE_SERVER_SIDE_ENCRYPTION',
        DISABLE_SERVER_SIDE_ENCRYPTION: 'DISABLE_SERVER_SIDE_ENCRYPTION',
        SET_CIPHER_CREDENTIALS: 'SET_CIPHER_CREDENTIALS',
        DECRYPT_SCHOOL_TERM_ENCRYPTION_KEY: 'DECRYPT_SCHOOL_TERM_ENCRYPTION_KEY',

        START_DB_UPDATER: 'START_DB_UPDATER',
        SAVE_TWO_FACTOR_AUTH_SETTINGS: 'SAVE_TWO_FACTOR_AUTH_SETTINGS',
        START_TWO_FACTOR_CHALLENGE: 'START_TWO_FACTOR_CHALLENGE',
        REMOVE_TWO_FACTOR_AUTH_METHOD: 'REMOVE_TWO_FACTOR_AUTH_METHOD',

        START_SCREEN_RECORD: 'START_SCREEN_RECORD',
        STOP_SCREEN_RECORD: 'STOP_SCREEN_RECORD',
        ADD_LOCAL_COURSE_WORK: 'ADD_LOCAL_COURSE_WORK',
        EDIT_LOCAL_COURSE_WORK: 'EDIT_LOCAL_COURSE_WORK',
        DELETE_COURSE_WORK: 'DELETE_COURSE_WORK',
        ADD_GOOGLE_COURSE_WORK: 'ADD_GOOGLE_COURSE_WORK',
        EDIT_GOOGLE_COURSE_WORK: 'EDIT_GOOGLE_COURSE_WORK',
        EDIT_GOOGLE_COURSE_WORK_PROPERTIES: 'EDIT_GOOGLE_COURSE_WORK_PROPERTIES',
        DELETE_GOOGLE_COURSE_WORK: 'DELETE_GOOGLE_COURSE_WORK',

        UPSERT_GRADE_TEMPLATE_CATEGORY_GOOGLE_CATEGORY: 'UPSERT_GRADE_TEMPLATE_CATEGORY_GOOGLE_CATEGORY',

        BULK_EDIT_TEACHER_ASSIGNMENTS: 'BULK_EDIT_TEACHER_ASSIGNMENTS',
        BULK_EDIT_TEACHER_ASSIGNMENT_STATE: 'BULK_EDIT_TEACHER_ASSIGNMENT_STATE',

        SAVE_COURSE_SECTION_ATTENDANCE: 'SAVE_COURSE_SECTION_ATTENDANCE',
        SAVE_COURSE_WORK_GRADES: 'SAVE_COURSE_WORK_GRADES',
        GET_ATTENDANCE_FOR_STUDENT: 'GET_ATTENDANCE_FOR_STUDENT',
        POPULATE_STUDENT_DATA_VIEW: 'POPULATE_STUDENT_DATA_VIEW',

        CALCULATE_SETUP_STEPS: 'CALCULATE_SETUP_STEPS',
    },
    getters: {
        IS_DB_LOADED: 'IS_DB_LOADED',
        IS_NYC_GOOGLE_DOMAIN_USER: 'IS_NYC_GOOGLE_DOMAIN_USER',
        GET_MONTHS_IN_TERM: 'GET_MONTHS_IN_TERM',

        GET_STUDENT_PORTFOLIO_ATTENDANCE_VIEW: 'GET_STUDENT_PORTFOLIO_ATTENDANCE_VIEW',
        GET_MY_CLASSROOM_PORTFOLIOS: 'GET_MY_CLASSROOM_PORTFOLIOS',
        GET_MY_CLASSROOM_PORTFOLIO_COURSE: 'GET_MY_CLASSROOM_PORTFOLIO_COURSE',
        GET_MY_CLASSROOM_PORTFOLIO_SYNCGRADE_COURSES: 'GET_MY_CLASSROOM_PORTFOLIO_SYNCGRADE_COURSES',
        GET_MY_CLASSROOM_PORTFOLIO_SCHOOL_COURSES: 'GET_MY_CLASSROOM_PORTFOLIO_SCHOOL_COURSES',
        GET_MY_CLASSROOM_PORTFOLIO_GOOGLE_COURSES: 'GET_MY_CLASSROOM_PORTFOLIO_GOOGLE_COURSES',
        GET_MY_CLASSROOM_PORTFOLIO_TEACHER: 'GET_MY_CLASSROOM_PORTFOLIO_TEACHER',
        GET_MY_CLASSROOM_PORTFOLIO_GOOGLE_TEACHERS: 'GET_MY_CLASSROOM_PORTFOLIO_GOOGLE_TEACHERS',
        IS_MY_CLASSROOM_PORTFOLIO_ACTIVE_COURSE: 'IS_MY_CLASSROOM_PORTFOLIO_ACTIVE_COURSE',
        IS_MY_CLASSROOM_PORTFOLIO_COURSE_READ_ONLY: 'IS_MY_CLASSROOM_PORTFOLIO_COURSE_READ_ONLY',
        GET_MY_CLASSROOM_PORTFOLIO_COURSE_TEACHERS: 'GET_MY_CLASSROOM_PORTFOLIO_COURSE_TEACHERS',

        GET_STUDENT_PORTFOLIOS: 'GET_STUDENT_PORTFOLIOS',
        GET_STUDENT_PORTFOLIO_COURSE: 'GET_STUDENT_PORTFOLIO_COURSE',
        GET_STUDENT_PORTFOLIO_SYNCGRADE_COURSES: 'GET_STUDENT_PORTFOLIO_SYNCGRADE_COURSES',
        GET_STUDENT_PORTFOLIO_SCHOOL_COURSES: 'GET_STUDENT_PORTFOLIO_SCHOOL_COURSES',
        GET_STUDENT_PORTFOLIO_GOOGLE_COURSES: 'GET_STUDENT_PORTFOLIO_GOOGLE_COURSES',
        GET_STUDENT_PORTFOLIO_STUDENT: 'GET_STUDENT_PORTFOLIO_STUDENT',
        GET_STUDENT_PORTFOLIO_PERSONAL_INFO: 'GET_STUDENT_PORTFOLIO_PERSONAL_INFO',

        GET_STUDENTS_WITH_COURSE_NAME: 'GET_STUDENTS_WITH_COURSE_NAME',

        GET_TEACHER_PORTFOLIOS: 'GET_TEACHER_PORTFOLIOS',
        GET_TEACHER_PORTFOLIO_COURSE: 'GET_TEACHER_PORTFOLIO_COURSE',
        GET_TEACHER_PORTFOLIO_SYNCGRADE_COURSES: 'GET_TEACHER_PORTFOLIO_SYNCGRADE_COURSES',
        GET_TEACHER_PORTFOLIO_SCHOOL_COURSES: 'GET_TEACHER_PORTFOLIO_SCHOOL_COURSES',
        GET_TEACHER_PORTFOLIO_GOOGLE_COURSES: 'GET_TEACHER_PORTFOLIO_GOOGLE_COURSES',
        GET_TEACHER_PORTFOLIO_TEACHER: 'GET_TEACHER_PORTFOLIO_TEACHER',
        GET_TEACHER_PORTFOLIO_GOOGLE_TEACHERS: 'GET_TEACHER_PORTFOLIO_GOOGLE_TEACHERS',
        IS_TEACHER_PORTFOLIO_ACTIVE_COURSE: 'IS_TEACHER_PORTFOLIO_ACTIVE_COURSE',
        IS_TEACHER_PORTFOLIO_COURSE_READ_ONLY: 'IS_TEACHER_PORTFOLIO_COURSE_READ_ONLY',
        GET_TEACHER_PORTFOLIO_COURSE_TEACHERS: 'GET_TEACHER_PORTFOLIO_COURSE_TEACHERS',

        GET_ACTIVE_PORTFOLIO_TEACHER: 'GET_ACTIVE_PORTFOLIO_TEACHER',
        GET_ACTIVE_PORTFOLIO_COURSE: 'GET_ACTIVE_PORTFOLIO_COURSE',
        GET_ACTIVE_PORTFOLIO_SYNCGRADE_COURSES: 'GET_ACTIVE_PORTFOLIO_SYNCGRADE_COURSES',
        GET_ACTIVE_PORTFOLIO_SCHOOL_COURSES: 'GET_ACTIVE_PORTFOLIO_SCHOOL_COURSES',
        GET_ACTIVE_PORTFOLIO_GOOGLE_COURSES: 'GET_ACTIVE_PORTFOLIO_GOOGLE_COURSES',
        GET_ACTIVE_PORTFOLIO_GOOGLE_TEACHERS: 'GET_ACTIVE_PORTFOLIO_GOOGLE_TEACHERS',
        IS_ACTIVE_PORTFOLIO_COURSE_READ_ONLY: 'IS_ACTIVE_PORTFOLIO_COURSE_READ_ONLY',
        GET_ACTIVE_PORTFOLIO_COURSE_TEACHERS: 'GET_ACTIVE_PORTFOLIO_COURSE_TEACHERS',

        GET_GOOGLE_COURSES_FOR_SCHOOL_STAFF_ID: 'GET_GOOGLE_COURSES_FOR_SCHOOL_STAFF_ID',
        GET_GOOGLE_TEACHERS_FOR_GOOGLE_COURSE_ID: 'GET_GOOGLE_TEACHERS_FOR_GOOGLE_COURSE_ID',

        GET_SYNCGRADES_COURSE_BY_EMAIL_GOOGLE_COURSE_ID: 'GET_SYNCGRADES_COURSE_BY_EMAIL_GOOGLE_COURSE_ID',
        GET_STUDENTS_BY_GOOGLE_COURSE_ID: 'GET_STUDENTS_BY_GOOGLE_COURSE_ID',
        GET_ATTENDANCE_BY_GOOGLE_COURSE_ID: 'GET_ATTENDANCE_BY_GOOGLE_COURSE_ID',
        GET_GOOGLE_COURSE_WORK_BY_GOOGLE_COURSE_ID: 'GET_GOOGLE_COURSE_WORK_BY_GOOGLE_COURSE_ID',
        GET_STUDENT_SUBMISSIONS_BY_GOOGLE_COURSE_ID: 'GET_STUDENT_SUBMISSIONS_BY_GOOGLE_COURSE_ID',
        GET_TEACHERS_FOR_COURSE_SECTION_ID: 'GET_TEACHERS_FOR_COURSE_SECTION_ID',



        IS_SUBHEADER_ENABLED: 'IS_SUBHEADER_ENABLED',

        GET_EMAIL_FROM_OUTREACH: 'GET_EMAIL_FROM_OUTREACH',
        IS_TWO_FACTOR_AUTH_ENABLED: 'IS_TWO_FACTOR_AUTH_ENABLED',
        IS_TWO_FACTOR_AUTH_REQUIRED: 'IS_TWO_FACTOR_AUTH_REQUIRED',
        IS_CIPHER_SESSION_LOCKED: 'IS_CIPHER_SESSION_LOCKED',
        GET_USER_SCHOOL_ROLE: 'GET_USER_SCHOOL_ROLE',
    },
    mutations: {
        HANDLE_ERROR: 'HANDLE_ERROR',
        UPSERT_ITEM_TO_CACHE: 'UPSERT_ITEM_TO_CACHE',
        REMOVE_ITEM_FROM_CACHE: 'REMOVE_ITEM_FROM_CACHE',
        SET_LOCAL_CACHE_STATE_SAVE: 'SET_LOCAL_CACHE_STATE_SAVE',
        SET_RESTORED_TABLES_FROM_CACHE: 'SET_RESTORED_TABLES_FROM_CACHE',
        CLEAR_PORTFOLIO_CACHES: 'CLEAR_PORTFOLIO_CACHES',
        CACHE_GOOGLE_COURSE_LOADING: 'CACHE_GOOGLE_COURSE_LOADING',
        BUMP_RELOAD_KEY: 'BUMP_RELOAD_KEY',
        SET_ABORT_CONTROLLER: 'SET_ABORT_CONTROLLER',
        SET_IGNORE_WARNINGS: 'SET_IGNORE_WARNINGS',
        SET_GRADEBOOK_ACTIVE_CELL: 'SET_GRADEBOOK_ACTIVE_CELL',
        SET_GRADEBOOK_UPDATED_AVERAGE: 'SET_GRADEBOOK_UPDATED_AVERAGE',
        SET_WINDOW_SIZE: 'SET_WINDOW_SIZE',
        SET_SOCKET_ESTABLISHED: 'SET_SOCKET_ESTABLISHED',
        CLEAR_GRADEBOOK_AVERAGES: 'CLEAR_GRADEBOOK_AVERAGES',
        SET_ASSIGNMENT_COURSE_SECTIONS: 'SET_ASSIGNMENT_COURSE_SECTIONS',
        SET_ASSIGNMENT_IS_DIRTY: 'SET_ASSIGNMENT_IS_DIRTY',
        SHOW_GLOBAL_MODAL: 'SHOW_GLOBAL_MODAL',
        HIDE_GLOBAL_MODAL: 'HIDE_GLOBAL_MODAL',
        SET_UPLOADED_FILES: 'SET_UPLOADED_FILES',
        CLEAR_UPLOADED_FILES_FOR_DATA_SYSTEM_ID: 'CLEAR_UPLOADED_FILES_FOR_DATA_SYSTEM_ID',
        CLEAR_UPLOADED_FILES_BY_NAME: 'CLEAR_UPLOADED_FILES_BY_NAME',
        UPDATE_LOCAL_CACHE: 'UPDATE_LOCAL_CACHE',
        ABORT_REQUESTS: 'ABORT_REQUESTS',
        SET_FORCE_STUDENT_VIEW: 'SET_FORCE_STUDENT_VIEW',
        CLOSE_QUICK_PANEL: 'CLOSE_QUICK_PANEL',
        OPEN_QUICK_PANEL: 'OPEN_QUICK_PANEL',
        SET_AI_RESPONSE: 'SET_AI_RESPONSE',
        SET_AI_LOADING: 'SET_AI_LOADING',
        CLEAR_AI_RESPONSE: 'CLEAR_AI_RESPONSE',
        SET_COURSE_WORK: 'SET_COURSE_WORK',
        SET_MUTABLE_BADGE: 'SET_MUTABLE_BADGE',
        SET_DB_GOOGLE_COURSE_GRADE_CATEGORIES: 'SET_DB_GOOGLE_COURSE_GRADE_CATEGORIES',
        SET_DB_GOOGLE_COURSE_TOPICS: 'SET_DB_GOOGLE_COURSE_TOPICS',
        SET_GOOGLE_SETTING: 'SET_GOOGLE_SETTING',
        SET_GOOGLE_COURSE_REFRESH_STATUS: 'SET_GOOGLE_COURSE_REFRESH_STATUS',
        RESET_GOOGLE_COURSE_REFRESH_STATUS: 'RESET_GOOGLE_COURSE_REFRESH_STATUS',
        SET_STUDENT_PORTFOLIO_ATTENDANCE_COURSE: 'SET_STUDENT_PORTFOLIO_ATTENDANCE_COURSE',
        SET_STUDENT_PORTFOLIO_ATTENDANCE_DATE: 'SET_STUDENT_PORTFOLIO_ATTENDANCE_DATE',
        SET_STUDENT_PORTFOLIO_ATTENDANCE_MONTH: 'SET_STUDENT_PORTFOLIO_ATTENDANCE_MONTH',
        ADD_ANECDOTAL_EVENT: 'ADD_ANECDOTAL_EVENT',
        EDIT_ANECDOTAL_EVENT: 'EDIT_ANECDOTAL_EVENT',
        ADD_DB_ANECDOTAL_EVENTS: 'ADD_DB_ANECDOTAL_EVENTS',
        ADD_COURSE_SECTION_TEACHER: 'ADD_COURSE_SECTION_TEACHER',
        ADD_TO_EMAILS: 'ADD_TO_EMAILS',
        SET_HIDE_WIZARD: 'SET_HIDE_WIZARD',
        SKIP_SETUP_FOR_ROUTE: 'SKIP_SETUP_FOR_ROUTE',
        REMOVE_PORTFOLIO_HISTORY: 'REMOVE_PORTFOLIO_HISTORY',
        ADD_PORTFOLIO_HISTORY: 'ADD_PORTFOLIO_HISTORY',
        ADD_SCREEN_RECORD_HISTORY: 'ADD_SCREEN_RECORD_HISTORY',
        SET_IS_SCREEN_RECORDING: 'SET_IS_SCREEN_RECORDING',
        DELETE_COURSE_WORK: 'DELETE_COURSE_WORK',
        DELETE_COURSE_SECTION_LINK: 'DELETE_COURSE_SECTION_LINK',
        SET_COURSE_SECTION_LINK_BULK_SELECTION: 'SET_COURSE_SECTION_LINK_BULK_SELECTION',
        SET_COURSE_SECTION_LINK_ADD_ACTIVE_USER_AS_TEACHER: 'SET_COURSE_SECTION_LINK_ADD_ACTIVE_USER_AS_TEACHER',
        DELETE_ANECDOTAL_EVENT_CATEGORY: 'DELETE_ANECDOTAL_EVENT_CATEGORY',
        SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_POINT_VALUE',
        SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STAFF: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STAFF',
        SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_STUDENT',
        SET_ANECDOTAL_SEARCH_ANECDOTAL_DOWNLOAD_EXCEL: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_DOWNLOAD_EXCEL',
        EDIT_ANECDOTAL_EVENT_CATEGORY: 'EDIT_ANECDOTAL_EVENT_CATEGORY',
        ADD_ANECDOTAL_EVENT_CATEGORY: 'ADD_ANECDOTAL_EVENT_CATEGORY',
        SET_ANECDOTAL_SEARCH_ANECDOTAL_LOADING: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_LOADING',
        SET_ANECDOTAL_SEARCH_PAGE_NUMBER: 'SET_ANECDOTAL_SEARCH_PAGE_NUMBER',
        SET_ATTENDANCE_START_DATE: 'SET_ATTENDANCE_START_DATE',
        SET_ATTENDANCE_END_DATE: 'SET_ATTENDANCE_END_DATE',
        SET_ATTENDANCE_LABEL: 'SET_ATTENDANCE_LABEL',
        SET_ATTENDANCE_ONLY_COURSE_MEETINGS: 'SET_ATTENDANCE_ONLY_COURSE_MEETINGS',
        SET_ATTENDANCE_FOR_STUDENT: 'SET_ATTENDANCE_FOR_STUDENT',

        SET_STUDENT_DATA_VIEW_ROWS: 'SET_STUDENT_DATA_VIEW_ROWS',
        SET_STUDENT_DATA_VIEW_TOTAL_ROWS: 'SET_STUDENT_DATA_VIEW_TOTAL_ROWS',
        SET_STUDENT_DATA_VIEW_COLUMNS: 'SET_STUDENT_DATA_VIEW_COLUMNS',
        SET_STUDENT_DATA_VIEW_CURRENT_PAGE: 'SET_STUDENT_DATA_VIEW_CURRENT_PAGE',
        SET_STUDENT_DATA_VIEW_PER_PAGE: 'SET_STUDENT_DATA_VIEW_PER_PAGE',
        SET_STUDENT_DATA_VIEW_SORT_INDEX: 'SET_STUDENT_DATA_VIEW_SORT_INDEX',
        SET_STUDENT_DATA_VIEW_FILTER: 'SET_STUDENT_DATA_VIEW_FILTER',
        TOGGLE_STUDENT_DATA_VIEW_SORT_ORDER: 'TOGGLE_STUDENT_DATA_VIEW_SORT_ORDER',

        APPLY_USER_SETTINGS: 'APPLY_USER_SETTINGS',
        SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD: 'SAVE_SETTING_SCHOOL_TERM_MARKING_PERIOD',
        SAVE_SETTING_MESSAGE_NOTIFICATIONS_ENABLED: 'SAVE_SETTING_MESSAGE_NOTIFICATIONS_ENABLED',
        SAVE_SETTING_GRADE_BY: 'SAVE_SETTING_GRADE_BY',
        SAVE_SETTING_GRADEBOOK_SORTINGS: 'SAVE_SETTING_GRADEBOOK_SORTINGS',

        DELETE_GOOGLE_COURSE_COURSE_SECTIONS: 'DELETE_GOOGLE_COURSE_COURSE_SECTIONS',
        EDIT_GOOGLE_COURSE_COURSE_SECTIONS: 'EDIT_GOOGLE_COURSE_COURSE_SECTIONS',
        EDIT_COURSE_SECTION_STATE: 'EDIT_COURSE_SECTION_STATE',
        EDIT_GOOGLE_COURSE_COURSE_SECTION: 'EDIT_GOOGLE_COURSE_COURSE_SECTION',
        EDIT_GOOGLE_COURSE_GRADE_TEMPLATE: 'EDIT_GOOGLE_COURSE_GRADE_TEMPLATE',
        EDIT_GOOGLE_COURSE_OWNER: 'EDIT_GOOGLE_COURSE_OWNER',
        EDIT_GOOGLE_COURSE_STATE: 'EDIT_GOOGLE_COURSE_STATE',
        EDIT_STUDENT_EMAIL: 'EDIT_STUDENT_EMAIL',
        EDIT_TEACHER_EMAIL: 'EDIT_TEACHER_EMAIL',
        NAVIGATE_BACK_OR_HOME: 'NAVIGATE_BACK_OR_HOME',
        UPSERT_GOOGLE_COURSE_TEACHER: 'UPSERT_GOOGLE_COURSE_TEACHER',
        REMOVE_COURSE_SECTION_TEACHER: 'REMOVE_COURSE_SECTION_TEACHER',
        REMOVE_GOOGLE_COURSE_TEACHER: 'REMOVE_GOOGLE_COURSE_TEACHER',
        RESET_SCREEN_RECORD: 'RESET_SCREEN_RECORD',
        SET_ANECDOTAL_CREATE_CLOSE: 'SET_ANECDOTAL_CREATE_CLOSE',
        SET_ANECDOTAL_CREATE_OPEN: 'SET_ANECDOTAL_CREATE_OPEN',
        SET_STUDENT_BADGE_PANEL_CLOSE: 'SET_STUDENT_BADGE_PANEL_CLOSE',
        SET_STUDENT_BADGE_PANEL_OPEN: 'SET_STUDENT_BADGE_PANEL_OPEN',
        SET_CREATE_MESSAGE_STAFF: 'SET_CREATE_MESSAGE_STAFF',
        SET_CREATE_MESSAGE_STUDENT: 'SET_CREATE_MESSAGE_STUDENT',
        SET_CREATE_MESSAGE_GUARDIAN: 'SET_CREATE_MESSAGE_GUARDIAN',
        TOGGLE_CREATE_MESSAGE: 'TOGGLE_CREATE_MESSAGE',
        TOGGLE_CREATE_ANNOUNCEMENT: 'TOGGLE_CREATE_ANNOUNCEMENT',
        TOGGLE_CREATE_SCHOOL_ANNOUNCEMENT: 'TOGGLE_CREATE_SCHOOL_ANNOUNCEMENT',
        TOGGLE_CREATE_COMMUNICATION_GROUP: 'TOGGLE_CREATE_COMMUNICATION_GROUP',
        TOGGLE_COMMUNICATION_POP_UP_CHAT: 'TOGGLE_COMMUNICATION_POP_UP_CHAT',
        TOGGLE_COMMUNICATION_MESSAGE_NOTIFICATION: 'TOGGLE_COMMUNICATION_MESSAGE_NOTIFICATION',
        SET_SELECTED_COMMUNICATION_GROUP: 'SET_SELECTED_COMMUNICATION_GROUP',
        ENABLE_STARS_GRADING_FOR_MP: 'ENABLE_STARS_GRADING_FOR_MP',
        ADD_BADGE: 'ADD_BADGE',
        EDIT_BADGE: 'EDIT_BADGE',
        SET_STUDENT_AVATAR: 'SET_STUDENT_AVATAR',

        SET_BILTERACY_SEAL_DEMO_DATA: 'SET_BILTERACY_SEAL_DEMO_DATA',
        SET_BILITERACY_SEAL_SCHOOL_YEAR: 'SET_BILITERACY_SEAL_SCHOOL_YEAR',
        REMOVE_BILITERACY_PROFILE_HISTORY: 'REMOVE_BILITERACY_PROFILE_HISTORY',
        ADD_BILITERACY_PROFILE_HISTORY: 'ADD_BILITERACY_PROFILE_HISTORY',
        REMOVE_BILTERACY_DATA_POINT: 'REMOVE_BILTERACY_DATA_POINT',
        ADD_BILTERACY_DATA_POINT: 'ADD_BILTERACY_DATA_POINT',

        SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DESCRIPTION: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DESCRIPTION',
        SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DATE: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_DATE',
        SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY',
        SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_TERM: 'SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_TERM',

        SET_RPCD_COMMENT_CODES: 'SET_RPCD_COMMENT_CODES',
        SET_RPCD_VALID_MARKS: 'SET_RPCD_VALID_MARKS',
        SET_RPCD_VALID_COURSE_MARKS: 'SET_RPCD_VALID_COURSE_MARKS',
        SET_RPCD_VALID_EXAM_MARKS: 'SET_RPCD_VALID_EXAM_MARKS',
        SET_RPCD_DATA: 'SET_RPCD_DATA',

        SET_DB_SCHOOLS: 'SET_DB_SCHOOLS',
        SET_DB_TRANSACTIONS: 'SET_DB_TRANSACTIONS',
        SET_DB_STUDENT_BIOGRAPHICAL: 'SET_DB_STUDENT_BIOGRAPHICAL',
        SET_DB_SCHOOL_ATTENDANCE: 'SET_DB_SCHOOL_ATTENDANCE',
        SET_DB_ANECDOTAL_EVENT_CATEGORIES: 'SET_DB_ANECDOTAL_EVENT_CATEGORIES',
        SET_DB_ANECDOTAL_EVENTS: 'SET_DB_ANECDOTAL_EVENTS',
        SET_DB_COURSE_SECTION_ATTENDANCE: 'SET_DB_COURSE_SECTION_ATTENDANCE',
        SET_DB_COURSE_SECTION_GRADE_TEMPLATES: 'SET_DB_COURSE_SECTION_GRADE_TEMPLATES',
        SET_DB_COURSE_SECTION_STUDENTS: 'SET_DB_COURSE_SECTION_STUDENTS',
        REMOVE_COURSE_SECTION_STUDENT: 'REMOVE_COURSE_SECTION_STUDENT',
        SET_DB_COURSE_SECTION_TEACHERS: 'SET_DB_COURSE_SECTION_TEACHERS',
        SET_DB_SCHOOL_STAFF: 'SET_DB_SCHOOL_STAFF',
        SET_DB_COURSE_SECTION_TEACHER_METADATA: 'SET_DB_COURSE_SECTION_TEACHER_METADATA',
        SET_DB_STUDENT_GUARDIANS: 'SET_DB_STUDENT_GUARDIANS',
        SET_DB_COURSE_SECTIONS: 'SET_DB_COURSE_SECTIONS',
        SET_DB_STUDENT_MARKING_PERIOD_GRADES: 'SET_DB_STUDENT_MARKING_PERIOD_GRADES',
        SET_DB_DATA_SYSTEMS: 'SET_DB_DATA_SYSTEMS',
        SET_DB_GOOGLE_COURSE_COURSE_SECTIONS: 'SET_DB_GOOGLE_COURSE_COURSE_SECTIONS',
        SET_DB_GOOGLE_COURSE_STUDENTS: 'SET_DB_GOOGLE_COURSE_STUDENTS',
        SET_DB_GOOGLE_COURSE_TEACHERS: 'SET_DB_GOOGLE_COURSE_TEACHERS',
        SET_DB_HELP_REDIRECTS: 'SET_DB_HELP_REDIRECTS',
        SET_DB_COURSE_WORK: 'SET_DB_COURSE_WORK',
        SET_DB_MESSAGES: 'SET_DB_MESSAGES',
        SET_DB_STUDENT_MESSAGES: 'SET_DB_STUDENT_MESSAGES',
        SET_DB_COMMUNICATION_GROUPS: 'SET_DB_COMMUNICATION_GROUPS',
        SET_DB_STUDENT_GROUPS: 'SET_DB_STUDENT_GROUPS',
        SET_DB_COURSE_WORK_GRADES: 'SET_DB_COURSE_WORK_GRADES',
        SET_DB_GOOGLE_COURSE_WORK: 'SET_DB_GOOGLE_COURSE_WORK',
        SET_DB_GOOGLE_COURSES: 'SET_DB_GOOGLE_COURSES',
        SET_DB_GOOGLE_STUDENT_SUBMISSIONS: 'SET_DB_GOOGLE_STUDENT_SUBMISSIONS',
        SET_DB_GOOGLE_TEACHERS: 'SET_DB_GOOGLE_TEACHERS',
        SET_DB_GOOGLE_STUDENTS: 'SET_DB_GOOGLE_STUDENTS',
        SET_DB_GRADE_SCALES: 'SET_DB_GRADE_SCALES',
        SET_DB_GRADE_TEMPLATES: 'SET_DB_GRADE_TEMPLATES',
        SET_DB_INDEX_FOR_STAFF: 'SET_DB_INDEX_FOR_STAFF', // deprecate
        SET_DB_INDEX_FOR_STUDENT: 'SET_DB_INDEX_FOR_STUDENT', // deprecate
        SET_DB_LOAD_STATE: 'SET_DB_LOAD_STATE',
        SET_DB_MARKING_PERIODS: 'SET_DB_MARKING_PERIODS',
        SET_DB_MARKING_PERIOD_STUDENT_AVERAGES: 'SET_DB_MARKING_PERIOD_STUDENT_AVERAGES',
        SET_DB_SCHOOL_TERMS: 'SET_DB_SCHOOL_TERMS',
        SET_DB_STUDENTS: 'SET_DB_STUDENTS',
        SET_DB_BADGES: 'SET_DB_BADGES',
        SET_DB_STUDENT_AGGREGATES: 'SET_DB_STUDENT_AGGREGATES',
        SET_DB_STUDENT_BADGES: 'SET_DB_STUDENT_BADGES',
        UPSERT_STUDENT_BADGES: 'UPSERT_STUDENT_BADGES',
        SET_DB_CACHE: 'SET_DB_CACHE',
        SET_DB_TEACHERS: 'SET_DB_TEACHERS',
        SET_DB_USAGE_TOTALS: 'SET_DB_USAGE_TOTALS',
        SET_DB_USER_ROLES: 'SET_DB_USER_ROLES',
        SET_DB_USERS: 'SET_DB_USERS',
        SET_DB_NOTIFICATION_TYPES: 'SET_DB_NOTIFICATION_TYPES',
        SET_ERROR: 'SET_ERROR',
        SET_IS_LOADING: 'SET_IS_LOADING',
        SET_PROFILE_HAS_COURSES: 'SET_PROFILE_HAS_COURSES',
        SET_DB_STUDENT_PDF_REPORTS: 'SET_DB_STUDENT_PDF_REPORTS',
        SET_RESPONSIVE_DEVICE_TYPE: 'SET_RESPONSIVE_DEVICE_TYPE',
        SET_SCHOOL_TERM: 'SET_SCHOOL_TERM',

        // SET_RECIPIENT_BY_COURSES_ISSELECTED: 'SET_RECIPIENT_BY_COURSES_ISSELECTED',
        // SET_RECIPIENT_BY_COURSES: 'SET_RECIPIENT_BY_COURSES',
        // SET_RECIPIENT_BY_ROLE_ISSELECTED: ' SET_RECIPIENT_BY_ROLE_ISSELECTED',
        // SET_RECIPIENT_BY_ROLES: 'SET_RECIPIENT_BY_ROLES',
        // SET_MY_CLASSROOM_PORTFOLIO_COURSE: 'SET_MY_CLASSROOM_COURSE',
        // SET_MY_COURSE_NAMES: 'SET_MY_COURSE_NAMES',
        // SET_OUTREACH_EMAIL: 'SET_OUTREACH_EMAIL',
        // SET_OUTREACH_RECIPIENTS: 'SET_OUTREACH_RECIPIENTS',

        // SET_SHOW_PORTFOLIO_NAVIGATION_MENU: 'SET_SHOW_PORTFOLIO_NAVIGATION_MENU',
        // SET_STUDENT_PORTFOLIO_COURSE: 'SET_STUDENT_PORTFOLIO_COURSE',
        // SET_STUDENT_PORTFOLIO_STUDENT_PERSONAL_INFO: 'SET_STUDENT_PORTFOLIO_STUDENT_PERSONAL_INFO',
        // SET_STUDENT_PORTFOLIO_STUDENT: 'SET_STUDENT_PORTFOLIO_STUDENT',
        // SET_STUDENTS_WITH_COURSE_NAMES: 'SET_STUDENTS_WITH_COURSE_NAMES',
        // SET_TEACHER_PORTFOLIO_COURSE: 'SET_TEACHER_PORTFOLIO_COURSE',
        // SET_TEACHER_PORTFOLIO_DEFAULT_TEACHER: 'SET_TEACHER_PORTFOLIO_DEFAULT_TEACHER',
        // SET_TEACHER_PORTFOLIO_TEACHER: 'SET_TEACHER_PORTFOLIO_TEACHER',

        SET_USER_PROFILE: 'SET_USER_PROFILE',
        UPSERT_DB_GOOGLE_COURSE_WORK: 'UPSERT_DB_GOOGLE_COURSE_WORK',
        UPSERT_USER_ROLE: 'UPSERT_USER_ROLE',
        UPSERT_USER: 'UPSERT_USER',
        SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION: 'SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SELECTION',
        SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SAVING: 'SET_CONFIGURATION_TEMPLATE_ASSIGNMENTS_BULK_SAVING',
        EDIT_COURSE_SECTION_GRADE_TEMPLATE: 'EDIT_COURSE_SECTION_GRADE_TEMPLATE',
        SET_USER_TWO_FACTOR_AUTH_METHOD_ID: 'SET_USER_TWO_FACTOR_AUTH_METHOD_ID',
        SET_VERIFICATION_KEY: 'SET_VERIFICATION_KEY',
        SET_VERIFICATION_OTP: 'SET_VERIFICATION_OTP',
        SET_CIPHER_SESSION_ID: 'SET_CIPHER_SESSION_ID',
        SET_CIPHER_EPOCH_EXPIRY: 'SET_CIPHER_EPOCH_EXPIRY',
        SET_CIPHER_CHALLENGE_METHOD: 'SET_CIPHER_CHALLENGE_METHOD',
        SET_CIPHER_SESSION_LOCKED: 'SET_CIPHER_SESSION_LOCKED',
        CYCLE_APP_KEY: 'CYCLE_APP_KEY',

        SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SELECTION: 'SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SELECTION',
        SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SAVING: 'SET_TEACHER_PORTFOLIO_ASSIGNMENTS_BULK_SAVING',

        UPSERT_COURSE_SECTION_ATTENDANCE: 'UPSERT_COURSE_SECTION_ATTENDANCE',
        EDIT_DATA_SYSTEM_SYNC_FILE: 'EDIT_DATA_SYSTEM_SYNC_FILE',

        SET_ENCRYPTION_HASH: 'SET_ENCRYPTION_HASH',
        SET_SERVER_SIDE_ENCRYPTION_ENABLED: 'SET_SERVER_SIDE_ENCRYPTION_ENABLED',
        SET_SCHOOL_TERM_ENCRYPTION_KEY: 'SET_SCHOOL_TERM_ENCRYPTION_KEY',
        SHOW_SCHOOL_PASSWORD_MODAL: 'SHOW_SCHOOL_PASSWORD_MODAL',
        SET_WIZARD_STEPS: 'SET_WIZARD_STEPS',

    },
};

export default types;
