/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getStudentProfileDocuments from './getStudentProfileDocuments';
import addStudentProfileDocument from './addStudentProfileDocument';
import deleteStudentProfileDocument from './deleteStudentProfileDocument';
import downloadStudentDocumentProfile from './downloadStudentDocumentProfile';

export {
    getStudentProfileDocuments,
    addStudentProfileDocument,
    deleteStudentProfileDocument,
    downloadStudentDocumentProfile,
 };
