function CommunicationGroup(props) {
    this.communicationGroupId = props.communicationGroupId;
    this.schoolStaffId = props.schoolStaffId;
    this.schoolTermId = props.schoolTermId;
    this.messages = props.messages || [];
    this.members = props.members || [];
    this.groupName = props.groupName || null;
    this.created = props.created;
    this.author = props.author;
    this.deleted = props.deleted;
}

module.exports = CommunicationGroup;
