var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.options.toolbar
    ? _c(
        "a",
        {
          directives: [
            {
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: _vm.title,
              expression: "title",
              modifiers: { hover: true },
            },
          ],
          staticClass: "btn btn-clean 'btn-sm'",
          class: !_vm.desktop ? ["btn-icon", "btn-icon-md"] : "dropdown-item",
          attrs: { href: _vm.href, target: "_blank" },
        },
        [
          _c("img", {
            staticStyle: { width: "19px" },
            attrs: { src: "/images/google_classroom_toolbar.svg" },
          }),
          _vm.desktop
            ? _c(
                "span",
                {
                  staticClass: "pt-2",
                  staticStyle: { color: "#93a2dd", "font-size": "11px" },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e(),
        ]
      )
    : _c(
        "a",
        {
          staticClass: "btn btn-google btn-wide",
          class: _vm.variant ? `btn-${_vm.variant}` : "",
          attrs: { type: "button", href: _vm.href, target: "_blank" },
          on: {
            mouseenter: function ($event) {
              _vm.options.showHover
                ? (_vm.hovering = true)
                : (_vm.hovering = false)
            },
            mouseleave: function ($event) {
              _vm.options.showHover
                ? (_vm.hovering = false)
                : (_vm.hovering = false)
            },
          },
        },
        [
          !_vm.hovering
            ? _c("img", {
                staticClass: "gc",
                attrs: { src: "/images/google_classroom_mono.svg" },
              })
            : _c("img", {
                staticClass: "gc",
                attrs: { src: "/images/google_classroom.svg" },
              }),
          _vm.title
            ? _c("span", [_vm._v(" " + _vm._s(_vm.title) + " ")])
            : _vm._e(),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }