module.exports = {
    clickHere: 'Click here to setup',
    filter: 'Filter',
    noData: 'There is no data available because this course is not linked to google classroom.',
    googleCategoriesModal: {
        title: 'Google categories link selection',
        info: 'Select the SyncGrades category that corresponds to the category in google classroom!',
    },
};

module.exports.activity = {
    activity: 'Activity',
    created: 'CREATED',
    createdSubmission: 'CREATED Submission',
    filterByDate: 'Filter by date',
    interactionCountByDate: '{count} interactions on {date}',
    new: 'NEW',
    noActivity: 'There is no activity found, if this is incorrect click the refresh button below to pull latest information from google.',
    reclaimed_by_student: 'RECLAIMED BY STUDENT',
    refreshActivity: 'Refresh Activity',
    returned: 'RETURNED',
    submissionState: '{state} Submission',
    turned_in: 'TURNED IN',
};

module.exports.assignments = {
    allCategories: 'All Categories',
    allMarkingPeriods: 'All Marking Periods',
    allAssignments: 'All Assignments',
    markingPeriod: 'Marking Period',
    assigned: 'Assigned',
    assignment: 'Assignment',
    assignments: 'Assignments',
    auto: 'Auto',
    category: 'Category',
    confirmConfirmDelete: 'Are you sure? THIS CANNOT BE UNDONE!',
    confirmDelete: 'You are deleting an assignment. Ensure that this is what you would like to do before proceeding. THIS CANNOT BE UNDONE. Would you like to continue?',
    createAssignment: 'Create New Assignment',
    customDueDate: 'Custom due date',
    date: 'Date',
    deleteAssignment: 'Delete Assignment',
    description: 'Description',
    downloadAssignments: 'Download Assignments',
    draft: 'Draft',
    due: 'Due',
    dueDate: 'Due Date',
    dueDateAndTime: 'Due Date & Time',
    dueWhenFuture: 'in {count} {unit}',
    dueWhenNow: 'today',
    dueWhenPast: '{count} {unit} ago',
    editAssignment: 'Edit Assignment',
    editDraftAssignment: 'Edit Draft Assignment',
    excludeFromMarkingPeriods: 'Exclude from Marking Periods',
    exclusive: 'Exclusive',
    exclusiveInstructions: 'If marking period <code>{auto}</code> is chosen, the assignment is put in one or more marking periods based off the due date in google and the marking period date ranges. If there is no due date set, the creation date is used. If <code>{exclusive}</code> average is enabled, the assignment grade will only be averaged in marking period chosen, otherwise the assignment can fall into multiple marking periods when marking period dates overlap.',
    grade: 'Grade',
    groupingDate: 'Grouping Date',
    markingPeriodInitials: 'MP',
    maxPoints: 'Max Points',
    missing: 'Missing',
    newAssignment: 'New Assignment',
    noAssignments: 'There are no assignments found, if this is incorrect click the refresh button below to pull latest information from google.',
    noAssignmentsFound: 'There are no assignments found.',
    noCategories: 'No Categories',
    noDueDate: 'No due date',
    notLinked: 'Not Linked',
    openInGoogle: 'Open in Google',
    properties: 'Properties',
    published: 'Published',
    publishFirst: 'Publish first to open in Google',
    refreshAssignments: 'Refresh Assignments',
    relativeDate: 'Relative Date',
    search: 'Search',
    sections: 'Sections',
    template: 'Template',
    title: 'Title',
    uncategorized: 'Uncategorized',
    weight: 'Weight',
    worth: 'Worth',
};

module.exports.gradeTemplate = {
    noTemplate: 'There are no template found, if this is incorrect click the refresh button below to pull latest information from google.',
    notLinked: 'Not Linked',
    refreshAssignments: 'Refresh Assignments',
    template: 'Template',
};

module.exports.attendance = {
    absent: 'Absent',
    applyAsRemoteAttendance: 'Apply as remote attendance',
    attendance: 'Attendance',
    changeAttendanceFor: 'Change attendance for {count} selected students to:',
    comment: 'Comment',
    commentFor: 'Comment for',
    excused: 'Excused',
    inSchool: 'In School',
    late: 'Late',
    'n/a': 'None',
    present: 'Present',
    remote: 'Remote',
    savedAs: '{name} saved as ??? {location}: {state}',
    savingChanges: 'Saving Changes',
    saveSingleChange: 'Save 1 change',
    saveMultipleChange: 'Save {count} changes',
};

module.exports.averages = {
    average: 'Average',
    averages: 'Averages',
    dateThroughDate: '{start} through {end}',
    excused: 'Excused',
    failing: 'Failing',
    late: 'Late',
    lowestGrade: 'Lowest Grade',
    missing: 'Missing',
    overall: 'Overall',
    passing: 'Passing',
    scaled: 'Scaled',
    total: 'Total',
};

module.exports.progressReports = {
    allStudents: 'All Students',
    createNew: 'Create New',
    downloadBulk: 'Download Bulk',
    missingComments: 'Missing Comments',
    problemLoadingUser: 'There was a problem loading your user',
    problemLoadingMarkingPeriods: 'There was a problem loading the marking periods',
    problemLoadingStudents: 'There was a problem loading your students',
    problemLoadingStudentComments: 'There was a problem loading these students comments',
    progressReportQueue: 'Progress Report Queue',
    progressReports: 'Progress Reports',
    progressReportModal: {
        generateProgressReport: 'Generate @:pages.teacher.progressReports.progressReportModal.progressReport',
        forTeacher: 'For Teacher',
        forStudent: 'For Student',
        forSchool: 'For School',
        courseLevel: 'Course Level',
        schoolLevel: 'School Level',
        progressReport: 'Progress Report',
        createNewProgressReport: 'Create a new progress report',
        dividedByEachCourse: 'divided by each course',
        withAllCoursesCombined: 'with all courses combined',
        runForHomeRoom: 'Run for Home Room',
        allHomeRooms: 'All Home Rooms',
        generateForAllHomeRooms: 'Generate for all Home Rooms',
        homeRoom: 'Home Room',
        runForCourses: 'Run for Courses',
        allCourses: 'All Courses',
        generateForAllCourses: 'Generate for all Courses',
        emailToStudents: 'Email to students',
        sendACopy: 'Send a copy of this PDF to the student and guardians.',
        showOnPortfolio: 'Show on portfolio',
        showACopy: 'Show a copy of this PDF on each students portfolio page.',
        schoolAnnouncementsCheckbox: 'School Announcement:',
        schoolAnnouncementsSave: 'Save Announcement',
        sortOrder: 'Sort Order',
        generateReport: 'Generate Report',
        overallAverage: {
            name: 'Overall Average',
            description: 'Includes a section with a students simple projected marking period average.',
        },
        courseAverages: {
            name: 'Course Averages',
            description: 'Adds a section with a marking period average for each course',
        },
        gradeBreakdown: {
            name: 'Grade Breakdown',
            description: 'Adds a data table of grading categories with student category average and scaled score',
        },
        assignments: {
            name: 'Assignments',
            description: 'Lists each assignment, completion status, grade and scaled score',
        },
        courseAttendance: {
            name: 'Course Attendance',
            description: 'Prints the most recent non-present course attendance records',
        },
        teacherReportComments: {
            name: 'Teacher Report Comments',
            description: 'Adds comments as defined by the teacher for the course in this marking period',
        },
        missingAssignments: {
            name: 'Missing Assignments',
            description: 'Adds a section with only missing assignments for the course',
        },
        allMissingAssignments: {
            name: 'All Missing Assignments',
            description: 'Adds a table with only missing assignments across all courses',
        },
        schoolAnnouncements: {
            name: 'School Announcements',
            description: 'Add a section with the school announcement',
        },
    },
};

module.exports.reportCards = {
    excelUploadNeeded: 'Excel upload needed',
    gradingStatus: 'Grading Status',
    gradingSubmissions: 'Grading Submissions',
    readyToGrade: 'Ready to grade',
    reportCards: 'Report Cards',
    submissionWindow: 'Grade Submission Window',
    submissionWindowClosed: 'Grade Submission window closed',
};

module.exports.students = {
    courseSection: 'Course Section',
    enterValidEmail: 'Enter a valid student google email address',
    googleAccount: 'Google Account',
    id: 'ID',
    linkStudent: 'Link Student',
    student: 'Student',
    studentEmail: 'Student Email',
    students: 'Students',
    studentFilter: 'Student Filter',
};
