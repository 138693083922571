import models from '../models';
import * as network from '../network';
import Types from './Types';

/* full sample tableDefinition {
    tableName: Y // required, maps to state.database[tableName]
    model: models.MyModel, // required for loading and deserialization
    roles: ['School Admin'], // optional list of roles
    portfolioData: true, // optional if portfolio data
    cacheLocally: true, // optional to cache in local storage
    cacheType: 'baseTables' || 'googleBaseTables' ||
                'portfolioCourseTables' || 'googleCourseTables' || portfolioCourseTeacherTables
    saturation: {
        mutation: Types.mutations.SET_DB_XXX, //used when populating from virgin
        saturate(params, completed) {
            // network call to get the data
            network.whatever(params, completed);
        },
    } }
*/

const tableDefinitions = [{
    tableName: 'schools',
    model: models.School,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_SCHOOLS,
        saturate(params, completed) {
            network.schools.listSchools(params, completed);
        },
    },
}, {
    tableName: 'schoolTerms',
    model: models.SchoolTerm,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_SCHOOL_TERMS,
        saturate(params, completed) {
            network.schoolTerms.listForSchool(params, completed);
        },
    },
}, {
    tableName: 'students',
    model: models.Student,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENTS,
        saturate(params, completed) {
            network.students.listStudents(params, completed);
        },
    },
}, {
    tableName: 'badges',
    model: models.Badge,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_BADGES,
        saturate(params, completed) {
            network.badges.listBadgesForSchool(params, completed);
        },
    },
}, {
    tableName: 'helpRedirects',
    model: models.HelpRedirect,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_HELP_REDIRECTS,
        saturate(nada, completed) {
            const params = { url: null, body: null, query: null };
            network.help.getRedirects(params, completed);
        },
    },
}, {
    tableName: 'studentAggregates',
    model: models.StudentAggregate,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENT_AGGREGATES,
        saturate(params, completed) {
            network.aggregates.listStudentAggregates(params, completed);
        },
    },
}, {
    tableName: 'studentBadges',
    model: models.StudentBadge,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENT_BADGES,
        saturate(params, completed) {
            network.badges.listStudentBadgesForUser(params, completed);
        },
    },
}, {
    tableName: 'teachers',
    model: models.Teacher,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_TEACHERS,
        saturate(params, completed) {
            network.teachers.listTeachers(params, completed);
        },
    },
}, {
    tableName: 'schoolStaff',
    model: models.SchoolStaff,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_TEACHERS,
        saturate(params, completed) {
            network.teachers.listSchoolStaff(params, completed);
        },
    },
}, {
    tableName: 'markingPeriods',
    model: models.MarkingPeriod,
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_MARKING_PERIODS,
        saturate(params, completed) {
            network.markingPeriods.getForSchoolTerm(params, completed);
        },
    },
}, {
    tableName: 'courseSections',
    model: models.CourseSection,
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_COURSE_SECTIONS,
        saturate(params, completed) {
            network.courseSections.listCourseSections(params, completed);
        },
    },
}, {
    tableName: 'courseSectionTeachers',
    model: models.CourseSectionTeacher,
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_COURSE_SECTION_TEACHERS,
        saturate(params, completed) {
            network.courseSections.listCourseSectionTeachers(params, completed);
        },
    },
}, {
    tableName: 'courseSectionGradeTemplates',
    model: models.CourseSectionGradeTemplate,
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_COURSE_SECTION_GRADE_TEMPLATES,
        saturate(params, completed) {
            network.gradeTemplates.listCourseSectionGradeTemplates(params, completed);
        },
    },
}, {
    tableName: 'studentGroups',
    model: models.StudentGroup,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENT_GROUPS,
        saturate(params, completed) {
            network.studentGroups.listStudentGroups(params, completed);
        },
    },
}, {
    tableName: 'messages',
    model: models.Message,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_MESSAGES,
        saturate(params, completed) {
            network.communication.listMessages(params, completed);
        },
    },
}, {
    tableName: 'communicationGroups',
    model: models.CommunicationGroup,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_COMMUNICATION_GROUPS,
        saturate(params, completed) {
            network.communication.listCommunicationGroups(params, completed);
        },
    },
}, {
    tableName: 'courseSectionStudents',
    model: models.CourseSectionStudent,
    cacheType: 'portfolioCourseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_COURSE_SECTION_STUDENTS,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.courseSectionStudents.getForTeacher(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.courseSectionStudents.getForStudent(params, completed);
            }
            return completed(new Error('Unknown portfolio courseSectionStudents saturation type'));
        },
    },
},
{
    tableName: 'schoolAttendance',
    model: models.SchoolAttendance,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_SCHOOL_ATTENDANCE,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.attendance.getForTeacher(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.attendance.getForStudent(params, completed);
            }
            return completed(new Error('Unknown portfolio schoolAttendance saturation type'));
        },
    },
},
{
    tableName: 'courseSectionAttendance',
    model: models.CourseSectionAttendance,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_COURSE_SECTION_ATTENDANCE,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.courseSectionAttendance.getForTeacher(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.courseSectionAttendance.getForStudent(params, completed);
            }
            return completed(new Error('Unknown portfolio courseSectionAttendance saturation type'));
        },
    },
},
{
    tableName: 'courseWork',
    model: models.CourseWork,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_COURSE_WORK,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.courseWork.getCourseWorkByStaffId(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.courseWork.getCourseWorkByStudentEnrollmentId(params, completed);
            }
            return completed(new Error('Unknown portfolio courseWork saturation type'));
        },
    },
}, {
    tableName: 'courseWorkGrades',
    model: models.CourseWorkGrade,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_COURSE_WORK_GRADES,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.courseWorkGrades.getCourseWorkGradesByStaffId(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.courseWorkGrades.getCourseWorkGradesByStudentEnrollmentId(params, completed);
            }
            return completed(new Error('Unknown portfolio courseWorkGrades saturation type'));
        },
    },
}, {
    tableName: 'markingPeriodStudentAverages',
    model: models.MarkingPeriodStudentAverage,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_MARKING_PERIOD_STUDENT_AVERAGES,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.averages.getAveragesForStaff(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.averages.getAveragesForStudent(params, completed);
            }
            return completed(new Error('Unknown portfolio markingPeriodStudentAverages saturation type'));
        },
    },
}, {
    tableName: 'googleCourseStudents',
    model: models.GoogleCourseStudent,
    cacheType: 'googleCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_COURSE_STUDENTS,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.google.getGoogleCourseStudentsForTeacher(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.google.getGoogleCourseStudentsForStudent(params, completed);
            }
            return completed(new Error('Unknown portfolio googleCourseStudents saturation type'));
        },
    },
}, {
    tableName: 'googleCourseWork',
    model: models.GoogleCourseWork,
    cacheType: 'googleCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_COURSE_WORK,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.googleCourseWork.getCourseWorkForTeacher(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.googleCourseWork.getCourseWorkForStudent(params, completed);
            }
            return completed(new Error('Unknown portfolio googleCourseWork saturation type'));
        },
    },
}, {
    tableName: 'googleStudentSubmissions',
    model: models.GoogleStudentSubmission,
    cacheType: 'googleCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_STUDENT_SUBMISSIONS,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.googleStudentSubmissions.getSubmissionsForTeacher(params, completed);
            }
            if (params.url.studentEnrollmentId) {
                return network.googleStudentSubmissions.getSubmissionsForStudent(params, completed);
            }
            return completed(new Error('Unknown portfolio googleStudentSubmissions saturation type'));
        },
    },
}, {
    tableName: 'studentMessages',
    model: models.Message,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENT_MESSAGES,
        saturate(params, completed) {
            if (params.url.studentEnrollmentId) {
                return network.communication.listStudentMessages(params, (err, res) => {
                    if (err) return completed(err);
                    completed(err, res);
                });
            }
            if (params.url.schoolStaffId) {
                return completed(null, { studentMessages: [] });
            }
            return completed(new Error('Unknown portfolio studentMessages saturation type'));
        },
    },
}, {
    tableName: 'studentBiographical',
    model: models.StudentBiographical,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENT_BIOGRAPHICAL,
        saturate(params, completed) {
            if (params.url.studentEnrollmentId) {
                return network.students.getBiographicsForStudent(params, (err, res) => {
                    if (err) return completed(err);
                    completed(err, res);
                });
            }
            if (params.url.schoolStaffId) {
                return completed(null, { studentBiographical: [] });
            }
            return completed(new Error('Unknown portfolio studentBiographical saturation type'));
        },
    },
}, {
    tableName: 'studentMarkingPeriodGrades',
    model: models.StudentMarkingPeriodGrade,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENT_MARKING_PERIOD_GRADES,
        saturate(params, completed) {
            if (params.url.studentEnrollmentId) {
                return network.reportCard.getStudentMarkingPeriodGradesForStudent(params, completed);
            }
            if (params.url.schoolStaffId) {
                return network.reportCard.getStudentMarkingPeriodGradesForTeacher(params, completed);
            }
            return completed(new Error('Unknown portfolio studentMarkingPeriodGrades saturation type'));
        },
    },
}, {
    tableName: 'studentPdfReports',
    model: models.StudentPdfReport,
    cacheType: 'portfolioCourseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENT_PDF_REPORTS,
        saturate(params, completed) {
            if (params.url.studentEnrollmentId) {
                return network.studentPdfReports.getPdfReportsForStudent(params, completed);
            }
            if (params.url.schoolStaffId) {
                return completed(null, { studentPdfReports: [] });
            }
            return completed(new Error('Unknown portfolio studentPdfReports saturation type'));
        },
    },
}, {
    tableName: 'googleCourseTopics',
    model: models.GoogleCourseTopic,
    cacheType: 'portfolioCourseTeacherTables',
    roles: ['School Admin', 'School Staff', 'Teacher'],
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_COURSE_TOPICS,
        saturate(params, completed) {
            network.google.getTopicsForTeacher(params, completed);
        },
    },
}, {
    tableName: 'googleCourseGradeCategories',
    model: models.GoogleCourseGradeCategory,
    cacheType: 'portfolioCourseTeacherTables',
    roles: ['School Admin', 'Teacher', 'School Staff'],
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_COURSE_GRADE_CATEGORIES,
        saturate(params, completed) {
            if (params.url.schoolStaffId) {
                return network.google.getGoogleCourseGradeCategoriesForTeacher(params, completed);
            }
            completed();
        },
    },
}, {
    // when this table is modified, invalidate the roster cache
    // so non google tables are pulled
    tableName: 'googleCourseCourseSections',
    model: models.GoogleCourseCourseSection,
    cacheType: 'googleBaseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_COURSE_COURSE_SECTIONS,
        saturate(params, completed) {
            network.google.listGoogleCourseCourseSections(params, completed);
        },
    },
}, {
    tableName: 'googleCourses',
    model: models.GoogleCourse,
    cacheType: 'googleBaseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_COURSES,
        saturate(params, completed) {
            network.google.listGoogleCourses(params, completed);
        },
    },
}, {
    tableName: 'googleCourseTeachers',
    model: models.GoogleCourseTeacher,
    cacheType: 'googleBaseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_COURSE_TEACHERS,
        saturate(params, completed) {
            network.google.listGoogleCourseTeachers(params, completed);
        },
    },
}, {
    tableName: 'googleTeachers',
    model: models.GoogleTeacher,
    cacheType: 'googleBaseTables',
    roles: ['School Admin', 'School Staff', 'Teacher'],
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_TEACHERS,
        saturate(params, completed) {
            network.google.listGoogleTeachers(params, completed);
        },
    },
}, {
    tableName: 'googleStudents',
    model: models.GoogleStudent,
    cacheType: 'googleBaseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_GOOGLE_STUDENTS,
        saturate(params, completed) {
            network.google.listGoogleStudents(params, completed);
        },
    },
}, {
    tableName: 'gradeTemplates',
    model: models.GradeTemplate,
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_GRADE_TEMPLATES,
        saturate(params, completed) {
            network.gradeTemplates.listGradeTemplatesForSchool(params, completed);
        },
    },
}, {
    tableName: 'gradeScales',
    model: models.Student,
    roles: ['School Admin'],
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_GRADE_SCALES,
        saturate(params, completed) {
            network.gradeTemplates.listGradeScales(params, completed);
        },
    },
}, {
    tableName: 'users',
    model: models.StaffUser,
    roles: ['School Admin'],
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_USERS,
        saturate(params, completed) {
            network.users.listUsersForSchool(params, completed);
        },
    },
}, {
    tableName: 'anecdotalEventCategories',
    model: models.AnecdotalEventCategory,
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_EVENT_CATEGORY,
        saturate(params, completed) {
            network.anecdotals.listCategories(params, completed);
        },
    },
}, {
    tableName: 'dataSystems',
    model: models.DataSystem,
    roles: ['School Admin', 'School Staff', 'Teacher'],
    cacheType: 'baseTables',
    cacheLocally: true,
    saturation: {
        mutation: Types.mutations.SET_DB_DATA_SYSTEMS,
        saturate(params, completed) {
            network.schools.getDataSystems(params, completed);
        },
    },
}, {
    tableName: 'studentGuardians',
    model: null,
    cacheType: 'baseTables',
    saturation: {
        mutation: Types.mutations.SET_DB_STUDENT_GUARDIANS,
        saturate(params, completed) {
            network.guardians.listStudentGuardians(params, completed);
        },
    },
}, {
    tableName: 'anecdotalEvents',
    model: models.AnecdotalEvent,
}, {
    tableName: 'cache',
    model: models.Cache,
    mutation: Types.mutations.SET_DB_CACHE,
},
];

export { tableDefinitions };
