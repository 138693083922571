/* *
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
* */

const wrapper = require('../wrapper');

/**
 * @param {Object} params - http request parameters
 * @param {Object} params.url
 * @param {String} params.url.userTwoFactorAuthMethodId
 * @param {Function} callback
 */
export default function (params, callback) {
    wrapper.default('initiateChallenge', 'PUT', '/api/cipher/challenge/:userTwoFactorAuthMethodId', params, callback);
}
