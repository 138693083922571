import Types from '../Types';

export default {
    pageParameters(state) {
        return state.navigation.pageParameters || {};
    },
    [Types.getters.IS_SUBHEADER_ENABLED]: (state, getters, rootState) => {
        const { subHeader, subHeaderSections } = rootState.route.meta;
        const { section } = rootState.route.params;
        // console.log(rootState.route);
        // console.log(subHeader, subHeaderSections, section);
        if (subHeader) {
            if (subHeaderSections) {
                return subHeaderSections.includes(section);
            }
            return true;
        }
        return false;
    },
};
