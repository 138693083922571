const SetupWizard = () => import( '../../rootComponents/SetupWizard.vue');

const setupWizardRoutes = [
    {
        name: 'SetupWizard',
        path: '/configuration/wizard',
        title: 'Setup Wizard',
        component: SetupWizard,
        meta: {
            subHeader: true,
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    },
    {
        name: 'SetupWizardRoute',
        path: '/configuration/wizard/:routeName',
        title: 'Setup Wizard',
        component: SetupWizard,
        meta: {
            subHeader: true,
            roles: ['School Admin', 'Staff', 'Teacher'],
        },
    },
];


export default setupWizardRoutes;
