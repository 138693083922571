const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.hasValidToken = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/google/token`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.setCourseState = function (user, googleCourseId, courseState, callback) {
    request
        .put(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}`)
        .set('accept', 'json')
        .send({ courseState })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.updateGoogleCourse = function (user, googleCourseId, course, callback) {
    request
        .put(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}`)
        .set('accept', 'json')
        .send(course)
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshCourses = function (user, courseStatus, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses`)
        .set('accept', 'json')
        // .query({ _r: Date.now(), courseStatus })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshStudentsForCourse = function (user, googleCourseId, callback) {
    // deprecate
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/students`)
        .set('accept', 'json')
        .query({ _r: Date.now() })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshStudentsForBatch = function (user, googleCourseIds, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/students`)
        .send({ googleCourseIds })
        .query({ _r: Date.now() })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshTeachersForCourse = function (user, googleCourseId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/teachers`)
        .set('accept', 'json')
        .query({ _r: Date.now() })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshTeachersForBatch = function (user, googleCourseIds, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/teachers`)
        .send({ googleCourseIds })
        .query({ _r: Date.now() })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshTopicsForCourse = function (user, googleCourseId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/topics`)
        .set('accept', 'json')
        .query({ _r: Date.now() })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshCourseWorkForBatch = function (user, googleCourseIds, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courseWork`)
        .send({ googleCourseIds })
        .query({ _r: Date.now() })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshCourseWorkForCourse = function (user, googleCourseId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/courseWork`)
        .set('accept', 'json')
        .query({ _r: Date.now() })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshStudentSubmissionsForCourse = function (user, googleCourseId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/studentSubmissions`)
        .set('accept', 'json')
        .query({ _r: Date.now() })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.refreshAveragesForCourse = function (user, googleCourseId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/averages`)
        .set('accept', 'json')
        .query({ _r: Date.now() })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.updateGradingCategoriesMappings = function (user, googleCourseId, gradeTemplateCategoryGoogleCategory, callback) {
    request
        .put(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/grading-categories`)
        .set('accept', 'json')
        .send({ gradeTemplateCategoryGoogleCategory })
        .query({ _r: Date.now() })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

// DEPRECATED - JCB
module.exports.refreshStudentSubmissionsForBatch = function (user, googleCourseIds, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/studentSubmissions`)
        .send({ googleCourseIds })
        .set('accept', 'json')
        .query({ _r: Date.now() })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.coursesWithLinkStatus = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/coursesWithLinks`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.coursesWithLinkStatusAndTemplates = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/coursesWithLinksAndTemplates`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.courses = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.coursesByCourseWorkLinkingGuid = function (user, linkingGuid, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses?linkingGuid=${linkingGuid}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.students = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/students`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.teachers = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/teachers`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.userImage = function (user, googleUserId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/${googleUserId}/images/aws`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getQueueRequests = function (user, requestType, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/queueRequests?requestType=${requestType}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getQueueRequestItemCount = function (user, googleQueueRequestId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/queueRequests/${googleQueueRequestId}/count`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getQueueRequestItems = function (user, googleQueueRequestId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/queueRequests/${googleQueueRequestId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.cancelQueueRequest = function (user, googleQueueRequestId, callback) {
    request
        .put(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/queueRequests/${googleQueueRequestId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getStudentAverageBlob = function (user, courseSectionId, studentEnrollmentId, schoolTermMarkingPeriodId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}/students/${studentEnrollmentId}/markingPeriods/${schoolTermMarkingPeriodId}/averages`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.getStudentAverageBlobsForStudent = function (user, studentEnrollmentId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/students/${studentEnrollmentId}/averages`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.registrations = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/registrations`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.addRegistration = function (user, courseSectionId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/registrations`)
        .send({ courseSectionId })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.deleteRegistration = function (user, courseSectionId, callback) {
    request
        .delete(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/registrations`)
        .send({ courseSectionId })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.addToQueue = function (user, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/queue`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.courseWork = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courseWork`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.courseWorkCounts = function (user, googleCourseWorkId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseWorkId}/courseWork/counts`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.courseWorkByLinkingGuid = function (user, linkingGuid, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courseWork/${linkingGuid}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.courseWorkGrades = function (user, googleCourseId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/courseWorkGrades`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.addCourseWork = function (user, googleCourseWork, googleCourseIds, callback) {
    request
        .put(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courseWork`)
        .set('accept', 'json')
        .send({ googleCourseWork, googleCourseIds })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.deleteCourseWork = function (user, googleCourseWorkId, callback) {
    request
        .delete(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courseWork/${googleCourseWorkId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.submissionsForDateRange = function (user, startDate, endDate, includeCreated, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/studentSubmissions`)
        .query({ startDate })
        .query({ endDate })
        .query({ includeCreated })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.googleTeacherByGoogleEmail = function (user, googleEmail, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/teachers/${googleEmail}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.linkTeacher = function (user, googleTeacherId, schoolStaffId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/teachers/${googleTeacherId}`)
        .set('accept', 'json')
        .send({ schoolStaffId })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.googleStudentByGoogleEmail = function (user, googleEmail, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/students/${googleEmail}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.linkStudent = function (user, googleStudentId, studentEnrollmentId, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/students/${googleStudentId}`)
        .set('accept', 'json')
        .send({ studentEnrollmentId })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.linkCourse = function (user, googleCourseId, courseSectionIds, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}`)
        .set('accept', 'json')
        .send({ courseSectionIds })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.deleteLinkedCourse = function (user, courseSectionId, callback) {
    request
        .delete(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.autoLinkCourses = function (user, courseSectionIds, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/autolink`)
        .set('accept', 'json')
        .send({ courseSectionIds })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.deleteLinkForStaffId = function (user, schoolStaffId, callback) {
    request
        .delete(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${schoolStaffId}/google/courses`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.courseInfo = function (user, googleCourseId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.invitationsForGoogleCourse = function (user, googleCourseId, callback) {
    request
        .get(`/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/invitations`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.invitationStats = function (user, callback) {
    request
        .get(`/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/invitations/stats`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.acceptGoogleCourseTeacherInvitations = function (user, callback) {
    request
        .put(`/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/invitations/accept`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
