import configuration from './configuration';
import database from './database';
import gradeTemplates from './gradeTemplates';
import wizard from './wizard';
import navigation from './navigation';
import settings from './settings';
import user from './user';

export default {
    error: null,
    appKey: 1,
    // dataSystems: [],
    user,
    navigation,
    database,
    settings,
    wizard,
    abortController: null,
    forceStudentView: false,
    ignoreWarnings: false,
    portfolioHistory: [],
    deviceType: 'desktop',
    socketEstablished: false,

    window: {
        width: 0,
        height: 0,
    },
    search: {
        searchTerm: '',
        searchSchool: '',
    },
    login: {
        panel: 'EmailChecker',
        error: null,
        errorVariant: null,
        errorIcon: null,
        loading: false,
        redirect: null,
        emailAddress: null,
    },
    ...gradeTemplates,
    communication: {
        createMessage: false,
        createMessageStaff: false,
        createMessageStudent: null,
        createMessageGuardian: null,
        createAnnouncement: false,
        createSchoolAnnouncement: false,
        createCommunicationGroup: false,
        communicationGroup: null,
        popupChat: false,
        messageNotification: true,
    },
    createAnecdotalPanel: {
        open: false,
        students: [],
    },
    addStudentBadgePanel: {
        open: false,
        badgeType: null,
        students: [],
    },
    coursePicker: {
        open: false,
    },
    quickPanel: {
        open: false,
    },
    configuration,
    screenRecord: {
        isRecording: false,
        recorder: null,
        recordingFile: null,
        history: [],
    },
    globalModal: {
        show: null,
    },
    biliteracySeal: {
        schoolYear: 2021,
        schoolYears: [],
        profileHistory: [],
        dataPoints: [{
            name: 'New York City',
            type: 'Big 5',
            id: '0_2021',
            schoolYear: 2021,
        },
        {
            name: 'New York City',
            type: 'Big 5',
            id: '0_2019',
            schoolYear: 2019,
        },
        {
            name: 'West',
            type: 'Regions',
            id: '4_2019',
            schoolYear: 2019,
        },
        {
            name: 'Adirondack CSD',
            type: 'Districts',
            id: '0_2019',
            schoolYear: 2019,
        },
        {
            name: 'Alexander Hamilton HS',
            type: 'Schools',
            id: '6_2019',
            schoolYear: 2019,
        }],
        big5: [],
        regions: [],
        districts: [],
        schools: [],
    },
};
