/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listEmailsForUser from './listEmailsForUser';
import createEmail from './createEmail';
import sendEmailById from './sendEmailById';

export {
    listEmailsForUser,
    createEmail,
    sendEmailById,
 };
