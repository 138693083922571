import moment from 'moment';

// level = info, error, warn
const logger = {
    log(message, level = 'info', category = 'default', duration = '') {
        const timestamp = moment().format('hh:mm:SSa');
        const log = {
            timestamp,
            message,
            level: level.toUpperCase(),
            category,
            duration,
        };
        this.logs.push(log);
        consoleWrite(log);
    },
    print() {
        this.logs.forEach((log) => {
            consoleWrite(log);
        });
    },
    history: [],
    logs: [],
};

function consoleWrite(log) {
    if (log.level == 'ERROR') return consoleError(log);
    if (log.level == 'WARN') return consoleWarn(log);
    consoleLog(log);
}

function consoleError(log) {
    const colors = consoleColors(log);
    const { format1, format2, format3 } = colors;
    const { format4, format5, str } = colors;
    window.console.error(str, format1, format2, format3, format4, format5);
}

function consoleWarn(log) {
    const colors = consoleColors(log);
    const { format1, format2, format3 } = colors;
    const { format4, format5, str } = colors;
    window.console.warn(str, format1, format2, format3, format4, format5);
}

function consoleLog(log) {
    const colors = consoleColors(log);
    const { format1, format2, format3 } = colors;
    const { format4, format5, str } = colors;
    window.console.log(str, format1, format2, format3, format4, format5);
}

function consoleColors(log) {
    const { timestamp, message } = log;
    const { level, category, duration } = log;
    const parts = [
        `%c${timestamp}`,
        `%c${level}`,
        `%c${category}`,
        `%c${message}`,
        `%c${duration}`,
    ];
    const str = parts.join(' ');
    return {
        str,
        format1: 'color: #0f88ef', // timestamp
        format2: getCSSForLevel(level),
        format3: getCSSForCategory(category),
        format4: '', // message
        format5: 'color: #0f88ef', // duration
    };
}

function getCSSForLevel(level) {
    if (level == 'INFO') return 'color: #0abb87; font-weight: 700';
    if (level == 'WARN') return 'color: #ffb822; font-weight: 700';
    return 'color: #fd397a; font-weight: 700';
}

function getCSSForCategory(category) {
    if (category == 'navigation') return 'color: #0f88ef';
    if (category == 'network') return 'color: #ffc107';
    if (category == 'baseTables') return 'color: #5578eb';
    if (category == 'portfolioTables') return 'color: #5578eb';
    if (category == 'googleTables') return 'color: #5578eb';
    if (category == 'TheRouterLayout') return 'color: #20c997';
    if (category == 'calculate') return 'color: #fd7e14';
    return 'color: #6f42c1';
}

export default logger;
