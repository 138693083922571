import Types from '../Types';

export default {
    async startScreenRecord(state, commit, recordVideo, callback) {
        const { screenRecord } = state;
        if (!recordVideo) {
            commit(Types.mutations.SET_IS_SCREEN_RECORDING, true);
            return callback(null);
        }

        try {
            let audioTrack; let videoTrack; let stream;

            navigator.mediaDevices.getDisplayMedia({ video: { displaySurface: 'monitor' } })
                .then(async (displayStream) => {
                    [videoTrack] = displayStream.getVideoTracks();

                    const audioStream = await navigator.mediaDevices
                        .getUserMedia({ audio: true })
                        .catch((e) => { throw e; });

                    [audioTrack] = audioStream.getAudioTracks();
                    stream = new MediaStream([videoTrack, audioTrack]);

                    screenRecord.recorder = new MediaRecorder(stream);

                    const chunks = [];
                    screenRecord.recorder.ondataavailable = (e) => chunks.push(e.data);
                    screenRecord.recorder.onstop = () => {
                    const completeBlob = new Blob(chunks, { type: chunks[0].type });
                        commit(Types.mutations.SET_IS_SCREEN_RECORDING, false);
                        screenRecord.recordingFile = new File([completeBlob], 'SGScreenRecording.webm', { type: completeBlob.type });
                    };

                    screenRecord.recorder.start();
                    commit(Types.mutations.SET_IS_SCREEN_RECORDING, true);
                    callback(null);
                })
                .catch((err) => {
                    commit(Types.mutations.SET_IS_SCREEN_RECORDING, false);
                    callback(err);
                });
        } catch (err) {
            commit(Types.mutations.SET_IS_SCREEN_RECORDING, false);
            callback(err);
        }
    },
    stopScreenRecord(state, commit, callback) {
        const { recorder } = state.screenRecord;
        if (recorder) {
            recorder.stop();
            recorder.stream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        callback();
    },
};
