function CourseSectionAttendance(props) {
	this.studentEnrollmentId = props.studentEnrollmentId;
	this.courseSectionStudentId = props.courseSectionStudentId;
	this.courseSectionId = props.courseSectionId;
	this.attendanceDate = props.attendanceDate;
	this.attendanceState = props.attendanceState;
	this.attendanceComment = props.attendanceComment;
	this.deleted = props.deleted;
}

module.exports = CourseSectionAttendance;
