const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.generateReport = function (user, reportName, payload, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/pdf/${reportName}`)
        .set('accept', 'json')
        .send(payload)
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.progressReportByStudent = function (user, studentEnrollmentId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/student/${studentEnrollmentId}/progressReports`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.progressReportsByUser = function (user, schoolStaffId, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/staff/${schoolStaffId}/progressReports`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.upsertProgressReportComment = function (user, courseSectionId, payload, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/courses/${courseSectionId}/comments`)
        .set('accept', 'json')
        .send(payload)
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.schoolAnnouncements = function (user, callback) {
    request
        .get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/announcements`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.upsertAnnouncements = function (user, payload, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/announcements`)
        .set('accept', 'json')
        .send(payload)
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
