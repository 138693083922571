const request = require('./requestWrapper');

const syncGradesHost = '';

module.exports.getSchoolCourseStatistics = (callback) => {
    request.get(`${syncGradesHost}/api/statistics/school/courses`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.getSchoolStudentStatistics = (callback) => {
    request.get(`${syncGradesHost}/api/statistics/school/students`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.getSchoolInteractionStatistics = (callback) => {
    request.get(`${syncGradesHost}/api/statistics/school/interactions`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.getSchoolDailyAttendanceStatistics = (callback) => {
    request.get(`${syncGradesHost}/api/statistics/school/attendance/daily`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};

module.exports.getSchoolCourseAttendanceStatistics = (callback) => {
    request.get(`${syncGradesHost}/api/statistics/school/attendance/course`).end((err, res) => {
        if (err) return callback(err);
        callback(err, res.body);
    });
};
