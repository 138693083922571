<template>
<a
    v-if="options.toolbar"
    v-b-tooltip.hover="title"
    :href="href"
    target="_blank"
    class="btn btn-clean 'btn-sm' "
    :class="!desktop ? ['btn-icon', 'btn-icon-md'] : 'dropdown-item'"
>
    <img
        style="width: 19px;"
        src="/images/google_classroom_toolbar.svg"
    >
    <span
        v-if="desktop"
        class="pt-2"
        style="color:#93a2dd; font-size: 11px;"
    >
        {{ title }}
    </span>
</a>
<a
    v-else
    type="button"
    :href="href"
    target="_blank"
    :class="variant ? `btn-${variant}` : ''"
    class="btn btn-google btn-wide"
    @mouseenter="options.showHover ? hovering = true : hovering = false"
    @mouseleave="options.showHover ? hovering = false : hovering = false"
>

    <img
        v-if="!hovering"
        class="gc"
        src="/images/google_classroom_mono.svg"
    >
    <img
        v-else
        class="gc"
        src="/images/google_classroom.svg"
    >
    <span v-if="title">
        {{ title }}
    </span>
</a>
</template>

<script>
export default {
    props: {
        desktop: {
            type: Boolean,
            required: false,
            default: false,
        },
        variant: {
            type: String,
            required: false,
            default: '',
        },
        title: {
            type: String,
            required: false,
            default: 'Open In Google',
        },
        href: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            hovering: false,
        };
    },
};
</script>

<style scoped>
img.gc {
    width: 20px;
}

</style>
