const request = require('./requestWrapper');
const { prettyError } = require('../utils');

module.exports.validateEmail = function validateEmail(email, callback) {
    request
        .post('/api/auth/validate')
        .set('accept', 'json')
        .send({ email })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(null, res.body);
        });
};

module.exports.logIn = function logIn(username, password, callback) {
    request
        .post('/api/auth/login')
        .set('accept', 'json')
        .send({ username, password })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(null, res.body);
        });
};

module.exports.passwordGuidDetails = function passwordGuidDetails(guid, callback) {
    request
        .get(`/api/auth/register/user/${guid}`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.savePassword = function savePassword(parameters, callback) {
    request
        .put('/api/auth/register/user/')
        .set('accept', 'json')
        .send(parameters)
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.changePassword = function changePassword(oldPassword, newPassword, callback) {
    request
        .post('/api/auth/password')
        .set('accept', 'json')
        .send({ oldPassword, newPassword })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.userLogin = function userLogin(username, password, callback) {
    request
        .post('/api/auth/login')
        .set('accept', 'json')
        .send({ username, password })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

module.exports.forgotPassword = function forgotPassword(emailAddress, callback) {
    request
        .post('/api/auth/user/forgot/password')
        .set('accept', 'json')
        .send({ emailAddress })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};

// module.exports.ssoLogout = function ssoLogout(callback) {
//     request
//         .get('/api/auth/ssoLogout')
//         .set('accept', 'json')
//         .end((err, res) => {
//             if (err) return callback(prettyError(err, res));
//             callback(err, res.body);
//         });
// };

module.exports.validateJWT = function validateJWT(body, callback) {
    request
        .post('/api/auth/google/signin')
        .set('accept', 'json')
        .send(body)
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
