const request = require('./requestWrapper');

const syncGradesHost = '';

module.exports.getReportCardGradeData = (user, callback) => {
    request.get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/students/studentGrades`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};
