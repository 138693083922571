/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import upsertCourseSectionMetadata from './upsertCourseSectionMetadata';
import upsertCourseSectionCombos from './upsertCourseSectionCombos';
import upsertCourseSectionTotals from './upsertCourseSectionTotals';

export {
    upsertCourseSectionMetadata,
    upsertCourseSectionCombos,
    upsertCourseSectionTotals,
 };
