function AnecdotalEventCategory(props) {
	this.anecdotalEventCategoryId = props.anecdotalEventCategoryId;
	this.anecdotalEventCategoryTitle = props.anecdotalEventCategoryTitle;
	this.categoryColor = props.categoryColor;
	this.categoryIconShortName = props.categoryIconShortName;
	this.categoryIcon = props.categoryIcon;
	this.pointValue = props.pointValue;
	this.defaultGuardianShare = props.defaultGuardianShare;
	this.defaultStudentShare = props.defaultStudentShare;
	this.defaultDescription = props.defaultDescription;
	this.subscribers = props.subscribers;
}

module.exports = AnecdotalEventCategory;