/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import unsubscribeEmail from './unsubscribeEmail';
import changeEmailSubscription from './changeEmailSubscription';
import getSubscribedStatus from './getSubscribedStatus';
import listMessages from './listMessages';
import listStudentMessages from './listStudentMessages';
import getMessageData from './getMessageData';
import listCommunicationGroups from './listCommunicationGroups';
import createMessage from './createMessage';
import createAnnouncement from './createAnnouncement';
import postComment from './postComment';
import setMessageDeleted from './setMessageDeleted';
import setMessageSettings from './setMessageSettings';
import createGroup from './createGroup';
import renameGroup from './renameGroup';
import getMembersForGroup from './getMembersForGroup';
import addGroupMembers from './addGroupMembers';
import leaveGroup from './leaveGroup';
import sendGroupMessage from './sendGroupMessage';
import setReadGroupMessages from './setReadGroupMessages';

export {
    unsubscribeEmail,
    changeEmailSubscription,
    getSubscribedStatus,
    listMessages,
    listStudentMessages,
    getMessageData,
    listCommunicationGroups,
    createMessage,
    createAnnouncement,
    postComment,
    setMessageDeleted,
    setMessageSettings,
    createGroup,
    renameGroup,
    getMembersForGroup,
    addGroupMembers,
    leaveGroup,
    sendGroupMessage,
    setReadGroupMessages,
 };
