

function GradeTemplate(props) {
    this.gradeTemplateId = props.gradeTemplateId;
    this.gradeTemplateName = props.gradeTemplateName;
    this.schoolId = props.schoolId;
    this.userId = props.userId;
    this.created = props.created;
    this.modified = props.modified;
    this.deleted = props.deleted;
    this.averageMethod = props.averageMethod;
    this.allowAllNumerics = props.allowAllNumerics;
    this.schoolDefaultSchoolId = props.schoolDefaultSchoolId;
    this.author = props.author;
    this.properties = props.properties|| {};
    this.scale = props.scale || {};
    this.marks = props.marks || [];
    this.categories = props.categories || [];
    this.maxPoints = props.maxPoints || null;
    this.maxWeight = props.maxWeight || null;
    this.floorAverages = props.floorAverages || null;
    this.schoolScaledDisplayPreference = props.schoolScaledDisplayPreference || 'All';
    this.homeScaledDisplayPreference = props.homeScaledDisplayPreference || 'All';
}

module.exports = GradeTemplate;