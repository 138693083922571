module.exports = [{
    name: 'ELL Population',
    type: 'Link',
    districts: ['DEMO', 'DEMO-Elem'],
}, {
    name: 'ELL Completion',
    type: 'Link',
    districts: ['DEMO', 'DEMO-Elem'],
}, {
    name: 'ELL Performance',
    type: 'Link',
    districts: ['DEMO', 'DEMO-Elem'],
}, {
    name: 'ELL Advancement',
    type: 'Link',
    districts: ['DEMO', 'DEMO-Elem'],
}];
