import Types from '../Types';
import * as network from '../../network';

export default function (payload, commit, state) {
    const { callback, courseSectionGradeTemplates } = payload;
    const { schoolId, schoolTermId } = state.user.school;
    const params = { url: { schoolId, schoolTermId }, body: { courseSectionGradeTemplates } };
    network.courseSections.assignTemplate(params, (err) => {
        if (err) return callback(err);
        courseSectionGradeTemplates.forEach((item) => {
            const { gradeTemplateId, courseSectionId } = item;
            commit(Types.mutations.EDIT_COURSE_SECTION_GRADE_TEMPLATE, { gradeTemplateId, courseSectionId });
        });
        callback();
    });
}
