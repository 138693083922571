import axios from 'axios';
import { store } from '../store';
import Types from '../store/Types';

const slowNetworkIndicator = 1000; // ms

/**
 * @param {String} requestName
 * @param {import("axios").Method} method
 * @param {String} url
 * @param {Object} params
 * @param {Function} callback
 */
export default function makeRequest(requestName, method, url, params, callback) {
    const config = {
        finished: false,
        headers: {
            'X-SG-REQUEST': requestName,
        },
        method,
        url,
        validateStatus(status) {
            return status < 500; // Resolve only if the status code is less than 500
        },
    };

    if (params.abortController) {
        config.signal = params.abortController.signal;
    }

    if (params.url) { // add url parameters
        Object.keys(params.url).forEach((name) => {
            const key = name;
            const value = params.url[name];
            config.url = config.url.replace(`:${key}`, value);
        });
    }

    if (params.query) { // add querystring parameters
        config.params = {};
        Object.keys(params.query).forEach((name) => {
            const key = name;
            const value = params.query[name];
            config.params[key] = value;
        });
    }

    if (params.body) { // add body parameters
        config.data = {};
        Object.keys(params.body).forEach((name) => {
            const key = name;
            const value = params.body[name];
            config.data[key] = value;
        });
    }
    const requestId = `id${Math.random().toString(16).slice(2)}`;
    const startIndicator = `startReq_${requestName}-${requestId}`;
    const endIndicator = `endReq_${requestName}-${requestId}`;
    const performanceIndicator = `req_${requestName}-${requestId}`;

    window.performance.mark(startIndicator);
    axios(config)
        .then((res) => { // 'then' method is executed only when the request is successfull.
            if (config.finished) return;

            const statusCode = res?.status || 500;
            if (statusCode >= 200 && statusCode < 300) {
                config.finished = true;
                window.performance.mark(endIndicator);
                window.performance.measure(performanceIndicator, startIndicator, endIndicator);
                const [item] = performance.getEntriesByName(performanceIndicator);
                window.syncGrades.log(`${requestName}: ${item.duration.toFixed(2)}ms`, item.duration > slowNetworkIndicator ? 'warn' : 'info', 'network', item.duration.toFixed(2));

                return callback(null, res.data);
            }
            if (statusCode == 403 && config.url.indexOf('/google/courses') == -1) {
                // cipher auth needed
                store.commit(Types.mutations.SET_CIPHER_SESSION_LOCKED);
                return;
            }

            config.finished = true;
            handleError(res.data, callback);
        })
        .catch((err) => { // is executed only when the request fails to complete.
            if (config.finished) return;
            if (err) {
                config.finished = true;
                handleError(err, callback);
            }
        });
}

function handleError(body, callback) {
    let err = 'Generic Request Error';

    if (body) {
        if (body?.message == 'canceled') return; // aborted by user

        if (body?.message && body?.code) {
            err = `${body?.code}: ${body?.message}`;
        } else {
            err = body;
        }
        if (body?.response?.data?.message) {
            err = `${body.response.data.code}: ${body.response.data.message}`;
        }
        if (body?.response?.errors) {
            err = `${body?.response?.errors}`;
        }
    }
    if (callback) {
        return callback(err);
    }
}
