import Vue from 'vue';
import VueRouter from 'vue-router';

import { sync } from 'vuex-router-sync';

import { BootstrapVue, IconsPlugin, BCalendar } from 'bootstrap-vue';
import vSelect from 'vue-select';
import VueSlider from 'vue-slider-component';
import VueClipboard from 'vue-clipboard2';
import VueI18n from 'vue-i18n';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import VueProgress from 'vue-progress-path';
import VueMarkdownEditor from '@kangc/v-md-editor';
import githubTheme from '@kangc/v-md-editor/lib/theme/github';
import enUS from '@kangc/v-md-editor/lib/lang/en-US';
import { HotTable, HotColumn } from '@handsontable/vue';
import { registerValidator } from 'handsontable/validators';
import VueVirtualScroller from 'vue-virtual-scroller';
import ToggleButton from 'vue-js-toggle-button';
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;

import 'bootstrap/dist/css/bootstrap.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import 'vue-select/dist/vue-select.css';
import 'vue-slider-component/theme/default.css';
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'; // js bundle
import 'vue-progress-path/dist/vue-progress-path.css';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import '@kangc/v-md-editor/lib/style/base-editor.css';

import 'swiper/swiper-bundle.css';

// import function to register Swiper custom elements

// import Handsontable from 'handsontable'; // Dont erase
import 'handsontable/dist/handsontable.full.css';

import VueEllipseProgress from 'vue-ellipse-progress';

import {
    registerCellType,
    NumericCellType,
    DropdownCellType,
    AutocompleteCellType,
    DateCellType,
    TimeCellType,
    CheckboxCellType,
} from 'handsontable/cellTypes';

import {
    AutoColumnSize,
    Autofill,
    UndoRedo,
    ColumnSorting,
    Comments,
    ContextMenu,
    ExportFile,
    registerPlugin,
    ManualColumnResize,
    ManualColumnMove,
    HiddenColumns,
    HiddenRows,
} from 'handsontable/plugins';

import hljs from 'highlight.js';
import json from 'highlight.js/lib/languages/json';
import i18n from './lib/i18n';
import logger from './lib/logger';
import router from './router';
import { store } from './store';
import SyncGrades from './SyncGrades.vue';
import SVGIcon from './components/SVGIcon/SVGIcon.vue';
import GoogleButton from './components/GoogleButton.vue';
import globalMixins from './store/mixins/globalMixins';

// register Swiper custom elements

registerCellType(NumericCellType);
registerCellType(DropdownCellType);
registerCellType(DateCellType);
registerCellType(TimeCellType);
registerCellType(AutocompleteCellType);
registerCellType(CheckboxCellType);

registerPlugin(AutoColumnSize);
registerPlugin(ManualColumnResize);
registerPlugin(ManualColumnMove);
registerPlugin(ColumnSorting);
registerPlugin(Comments);
registerPlugin(ContextMenu);
registerPlugin(ExportFile);
registerPlugin(Autofill);
registerPlugin(UndoRedo);
registerPlugin(HiddenColumns);
registerPlugin(HiddenRows);

// import { registerAllModules } from 'handsontable/registry';
// registerAllModules();

registerValidator('sg.attendance', (val, callback) => {
    if (!val) return callback(true);
    const isValid = ['', 'Present', 'Absent', 'Late', 'Excused'].includes(val);
    callback(isValid);
});

// registerRenderer('cell-green', (hotInstance, TD, ...rest) => {
//     textRenderer(hotInstance, TD, ...rest);
//     const element = TD;
//     element.style.fontWeight = 'bold';
//     element.style.color = 'green';
//     element.style.background = '#d7f1e1';
// });

sync(store, router);

hljs.registerLanguage('json', json);
VueMarkdownEditor.lang.use('en-US', enUS);
VueMarkdownEditor.use(githubTheme, {
    Hljs: hljs,
    codeHighlightExtensionMap: {
        vue: 'xml',
    },
});

/*
this directive adds a fallback to when an image doesn't load
so if oakleys image fails, a html badge of [CO] is shown.
*/
Vue.directive('image-loaded', {
    inserted(el, b) {
        const binding = b;
        // @ts-ignore
        if (el.complete) {
            binding.value.loaded = true;
        }
    },
});

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueClipboard);
Vue.use(PerfectScrollbar);
Vue.use(VueProgress, {});
Vue.use(VueMarkdownEditor);
Vue.use(VueI18n);
Vue.use(VueRouter);

Vue.use(VueVirtualScroller);
Vue.use(VueEllipseProgress);
Vue.use(ToggleButton);

/* register these as global components */
Vue.component('GoogleButton', GoogleButton);
Vue.component('SVGIcon', SVGIcon);
Vue.component('VSelect', vSelect);
Vue.component('VSlider', VueSlider);
Vue.component('BCalendar', BCalendar);
Vue.component('HotTable', HotTable);
Vue.component('HotColumn', HotColumn);
Vue.component('RecycleScroller', VueVirtualScroller.RecycleScroller);
Vue.component('DynamicScroller', VueVirtualScroller.DynamicScroller);
Vue.component('DynamicScrollerItem', VueVirtualScroller.DynamicScrollerItem);

Vue.mixin(globalMixins);

Vue.config.productionTip = false;
Vue.config.performance = Boolean(process.env.NODE_ENV !== 'production');

window.syncGrades = logger;

Vue.prototype.sgWebSocket = null;

new Vue({
    router,
    // @ts-ignore
    store,
    i18n,
    render: (h) => h(SyncGrades),
}).$mount('#app');
