export default {
    // hideWizard: localStorage.getItem('hideWizard') || '',
    hide: localStorage.getItem('hideWizard') || '',
    selectedIndex: 0,

    bypassWizardGoogleDomain: localStorage.getItem('bypassWizardGoogleDomain') || '',
    bypassWizardLinkGoogleAccount: localStorage.getItem('bypassWizardLinkGoogleAccount') || '',
    bypassWizardGoogleArchive: localStorage.getItem('bypassWizardGoogleArchive') || '',
    bypassWizardDataUploads: localStorage.getItem('bypassWizardDataUploads') || '',
    bypassWizardEncryption: localStorage.getItem('bypassWizardEncryption') || '',
    bypassWizardBadges: localStorage.getItem('bypassWizardBadges') || '',
    bypassWizardCommunication: localStorage.getItem('bypassWizardCommunication') || '',
    bypassWizardUserLinking: localStorage.getItem('bypassWizardUserLinking') || '',
    bypassWizardSchoolTerm: localStorage.getItem('bypassWizardSchoolTerm') || '',

    bypassWizardGradingEnabled: localStorage.getItem('bypassWizardGradingEnabled') || '',
    bypassWizardReportCardsEnabled: localStorage.getItem('bypassWizardReportCardsEnabled') || '',

    steps: [{
        routeName: 'SetupDataUploads',
        localStorageName: 'bypassWizardDataUploads',
        componentName: 'UploadRosters',
        title: 'Upload Data Files',
        showSubHeader: false,
        isToggle: false,
        visible: true,
        enabled: true,
        status: 'danger',
        value: null,
        message: null,
    }, {
        routeName: 'setup-grading',
        localStorageName: 'bypassWizardGradingEnabled',
        componentName: 'GradeTemplateEditCreate',
        title: 'Setup Grading',
        showSubHeader: true,
        isToggle: true,
        visible: true,
        enabled: true,
        status: 'danger',
        value: null,
        message: null,
    }, {
        routeName: 'setup-google-classroom',
        localStorageName: 'bypassWizardGoogleDomain',
        componentName: 'SetupGoogleDomain',
        title: 'Setup Google Classroom',
        showSubHeader: false,
        isToggle: true,
        visible: true,
        enabled: true,
        status: 'danger',
        value: '',
        message: null,
    }, {
        routeName: 'setup-communication',
        localStorageName: 'bypassWizardCommunication',
        componentName: 'CommunicationSetup',
        title: 'Setup Communication',
        showSubHeader: false,
        isToggle: true,
        visible: true,
        enabled: true,
        status: 'danger',
        value: null,
        message: null,
    },
    {
        routeName: 'setup-anecdotals',
        localStorageName: 'bypassWizardBadges',
        componentName: 'BadgeSetupWizard',
        title: 'Setup Badges',
        showSubHeader: false,
        isToggle: true,
        visible: true,
        enabled: true,
        status: 'danger',
        value: null,
        message: null,
    }, {
        routeName: 'setup-encryption',
        localStorageName: 'bypassWizardEncryption',
        componentName: 'DataEncryptionSetup',
        title: 'Data Encryption',
        isToggle: true,
        showSubHeader: false,
        visible: false,
        enabled: true,
        status: 'danger',
        value: null,
        message: null,
    }, {
        routeName: 'GoogleAccount',
        localStorageName: 'bypassWizardLinkGoogleAccount',
        componentName: 'GoogleAccount',
        title: 'Link Google Account',
        isToggle: false,
        showSubHeader: false,
        visible: true,
        enabled: false,
        status: 'danger',
        value: null,
        message: null,
    }, {
        routeName: 'SetupGoogleArchive',
        localStorageName: 'bypassWizardGoogleArchive',
        componentName: 'GoogleCourseManager',
        title: 'Archive Google Courses',
        showSubHeader: true,
        isToggle: false,
        visible: true,
        enabled: false,
        status: 'danger',
        value: null,
        message: null,
    }, {
        routeName: 'SetupUserLinking',
        localStorageName: 'bypassWizardUserLinking',
        componentName: 'GoogleUserLink',
        title: 'User Linking',
        showSubHeader: true,
        isToggle: false,
        visible: true,
        enabled: false,
        status: 'danger',
        value: null,
        message: null,
    }, {
        routeName: 'SetupSchoolTerm',
        localStorageName: 'bypassWizardSchoolTerm',
        componentName: 'SchoolTermSettings',
        title: 'Finalize school term',
        isToggle: false,
        showSubHeader: false,
        visible: true,
        enabled: true,
        status: 'danger',
        value: null,
        message: null,
    }],
};

