import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadAdminTransactions(commit, state) {
    if (!state.user.isSuperAdmin) return;

    network.admin.listTransactions({}, (err, res) => {
        if (err) return commit(Types.mutations.SET_ERROR, err);
        const { transactions } = res;
        commit(Types.mutations.SET_DB_ADMIN_TRANSACTIONS, transactions);
    });
}
