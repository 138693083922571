function GoogleCourseWork(props) {
    this.courseWorkDomain = 'Google';
    this.alternateLink = props.alternateLink;
    this.assigneeMode = props.assigneeMode;
    this.associatedWithDeveloper = props.associatedWithDeveloper;
    this.courseWorkDescription = props.courseWorkDescription;
    this.courseWorkState = props.courseWorkState;
    this.courseWorkTitle = props.courseWorkTitle;
    this.courseWorkType = props.courseWorkType;
    this.creationTime = props.creationTime;
    this.dueDate = props.dueDate;
    this.extCourseWorkId = props.extCourseWorkId;
    this.googleCourseId = props.googleCourseId;
    this.googleCourseWorkId = props.googleCourseWorkId;
    this.maxPoints = props.maxPoints;
    this.linkingGuid = props.linkingGuid;

    this.publishDate = props.publishDate;
    this.numericPublishDate = props.numericPublishDate;
    this.sortDate = props.sortDate;
    this.numericSortDate = props.numericSortDate;

    this.schoolTermId = props.schoolTermId;
    this.courseSectionId = props.courseSectionId;
    this.categoryName = props.categoryName;
    this.gradeCategoryId = props.gradeCategoryId;
    this.googleCourseGradeCategoryId = props?.googleCourseGradeCategoryId || null;
    this.googleCourseGradeCategoryName = props?.googleCourseGradeCategoryName || null;
    this.googleCourseTopicId = props?.googleCourseTopicId || null;
    this.googleCourseTopicName = props?.googleCourseTopicName || null;
    this.color = props.color;
    this.gradeTemplateCategoryId = props.gradeTemplateCategoryId;
    this.gradeTemplateId = props.gradeTemplateId;
    this.averageExclusiveForMp = props.averageExclusiveForMp;
    this.courseWorkWeight = props.courseWorkWeight;
    this.schoolTermMarkingPeriodId = props.schoolTermMarkingPeriodId;

    this.teacherExtCourseWorkId = props.teacherExtCourseWorkId || null;
    this.hideMarks = props.hideMarks || false;
    this.excluded = props.excluded || false;

    this.deleted = props.deleted;
}

module.exports = GoogleCourseWork;
