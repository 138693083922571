export default {
    loading: false,
    showAside: false,
    showMobileMenu: false,
    showMobileTopBar: false,
    showSearchBar: false,
    showLocales: false,
    showLinkingMenu: false,
    showUserBar: false,
    showSuperMenuButton: false,
    hideAllNavigation: false,
    closePortfolioMenu: false,
    pageParameters: {},
};
