module.exports = {
    template: 'Plantilla',
    scale: 'Escala',
    averageMethod: 'Método promedio',
    gradeScales: 'Escalas de calificaciones',
    type: 'Escribe',
    locked: 'Bloqueada',
    excusedGrade: 'Calificación justificada',
    gradingCategories: 'Categorías de calificación',
    dropLowest: 'Gota más baja',
};
