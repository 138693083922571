export default {
    setSearchTerm(s, searchTerm) {
        const state = s;
        state.search.searchTerm = searchTerm;
    },
    setSearchSchool(s, searchSchool) {
        const state = s;
        state.search.searchSchool = searchSchool;
    },
};
