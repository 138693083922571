import moment from 'moment';
import * as net from '../../network';
import activities from './activities';
import assignments from './assignments';
import gradeTemplates from './gradeTemplates';
import queueRequests from './queueRequests';
import database from './database';
import locale from './locale';
import setupWizard from './setupWizard';

import * as etl from './etl/index';
import * as cipher from './cipher';
import * as google from './google';
import editCourseTemplateAssignment from './editCourseTemplateAssignment';
import * as anecdotals from './anecdotals';
import screenRecord from './screenRecord';

import Types from '../Types';
import bulkEditCourseWorkCategory from './bulkEditCourseWorkCategory';
import bulkEditCourseWorkState from './bulkEditCourseWorkState';
import { saveAttendance } from './attendance';
import { saveCourseWorkGrades } from './courseWorkGrades';
import { createDataSet } from './studentDataView';

import { getGoogleCourses, GoogleCourseFilter } from '../mixins/googleCourseMixins';
import {
    addGoogleCourseWork, deleteGoogleCourseWork, editGoogleCourseWork, editGoogleCourseWorkProperties,
} from './googleCourseWork';

import { addLocalCourseWork, editLocalCourseWork } from './courseWork';
// import { getCourses, CourseFilter } from '../mixins/courseMixins';

const legacyActions = {
    refreshSession({ commit }) {
        commit('setLoading', true);
        net.session.refreshSession({}, (err, user) => {
            commit('setLoading', false);
            if (err) return commit(Types.mutations.HANDLE_ERROR, 'Session expired');
            commit(Types.mutations.SET_USER_PROFILE, user);
            // commit('setUserProfile', user);
        });
    },
    startSpinner({ commit }) {
        commit('setLoading', true);
    },
    stopSpinner({ commit }) {
        commit('setLoading', false);
    },
    ...setupWizard,
    ...activities,
    ...assignments,
    ...gradeTemplates,
    ...queueRequests,
    ...locale,
    ...database,
};

const actions = {
    [Types.actions.IMPERSONATE_PRIMARY]({ commit, state }, school) {
        const userId = school.contactUserId;
        if (!userId) {
            commit(Types.mutations.SET_ERROR, 'Cant impersonate, invalid primary contact');
            return;
        }

        const currentTerm = school.schoolTerms.find((t) => t.current) || null;
        if (!currentTerm) {
            commit(Types.mutations.SET_ERROR, 'Cant impersonate, cannot find current term');
            return;
        }
        const { schoolTermId } = currentTerm;
        const keys = Object.keys(state.wizard);
        keys.forEach(function(key) {
            if (key.startsWith('bypassWizard')) {
                localStorage.removeItem(key);
            }
        });
        net.users.impersonate({ body: { userId, schoolTermId } }, (err) => {
            if (err) return commit(Types.mutations.SET_ERROR, err);
            window.location.href = '/home';
        });
    },
    [Types.actions.IMPERSONATE]({ commit, state }, user) {
        const { href = '/home', userId, schoolTermId } = user;

        const keys = Object.keys(state.wizard);
        keys.forEach(function(key) {
            if (key.startsWith('bypassWizard')) {
                localStorage.removeItem(key);
            }
        });
        net.users.impersonate({ body: { userId, schoolTermId } }, (err) => {
            if (err) return commit(Types.mutations.HANDLE_ERROR, 'Could not impersonate');
            window.location.href = href;
        });
    },
    [Types.actions.POPULATE_REPORT_CARDS]({ commit, state }, payload) {
        const { teacher } = payload;
        const { callback } = payload;
        etl.populateReportCardData(commit, state, teacher, callback);
    },
    [Types.actions.POPULATE_PORTFOLIO]({ commit, state, dispatch }, payload) {
        etl.populatePortfolioData(commit, state, payload, dispatch);
    },
    [Types.actions.START_DB_UPDATER]({ state, commit }, callback) {
        etl.populateBaseTables(commit, state, callback);
    },
    [Types.actions.REFRESH_GOOGLE_BASE_TABLES]({ commit, state }, callback) {
        google.updateGoogleTables(commit, state, callback);
    },
    [Types.actions.REFRESH_GOOGLE_COURSES_FOR_TEACHER]({ commit, state }, callback) {
        const { schoolEmail } = state.route.params;
        if (!schoolEmail) return callback('There is no schoolEmail in route params');
        const teacher = state.database.teachers.find((t) => t.schoolEmail == schoolEmail);
        if (!teacher) return callback('There is no teacher in route params');
        const { schoolStaffId } = teacher;
        const googleCourseIds = getGoogleCourses(
            state.database, new GoogleCourseFilter({ schoolStaffId }),
        ).map((c) => c.googleCourseId);

        if (googleCourseIds.length == 0) return;

        commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key: 'googleBaseTables' });
        commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key: 'googleCourseTables' });

        google.syncCourses(googleCourseIds, commit, state, callback);
    },
    [Types.actions.REFRESH_GOOGLE_COURSE]({ commit, state }, callback) {
        const { schoolEmail, extCourseSectionId } = state.route.params;
        if (!schoolEmail) return callback('There is no schoolEmail in route params');
        const teacher = state.database.teachers.find((t) => t.schoolEmail == schoolEmail);
        if (!teacher) return callback('There is no teacher in route params');
        // const { schoolStaffId } = teacher;

        if (!extCourseSectionId) return callback('There is no extCourseSectionId in route params');
        const [googleCourse] = getGoogleCourses(state.database, new GoogleCourseFilter({ extCourseSectionId }));

        commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key: 'googleBaseTables' });
        commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key: 'googleCourseTables' });

        google.syncCourses([googleCourse.googleCourseId], commit, state, callback);
    },
    [Types.actions.REFRESH_GOOGLE_COURSES_BY_STAFF_ID]({ commit, state }, { schoolStaffId, callback }) {
        const teacher = state.database.teachers.find((t) => t.schoolStaffId == schoolStaffId);
        if (!teacher) return callback(`There is no teacher found for schoolStaffId ${schoolStaffId}`);

        const googleCourseIds = getGoogleCourses(
            state.database, new GoogleCourseFilter({ schoolStaffId }),
        ).map((c) => c.googleCourseId);

        if (googleCourseIds.length == 0) {
            return callback(new Error(`No google courses found for this schoolStaffId ${schoolStaffId}`));
        }

        commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key: 'googleBaseTables' });
        commit(Types.mutations.REMOVE_ITEM_FROM_CACHE, { key: 'googleCourseTables' });

        google.syncCourses(googleCourseIds, commit, state, callback);
    },
    [Types.actions.REFRESH_TABLE_BY_NAME]({ state, commit }, { tableName, callback }) {
        etl.saturateTableByName(commit, state, tableName, callback);
    },
    [Types.actions.REFRESH_GOOGLE_TABLES]({ state, commit }, callback) {
        etl.populateGoogleTables(commit, state, callback);
    },
    [Types.actions.REFRESH_GOOGLE_COURSE_TEACHERS]({ commit, state, getters }) {
        // etl.reloadGoogleCourseTeachers(commit, state, getters);
    },
    [Types.actions.REFRESH_GOOGLE_COURSE_COURSE_SECTIONS]({ commit, state, getters }) {
        etl.reloadGoogleCourseCourseSections(commit, state, getters);
    },
    [Types.actions.REFRESH_TEACHERS]({ commit, state, getters }) {
        etl.reloadTeachers(commit, state, getters);
    },
    [Types.actions.REFRESH_GOOGLE_COURSES]({ commit, state, getters }) {
        etl.reloadGoogleCourses(commit, state, getters);
    },
    [Types.actions.REFRESH_STUDENTS]({ commit, state, getters }) {
        etl.reloadStudents(commit, state, getters);
    },
    [Types.actions.RELOAD_GRADE_TEMPLATES]({ commit, state, getters }) {
        etl.reloadGradeTemplates(commit, state, getters);
    },
    [Types.actions.REFRESH_GOOGLE_COURSE_STUDENTS]({ commit, state, getters }) {
        etl.reloadGoogleCourseStudents(commit, state, getters);
    },
    [Types.actions.REFRESH_COURSE_SECTIONS]({ commit, state }, callback) {
        etl.reloadCourseSections(commit, state, callback);
    },
    [Types.actions.REFRESH_ADMIN_TRANSACTIONS]({ commit, state, getters }) {
        etl.reloadtransactions(commit, state, getters);
    },
    [Types.actions.REFRESH_ADMIN_SCHOOLS]({ commit, state, getters }) {
        etl.reloadschools(commit, state, getters);
    },
    [Types.actions.REFRESH_ADMIN_SCHOOL_TERMS]({ commit, state, getters }) {
        etl.reloadschoolTerms(commit, state, getters);
    },
    [Types.actions.REFRESH_DATA_SYSTEMS]({ commit, state }, callback) {
        etl.reloadDataSystems(commit, state, callback);
    },
    [Types.actions.REFRESH_MARKING_PERIODS]({ commit, state }, callback) {
        etl.reloadMarkingPeriods(commit, state, callback);
    },
    [Types.actions.EDIT_ANECDOTAL]({ commit, state, dispatch }, { anecdotalEvent, callback }) {
        anecdotals.edit(anecdotalEvent, commit, state, dispatch, callback);
    },
    [Types.actions.RESOLVE_ANECDOTAL_EVENT]({ commit, state }, { anecdotalEvent, callback }) {
        const resolved = true;
        anecdotals.resolve(anecdotalEvent, resolved, commit, state, callback);
    },
    [Types.actions.UNRESOLVE_ANECDOTAL_EVENT]({ commit, state }, { anecdotalEvent, callback }) {
        const resolved = false;
        anecdotals.resolve(anecdotalEvent, resolved, commit, state, callback);
    },
    [Types.actions.CREATE_STUDENT_NOTIFICATION]({ commit, state, rootState }, { studentNotification, callback }) {
        createNotification(studentNotification, commit, state, rootState, callback);
    },
    [Types.actions.UPDATE_READ_STATE]({ commit, state }, { studentNotificationId, studentEnrollmentId, callback }) {
        markAsRead(studentNotificationId, studentEnrollmentId, commit, state, callback);
    },
    [Types.actions.CREATE_ANECDOTAL]({ commit, state, dispatch }, { anecdotalEvent, callback }) {
        anecdotals.create(anecdotalEvent, commit, state, dispatch, callback);
    },
    [Types.actions.REFRESH_ANECDOTAL_EVENT_CATEGORIES]({ commit, state, getters }) {
        etl.reloadAnecdotalEventCategories(commit, state, getters);
    },
    [Types.actions.GET_ANECDOTAL_EVENT_BY_ID]({ commit, state, getters }, { anecdotalEventId }) {
        etl.addAnecdotalEventById(anecdotalEventId, commit, state, getters);
    },
    [Types.actions.REFRESH_ANECDOTAL_EVENTS]({ commit, state, rootState }) {
        etl.reloadAnecdotalEvents(commit, state, rootState);
    },
    [Types.actions.CREATE_AND_LINK_COURSE_SECTION_TO_GOOGLE]({ commit, state }, payload) {
        google.createCourse(payload, commit, state);
    },
    [Types.actions.LINK_COURSE_SECTION_TO_GOOGLE]({ commit, state, dispatch }, { googleCourseId, courseSectionId, callback }) {
        google.linkCourse({ googleCourseId, courseSectionId, callback }, commit, state);
    },
    // this pulls google teachers for a google course
    [Types.actions.GOOGLE_REFRESH_TEACHERS_FOR_COURSE]({ commit, state, dispatch }, googleCourseId) {
        google.updateTeachersForCourse(googleCourseId, commit, state, dispatch);
    },
    [Types.actions.BULK_EDIT_TEACHER_ASSIGNMENT_STATE]({ commit, state, getters }, payload) {
        bulkEditCourseWorkState(payload, commit, state, getters);
    },
    [Types.actions.BULK_EDIT_TEACHER_ASSIGNMENTS]({ commit, state, getters }, payload) {
        bulkEditCourseWorkCategory(payload, commit, state, getters);
    },
    [Types.actions.EDIT_COURSE_TEMPLATE_ASSIGNMENTS]({ commit, state }, payload) {
        editCourseTemplateAssignment(payload, commit, state);
    },
    [Types.actions.DECRYPT_SCHOOL_TERM_ENCRYPTION_KEY]({ state, commit }, { callback }) {
        cipher.decryptSchoolTermEncryptionKey(state.user.school, callback);
    },
    [Types.actions.SET_CIPHER_CREDENTIALS]({ state, commit }, { schoolPassword, callback }) {
        cipher.saveCipherCredentials(state, commit, schoolPassword, callback);
    },
    [Types.actions.ENABLE_SERVER_SIDE_ENCRYPTION]({ commit, state }, { schoolPassword, callback }) {
        cipher.enableEncryption(state, commit, schoolPassword, callback);
    },
    [Types.actions.DISABLE_SERVER_SIDE_ENCRYPTION]({ commit, state }, { callback }) {
        cipher.disableEncryption(state, commit, callback);
    },
    [Types.actions.SAVE_TWO_FACTOR_AUTH_SETTINGS]({ state, commit }, { twoFactorAuthMethod, callback }) {
        cipher.saveTwoFactorSettings(state, commit, twoFactorAuthMethod, callback);
    },
    [Types.actions.REMOVE_TWO_FACTOR_AUTH_METHOD]({ commit, state }, { userTwoFactorAuthMethodId, callback }) {
        cipher.removeAuthMethod(state, commit, userTwoFactorAuthMethodId, callback);
    },
    [Types.actions.START_SCREEN_RECORD]({ state, commit }, { recordVideo, callback }) {
        screenRecord.startScreenRecord(state, commit, recordVideo, callback);
    },
    [Types.actions.STOP_SCREEN_RECORD]({ state, commit }, callback) {
        screenRecord.stopScreenRecord(state, commit, callback);
    },
    [Types.actions.EDIT_LOCAL_COURSE_WORK]({ commit, state }, params) {
        editLocalCourseWork(params, commit, state);
    },
    [Types.actions.ADD_LOCAL_COURSE_WORK]({ commit, state }, params) {
        addLocalCourseWork(params, commit, state);
    },
    [Types.actions.EDIT_GOOGLE_COURSE_WORK_PROPERTIES]({ commit, state }, params) {
        editGoogleCourseWorkProperties(params, commit, state);
    },
    [Types.actions.EDIT_GOOGLE_COURSE_WORK]({ commit, state }, params) {
        editGoogleCourseWork(params, commit, state);
    },
    [Types.actions.ADD_GOOGLE_COURSE_WORK]({ commit, state }, params) {
        addGoogleCourseWork(params, commit, state);
    },
    [Types.actions.DELETE_GOOGLE_COURSE_WORK]({ commit, state }, params) {
        deleteGoogleCourseWork(params, commit, state);
    },
    [Types.actions.UPSERT_GRADE_TEMPLATE_CATEGORY_GOOGLE_CATEGORY]({ commit, state, getters }, params) {
        google.updateGoogleGradeCategories(params, commit, state, getters);
    },
    [Types.actions.REFRESH_GOOGLE_COURSE_GRADE_CATEGORIES]({ commit, state }, schoolStaffId) {
        etl.reloadGoogleCourseGradeCategories(commit, state, schoolStaffId);
    },
    [Types.actions.REFRESH_GOOGLE_COURSE_TOPICS]({ commit, state }, googleCourseId) {
        etl.reloadGoogleCourseTopics(commit, state, googleCourseId);
    },
    [Types.actions.SAVE_COURSE_SECTION_ATTENDANCE]({ commit, state }, { params, callback }) {
        saveAttendance(commit, state, params, callback);
    },
    [Types.actions.SAVE_COURSE_WORK_GRADES]({ commit, state }, { courseWorkGrades, callback }) {
        saveCourseWorkGrades(commit, state, courseWorkGrades, callback);
    },
    [Types.actions.POPULATE_STUDENT_DATA_VIEW]({ commit, state }, { students, columns, columnSortIndex, callback }) {
        const rows = createDataSet(state, students, columns, columnSortIndex);

        // paginate the rows
        const { perPage, currentPage } = state.database.studentDataView;
        const totalRows = rows.length;
        let slicedPage = currentPage;
        if (currentPage < 1 || currentPage > Math.ceil(rows.length / perPage)) {
            slicedPage = 1;
        }
        const output = rows.slice(
            (slicedPage - 1) * perPage,
            slicedPage * perPage,
        );

        // add the header row
        const header = columns.map((c) => {
            const column = { ...c };
            return {
                column,
                cellValue: column.title,
                rowPosition: 0,
                rowHidden: false,
            };
        });
        output.unshift(header);

        commit(Types.mutations.SET_STUDENT_DATA_VIEW_TOTAL_ROWS, totalRows);
        commit(Types.mutations.SET_STUDENT_DATA_VIEW_COLUMNS, columns);
        commit(Types.mutations.SET_STUDENT_DATA_VIEW_ROWS, output);
        setTimeout(() => {
            callback();
        }, 100);
    },
    [Types.actions.GET_ATTENDANCE_FOR_STUDENT]({ commit, state }, { studentEnrollmentId, callback }) {
        const { schoolId, schoolTermId } = state.user.school;
        const { courseSectionAttendance, schoolAttendance } = state.database;

        net.schoolDayCalendar.listSchoolDayCalendars({ url: { schoolTermId, schoolId } }, (err, resp) => {
            if (err) return callback(err);
            const { schoolDayCalendar } = resp;
            const schoolDays = Object.keys(schoolDayCalendar);
            const formattedAttendance = {};
            schoolDays.forEach((day) => {
                const date = moment(day, 'YYYY-MM-DD');
                if (date.isAfter(moment())) return;

                const schoolDay = schoolDayCalendar[day];
                const attendance = schoolAttendance
                    .find((sa) => sa.attendanceDate == day && sa.studentEnrollmentId == studentEnrollmentId) || null;

                const courseAttendance = courseSectionAttendance
                    .filter((ca) => ca.attendanceDate == day && ca.studentEnrollmentId == studentEnrollmentId);

                formattedAttendance[day] = {
                    schoolDate: day,
                    nonSchoolDay: schoolDay.nonSchoolDay,
                    courseAttendance,
                    schoolAttendance: {
                        attendanceState: attendance ? attendance.attendanceState : null,
                        attendanceComment: attendance ? attendance.attendanceComment : null,
                    },
                };
            });
            commit(Types.mutations.SET_ATTENDANCE_FOR_STUDENT, formattedAttendance);
            callback();
        });
    },
};

const output = {
    ...actions,
    ...legacyActions,
};

export default output;
