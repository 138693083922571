function StudentBiographical(props) {
    this.studentBiographicalId = props.studentBiographicalId;
    this.studentId = props.studentId;
    this.bioTitle = props.bioTitle;
    this.bioValue = props.bioValue;
    this.bioCategory = props.bioCategory;
    this.schoolYear = props.schoolYear;
    this.isEncrypted = props.isEncrypted;
    this.encryptedData = props.encryptedData;
}

module.exports = StudentBiographical;
