import async from 'async';
import humps from 'humps';
import { tableDefinitions } from '../../database';

const logCategory = 'googleTables';
const slowTableIndicator = 10; // ms

/**
 * @description This routine updates tables database.google (non portfolio specific).
 * @param {Function} commit
 * @param {Object} state
 * @param {Function} callback
 */
export default updateLocalGoogleTables;

function updateLocalGoogleTables(commit, state, callback) {
    const { schoolId, schoolTermId } = state.user.school;
    const { role, schoolStaffId } = state.user.school;

    const params = { url: { schoolId, schoolTermId, schoolStaffId } };

    const items = tableDefinitions.filter((t) => {
        if (!t.saturation) return false;
        if (t.cacheType != 'googleBaseTables' && t.cacheType != 'googleCourseTables') return false;
        if (t.roles && !t.roles.includes(role)) return false;
        return true;
    }).map((t) => {
        // remove pointer to original object def
        const table = { ...t };
        return table;
    });
    async.eachSeries(items, (table, next) => {
        // see frontend/src/store/database.js for saturation routines for each table
        table.saturation.saturate(params, (err, res) => {
            if (err) return next(err);
            // apply to state
            const { tableName } = table;
            const records = res[tableName];
            window.performance.mark(`startDataLoading_${tableName}`);
            commit(`SET_DB_${humps.decamelize(tableName).toUpperCase()}`, records);
            window.performance.mark(`endDataLoading_${tableName}`);
            window.performance.measure(`${tableName}_dataLoad`, `startDataLoading_${tableName}`, `endDataLoading_${tableName}`);
            const [item] = performance.getEntriesByName(`${tableName}_dataLoad`);
            const message = `${tableName}_dataLoad: ${item.duration.toFixed(2)}ms, ${records.length} items`;
            window.syncGrades.log(message, item.duration > slowTableIndicator ? 'warn' : 'info', logCategory, item.duration.toFixed(2));
            next();
        });
    }, (err) => {
        if (err) return callback(err);
        callback(err);
    });
}
