import Types from '../Types';

export default {
    [Types.mutations.SET_USER_PROFILE](s, user) {
        const state = s;
        state.user.userId = user.userId;
        state.user.userName = user.userName;
        state.user.firstName = user.firstName;
        state.user.lastName = user.lastName;
        state.user.isDemoUser = user.isDemoUser;
        state.user.hasLocalPassword = user.hasLocalPassword;

        if (user.superAdmin) {
            state.user.isSuperAdmin = user.superAdmin;
            state.user.mappedUser = user.mappedUser;
            state.user.mappedUserName = user.mappedUserName;
            state.user.mappedUserId = user.mappedUserId;
        }
        state.user.unlinkedGoogleEmail = user.unlinkedGoogleEmail;
        state.user.awsImageUrl = user.awsImageUrl;
        state.user.googleAccountId = user.googleAccountId || 0;
        state.user.googleAccountUserId = user.googleAccountUserId || 0;
        state.user.googleUserId = user.googleUserId || 0;
        state.user.googleEmail = user.googleEmail || '';
        state.user.localStorageKey = user.localStorageKey;
        state.user.version = user.version;

        state.user.schoolTerms = (user.schoolTerms || []).slice();

        if (state.user.schoolTerms.length == 0) {
            state.error = 'No school terms found for user';
            return;
        }

        const schoolTermId = window.sessionStorage.getItem(Types.mutations.SET_SCHOOL_TERM) || 0;
        // find wanted term
        let term = state.user.schoolTerms.find((t) => t.schoolTermId == schoolTermId);

        if (!term) {
            // find current term
            term = state.user.schoolTerms.find((t) => t.current);
            // just pick the first one
            if (!term) {
                const { schoolTerms } = state.user;
                [term] = schoolTerms; // pick first school, arrau destructuring
            }
        }

        state.user.access = {
            enabled: user.access.enabled,
            type: user.access.type,
            expires: user.access.expires,
            lastCheck: user.access.lastCheck,
            delinquent: user.access.delinquent,
            disabled: user.access.disabled,
            schoolSubscriptionMessage: user.access.schoolSubscriptionMessage,
        };

        state.user.subscription = {
            adminLocked: term.subscription.adminLocked,
            anecdotalsEnabled: term.subscription.anecdotalsEnabled,
            gradingEnabled: term.subscription.gradingEnabled,
            encryptionEnabled: term.subscription.encryptionEnabled,
            reportingEnabled: term.subscription.reportingEnabled,
            reportCardsEnabled: term.subscription.reportCardsEnabled,
            communicationEnabled: term.subscription.communicationEnabled,
            communicationPlan: term.subscription.communicationPlan,
            emailQuota: term.subscription.emailQuota,
            smsQuota: term.subscription.smsQuota,
        };

        state.user.userPermissions = {
            canManageCourses: term.userPermissions.canManageCourses,
            accessAllTeacherPortfolios: term.userPermissions.accessAllTeacherPortfolios,
            accessAllStudentPortfolios: term.userPermissions.accessAllStudentPortfolios,
            accessAllAnecdotalEvents: term.userPermissions.accessAllAnecdotalEvents,
            editSchoolAnecdotalEvents: term.userPermissions.editSchoolAnecdotalEvents,
            canChooseGradeTemplate: term.userPermissions.canChooseGradeTemplate,
            canManagePurchaseOrders: term.userPermissions.canManagePurchaseOrders,
            canSubmitBugReports: term.userPermissions.canSubmitBugReports,
            canMessageSchoolUsers: term.userPermissions.canMessageSchoolUsers,
            canMessageHomeUsers: term.userPermissions.canMessageHomeUsers,
            canNotifyEmail: term.userPermissions.canNotifyEmail,
            canNotifySms: term.userPermissions.canNotifySms,
        };

        if (user.twoFactorAuth) {
            state.user.twoFactorAuth.cipherSessionId = user.twoFactorAuth.cipherSessionId;
            state.user.twoFactorAuth.cipherEpochExpiry = user.twoFactorAuth.cipherEpochExpiry;
            state.user.twoFactorAuth.twoFactorAuthEnabled = user.twoFactorAuth.twoFactorAuthEnabled;
            state.user.twoFactorAuth.verificationKey = user.twoFactorAuth.verificationKey;
            state.user.twoFactorAuth.oneTimePassword = user.twoFactorAuth.oneTimePassword;
            state.user.twoFactorAuth.softLocked = user.twoFactorAuth.softLocked;
        }

        state.user.school.externalSchoolId = term.externalSchoolId;
        state.user.school.schoolStaffId = term.schoolStaffId || 0;
        state.user.school.studentEnrollmentId = term.studentEnrollmentId || 0;
        state.user.school.studentId = term.studentId || 0;
        state.user.school.studentGuardianId = term.studentGuardianId || 0;
        state.user.school.externalDistrictId = term.externalDistrictId;
        state.user.school.districtId = term.districtId;
        state.user.school.schoolName = term.schoolName;
        state.user.school.schoolId = term.schoolId;
        state.user.school.schoolTermId = term.schoolTermId;
        state.user.school.schoolYear = term.schoolYear;
        state.user.school.schoolTerm = term.schoolTerm;
        state.user.school.name = term.name;
        state.user.school.termStart = term.termStart;
        state.user.school.termEnd = term.termEnd;
        state.user.school.role = term.role;
        state.user.school.current = term.current;
        state.user.school.finalized = term.finalized;
        state.user.school.googleAccountId = term.googleAccountId;
        state.user.school.googleSetting = term.googleSetting;
        state.user.school.hasCourses = term.hasCourses;
        state.user.school.requireTwoFactorAuth = term.requireTwoFactorAuth;
        state.user.school.isBetaSchool = term.isBetaSchool;

        state.user.school.clientSideEncryptionEnabled = Boolean(term.encryptionHash);
        state.user.school.serverSideEncryptionEnabled = Boolean(term.encryptionHash);
        state.user.school.encryptionHash = term.encryptionHash;
        state.user.school.rostersLastUpdated = term.rostersLastUpdated;
    },
};
