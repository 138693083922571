function SchoolTerm(props) {
    this.current = props.current;
    this.demoDistrict = props.demoDistrict;
    this.districtId = props.districtId;
    this.externalDistrictId = props.externalDistrictId;
    this.externalSchoolId = props.externalSchoolId;
    this.finalized = props.finalized;
    this.googleAccountId = props.googleAccountId;
    this.googleSetting = props.googleSetting;
    this.hasCourses = props.hasCourses;
    this.name = props.name;
    this.role = props.role;
    this.userId = props.userId;

    this.schoolStaffId = null;
    if (['School Admin', 'Staff', 'Teacher'].includes(this.role)) {
        this.schoolStaffId = props.schoolStaffId;
    }
    this.studentEnrollmentId = null;
    this.studentId = null;
    if (this.role == 'Student') {
        this.studentEnrollmentId = props.studentEnrollmentId;
        this.studentId = props.studentId;
    }
    this.studentGuardianId = null;
    if (this.role == 'Guardian') {
        this.studentGuardianId = props.studentGuardianId;
    }

    this.schoolId = props.schoolId;
    this.schoolName = props.schoolName;
    this.schoolTerm = props.schoolTerm;
    this.schoolTermId = props.schoolTermId;
    this.schoolYear = props.schoolYear;
    this.requireTwoFactorAuth = props.requireTwoFactorAuth;
    this.isBetaSchool = props.isBetaSchool;

    this.encryptionHash = props.encryptionHash;

    this.termEnd = props.termEnd ? String(props.termEnd || '').substring(0, 10) : null;
    this.termStart = props.termStart ? String(props.termStart || '').substring(0, 10) : null;

    this.rostersLastUpdated = props.rostersLastUpdated;

    // school subscriptions
    this.subscription = {
        adminLocked: props.adminLocked,
        anecdotalsEnabled: props.anecdotalsEnabled,
        gradingEnabled: props.gradingEnabled,
        encryptionEnabled: props.encryptionEnabled,
        reportingEnabled: props.reportingEnabled,
        reportCardsEnabled: props.reportCardsEnabled,
        communicationEnabled: props.communicationEnabled,
        communicationPlan: props.communicationPlan,
        emailQuota: props.emailQuota,
        smsQuota: props.smsQuota,
    };

}

module.exports = SchoolTerm;
