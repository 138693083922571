/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listSchoolDayCalendars from './listSchoolDayCalendars';
import upsertSchoolDayCalendar from './upsertSchoolDayCalendar';

export {
    listSchoolDayCalendars,
    upsertSchoolDayCalendar,
 };
