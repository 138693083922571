function StudentGroup(props) {
    this.studentGroupId = props.studentGroupId;
    this.schoolStaffId = props.schoolStaffId;
    this.schoolTermId = props.schoolTermId;
    this.schoolTermMarkingPeriodId = props.schoolTermMarkingPeriodId;
    this.staff = props.staff;
    this.students = props.students;
    this.groupType = props.groupType;
    this.groupRules = props.groupRules;
    this.groupIndex = props.groupIndex;
    this.canEditGroup = props.canEditGroup;
    this.isGroupOwner = props.isGroupOwner;
    this.groupName = props.groupName || null;
    this.groupDescription = props.groupDescription || null;
    this.groupColor = props.groupColor || null;
    this.groupIcon = props.groupIcon || null;
    this.created = props.created;
    this.author = props.author;
    this.deleted = props.deleted;
}

module.exports = StudentGroup;
