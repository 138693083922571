const Communication = () => import( '../../rootComponents/Communication.vue');
const EmailUnsubscribe = () => import( '../../rootComponents/EmailUnsubscribe.vue');
const ViewMessage = () => import( '../../components/Communication/ViewMessage.vue');

const communicationRoutes = [
    {
        name: 'EmailUnsubscribe',
        path: '/communication/email/unsubscribe/:token',
        component: EmailUnsubscribe,
        meta: {
            roles: ['All'],
            public: true,
        },
    },
    {
        name: 'Communication',
        path: '/communication',
        component: Communication,
        meta: {
            subHeader: true,
            title: 'Communication',
            icon: 'communication',
            roles: ['All'],
        },
    },
    {
        name: 'ViewMessage',
        path: '/communication/message/:messageId',
        component: ViewMessage,
        meta: {
            subHeader: true,
            title: 'Communication',
            icon: 'communication',
            roles: ['All'],
        },
    },
];

export default communicationRoutes;
