export default {
    dirty: false,
    schoolTermMarkingPeriodId: 0,
    communication: {
        messageNotificationsEnabled: true,
    },
    gradebook: {
        showHeader: false,
        showFloatingPortlet: true,
        gradeBy: 'Points', // 'Points' or 'Percentages'
        stickyColumns: ['mpAverage'],
        stickyRows: ['PointsPercent'],
        sort: 'date',
        sortDirection: 'descending',
        filterByString: null,
        filterByCategory: null,
        maxColumns: 20,
        sortings: [],
    },
};
