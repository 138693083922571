import async from 'async';
import network from '../../lib/network';
import * as net from '../../network';
import Types from '../../store/Types';

export default {
    /* all of these will be deprectaed by vuex */
    selectCourse({ commit, state }, thisCourse) {
        let teachers = [...state.database.teachers];
        teachers = teachers.map((t) => {
            const teacher = t;
            if (teacher.selected) {
                teacher.googleCourses = teacher.googleCourses.map((c) => {
                    const course = c;
                    course.selected = Boolean(thisCourse.googleCourseId === c.googleCourseId);
                    return course;
                });
            }
            return teacher;
        });
        commit('setTeachers', teachers);
    },
    selectPortfolioByEmail({ commit, state }, googleEmail) {
        commit('setLoading', true);
        const portfolio = state.database.portfolios.find((p) => p.googleEmail == googleEmail || p.schoolEmail == googleEmail);
        if (!portfolio) {
            commit('setLoading', false);
            return window.console.error('Cannot find this portfolio');
        }

        commit('selectPortfolio', portfolio.schoolEmail || portfolio.googleEmail);

        getGoogleCoursesForTeacher(state.user, portfolio.schoolEmail, (err, res) => {
            commit('setLoading', false);
            if (err) return commit(Types.mutations.HANDLE_ERROR, err);
            const { courses, googleCourses } = res;
            commit('setPortfolioCourses', { courses, googleCourses });
        });
    },
    getStudents({ commit, state }) {
        commit('setLoading', true);
        async.auto({
            students(next) {
                network.getStudentsForTerm(state.user.school.schoolId, state.user.school.schoolTermId, (err, result) => {
                    if (err) return next(err);
                    return next(null, result.students);
                });
            },
        }, (err, results) => {
            commit('setLoading', false);
            if (err) return commit(Types.mutations.HANDLE_ERROR, err);
            commit('setStudents', results.students);
        });
    },
    getCourses({ commit, state }) {
        commit('setLoading', true);
        async.auto({
            courses(next) {
                network.courses(state.user, (err, result) => {
                    if (err) return next(err);
                    const mapped = result.courses.map((c) => {
                        const course = c;
                        const names = course.teacher.split(', ');
                        course.firstName = names.pop().trim();
                        course.lastName = names.pop().trim();
                        return course;
                    });
                    return next(err, mapped);
                });
            },
        }, (err, results) => {
            commit('setLoading', false);
            if (err) return commit(Types.mutations.HANDLE_ERROR, err);
            commit('setCourses', results.courses);
        });
    },
    getCoursesForActivePortfolio({ commit, state }, email) {
        const portfolio = state.database.portfolios.find((p) => p.googleEmail == email || p.schoolEmail == email);
        if (!portfolio) {
            commit('setLoading', false);
            return window.console.error('Cannot find the portfolio');
        }

        const { schoolId, schoolTermId } = state.user.school;
        if (portfolio.portfolioType == 'Teacher') {
            getGoogleCoursesForTeacher(state.user, portfolio.schoolEmail, (err, res) => {
                commit('setLoading', false);
                if (err) return commit(Types.mutations.HANDLE_ERROR, err);
                const { courses, googleCourses } = res;
                commit('setPortfolioCourses', { courses, googleCourses });
            });
        }

        if (portfolio.portfolioType == 'Student') {
            const { studentEnrollmentId } = portfolio;
            if (1 == 1) {
                console.error('THIS SHOULD BE DEPRECATRED, USE STATE');
                return;
            }
            net.students.getStudentCourses({ url: { schoolId, schoolTermId, studentEnrollmentId } }, (err, res) => {
                commit('setLoading', false);
                if (err) return commit(Types.mutations.HANDLE_ERROR, err);
                const { courses, googleCourses } = res;
                commit('setPortfolioCourses', { courses, googleCourses });
            });
        }
    },
};

function getGoogleCoursesForTeacher(user, email, callback) {
    const { schoolId, schoolTermId } = user.school;
    if (!email) return callback('no email passed to function');
    if (1 == 1) {
        return callback('deprecate this');
    }

    net.teachers.getTeacherCoursesByEmail({ url: { schoolId, schoolTermId, email } }, (err, res) => {
        if (err) return callback(err);

        const { teacher } = res;

        // reformat the response
        teacher.googleCourses = teacher.googleCourses.map((c, idx) => {
            const course = c;
            course.selected = Boolean(idx == 0);
            return course;
        }).filter((c) => c.courseName !== 'Unlinked Google Courses');

        teacher.courses = teacher.courses.map((c, idx) => {
            const course = c;
            course.selected = Boolean(idx == 0);
            return course;
        });

        callback(err, teacher);
    });
}
