module.exports = {
    googleClassroomAccount: 'Cuenta de aula de Google',
    clickToSetup: 'Haga clic para configurar',
    navigationInstructions: 'Haga clic en la barra lateral de navegación en la parte superior izquierda para navegar por el sitio. Haga clic en su nombre en la esquina superior derecha para acceder a las funciones de soporte.',
    termDates: 'De {start} a {end}',
    termCountdown: 'El período escolar termina en {count} meses',
    subheader: {
        title: 'Casa',
        welcome: 'Bienvenidos',
    },
};
