const badge = {
    badgeId: 0,
    schoolId: 0,
    badgeType: null,
    badgeTitle: null,
    badgeDescription: null,
    badgeRank: -1,
    hasRequirements: false,
    badgeLimiterType: null,
    badgeColor: 'Blue',
    badgeIcon: null,
    badgeIconShortName: null,
    guardianShare: false,
    studentShare: false,
    unlocksAvatars: false,
    isPublic: false,
    badgeRequirements: [],
    badgeManagers: [],
    deleted: false,
};
export default badge;
