const request = require('./requestWrapper');

const syncGradesHost = '';

module.exports.getValidMarks = (user, callback) => {
    request.get(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/validMarks`)
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};

module.exports.setValidMarkReportCardStatus = (user, dirtyValidMark, callback) => {
    request.post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/validMarks/${dirtyValidMark.markingPeriodValidMarkId}`)
        .send({ isReportCards: dirtyValidMark.isReportCards })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};
