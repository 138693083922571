/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import initiateChallenge from './initiateChallenge';
import validateOneTimePassword from './validateOneTimePassword';
import saveTwoFactorSettings from './saveTwoFactorSettings';
import removeAuthMethod from './removeAuthMethod';
import getUserTwoFactorAuthMethods from './getUserTwoFactorAuthMethods';
import lockSession from './lockSession';
import unLockSession from './unLockSession';
import setSchoolTermEncryptionPassword from './setSchoolTermEncryptionPassword';
import removeSchoolTermEncryptionPassword from './removeSchoolTermEncryptionPassword';
import getSchoolTermEncryptionKey from './getSchoolTermEncryptionKey';
import getHashEncryptionKey from './getHashEncryptionKey';
import encryptTable from './encryptTable';
import decryptTable from './decryptTable';

export {
    initiateChallenge,
    validateOneTimePassword,
    saveTwoFactorSettings,
    removeAuthMethod,
    getUserTwoFactorAuthMethods,
    lockSession,
    unLockSession,
    setSchoolTermEncryptionPassword,
    removeSchoolTermEncryptionPassword,
    getSchoolTermEncryptionKey,
    getHashEncryptionKey,
    encryptTable,
    decryptTable,
 };
