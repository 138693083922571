/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listTeachers from './listTeachers';
import listSchoolStaff from './listSchoolStaff';
import getStatistics from './getStatistics';
import getUsageTotals from './getUsageTotals';

export {
    listTeachers,
    listSchoolStaff,
    getStatistics,
    getUsageTotals,
 };
