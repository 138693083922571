import * as network from '../../network';
import Types from '../Types';

export function saveAttendance(commit, state, params, callback) {
    const { schoolId, schoolTermId } = state.user.school;
    const url = {
        schoolId,
        schoolTermId,
        courseSectionId: params.courseSectionId,
    };

    const body = {
        courseSectionAttendance: params.courseSectionAttendance,
    };

    network.courseSectionAttendance.saveCourseAttendance({ url, body }, (err, res) => {
        if (err) return callback(err);
        const { courseSectionAttendance } = body;
        commit(Types.mutations.UPSERT_COURSE_SECTION_ATTENDANCE, courseSectionAttendance);
        return callback(err, res);
    });
}
