export default {
    setIsFetchingQueueRequests(s, isFetching) {
        const state = s;
        state.isFetchingQueueRequests = isFetching;
    },
    setQueueRequests(s, queueRequests) {
        const state = s;
        state.queueRequests = queueRequests;
    },
};
