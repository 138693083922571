/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import generateReportCard from './generateReportCard';
import releaseReportCard from './releaseReportCard';
import getReportsForStaff from './getReportsForStaff';
import getReportsForStudent from './getReportsForStudent';
import getReportPdf from './getReportPdf';
import deleteReport from './deleteReport';

export {
    generateReportCard,
    releaseReportCard,
    getReportsForStaff,
    getReportsForStudent,
    getReportPdf,
    deleteReport,
 };
