const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.save = function (user, googleCourseId, body, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${user.school.schoolId}/terms/${user.school.schoolTermId}/google/courses/${googleCourseId}/courseWorkGrades`)
        .set('accept', 'json')
        .send(body)
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
