import async from 'async';
import moment from 'moment';
import Types from '../../Types';

import network from '../../../lib/network';

// logging, perf tools
const logCategory = 'reportCardTables';
const slowTableIndicator = 10; // ms

export function populateReportCardData(commit, state, teacher, callback) {
    const { user } = state;
    const { schoolStaffId } = teacher;

    const key = `reportCardData_${schoolStaffId}`;
    const cache = {
        key,
        cacheTitle: `${teacher.lastName}, ${teacher.firstName}`,
        cacheType: 'Report Cards',
        status: 'loading',
        timestamp: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache);

    async.auto({
        submissionLookups(next) {
            network.getSubmissionLookupsForStaffId(user, schoolStaffId, [], (err, resp) => {
                if (err) return next(err);
                const tableName = 'reportCardTables';
                window.performance.mark(`startDataLoading_${tableName}`);
                commit(Types.mutations.SET_RPCD_COMMENT_CODES, resp.commentCodes);
                commit(Types.mutations.SET_RPCD_VALID_MARKS, resp.validMarks.filter((validMark) => validMark.isReportCards));
                commit(Types.mutations.SET_RPCD_VALID_COURSE_MARKS, resp.validMarks.filter((validMark) => validMark.isReportCards && validMark.isCourseMark));
                commit(Types.mutations.SET_RPCD_VALID_EXAM_MARKS, resp.validMarks.filter((validMark) => validMark.isReportCards && validMark.isExamMark));
                window.performance.mark(`endDataLoading_${tableName}`);
                window.performance.measure(`${tableName}_dataLoad`, `startDataLoading_${tableName}`, `endDataLoading_${tableName}`);
                const [item] = performance.getEntriesByName(`${tableName}_dataLoad`);
                window.syncGrades.log(`${tableName}_dataLoad: ${item.duration.toFixed(2)}ms`, item.duration > slowTableIndicator ? 'warn' : 'info', logCategory, item.duration.toFixed(2));
                next();
            });
        },
    }, 2, (err) => {
        if (err) return callback(err);
        // successful so save timestamp to cache table
        cache.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
        cache.status = 'cached';
        commit(Types.mutations.UPSERT_ITEM_TO_CACHE, cache);// mark as completed
        window.console.log(`Successfully saturated ${cache.cacheType} ${cache.cacheTitle}`);
        callback(err);
    });
}
