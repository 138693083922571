function School(props) {
    this.schoolId = props.schoolId;
    this.externalSchoolId = props.externalSchoolId;
    this.schoolStatus = props.schoolStatus;
    this.schoolName = props.schoolName; // name
    this.author = props.author;
    this.googleSetting = props.googleSetting;
    this.contactUserId = props.contactUserId;
    this.contactEmail = props?.contactEmail || null;
    this.requireTwoFactorAuth = props.requireTwoFactorAuth;
    this.restricted = props.restricted;
    this.schoolRenewalStatus = props.schoolRenewalStatus;
    this.schoolMessageEnabled = props.schoolMessageEnabled;
    this.schoolMessage = props.schoolMessage;
    this.schoolMessageScope = props.schoolMessageScope;
    this.schoolMessageVariant = props.schoolMessageVariant;
    this.schoolLogo = props.schoolLogo;
    this.isBetaSchool = props.isBetaSchool;
    this.deleted = props.deleted;
    this.created = props.created;

    if (props.district) {
        this.district = props.district;
    }
    if (props.createDistrict) {
        this.district = {
            name: props.createDistrict.name,
            districtId: props.createDistrict.districtId,
            externalDistrictId: props.createDistrict.externalDistrictId,
        };
    }

    if (props.schoolTerms) {
        this.schoolTerms = props.schoolTerms;
    }
    if (props.subscriptions) {
        this.subscriptions = (props.subscriptions || []).filter(function(sub) {
            return {
                schoolSubscriptionId: sub.schoolSubscriptionId,
                externalSchoolId: sub.externalSchoolId,
                schoolYear: sub.schoolYear,
                purchaseOrderNumber: sub.purchaseOrderNumber,
                deleted: sub.deleted,
                delinquent: sub.delinquent,
                disabled: sub.disabled,
                schoolSubscriptionMessage: sub.schoolSubscriptionMessage,
                certifiedPurchaseOrder: sub.certifiedPurchaseOrder,
                invoiceDetails: sub.invoiceDetails,
                invoice: sub.invoice,
                adminLocked: sub.adminLocked,
                communicationEnabled: sub.communicationEnabled,
                communicationPlan: sub.communicationPlan,
                anecdotalsEnabled: sub.anecdotalsEnabled,
                gradingEnabled: sub.gradingEnabled,
                encryptionEnabled: sub.encryptionEnabled,
                reportCardsEnabled: sub.reportCardsEnabled,
                reportingEnabled: sub.reportingEnabled,
                emailQuota: sub.emailQuota,
                smsQuota: sub.smsQuota,
            };
        });
    }

    if (props.createSchoolTerms) {
        this.schoolTerms = props.createSchoolTerms.map((schoolTerm) => {
            const subscribed = Boolean(props.createSubscriptions
                .find((sub) => sub.externalSchoolId == props.externalSchoolId && sub.schoolYear == schoolTerm.schoolYear));
            const expiresOn = subscribed ? `${schoolTerm.schoolYear + 1}-10-01` : null;

            return {
                ...schoolTerm,
                ...{ subscribed, expiresOn },
            };
        });
    }
    if (props.createSubscriptions) {
        this.subscriptions = props.createSubscriptions.filter((sub) => sub.externalSchoolId == props.externalSchoolId);
    }
}

module.exports = School;
