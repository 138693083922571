const deny = require('./deny');
const allow = require('./allow');

const presentation = {
    shouldAppear(name, type, externalDistrictId) {
        // check if always allowed
        const allowedRuleExists = allow.find((list) => list.name == name && list.type == type);
        if (allowedRuleExists) {
            return allowedRuleExists.districts.indexOf(externalDistrictId) > -1;
        }

        // check if denied
        const denied = deny.find((list) => list.name == name && list.type == type && list.districts.indexOf(externalDistrictId) > -1);
        if (denied) return false;

        // default allow it
        return true;
    },
};

export default presentation;
