import Types from '../../../Types';
import * as network from '../../../../network';

export default function reloadAnecdotalEvents(commit, state, rootState) {
    commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_LOADING, true);

    const {
        schoolId, schoolYear, schoolTermId,
    } = state.user.school;

    const { anecdotalEventSearch } = state.database;
    const query = {
        pageSize: anecdotalEventSearch.pageSize,
        pageNumber: anecdotalEventSearch.pageNumber,
        viewAsStudent: false,
        viewAsGuardian: false,
        downloadExcel: false,
    };

    if (rootState.route.name == 'StudentPortfolio' && state.forceStudentView) {
        query.viewAsStudent = true;
        query.viewAsGuardian = true;
    }
    if (anecdotalEventSearch.anecdotalEventDescription) {
        query.anecdotalEventDescription = anecdotalEventSearch.anecdotalEventDescription;
    }
    if (anecdotalEventSearch.anecdotalEventCategoryId) {
        query.anecdotalEventCategoryId = anecdotalEventSearch.anecdotalEventCategoryId || 0;
    }

    if (anecdotalEventSearch.schoolTermId) {
        query.schoolTermId = anecdotalEventSearch.schoolTermId || 0;
    } else {
        query.schoolYear = schoolYear;
    }

    if (anecdotalEventSearch.anecdotalEventDate) {
        query.anecdotalEventDate = anecdotalEventSearch.anecdotalEventDate;
    }
    if (anecdotalEventSearch.studentId) {
        query.studentId = anecdotalEventSearch.studentId || 0;
    }
    if (anecdotalEventSearch.schoolStaffId) {
        query.schoolStaffId = anecdotalEventSearch.schoolStaffId || 0;
    }
    if (anecdotalEventSearch.downloadExcel) {
        query.downloadExcel = true;
    }
    query.anecdotalPointValue = anecdotalEventSearch.anecdotalPointValue || '';

    network.anecdotals.listAnecdotals({
        url: { schoolId, schoolTermId },
        query,
    }, (err, res) => {
        commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_DOWNLOAD_EXCEL, false);
        commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_LOADING, false);

        if (err) return commit(Types.mutations.SET_ERROR, err);

        if (query.downloadExcel) {
            const blob = new Blob([res], { type: 'text/csv' });
            if (blob.size == 0) return commit(Types.mutations.SET_ERROR, 'no results found');
            const downloadUrl = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `${state.user.school.externalSchoolId}_anecdotals.csv`;
            document.body.appendChild(a);
            a.click();
            return;
        }

        const { anecdotalEvents } = res;
        commit(Types.mutations.SET_DB_ANECDOTAL_EVENTS, anecdotalEvents);
    });
}

export function addAnecdotalEventById(anecdotalEventId, commit, state) {
    commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_LOADING, true);

    const {
        schoolId, schoolTermId,
    } = state.user.school;

    const params = {
        url: { schoolId, schoolTermId, anecdotalEventId },
    };

    network.anecdotals.getByAnecdotalEventId(params, (err, res) => {
        commit(Types.mutations.SET_ANECDOTAL_SEARCH_ANECDOTAL_LOADING, false);
        if (err) return commit(Types.mutations.SET_ERROR, err);
        const { anecdotalEvents } = res;
        commit(Types.mutations.ADD_DB_ANECDOTAL_EVENTS, anecdotalEvents);
    });
}
