module.exports = {
    clickHere: 'Haga clic aquí para configurar',
    filter: 'Filtrar',
    noData: 'No hay datos disponibles porque este curso no está vinculado a Google Classroom.',
    googleCategoriesModal: {
        title: 'Selección de enlaces de categorías de Google',
        info: 'Seleccione la categoría de SyncGrades que corresponda a la categoría en Google Classroom!'
    }
};

module.exports.activity = {
    activity: 'Actividad',
    created: 'CREADO',
    createdSubmission: 'Envío CREADO',
    filterByDate: 'Filtrar por fecha',
    interactionCountByDate: '{count} interacciones el {date}',
    new: 'NUEVO',
    noActivity: 'No se encontró actividad, si esto es incorrecto, haga clic en el botón Actualizar a continuación para obtener la información más reciente de Google.',
    reclaimed_by_student: 'RECUPERADO POR EL ESTUDIANTE',
    refreshActivity: 'Actualizar actividad',
    returned: 'DEVUELTO',
    submissionState: '{state} Presentación',
    turned_in: 'CONVERTIDO EN',
};

module.exports.assignments = {
    allCategories: 'Todas las Categorias',
    allMarkingPeriods: 'Todos los Períodos de Calificación',
    allAssignments: 'Todas las asignaciones',
    markingPeriod: 'Período de Calificación',
    assigned: 'Asignado',
    assignment: 'Asignación',
    assignments: 'Tareas',
    auto: 'Auto',
    category: 'Categoría',
    confirmConfirmDelete: '¿Está seguro? ¡ESTO NO SE PUEDE DESHACER!',
    confirmDelete: 'Está eliminando una tarea. Asegúrese de que esto es lo que le gustaría hacer antes de continuar. ESTO NO SE PUEDE DESHACER. ¿Te gustaria continuar?',
    createAssignment: 'Crear nueva asignación',
    customDueDate: 'Personalizado',
    date: 'Fecha',
    deleteAssignment: 'Eliminar asignación',
    description: 'Descripción',
    downloadAssignments: 'Descargar Tareas',
    draft: 'Sequía',
    due: 'Oportuno',
    dueDate: 'Fecha de vencimiento',
    dueDateAndTime: 'Fecha y hora de vencimiento',
    dueWhenFuture: 'en {count} {unit}',
    dueWhenNow: 'hoy',
    dueWhenPast: 'hace {count} {unit}',
    editAssignment: 'Editar Tarea',
    editDraftAssignment: 'Editar Borrador de Tarea',
    excludeFromMarkingPeriods: 'Excluir de los períodos de calificación',
    exclusive: 'Exclusivo',
    exclusiveInstructions: 'Si se elige el período de calificación <code>{auto}</code>, la tarea se coloca en uno o más períodos de calificación en función de la fecha de vencimiento en Google y los intervalos de fechas del período de calificación. Si no se ha establecido una fecha de vencimiento, se utiliza la fecha de creación. Si se habilita el promedio de <code>{exclusive}</code>, la calificación de la tarea solo se promediará en el período de calificaciones elegido; de lo contrario, la tarea puede caer en varios períodos de calificaciones cuando las fechas de los períodos de calificaciones se superponen.',
    grade: 'Calificación',
    groupingDate: 'Fecha de agrupación',
    markingPeriodInitials: 'CP',
    maxPoints: 'Puntos Máximos',
    missing: 'Desaparecido',
    newAssignment: 'Nueva tarea',
    noAssignments: 'No se encontraron asignaciones, si esto es incorrecto, haga clic en el botón Actualizar a continuación para obtener la información más reciente de Google.',
    noAssignmentsFound: 'No se encontraron asignaciones.',
    noCategories: 'Sin categorías',
    noDueDate: 'Ninguno',
    notLinked: 'No vinculado',
    openInGoogle: 'Abierto en Google',
    properties: 'Propiedades',
    published: 'Publicado',
    publishFirst: 'Publicar primero para abrir en Google',
    refreshAssignments: 'Actualizar asignaciones',
    relativeDate: 'Fecha relativa',
    search: 'Búsqueda',
    sections: 'Secciones',
    template: 'Plantilla',
    title: 'Título',
    uncategorized: 'Sin categorizar',
    weight: 'Peso',
    worth: 'Valor',
};

module.exports.gradeTemplate = {
    noTemplate: 'No se encontraron plantillas, si esto es incorrecto, haga clic en el botón Actualizar a continuación para obtener la información más reciente de Google.',
    notLinked: 'No vinculado',
    refreshAssignments: 'Actualizar asignaciones',
    template: 'Plantilla'
};

module.exports.attendance = {
    absent: 'Ausente',
    applyAsRemoteAttendance: 'Aplicar como asistencia remota',
    attendance: 'Asistencia',
    changeAttendanceFor: 'Cambiar la asistencia de {count} estudiantes seleccionados a:',
    comment: 'Comentario',
    commentFor: 'Comentar para',
    excused: 'Excusado',
    inSchool: 'En el colegio',
    late: 'Tarde',
    'n/a': 'Ninguno',
    present: 'Regalo',
    remote: 'Remoto',
    savedAs: '{name} saved as ??? {location}: {state}',
    savingChanges: 'Guardando cambios',
    saveSingleChange: 'Guardar 1 cambio',
    saveMultipleChange: 'Guardar {count} cambios',
};

module.exports.averages = {
    average: 'Promedio',
    averages: 'Promedios',
    dateThroughDate: '{start} hasta el {end}',
    excused: 'Excusado',
    failing: 'Defecto',
    late: 'Tarde',
    lowestGrade: 'Grado más bajo',
    missing: 'Desaparecido',
    overall: 'General',
    passing: 'Paso',
    scaled: 'Escamoso',
    total: 'Total',
};

module.exports.progressReports = {
    allStudents: 'Todos los estudiantes',
    createNew: 'Crear nuevo',
    downloadBulk: 'Descargar a granel',
    missingComments: 'Comentarios faltantes',
    problemLoadingUser: 'Hubo un problema al cargar su usuario',
    problemLoadingMarkingPeriods: 'Hubo un problema al cargar los períodos de calificación',
    problemLoadingStudents: 'Hubo un problema al cargar a tus alumnos',
    problemLoadingStudentComments: 'Hubo un problema al cargar los comentarios de estos estudiantes.',
    progressReportQueue: 'Cola de informes de progreso',
    progressReports: 'Reporte de progreso',
    progressReportModal: {
        generateProgressReport: 'Generar @:pages.teacher.progressReports.progressReportModal.progressReport',
        forTeacher: 'Para el Maestro',
        forStudent: 'Para el Estudiante',
        forSchool: 'Para la Escuela',
        courseLevel: 'Nivel del Curso',
        schoolLevel: 'Nivel Escolar',
        progressReport: 'Informe de Progreso',
        createNewProgressReport: 'Crear un nuevo informe de progreso',
        dividedByEachCourse: 'dividido por cada curso',
        withAllCoursesCombined: 'con todos los cursos combinados',
        runForHomeRoom: 'Corre por la habitación de casa',
        allHomeRooms: 'Todas las habitaciones de la casa',
        generateForAllHomeRooms: 'Generar para todas las habitaciones de la casa',
        homeRoom: 'Habitación en Casa',
        runForCourses: 'Corre por Cursos',
        allCourses: 'Todos los cursos',
        generateForAllCourses: 'Generar para todos los Cursos',
        emailToStudents: 'Correo electrónico a los estudiantes',
        sendACopy: 'Envíe una copia de este PDF a la dirección de correo electrónico de los estudiantes si está vinculado en SyncGrades.',
        showOnPortfolio: 'Mostrar en cartera',
        showACopy: 'Muestre una copia de este PDF en la página del portafolio de cada estudiante.',
        schoolAnnouncementsCheckbox: 'Anuncio de la Escuela:',
        schoolAnnouncementsSave: 'Guardar Anuncio',
        sortOrder: 'Orden de Clasificación',
        generateReport: 'Generar informe',
        overallAverage: {
            name: 'Media general',
            description: 'Incluye una sección con un promedio simple del período de calificación proyectado de los estudiantes.',
        },
        courseAverages: {
            name: 'Promedios del curso',
            description: 'Agrega una sección con un promedio de período de calificación para cada curso.',
        },
        gradeBreakdown: {
            name: 'Desglose de calificaciones',
            description: 'Agrega una tabla de datos de categorías de calificación con promedio de categoría de estudiante y puntaje escalado',
        },
        assignments: {
            name: 'Asignaciones',
            description: 'Enumera cada tarea, el estado de finalización, la calificación y la puntuación escalada.',
        },
        courseAttendance: {
            name: 'Asistencia al curso',
            description: 'Imprime los registros de asistencia a cursos no presentes más recientes.',
        },
        teacherReportComments: {
            name: 'Comentarios del informe del maestro',
            description: 'Agrega comentarios según lo definido por el maestro para el curso en este período de calificación.',
        },
        missingAssignments: {
            name: 'Asignaciones faltantes',
            description: 'Agrega una sección con solo tareas faltantes para el curso.',
        },
        allMissingAssignments: {
            name: 'Todas las tareas que faltan',
            description: 'Agrega una tabla con solo tareas faltantes en todos los cursos.',
        },
        schoolAnnouncements: {
            name: 'Anuncios escolares',
            description: 'Agregar una sección con el anuncio de la escuela.',
        },
    },
};

module.exports.reportCards = {
    excelUploadNeeded: 'Carga de Excel necesaria',
    gradingStatus: 'Estado de calificación',
    gradingSubmissions: 'Calificación de envíos',
    readyToGrade: 'Listo para calificar',
    reportCards: 'Las boletas de calificaciones',
    submissionWindow: 'Ventana de envío',
    submissionWindowClosed: 'Ventana de envío cerrada',
};

module.exports.students = {
    courseSection: 'Sección del curso',
    enterValidEmail: 'Ingrese una dirección de correo electrónico válida de Google para estudiantes',
    googleAccount: 'Cuenta Google',
    id: 'ID',
    linkStudent: 'Estudiante de enlace',
    student: 'Alumno',
    studentEmail: 'Correo electrónico del estudiante',
    students: 'Estudiantes',
    studentFilter: 'Filtro de estudiante',
};
