function CourseSectionTeacherMetadata(props) {
	this.schoolTermId = props.schoolTermId;
	this.courseSectionId = props.courseSectionId;
	this.schoolStaffId = props.schoolStaffId;
	this.comboCourseSectionId = props.comboCourseSectionId;
	this.courseSectionTitle = props.courseSectionTitle;
	this.courseSectionColor = props.courseSectionColor;
	this.courseSectionAbbreviation = props.courseSectionAbbreviation;
}

module.exports = CourseSectionTeacherMetadata;