function GoogleCourse(props) {
	this.googleCourseId = props.googleCourseId;
	this.extGoogleCourseId = props.extGoogleCourseId;
	this.alternateLink = props.alternateLink;
	this.googleCourseDescription = props.googleCourseDescription;

	let name = props.googleCourseName || '';
	name = name.replace('[LOCAL] ', '');
	name = name.replace('[STAGING] ', '');
	name = name.replace('[DEV] ', '');

	this.googleCourseName = name;
	this.googleCourseRoom = props.googleCourseRoom;
	this.googleCourseSection = props.googleCourseSection;
	this.googleCourseState = props.googleCourseState;
	this.deleted = props.deleted;
	this.extOwnerId = props.extOwnerId;
	this.visibleToUser = props.visibleToUser;
	this.enrollmentCode = props.enrollmentCode;
	this.extCalendarId = props.extCalendarId;
	// this.extResourceBody = props.extResourceBody;
}

module.exports = GoogleCourse;