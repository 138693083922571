function StudentBadge(props) {
	this.studentBadgeId = props.studentBadgeId || 0;
	this.studentId = props.studentId;
	this.schoolStaffId = props.schoolStaffId;
	this.courseSectionId = props.courseSectionId;
	this.studentEnrollmentId = props.studentEnrollmentId;
	this.achievementBadge = props.achievementBadge || null;
	this.stickyBadge = props.stickyBadge || null;
	this.earnedBadges = props.earnedBadges || [];
	this.awardedOn = props.awardedOn;
	this.author = props.author;
	this.modified = props.modified;
	this.rationale = props.rationale;
}

module.exports = StudentBadge;