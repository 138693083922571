import moment from 'moment';
// @ts-ignore
import cssVars from '../../css/colors.scss';
import { getSessionUser } from './userMixins';
import { getTeacherFromRoute } from './teacherMixins';

const courseMixins = {
    methods: {
        $_courseMixins_getCourseFromRoute() {
            return getCourseFromRoute(this.$route, this.$store);
        },
        $_courseMixins_getCourses(params) {
            const { database } = this.$store.state;
            const user = getSessionUser(this.$store.state);
            const limitBy = { ...params };
            limitBy.user = user;
            return getCourses(database, limitBy);
        },
        $_courseMixins_getReducedCourses(params) {
            const { database } = this.$store.state;
            const user = getSessionUser(this.$store.state);
            const limitBy = { ...params };
            limitBy.user = user;
            return getReducedCourses(database, limitBy);
        },
        $_courseMixins_getCourseById(courseSectionId) {
            const { database } = this.$store.state;
            const session = getSessionUser(this.$store.state);
            const courseSections = getCourses(database, new CourseFilter({ courseSectionId, session }));
            const [firstItem] = courseSections;
            return firstItem || null;
        },
        $_courseMixins_getCourseByExtId(extCourseSectionId) {
            const { database } = this.$store.state;
            const session = getSessionUser(this.$store.state);
            const courseSections = getCourses(database, new CourseFilter({ extCourseSectionId, session }));
            const [firstItem] = courseSections;
            return firstItem || null;
        },
        $_courseMixins_getCoursesForStaffId(schoolStaffId) {
            const { database } = this.$store.state;
            const session = getSessionUser(this.$store.state);
            const filter = new CourseFilter({ schoolStaffId, session });
            const courses = getCourses(database, filter);
            return courses;
        },
        $_courseMixins_getReducedCoursesForStaffId(schoolStaffId) {
            const { database } = this.$store.state;
            const session = getSessionUser(this.$store.state);
            const filter = new CourseFilter({ schoolStaffId, session });
            const courses = getReducedCourses(database, filter);
            return courses;
        },
        $_courseMixins_getCoursesForStudentEnrollmentId(studentEnrollmentId) {
            const { $store } = this;
            return getCoursesForStudentEnrollmentId($store, studentEnrollmentId);
        },
        $_courseMixins_getHexColorForCourse(course) {
            return cssVars[getColorForCourse(course)];
        },
        $_courseMixins_getColorForCourse(course) {
            return getColorForCourse(course);
        },
    },
};

function getColorForCourse(course) {
    if (course.courseSectionColor) return course.courseSectionColor;
    if (course.nextMeeting && course.nextMeeting.period) {
        const digit = (course.nextMeeting.period || '0').slice(-1); // last char
        return digit ? `color${digit}` : 'color0';
    }
    const digit = (course.extCourseSectionId || '0').slice(-1); // last char
    return digit ? `color${digit}` : 'color0';
}

function getCoursesForStudentEnrollmentId(store, studentEnrollmentId) {
    const { database } = store.state;
    const session = getSessionUser(store.state);
    const filter = new CourseFilter({ studentEnrollmentId, session });
    const courses = getCourses(database, filter);
    return courses;
}


function CourseFilter(props) {
    this.session = props.session || false;
    this.schoolStaffId = props.schoolStaffId || false;
    this.studentEnrollmentId = props.studentEnrollmentId || false;
    this.extCourseSectionId = props.extCourseSectionId || false;
    this.courseSectionId = props.courseSectionId || false;
    return this;
}

function getCourses(database, limitBy = {
    schoolStaffId: null,
    extCourseSectionId: null,
    courseSectionId: null,
    studentEnrollmentId: null,
    session: null,
}) {
    const {
        courseSectionTeachers,
        courseSectionAttendance,
        teachers,
        courseSections,
        courseSectionStudents,
        markingPeriods,
        markingPeriodStudentAverages,
        googleCourseCourseSections,
        studentMarkingPeriodGrades,
    } = database;

    const mutableCourses = courseSections.filter((cs) => {
        if (limitBy.courseSectionId) {
            return cs.courseSectionId == limitBy.courseSectionId;
        }
        if (limitBy.extCourseSectionId) {
            return cs.extCourseSectionId == limitBy.extCourseSectionId;
        }
        if (limitBy.studentEnrollmentId) {
            return Boolean(courseSectionStudents
                .find((css) => css.courseSectionId == cs.courseSectionId
                    && css.studentEnrollmentId == limitBy.studentEnrollmentId));
        }

        if (limitBy.schoolStaffId) {
            return Boolean(courseSectionTeachers
                .find((cst) => cst.courseSectionId == cs.courseSectionId
                    && cst.schoolStaffId == limitBy.schoolStaffId));
        }
        return true;
    })
    .map((cs) => { // formats results
        const course = { ...cs };
        const { courseSectionId } = cs;

        // add googleCourseId
        const exists = googleCourseCourseSections.find((g) => g.courseSectionId == courseSectionId);
        course.googleCourseId = exists ? exists.googleCourseId : null;

        // add teachers
        const courseTeachers = courseSectionTeachers.filter((cst) => cst.courseSectionId == courseSectionId && !cst.deleted);

        course.teachers = teachers
            .filter((t) => courseTeachers.map((cst) => cst.schoolStaffId).includes(t.schoolStaffId))
            .map((t) => {
                const teacher = { ...t };
                teacher.courseSectionTotals = courseTeachers.find((cst) => cst.schoolStaffId == t.schoolStaffId)?.totals || null;
                return teacher;
            });

        // adds search
        course.search = `${cs.extCourseSectionId} ${cs.name} ${cs.meetingName}`;
        course.teachers.forEach((t) => {
            course.search = `${course.search} ${t.lastName} ${t.firstName}`;
        });
        course.search = `${course.search}`.toLowerCase();

        // adds grade template
        course.gradeTemplate = getGradeTemplateForCourseSectionId(database, courseSectionId);

        // if current user is teacher
        course.canEditCourse = limitBy.session ? courseTeachers.map((cst) => cst.schoolStaffId).indexOf(limitBy.session.school.schoolStaffId) > -1 : false;
        if (!course.canEditCourse) {
            if (limitBy.session) {
                const role = limitBy.session.school ? limitBy.session.school.role : '';
                course.canEditCourse = role == 'School Admin';
            }
        }

        // is teacher, or has both access to all portfolios, and access all student portfolios
        if (course.canEditCourse) {
            course.canViewRosters = true;
        } else {
            course.canViewRosters = limitBy.session ? limitBy.session.userPermissions.accessAllStudentPortfolios && limitBy.session.userPermissions.accessAllTeacherPortfolios : false;
        }

        // add course meetings
        const { courseMeetings } = course;
        const today = moment();
        course.meetingName = 'Not meeting';
        course.nextMeeting = {
            day: '',
            period: null,
            room: null,
        };
        if (courseMeetings) {
            // calculates next meeting
            const todaysMeeting = courseMeetings.find((m) => m.day == today.format('dddd'));

            if (todaysMeeting) {
                course.periodSort = `000 ${todaysMeeting.period.padStart(3, '0')}`;
                course.meetingName = `Pd ${todaysMeeting.period} • ${todaysMeeting.room}`;
                course.search = `${course.search} ${course.meetingName}`.toLowerCase();
                course.nextMeeting = {
                    day: todaysMeeting.day,
                    period: todaysMeeting.period,
                    room: todaysMeeting.room,
                };
            }

            // loop the next 7 days until i find the next meeting time
            if (course.nextMeeting.period == null) {
                let i = 1;
                for (i = 1; i <= 7; i += 1) {
                    today.add(1, 'day');
                    const nextMeeting = courseMeetings.find((m) => m.day == today.format('dddd'));
                    if (nextMeeting) {
                        course.periodSort = `${i.toString().padStart(3, '0')} ${nextMeeting.period.padStart(3, '0')} ${course.extCourseSectionId}`;
                        course.meetingName = `${today.format('dd')} Pd ${nextMeeting.period}`;
                        course.search = `${course.search} ${course.meetingName}`.toLowerCase();
                        course.nextMeeting = {
                            day: nextMeeting.day,
                            period: nextMeeting.period,
                            room: nextMeeting.room,
                        };
                        break;
                    }
                }
            }
        }

        // add metadata for teacher portfolio view
        course.courseSectionIds = [courseSectionId];

        const { schoolStaffId } = limitBy;
        if (schoolStaffId) {
            const teacher = teachers.find((t) => t.schoolStaffId == schoolStaffId);
            if (teacher) {
                const formattedCourse = formatCourseForPortfolioUser(teacher, course);
                formattedCourse.comboCourses = formattedCourse.comboCourses.map((cc) => {
                    const lookup = database.courseSections.find((x) => x.courseSectionId == cc.courseSectionId);
                    return lookup ? formatCourseForPortfolioUser(teacher, lookup) : null;
                })
                .filter((x) => x);
                return formattedCourse;
            }
        }

        // add metadata for student portfolio view
        const { studentEnrollmentId } = limitBy;
        if (studentEnrollmentId) {
            const shortDate = 'ddd, MMM Do';
            const studentAverages = markingPeriodStudentAverages.filter((m) => m.studentEnrollmentId == studentEnrollmentId);
            const reportCardGrades = studentMarkingPeriodGrades.filter((m) => m.studentEnrollmentId == studentEnrollmentId);

            // add marking periods sub array with report card and progress avg
            const { scale } = course.gradeTemplate;
            course.studentMarkingPeriodAverages = markingPeriods
                .filter((mp) => !mp.deleted)
                .map((mp) => {
                    const markingPeriod = { ...mp };
                    const markingPeriodStart = moment(markingPeriod.markingPeriodStart);
                    const markingPeriodEnd = moment(markingPeriod.markingPeriodEnd);
                    markingPeriod.markingPeriodRange = `${markingPeriodStart.format(shortDate)} - ${markingPeriodEnd.format(shortDate)}`;

                    let reportCardGrade = reportCardGrades.find((m) => m.courseSectionId == courseSectionId && m.schoolTermMarkingPeriodId == mp.schoolTermMarkingPeriodId) || null;
                    if (reportCardGrade && (reportCardGrade.mark == null || reportCardGrade.mark == '')) reportCardGrade = null;

                    if (reportCardGrade) {
                        // if (reportCardGrade.courseSectionStudentId == '17362384') debugger;
                        const { numericEquivalent } = reportCardGrade;
                        reportCardGrade.scaled = numericEquivalent === 0 || numericEquivalent === null ? null
                            : scale.grades.find((s) => numericEquivalent >= s.minGrade && numericEquivalent <= s.maxGrade) || null;
                    }
                    let progressAverage = studentAverages.find((m) => m.courseSectionId == courseSectionId && m.schoolTermMarkingPeriodId == mp.schoolTermMarkingPeriodId) || null;
                    if (progressAverage && progressAverage.averageCalculation.markingPeriod) {
                        progressAverage = progressAverage.averageCalculation.markingPeriod;
                    } else {
                        progressAverage = null;
                    }

                    const studentCourseAttendance = {
                        percent: {
                            present: 0,
                            absent: 0,
                            late: 0,
                        },
                        total: {
                            present: 0,
                            absent: 0,
                            late: 0,
                            excused: 0,
                        },
                    };

                    return {
                        markingPeriod,
                        progressAverage,
                        reportCardGrade,
                        studentCourseAttendance,
                    };
                });

            // count attendance totals, and store records
            const studentCourseAttendance = {
                percent: {
                    present: 0,
                    absent: 0,
                    late: 0,
                },
                total: {
                    present: 0,
                    absent: 0,
                    late: 0,
                    excused: 0,
                },
                records: [],
            };

            studentCourseAttendance.records = courseSectionAttendance.filter((a) => {
                if (a.courseSectionId == courseSectionId && a.studentEnrollmentId == studentEnrollmentId) {
                    if (a.attendanceState == 'Present') studentCourseAttendance.total.present += 1;
                    if (a.attendanceState == 'Absent') studentCourseAttendance.total.absent += 1;
                    if (a.attendanceState == 'Late') studentCourseAttendance.total.late += 1;
                    if (a.attendanceState == 'Excused') studentCourseAttendance.total.excused += 1;

                    course.studentMarkingPeriodAverages.forEach((avg, idx) => {
                        const { markingPeriod } = avg;
                        const attendanceDate = moment(a.attendanceDate);
                        const markingPeriodStart = moment(markingPeriod.markingPeriodStart);
                        const markingPeriodEnd = moment(markingPeriod.markingPeriodEnd);

                        if (attendanceDate.isBetween(markingPeriodStart, markingPeriodEnd, 'day', '[]')) {
                            if (a.attendanceState == 'Present') course.studentMarkingPeriodAverages[idx].studentCourseAttendance.total.present += 1;
                            if (a.attendanceState == 'Absent') course.studentMarkingPeriodAverages[idx].studentCourseAttendance.total.absent += 1;
                            if (a.attendanceState == 'Late') course.studentMarkingPeriodAverages[idx].studentCourseAttendance.total.late += 1;
                            if (a.attendanceState == 'Excused') course.studentMarkingPeriodAverages[idx].studentCourseAttendance.total.excused += 1;
                        }
                    });
                    return true;
                }
                return false;
            });

            // set attendance totals and percentages for each marking period
            course.studentMarkingPeriodAverages.forEach((avg, idx) => {
                const courseAttendance = course.studentMarkingPeriodAverages[idx].studentCourseAttendance;
                const totalPresent = courseAttendance.total.present + courseAttendance.total.late + courseAttendance.total.excused;
                const { absent, late } = courseAttendance.total;
                const { present, excused } = courseAttendance.total;
                const totalDays = present + late + excused + absent;

                courseAttendance.percent.present = totalDays == 0 ? 0 : Math.round((totalPresent / totalDays) * 100);
                courseAttendance.percent.absent = totalDays == 0 ? 0 : Math.round((absent / totalDays) * 100);
                courseAttendance.percent.late = totalDays == 0 ? 0 : Math.round((late / totalDays) * 100);
            });

            // set overall attendance totals and percentages
            const present = studentCourseAttendance.total.present + studentCourseAttendance.total.late + studentCourseAttendance.total.excused;
            const { absent, late } = studentCourseAttendance.total;
            const totalDays = studentCourseAttendance.records.length;
            studentCourseAttendance.percent.present = totalDays == 0 ? 0 : Math.round((present / totalDays) * 100);
            studentCourseAttendance.percent.absent = totalDays == 0 ? 0 : Math.round((absent / totalDays) * 100);
            studentCourseAttendance.percent.late = totalDays == 0 ? 0 : Math.round((late / totalDays) * 100);

            course.studentCourseAttendance = studentCourseAttendance;
        }

        return course;
    });
    return mutableCourses;
}

// Function to get reduced course objects for performance in large lists
function getReducedCourses(database, limitBy = {
    schoolStaffId: null,
    extCourseSectionId: null,
    courseSectionId: null,
    studentEnrollmentId: null,
    session: null,
}) {
    const {
        courseSectionTeachers,
        teachers,
        courseSections,
        courseSectionStudents,
        googleCourseCourseSections,
    } = database;

    const mutableCourses = courseSections.filter((cs) => {
        if (limitBy.courseSectionId) {
            return cs.courseSectionId == limitBy.courseSectionId;
        }
        if (limitBy.extCourseSectionId) {
            return cs.extCourseSectionId == limitBy.extCourseSectionId;
        }
        if (limitBy.studentEnrollmentId) {
            return Boolean(courseSectionStudents
                .find((css) => css.courseSectionId == cs.courseSectionId
                    && css.studentEnrollmentId == limitBy.studentEnrollmentId));
        }

        if (limitBy.schoolStaffId) {
            return Boolean(courseSectionTeachers
                .find((cst) => cst.courseSectionId == cs.courseSectionId
                    && cst.schoolStaffId == limitBy.schoolStaffId));
        }
        return true;
    })
    .map((cs) => { // formats results
        const course = { ...cs };
        const { courseSectionId } = cs;

        // add googleCourseId
        const exists = googleCourseCourseSections.find((g) => g.courseSectionId == courseSectionId);
        course.googleCourseId = exists ? exists.googleCourseId : null;

        // add teachers
        const courseTeachers = courseSectionTeachers.filter((cst) => cst.courseSectionId == courseSectionId && !cst.deleted);

        course.teachers = teachers
            .filter((t) => courseTeachers.map((cst) => cst.schoolStaffId).includes(t.schoolStaffId))
            .map((t) => {
                const teacher = { ...t };
                teacher.courseSectionTotals = courseTeachers.find((cst) => cst.schoolStaffId == t.schoolStaffId)?.totals || null;
                return teacher;
            });

        // adds search
        course.search = `${cs.extCourseSectionId} ${cs.name} ${cs.meetingName}`;
        course.teachers.forEach((t) => {
            course.search = `${course.search} ${t.lastName} ${t.firstName}`;
        });
        course.search = `${course.search}`.toLowerCase();

        // add course meetings
        const { courseMeetings } = course;
        const today = moment();
        course.meetingName = 'Not meeting';
        course.nextMeeting = {
            day: '',
            period: null,
            room: null,
        };
        if (courseMeetings) {
            // calculates next meeting
            const todaysMeeting = courseMeetings.find((m) => m.day == today.format('dddd'));

            if (todaysMeeting) {
                course.periodSort = `000 ${todaysMeeting.period.padStart(3, '0')}`;
                course.meetingName = `Pd ${todaysMeeting.period} • ${todaysMeeting.room}`;
                course.search = `${course.search} ${course.meetingName}`.toLowerCase();
                course.nextMeeting = {
                    day: todaysMeeting.day,
                    period: todaysMeeting.period,
                    room: todaysMeeting.room,
                };
            }

            // loop the next 7 days until i find the next meeting time
            if (course.nextMeeting.period == null) {
                let i = 1;
                for (i = 1; i <= 7; i += 1) {
                    today.add(1, 'day');
                    const nextMeeting = courseMeetings.find((m) => m.day == today.format('dddd'));
                    if (nextMeeting) {
                        course.periodSort = `${i.toString().padStart(3, '0')} ${nextMeeting.period.padStart(3, '0')} ${course.extCourseSectionId}`;
                        course.meetingName = `${today.format('dd')} Pd ${nextMeeting.period}`;
                        course.search = `${course.search} ${course.meetingName}`.toLowerCase();
                        course.nextMeeting = {
                            day: nextMeeting.day,
                            period: nextMeeting.period,
                            room: nextMeeting.room,
                        };
                        break;
                    }
                }
            }
        }

        return course;
    });
    return mutableCourses;
}

function getCourseFromRoute($route, $store) {
    const extCourseSectionId = $route.params.extCourseSectionId || null;
    if (!extCourseSectionId) return null;
    const { state } = $store;
    const { database } = state;
    const teacher = getTeacherFromRoute($route, $store);
    const session = getSessionUser(state);
    const courseFilter = teacher
        ? new CourseFilter({
            extCourseSectionId,
            schoolStaffId: teacher.schoolStaffId,
            session,
        }) : new CourseFilter({
            extCourseSectionId,
            session,
        });

    const [firstCourse] = getCourses(database, courseFilter);
    return firstCourse;
}

function formatCourseForPortfolioUser(teacher, course) {
    const newCourse = { ...course };
    newCourse.courseSectionIds = [course.courseSectionId];

    if (!teacher) {
        window.console.log('No teacher found to format course');
        return newCourse;
    }

    const { schoolStaffId } = teacher;

    newCourse.courseSectionSchoolStaffId = schoolStaffId;

    const teacherMetadata = course.courseSectionTeacherMetadata.find((m) => m.schoolStaffId == schoolStaffId && m.courseSectionId == newCourse.courseSectionId);

    if (!teacherMetadata) {
        // window.console.log('No teacherMetadata saved to format course');
    } else {
        newCourse.courseSectionTitle = teacherMetadata.courseSectionTitle;
        newCourse.courseSectionColor = teacherMetadata.courseSectionColor;
        newCourse.courseSectionHexColor = cssVars[teacherMetadata.courseSectionColor];
        newCourse.courseSectionIcon = teacherMetadata.courseSectionIcon;
        newCourse.courseSectionAbbreviation = teacherMetadata.courseSectionAbbreviation;
        newCourse.hideFromNav = teacherMetadata.hideFromNav;
    }

    // populate combo courses
    const comboCourses = course.courseSectionCombos.filter((m) => m.schoolStaffId == schoolStaffId);
    newCourse.comboCourses = comboCourses.map((cc) => (
        { courseSectionId: cc.comboCourseSectionId }
    ));
    newCourse.comboCourses.forEach((cc) => {
        newCourse.courseSectionIds.push(cc.courseSectionId);
    });

    return newCourse;
}

function getGradeTemplateForCourseSectionId(database, courseSectionId) {
    const { courseSectionGradeTemplates, gradeTemplates, courseSections } = database;
    const courseSection = courseSections.find((cs) => cs.courseSectionId == courseSectionId) || null;
    const { schoolId } = courseSection;
    const schoolTemplate = getSchoolTemplate(database, schoolId);
    const exists = courseSectionGradeTemplates.find((t) => t.courseSectionId == courseSectionId) || null;
    const courseTemplate = gradeTemplates.find((t) => exists && t.gradeTemplateId == exists.gradeTemplateId) || null;
    let template = courseTemplate || schoolTemplate;
    template = { ...template, categories: template.categories.filter((c) => !c.deleted) };
    return template;
}

function getSchoolTemplate(database, schoolId) {
    const { gradeTemplates } = database;
    const schoolTemplate = gradeTemplates.find((t) => t.schoolDefaultSchoolId == schoolId) || null;
    if (!schoolTemplate) console.error('Cannot find school default template!');
    return schoolTemplate || null;
}

export {
    courseMixins as default,
    getCourses,
    getCourseFromRoute,
    getCoursesForStudentEnrollmentId,
    CourseFilter,
};
