module.exports.greetings = {
    hello: 'Hola',
    hi: 'Hola',
    welcome: 'Bienvenidos',
};

module.exports.labels = {
    account: 'Cuenta',
    action: 'Acción',
    actions: 'Accións',
    after: 'Después',
    before: 'Antes',
    cancel: 'Cancelar',
    close: 'Cerca',
    closed: 'Cerrado',
    comment: 'Comentario',
    configure: 'Configurar',
    confirm: 'Confirmar',
    continue: 'Continuar',
    course: 'Corso',
    created: 'Creado',
    daily: 'A Diario',
    date: 'Fecha',
    delete: 'Borrar',
    download: 'Descargar',
    editLink: 'Editar enlace',
    email: 'Correo electrónico',
    google: 'Google',
    googleClassroom: 'Google Classroom',
    id: 'ID',
    link: 'Enlace',
    linkAccount: 'Vincular cuenta',
    markingPeriod: 'Período de calificaciones',
    no: 'No',
    okay: 'Okey',
    open: 'Abierto',
    openInGoogle: 'Abierto en Google',
    password: 'Contraseña',
    pdf: 'PDF',
    percentage: 'Porcentaje',
    save: 'Ahorrar',
    somethingWentWrong: '¡Algo salió mal!',
    state: 'Expresar',
    status: 'Estado',
    teacherProfile: 'Perfil del maestro',
    term: 'Término',
    time: 'Hora',
    type: 'Escribe',
    user: 'Usuario',
    yes: 'Sí',
};

module.exports.missingInformation = {
    incorrectAverages: '¿Promedios incorrectos? Haga clic para volver a calcular los promedios.',
    recalculateAverages: 'Recalcular Promedios',
    refresh: 'Actualizar {type}',
    statement: '¿Falta información? Haga clic para importar el último {type} de Google.',
    types: {
        activity: 'Actividad',
        assignments: 'Tareas',
        courses: 'Cursos',
        courseList: 'Lista de cursos',
        grades: 'Los Grados',
        students: 'Estudiantes',
        teacherList: 'Lista de profesores',
        teachers: 'Maestros',
    },
};

module.exports.titles = {
    assistantPrincipal: 'Subdirector',
    principal: 'Principal',
    staff: 'Personal',
    teacher: 'Profesor',
    syncGrades: 'SyncGrades',
};

module.exports.units = {
    day: 'día | días',
};
