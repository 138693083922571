/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import commentsForEventId from './commentsForEventId';
import addAnecdotalsComment from './addAnecdotalsComment';
import listCategories from './listCategories';
import addCategory from './addCategory';
import editCategory from './editCategory';
import deleteCategory from './deleteCategory';
import listAnecdotals from './listAnecdotals';
import getByAnecdotalEventId from './getByAnecdotalEventId';
import create from './create';
import edit from './edit';
import markAsResolved from './markAsResolved';

export {
    commentsForEventId,
    addAnecdotalsComment,
    listCategories,
    addCategory,
    editCategory,
    deleteCategory,
    listAnecdotals,
    getByAnecdotalEventId,
    create,
    edit,
    markAsResolved,
 };
