/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getRDSData from './getRDSData';
import startRDSCluster from './startRDSCluster';
import stopRDSCluster from './stopRDSCluster';
import setRDSCapacity from './setRDSCapacity';

export {
    getRDSData,
    startRDSCluster,
    stopRDSCluster,
    setRDSCapacity,
 };
