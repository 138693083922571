function GoogleTeacher(props) {
	this.googleTeacherId = props.googleTeacherId;
	this.emailAddress = props.emailAddress;
	this.googleUserId = props.googleUserId;
	this.firstName = props.firstName;
	this.lastName = props.lastName;

	this.userId = props.userId;
	this.userName = props.userName;
	this.googleImageUrl = props.googleImageUrl;
	this.unlinkedGoogleEmail = props.unlinkedGoogleEmail;
	this.schoolStaffId = props.schoolStaffId;

}

module.exports = GoogleTeacher;