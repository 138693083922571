/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import getCourseWorkGradesByStaffId from './getCourseWorkGradesByStaffId';
import getCourseWorkGradesByStudentEnrollmentId from './getCourseWorkGradesByStudentEnrollmentId';
import saveCourseWorkGrades from './saveCourseWorkGrades';

export {
    getCourseWorkGradesByStaffId,
    getCourseWorkGradesByStudentEnrollmentId,
    saveCourseWorkGrades,
 };
