import validate from 'validate.js';
import numeral from 'numeral';

const utilMixins = {
    methods: {
        $_utilMixins_format_grade_level(gradeLevel) {
            const numeric = isNumber(gradeLevel);
            if (!numeric) return gradeLevel;
            return `${numeral(gradeLevel).format('0o')} Grade`;
        },
        $_utilMixins_format_email(email) {
            const parts = email.split('@');
            let [first, second] = parts;
            first = first.replace(/\./g, '<wbr>.');
            first = first.replace(/-/g, '<wbr>-');
            second = second.replace(/\./g, '<wbr>.');
            second = second.replace(/-/g, '<wbr>-');
            return `${first}<wbr>@${second}`;
        },

        $_utilMixins_format_ext_student_id(extStudentId) {
            if (extStudentId.length == 9) {
                return `${extStudentId.substring(0, 3)}-${extStudentId.substring(3, 6)}-${extStudentId.substring(6, 9)}`;
            }
            return extStudentId;
        },
        $_utilMixins_decimal_to_percent(v, showSymbol = true, decimals = 1) {
            return decimalToPercent(v, showSymbol, decimals);
        },
    },
};

function decimalToPercent(v, showSymbol = true, decimals = 1) {
    let value = v;
    if (value === 0) return 0;
    if (!value) return '-';
    value *= 100;
    // @ts-ignore
    return `${Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`)}${showSymbol ? '%' : ''}`;
}

function isNumber(n) {
    const validNumberError = validate.single(n, {
        numericality: {
            presence: false,
            greaterThanOrEqualTo: 0,
        },
    });
    return !validNumberError;
}

function round(value, decimals) {
    // @ts-ignore
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
}

export {
    utilMixins as default,
    decimalToPercent,
    round,
};
