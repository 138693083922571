/**
    This file is auto generated,
    running build script `npm run boot-scripts`
    will recreate this file based on server-side routes.
*/

import listCourseSections from './listCourseSections';
import setSyncStateForCourse from './setSyncStateForCourse';
import unlink from './unlink';
import listCourseSectionTeachers from './listCourseSectionTeachers';
import getAllCourseSectionStudentsForEnrollment from './getAllCourseSectionStudentsForEnrollment';
import assignTemplate from './assignTemplate';
import createCourseSection from './createCourseSection';
import transferCourseSectionData from './transferCourseSectionData';
import restoreCourseSection from './restoreCourseSection';
import deleteCourseSection from './deleteCourseSection';
import addTeacher from './addTeacher';
import removeTeacher from './removeTeacher';

export {
    listCourseSections,
    setSyncStateForCourse,
    unlink,
    listCourseSectionTeachers,
    getAllCourseSectionStudentsForEnrollment,
    assignTemplate,
    createCourseSection,
    transferCourseSectionData,
    restoreCourseSection,
    deleteCourseSection,
    addTeacher,
    removeTeacher,
 };
