module.exports = {
    googleClassroomAccount: 'Google Classroom Account',
    clickToSetup: 'Click to setup',
    navigationInstructions: 'Click the sidebar navigation in the upper left to navigate the site. Click your name in the upper right to access support features.',
    termDates: 'From {start} to {end}',
    termCountdown: 'School term ends in {count} months',
    subheader: {
        title: 'Home',
        welcome: 'Welcome',
    },
};
