import Vue from 'vue';
import async from 'async';
import VueRouter from 'vue-router';
import routes from './routes/index';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    // this saves and replays the window scroll position of the router-view
    scrollBehavior(to, from, savedPosition) {
        return new Promise((resolve) => {
            setTimeout(() => { // wait for page load
                if (savedPosition) {
                    const position = savedPosition;
                    return resolve(position);
                }
                return resolve({
                    selector: null,
                    offset: { x: 0, y: 0 },
                    behavior: 'smooth',
                });
            }, 50);
        });
    },
});

router.afterEach((to, from) => {
    window.syncGrades.history.push({
        name: to.name,
        fullPath: to.fullPath,
        from: from.name,
    });
    window.syncGrades.log(`${to.name} \`${to.fullPath}\``, 'info', 'navigation');
});

router.beforeResolve(async (to, from, next) => {
    async.doUntil(
        function iter(callback) {
            setTimeout(callback, 200);
        },
        function test(cb) {
            if (to.meta.public || to.meta.landing) return cb(null, true);
            const loaded = store.getters.IS_DB_LOADED;
            cb(null, loaded);
        },
        function finished() {
            const roles = to.meta.roles || [];
            const userRole = store.getters.GET_USER_SCHOOL_ROLE;
            if (!userRole) return next();
            const [firstRole] = roles;
            const authorized = roles.includes(userRole) || (firstRole && firstRole === 'All');
            if (!authorized) {
                next({ name: 'PageNotFound' });
            } else {
                next();
            }
        },
    );
});

// silence nav duplicated error
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) { return originalPush.call(this, location, onResolve, onReject); }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

export default router;
