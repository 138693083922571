const BiliteracySealDashboardHome = () => import( '../../rootComponents/BiliteracySealDashboardHome.vue');
const BiliteracySealAnalysis = () => import( '../../rootComponents/BiliteracySealAnalysis.vue');
const BiliteracySealBreakdown = () => import( '../../rootComponents/BiliteracySealBreakdown.vue');
const BiliteracySealConstituents = () => import( '../../rootComponents/BiliteracySealConstituents.vue');
const BiliteracySealProfile = () => import( '../../rootComponents/BiliteracySealProfile.vue');

const dataAnalyticsRoutes = [
    {
        name: 'BiliteracySealDashboardHome',
        path: '/nysed/biliteracy-seal',
        component: BiliteracySealDashboardHome,
        meta: {
            subHeader: true,
            title: 'Dashboard',
            nysed: true,
        },
    },
    {
        name: 'BiliteracySealConstituents',
        path: '/nysed/biliteracy-seal/constituents/:filter',
        component: BiliteracySealConstituents,
        meta: {
            subHeader: true,
            title: 'Constituents',
            nysed: true,
        },
    },
    {
        name: 'BiliteracySealAnalysis',
        path: '/nysed/biliteracy-seal/analytics',
        component: BiliteracySealAnalysis,
        meta: {
            subHeader: true,
            title: 'Analytics',
            nysed: true,
        },
    },
    {
        name: 'BiliteracySealBreakdown',
        path: '/nysed/biliteracy-seal/breakdown/:filter',
        component: BiliteracySealBreakdown,
        meta: {
            subHeader: true,
            title: 'Breakdown',
            nysed: true,
        },
    },
    {
        name: 'BiliteracySealRegionalProfile',
        path: '/nysed/biliteracy-seal/regions/:id',
        component: BiliteracySealProfile,
        meta: {
            subHeader: true,
            title: 'Regional Profile',
            nysed: true,
        },
    },
    {
        name: 'BiliteracySealBigFiveProfile',
        path: '/nysed/biliteracy-seal/big-five/:id',
        component: BiliteracySealProfile,
        meta: {
            subHeader: true,
            title: 'Big 5 Profile',
            nysed: true,
        },
    },
    {
        name: 'BiliteracySealDistrictProfile',
        path: '/nysed/biliteracy-seal/districts/:id',
        component: BiliteracySealProfile,
        meta: {
            subHeader: true,
            title: 'District Profile',
            nysed: true,
        },
    },
    {
        name: 'BiliteracySealSchoolProfile',
        path: '/nysed/biliteracy-seal/schools/:id',
        component: BiliteracySealProfile,
        meta: {
            subHeader: true,
            title: 'School Profile',
            nysed: true,
        },
    },
];

export default dataAnalyticsRoutes;

