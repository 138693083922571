const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.upsertUserRoles = function upsertUserRoles(user, userRoles, callback) {
    request
        .post(`${syncGradesHost}/api/userRoles`)
        .set('accept', 'json')
        .send({ userRoles })
        .end((err, res) => {
            if (err) return callback(prettyError(err, res));
            callback(err, res.body);
        });
};
