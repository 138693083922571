const request = require('./requestWrapper');
const { prettyError } = require('../utils');

const syncGradesHost = '';

module.exports.getSchools = function getSchools(callback) {
    request
        .get(`${syncGradesHost}/api/schools`)
        .set('accept', 'json')
        .end((err, res) => {
            if (err) {
                return callback(prettyError(err, res));
            }
            callback(err, res.body);
        });
};

module.exports.createSchool = function createSchool(school, schoolTerm, callback) {
    request
        .post(`${syncGradesHost}/api/schools`)
        .send({ school, schoolTerm })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) {
                return callback(prettyError(err, res));
            }
            callback(err, res.body);
        });
};

module.exports.calculateAverages = function calculateAverages(schoolTermId, callback) {
    request
        .patch(`${syncGradesHost}/api/averages`)
        .send({ schoolTermId })
        .set('accept', 'json')
        .end((err, res) => {
            if (err) {
                return callback(prettyError(err, res));
            }
            callback(err, res.body);
        });
};

module.exports.invitationRequest = function (body, callback) {
    request
        .post(`${syncGradesHost}/api/invitations/request`)
        .send(body)
        .end((err, res) => {
            callback(err);
        });
};

module.exports.listSubscriptions = function (schoolId, callback) {
    request.get(`${syncGradesHost}/api/schools/${schoolId}/subscriptions`).end((err, res) => {
        if (err) {
            return callback(prettyError(err, res));
        }
        callback(err, res.body);
    });
};

module.exports.extendSubscription = function (externalSchoolId, schoolYear, callback) {
    request
        .post(`${syncGradesHost}/api/schools/${externalSchoolId}/subscriptions`)
        .send({ schoolYear })
        .end((err, res) => {
            callback(err);
        });
};

module.exports.deleteSubscription = function (externalSchoolId, schoolYear, callback) {
    request
        .delete(`${syncGradesHost}/api/schools/${externalSchoolId}/subscriptions`)
        .send({ schoolYear })
        .end((err, res) => {
            callback(err);
        });
};

module.exports.createInvitation = function (invitations, callback) {
    request
        .post(`${syncGradesHost}/api/invitations`)
        .send({ invitations })
        .end((err, res) => {
            if (err) return callback(err);
            callback(err, res.body);
        });
};
