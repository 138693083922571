module.exports = {
    changePassword: 'Change your password',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    retypePassword: 'Re-type Password',
    passwordRequirements: {
        title: 'These are the password strength requirements for the SyncGrades site',
        length: 'Password must be at least 15 characters in length',
        uppercase: 'Password must contain at least 3 uppercase letter',
        digits: 'Password must contain at least 3 digit or number',
        special: 'Password must contain at least 3 special character',
        dictionary: 'Password cannot contain dictionary words',
    },
    impersonate: 'Impersonate',
    switchToUser: 'Switch to another user',
    myStudents: 'My Students',
    selectStudent: 'Select a different student',
    help: 'Need help?',
    wiki: 'Wiki',
    userDocs: 'User Documentation',
    feedback: 'Feedback',
    requestFeature: 'Request features',
    selectUser: 'Select a user',
    chooseUser: 'Choose user',
    chooseToImpersonate: 'Choose a user to impersonate',
    selectTerm: 'Select a school term',
    current: 'Current',
};
