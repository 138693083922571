const CourseAssignments = () => import( '../../rootComponents/CourseAssignments.vue');
const CourseAttendance = () => import( '../../rootComponents/CourseAttendance.vue');
const CourseAverages = () => import( '../../rootComponents/CourseAverages.vue');
const LocalCourseAssignmentCreate = () => import( '../../rootComponents/LocalCourseAssignmentCreate.vue');
const LocalCourseAssignmentEdit = () => import( '../../rootComponents/LocalCourseAssignmentEdit.vue');
const GoogleCourseAssignmentCreate = () => import( '../../rootComponents/GoogleCourseAssignment/GoogleCourseAssignmentCreate.vue');
const GoogleCourseAssignmentEdit = () => import( '../../rootComponents/GoogleCourseAssignment/GoogleCourseAssignmentEdit.vue');
const CourseGradebook = () => import( '../../rootComponents/CourseGradebook.vue');
const CourseGradeTemplate = () => import( '../../rootComponents/CourseGradeTemplate.vue');
const CourseInfo = () => import( '../../rootComponents/CourseInfo.vue');
const CourseReports = () => import( '../../rootComponents/CourseReports.vue');
const CourseReportCards = () => import( '../../rootComponents/CourseReportCards.vue');
const CourseReportCardGrades = () => import( '../../rootComponents/CourseReportCardGrades.vue');
const CourseRosters = () => import( '../../rootComponents/CourseRosters.vue');
const CourseGoogleLink = () => import( '../../rootComponents/CourseGoogleLink.vue');

const children = [{
    path: '', // root
    component: CourseInfo,
    meta: { subHeader: false },
}, {
    name: 'CourseInfo',
    path: 'info', // root
    component: CourseInfo,
    meta: {
        subHeader: false,
        title: 'Course Information',
        icon: 'info',
        teacherRouteName: 'TeacherCourseInfo', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseAttendance',
    path: 'attendance',
    component: CourseAttendance,
    meta: {
        subHeader: true,
        title: 'Attendance',
        icon: 'attendance',
        teacherRouteName: 'TeacherCourseAttendance', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseAssignments',
    path: 'assignments',
    component: CourseAssignments,
    meta: {
        subHeader: true,
        title: 'Assignments',
        icon: 'assignments',
        teacherRouteName: 'TeacherCourseAssignments', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseGradebook',
    path: 'gradebook',
    component: CourseGradebook,
    meta: {
        subHeader: true,
        title: 'Gradebook',
        icon: 'gradebook',
        teacherRouteName: 'TeacherCourseGradebook', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseAverages',
    path: 'averages',
    component: CourseAverages,
    meta: {
        subHeader: true,
        title: 'Averages',
        icon: 'averages',
        teacherRouteName: 'TeacherCourseAverages', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseRosters',
    path: 'rosters',
    component: CourseRosters,
    meta: {
        subHeader: true,
        title: 'Rosters',
        icon: 'students',
        teacherRouteName: 'TeacherCourseRosters', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseGradeTemplate',
    path: 'grade-template',
    component: CourseGradeTemplate,
    meta: {
        subHeader: true,
        title: 'Grade Template',
        icon: 'grade-template',
        teacherRouteName: 'TeacherCourseGradeTemplate', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseReports',
    path: 'reports',
    component: CourseReports,
    meta: {
        subHeader: true,
        title: 'Reports',
        icon: 'progressReports',
        teacherRouteName: 'TeacherCourseReports', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseReportCards',
    path: 'report-cards',
    component: CourseReportCards,
    meta: {
        subHeader: true,
        title: 'Report Cards',
        icon: 'reportCards',
        teacherRouteName: 'TeacherCourseReportCards', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    name: 'CourseReportCardGrades',
    path: 'report-cards/:schoolTermMarkingPeriodId/entry',
    component: CourseReportCardGrades,
    meta: {
        subHeader: true,
        // title: 'Report Card Grades',
        icon: 'reportCards',
        teacherRouteName: 'TeacherCourseReportCardGrades', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, { // Link course to google
    name: 'CourseGoogleLink',
    path: 'google-link',
    component: CourseGoogleLink,
    meta: {
        subHeader: false,
        teacherRouteName: 'TeacherCourseGoogleLink', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
},

// Assignment Crud
{
    // Create local assignment
    name: 'CourseAssignmentCreate',
    path: 'assignments/create',
    component: LocalCourseAssignmentCreate,
    meta: {
        subHeader: false,
        teacherRouteName: 'TeacherCourseAssignmentCreate', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    // Edit local assignment
    name: 'LocalCourseAssignmentEdit',
    path: 'assignments/:courseWorkId/edit-local-assignment',
    component: LocalCourseAssignmentEdit,
    meta: {
        subHeader: false,
        teacherRouteName: 'TeacherLocalCourseAssignmentEdit', // used for alias
    },
}, {
    // Create google assignment
    name: 'GoogleCourseAssignmentCreate',
    path: 'assignments/create/google',
    component: GoogleCourseAssignmentCreate,
    meta: {
        subHeader: false,
        teacherRouteName: 'TeacherGoogleCourseAssignmentCreate', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
}, {
    // Edit google assignment
    name: 'GoogleCourseAssignmentEdit',
    path: 'google-course-work/:googleCourseWorkId/edit-google-assignment',
    component: GoogleCourseAssignmentEdit,
    meta: {
        subHeader: false,
        teacherRouteName: 'TeacherGoogleCourseAssignmentEdit', // used for alias
        roles: ['School Admin', 'Staff', 'Teacher'],
    },
},

];

export default children;
