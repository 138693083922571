export default {
    clearLoginUser(s) {
        const state = s;
        state.login.redirect = null;
        state.login.emailAddress = null;
    },
    setLoginUserLoading(s, loading) {
        const state = s;
        state.login.loading = loading;
    },
    setLoginUserRedirect(s, redirect) {
        const state = s;
        state.login.redirect = redirect;
    },
    setLoginUserEmailAddress(s, emailAddress) {
        const state = s;
        state.login.emailAddress = emailAddress;
    },
    setLoginUser(s, resp) {
        const state = s;
        state.login.redirect = resp?.redirect || null;
        state.login.emailAddress = resp?.emailAddress || null;
    },
    setLoginPanel(s, panel) {
        const state = s;
        state.login.panel = panel;
    },
    setLoginError(s, props) {
        const state = s;
        state.login.error = props.message;
        state.login.errorVariant = props.variant;
        state.login.errorIcon = props.icon;
    },
};
